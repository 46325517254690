import {Component, inject, OnDestroy} from '@angular/core';
import {EditInfoComponent} from "../../../shared/components/policy-data/edit-info/edit-info.component";
import {JsonPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {FormDateFieldComponent} from "../../../shared/components/form-date-field/form-date-field.component";
import {FormPolicyAlertComponent} from "../../offers/form-policy-date/form-policy-alert/form-policy-alert.component";
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {ProlongationFormService} from "../../../shared/services/prolongationForm.service";
import {AutocompleteComponent} from "../../../shared/components/autocomplete/autocomplete.component";
import {AppService} from "../../../shared/services/app.service";
import {FormTextFieldComponent} from 'src/app/shared/components/form-text-field/form-text-field.component';
import {ProlongationService} from 'src/app/shared/services/prolongation.service';
import {NavigationService} from 'src/app/shared/services/navigation.service';
import {ROUTES} from 'src/app/shared/enums/routes.enum';
import {concatMap, delay, EMPTY, filter, mergeMap, of, tap} from "rxjs";
import {CacheService} from "../../../shared/services/cache.service";
import {FormService} from "../../../shared/services/form.service";
import {finalize} from "rxjs/operators";
import {OsagoService} from "../../../shared/services/osago.service";
import {APP_STATUS} from "../../../shared/enums/app-status.enum";
import moment from "moment-mini";
import {ValidationService} from "../../../shared/services/validation.service";
import {DaData} from "../../../shared/enums/da-data.enum";
import {ModalService} from "../../../shared/services/modal.service";
import {ErrorMessageComponent} from "../../../shared/components/error-message/error-message.component";
import {BsModalService, BsModalRef, ModalOptions} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-policy-number-form',
  standalone: true,
  imports: [
    EditInfoComponent,
    NgIf,
    NgOptimizedImage,
    FormDateFieldComponent,
    FormPolicyAlertComponent,
    ReactiveFormsModule,
    AutocompleteComponent,
    FormTextFieldComponent,
    JsonPipe
  ],
  providers: [ModalService, BsModalService],
  templateUrl: './policy-number-form.component.html',
  styleUrl: './policy-number-form.component.scss'
})
export class PolicyNumberFormComponent implements OnDestroy {

  public prolongationFormService = inject(ProlongationFormService);
  public appService = inject(AppService);
  private prolongationService = inject(ProlongationService);
  private navigationService = inject(NavigationService);
  private cacheService = inject(CacheService);
  public formService = inject(FormService);
  private osagoService = inject(OsagoService);
  private validationService = inject(ValidationService);
  public modalService = inject(BsModalService);

  // Форма "По номеру полиса"
  public formPolicyNumber = this.prolongationFormService.form.get('policyNumber') as any;

  // Флаг, идет загрузка списка
  public isLoading = false;
  // Флаг, показывать кнопку покупки нового полиса
  public isShowBtnNewPolicy = false;
  // Флаг, показывать кнопку покупки нового полиса
  public isInvalidForm = false;

  // ***********************************************************************************
  // Уничтожение
  public ngOnDestroy(): void {
    this.formService.drivers.controls.forEach((control) => {
      const driverPassportDatePropertyControl = this.formService.propertyControls['driverPassportDate'];
      this.validationService.setControlValidation(driverPassportDatePropertyControl, control.get('driverPassportDate') as FormControl);
      control.get('driverPassportDate')?.updateValueAndValidity()
    });
    this.validationService.setControlValidation(this.formService.propertyControls['ownerPassportDate'], this.formService.owner.get('ownerPassportDate') as FormControl);
    this.validationService.setControlValidation(this.formService.propertyControls['insurerPassportDate'], this.formService.insurer.get('insurerPassportDate') as FormControl);
  }
  // ***********************************************************************************

  public onSubmit(): void {
    if (this.formPolicyNumber.invalid) {
      this.formPolicyNumber.markAllAsTouched();
      return;
    }

    this.isLoading = true;
    this.isInvalidForm = false;
    this.isShowBtnNewPolicy = false;

    this.osagoService.getAppInfo()
      .pipe(
        concatMap((res: any) => {
          if (res.result === true) {
            if (res.value.applicationData.applicationStatus === APP_STATUS.WidgetDisplayed) {
              return this.prolongationService.getProlongationPolicy();
            } else {
              this.osagoService.offers = [];
              return this.appService.createApplication()
                .pipe(
                  concatMap(() => this.prolongationService.getProlongationPolicy())
                );
            }
          } else {
            this.isLoading = false;
            this.modalService.show(ErrorMessageComponent, {
              initialState: {
                title: res.error.type,
                message: res.error.message
              }
            } as ModalOptions);
            return of(null); // Возвращаем null для пропуска следующего блока
          }
        }),
        filter((res: any) => res !== null), // Пропускаем если res === null
        concatMap((response: any) => {
          if (response.result === true) {
            const urlParams = this.appService.getUrlParams();
            return this.cacheService.getOsagoCacheByApplicationId(urlParams)
              .pipe(
                delay(2000),
                concatMap(() => this.appService.getCarModels()),
                tap(() => {
                  this.formService.drivers.controls.forEach((control) => {
                    control.get('driverPassportDate')?.setValidators(null);
                    control.get('driverPassportDate')?.updateValueAndValidity();
                  });
                  this.formService.owner.get('ownerPassportDate')?.setValidators(null);
                  this.formService.owner.get('ownerPassportDate')?.updateValueAndValidity();
                  this.formService.insurer.get('insurerPassportDate')?.setValidators(null);
                  this.formService.insurer.get('insurerPassportDate')?.updateValueAndValidity();
                  this.formService.form.updateValueAndValidity();
                }),
                delay(1000),
                // Дожидаемся завершения всех операций внутри getOsagoCacheByApplicationId
                mergeMap(() => {
                  // Если форма недействительна, выводим ошибку
                  if (this.formService.form.invalid) {
                    this.isLoading = false;
                    this.modalService.show(ErrorMessageComponent, {
                      initialState: {
                        title: 'Обновите данные',
                        message: 'Нашли Ваш полис, но анкетные данные нужно обновить. Пожалуйста, нажмите на кнопку "Обновите данные".',
                        isInvalidForm: true
                      }
                    } as ModalOptions);
                    return of(null);
                  }
                  return of(EMPTY);
                })
              );
          } else {
            this.modalService.show(ErrorMessageComponent, {
              initialState: {
                title: response.error.type,
                message: response.error.message,
                isShowBtnNewPolicy: response.error.type !== 'Дата рождения указана неверно'
              }
            } as ModalOptions);
            return of(null);
          }
        }),
        filter((res: any) => res !== null),
        concatMap(() => {

          const policyStartDate = this.formService.form.get('policyStartDate')?.value;
          const insurerType = this.formPolicyNumber.get('PreviousPolicyInsurerType')?.value;

          if (policyStartDate) {
            // Преобразуем дату из формата DD.MM.YYYY в объект moment
            const policyDate = moment(policyStartDate, 'DD.MM.YYYY');
            const currentDate = moment().startOf('day'); // Текущая дата без времени

            // Проверяем, если policyStartDate меньше или равен текущей дате
            if (policyDate.isSameOrBefore(currentDate)) {
              // Устанавливаем policyStartDate на следующий день
              const newPolicyStartDate = currentDate.add(1, 'day').format('DD.MM.YYYY');
              this.formService.form.get('policyStartDate')?.setValue(newPolicyStartDate);
              return this.appService.createApplication().pipe(
                concatMap(() => this.osagoService.sendOsagoApplication()),
                concatMap(() => this.osagoService.sendToInsurersGetOffers(insurerType))
              );
            } else {
              return this.osagoService.sendToInsurersGetOffers(insurerType);
            }
          } else {
            return this.osagoService.sendToInsurersGetOffers(insurerType);
          }
        }),
        filter(() => {
          const offers = this.osagoService.offers;
          if (offers.length === 0) {
            this.isLoading = false;
            this.modalService.show(ErrorMessageComponent, {
              initialState: {
                title: 'К сожалению, предложений нет',
                message: 'Мы столкнулись со сложностью при продлении данного полиса онлайн. Вы можете оформить новый полис или обратиться за помощью в чат поддержки.',
                isShowBtnNewPolicy: true,
                isShowBtnNewChatMessage: true,
                isShowContinueButton: false
              }
            } as ModalOptions);
            return false;
          } else if (offers.length > 0) {
            const selectedOffer = offers.find(offer => offer.insurerType === 'Alfa');
            if (selectedOffer) {
              this.osagoService.selectedOffer = selectedOffer;
              return true;
            } else {
              return false;
            }
          } else {
            return false;
          }

        }), // Пропускаем если res === null
        concatMap(() => this.osagoService.selectOffer()),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: () => {
          setTimeout(() => {
            this.navigationService.navigate('create-policy', { fromFastProlongation: true });
          }, 1000);
        },
        error: (err) => {
          this.isLoading = false;
          this.modalService.show(ErrorMessageComponent, {
            initialState: {
              title: 'Ошибка!',
              message: err.message,
            }
          } as ModalOptions);
        }
      });
  }

  // Переход на главную
  public navigateToMainPage(): void {
    this.navigationService.navigate(ROUTES.MainPage, { fromFastProlongation: true });
  }

  // Переход на форму
  public navigateToFormPage(): void {
    this.navigationService.navigate(ROUTES.Form, { fromFastProlongation: true });
  }

  protected readonly daDataSystemName = DaData;
}
