import {Component, inject} from '@angular/core';
import {FormService} from "../../../shared/services/form.service";
import {ContentService} from "../../../shared/services/content.service";
import {NgClass} from "@angular/common";

@Component({
  selector: 'app-entity-individual',
  templateUrl: './entity-individual.component.html',
  standalone: true,
  imports: [
    NgClass
  ],
  styleUrls: ['./entity-individual.component.scss']
})
export class EntityIndividualComponent {

  // Сервисы
  public formService = inject(FormService);
  public contentService = inject(ContentService);

  // Форма
  public form = this.formService.form;

  // Выбираем Юр или Физ лицо
  public changeIsJuridical(isJuridical: boolean): void {
    this.formService.form.get('isJuridical')?.setValue(isJuridical);
  }

}
