<div class="card mb-4 h-auto border-0 custom-rounded p-0 p-sm-2">
  <div class="card-body">
    <div class="row">
      <div class="col-auto">
        <img alt="" height="48" width="48"
             loading="lazy"
             src="./assets/images/offers-icons/empty-offers.svg">
      </div>
      <div class="col">
        <div class="main-title-item mb-3">К сожалению, предложений нет</div>
        <div class="second-title-item">
          Мы не получили финальное предложение для вас от страховых компаний. Попробуйте изменить дату страхования или
          данные водителя/собственника ТС. Наличие водителей с кбм
          больше 1 существенно снижает вероятность получения предложений
        </div>
      </div>
    </div>

    <div class="row actions justify-content-end mt-4">
      <div class="col-12 col-sm-auto mb-3 mb-sm-0">
        <button (click)="openEditForm()"
                type="button"
                class="btn btn-outline-primary btn-add-driver btn-lg w-100 offers-btn">
          Изменить данные
        </button>
      </div>
      <div class="col-12 col-sm-auto">
        <button (click)="openFormStartDatePolicy()"
                type="button" class="btn btn-primary btn-lg w-100 offers-btn">
          <span>Изменить дату</span>
        </button>
      </div>
    </div>
  </div>
</div>
