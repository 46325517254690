import {IDocumentTypes} from '../interface/document-types.interface';
import {CarDocumentTypesEnum} from '../enums/car-document-types.enum';

// Список всех типов документа
export const DOCUMENT_TYPES: IDocumentTypes[] = [
  {
    systemName: CarDocumentTypesEnum.STS,
    value: 'СТС',
    description: 'Свидетельство о регистрации ТС'
  },
  {
    systemName: CarDocumentTypesEnum.PTS,
    value: 'ПТС',
    description: 'Паспорт ТС'
  },
  {
    systemName: CarDocumentTypesEnum.ePTS,
    value: 'еПТС',
    description: 'Электронный паспорт ТС'
  }
];
