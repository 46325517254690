import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {delay, Observable, of, tap} from "rxjs";
import {catchError, switchMap} from "rxjs/operators";
import {environment} from "@environment/environment";
import {IDriverForm} from "../interface/osago-application.interface";
import {SettingsService} from "./settings.service";
import {FormService} from "./form.service";
import {getFormatDate} from "../functions/formatDate";
import {getDriverLicense, getOldDriverLicense} from "../functions/applicationSendRequest";
import {YandexMetrikaGoalsEnum} from "../enums/yandex-metrika-goals.enum";
import {YandexMetrikaService} from "./yandex-metrika.service";
import {LoggingService} from "./loggingService";

@Injectable({
  providedIn: 'root'
})
export class KbmService {
  private readonly ym = inject(YandexMetrikaService);
  private readonly loggingService = inject(LoggingService);
  private readonly settingsService = inject(SettingsService);
  private readonly formService = inject(FormService);
  private readonly http = inject(HttpClient);

  createKbmReport(driver: IDriverForm): Observable<any> {
    const {value} = this.formService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: value?.applicationId,
      firstName: driver.driverFirstName,
      lastName: driver.driverLastName,
      middleName: driver.driverMiddleName,
      birthDate: getFormatDate(driver.driverBirthDate),
      driverLicense: getDriverLicense(driver),
      oldDriverLicense: driver.licenseWasChanged ? getOldDriverLicense(driver) : undefined
    };
    return this.http.post<any>(environment.apiUrl + `enrichment/CreateKbmReport`, request)
      .pipe(
        tap((res) => {
          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CreateKbmReportResultError);
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса enrichment/CreateKbmReport', error);
          this.loggingService.error('Ошиба запроса enrichment/CreateKbmReport');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorCreateKbmReport);
          return of([]);
        })
      );
  }

  getKbmReport(requestId: string): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey,
      requestId
    }
    return this.http.post<any>(environment.apiUrl + `enrichment/GetKbmReport`, request)
      .pipe(
        tap((res) => {
          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.GetKbmReportResultError);
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса enrichment/GetKbmReport', error);
          this.loggingService.error('Ошиба запроса enrichment/GetKbmReport');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetKbmReport);
          return of([]);
        })
      );
  }

  pollKbmReport(requestId: string): Observable<any> {
    return this.getKbmReport(requestId).pipe(
      switchMap(response => {
        if (response.value.status === 'Processing') {
          return of(response).pipe(delay(1000), switchMap(() => this.pollKbmReport(requestId)));
        } else {
          return of(response);
        }
      })
    );
  }
}
