@if (isLoading) {
  <div class="card mb-4 h-auto border-0 custom-rounded p-0 p-sm-2">
    <div class="card-body">
      <div class="row">
        <div class="col">
          <div class="main-title-item mb-3">Поиск предложений</div>
        </div>
        <div class="col-auto">
          {{ timerText }} <strong>{{ timerService.displayTime }}</strong>
        </div>
      </div>
      <div class="progress">
        <div class="progress-bar" role="progressbar" [style.width]="(1 - timerService.timeLeft / timerService.totalTime) * 100 + '%'"></div>
      </div>
    </div>
  </div>
}
