import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {OsagoService} from "../../../../shared/services/osago.service";
import {concatMap, filter, Subscription} from "rxjs";
import {IOffer} from "../../../../shared/interface/offers.interface";
import {finalize} from "rxjs/operators";

@Component({
  selector: 'app-send-payment-sms',
  standalone: true,
  imports: [],
  templateUrl: './send-payment-sms.component.html',
  styleUrl: './send-payment-sms.component.scss',
  changeDetection: ChangeDetectionStrategy.Default
})
export class SendPaymentSMSComponent {
  @Input() offer!: IOffer;
  isLoading = false;
  smsSent = false;
  timer!: number;
  timerDisplay!: string;
  countdownInterval: any;
  // Подписка
  public subscription = new Subscription();

  constructor(private osagoService: OsagoService) {}

  public sendPaymentLink(): void {
    this.isLoading = true;
    this.osagoService.selectedOffer = this.offer; // Передайте ваш оффер сюда
    this.subscription.add(
      this.osagoService.selectOffer()
        .pipe(
          concatMap(() => this.osagoService.getPaymentLinkRepeatedly()),
          filter((res: any) => {
            if (!res.result && res.error?.message) {
              // this.offer.errorMessage = res.error.message;
              this.offer.errorMessage = 'Оформление полиса в данной страховой компании сейчас недоступно. Пожалуйста, попробуйте позже или выберите предложение от другой страховой компании.';
            }
            return res.result && res.value?.status === 'Received'
          }),
          concatMap(() => this.osagoService.sendOsagoPaymentLink()),
          finalize(() => {
            this.isLoading = false;
            this.smsSent = true;
            this.startCountdown(120);
          })
        )
        .subscribe({
          error: (err) => {
            console.error(err);
            this.isLoading = false;
          }
        })
    );
  }

  public startCountdown(seconds: number): void {
    this.timer = seconds;
    this.updateTimerDisplay();
    this.countdownInterval = setInterval(() => {
      this.timer--;
      this.updateTimerDisplay();
      if (this.timer <= 0) {
        clearInterval(this.countdownInterval);
        this.smsSent = false;
      }
    }, 1000);
  }

  public updateTimerDisplay(): void {
    const minutes = Math.floor(this.timer / 60);
    const seconds = this.timer % 60;
    this.timerDisplay = `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  }

}
