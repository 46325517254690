<form [formGroup]="formLegalInsurer" (ngSubmit)="onSubmit()" novalidate>
  <div class="insurer">
    <!--{{formService.legalInsurer.get('legalInsurerType')?.value}}-->
    <div class="row">
      <div class="col-12 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="legalInsurerValue"
          (typeaheadOnSelect)="onChangeLegalInsurer($event)"
          [daDataControlName]="'legalInsurerDaData'"
          [suggestionPart]="daDataSystemName.LEGAL_NAME"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
    </div>

    @if (formLegalInsurer.get('legalInsurerDaData')?.value) {
      <div class="row">
        <div class="col-12 mb-3 mb-sm-4">
          <app-form-text-field formControlName="legalInsurerName" [isReadonly]="isLoading"></app-form-text-field>
        </div>
        <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
          <app-form-text-field formControlName="legalInsurerInn" [isReadonly]="isLoading"></app-form-text-field>
        </div>
        @if (formService.legalInsurer.get('legalInsurerType')?.value === TypeOrganisation.LEGAL) {
          <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
            <app-form-text-field formControlName="legalInsurerOgrn" [isReadonly]="isLoading"></app-form-text-field>
          </div>
          <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
            <app-form-text-field formControlName="legalInsurerKpp" [isReadonly]="isLoading"></app-form-text-field>
          </div>
          <div class="col-12 mb-3 mb-sm-4">
            <app-form-switch formControlName="legalInsurerWithoutCertificate" [label]="'Без свидетельства'"
                             [isReadonly]="isLoading"></app-form-switch>
          </div>

          @if (!formService.legalInsurer.get('legalInsurerWithoutCertificate')?.value) {
            <div class="col-12 col-md-6 mb-3 mb-sm-4">
              <app-form-text-field formControlName="legalInsurerRegCertificate"
                                   [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-6 mb-3 mb-sm-4">
              <app-form-text-field formControlName="legalInsurerRegCertificateDateIssue"
                                   [isReadonly]="isLoading"></app-form-text-field>
            </div>
          } @else {
            <div class="col-12 mb-3 mb-sm-4">
              <div class="alert alert-primary border-light mb-0"
                   [innerHTML]="contentService.content.formComponent.legalOwnerComponent.alert1"></div>
            </div>
            <div class="col-12 mb-3 mb-sm-4">
              <app-form-text-field formControlName="legalInsurerEgryl" [isReadonly]="isLoading"></app-form-text-field>
            </div>
          }
        }

      </div>

      @if (formService.legalInsurer.get('legalInsurerType')?.value === TypeOrganisation.INDIVIDUAL) {
        <div class="row">
          <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
            <app-autocomplete
              formControlName="legalInsurerLastName"
              [suggestionPart]="daDataSystemName.SURNAME"
              [isReadonly]="isLoading"></app-autocomplete>
          </div>
          <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
            <app-autocomplete
              formControlName="legalInsurerFirstName"
              [suggestionPart]="daDataSystemName.NAME"
              [isReadonly]="isLoading"></app-autocomplete>
          </div>
          <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
            <app-autocomplete
              formControlName="legalInsurerMiddleName"
              [suggestionPart]="daDataSystemName.PATRONYMIC"
              [isReadonly]="isLoading"></app-autocomplete>
          </div>
        </div>
      }

      <div class="row">
        @if (formService.legalInsurer.get('legalInsurerType')?.value === TypeOrganisation.INDIVIDUAL) {
          <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
            <app-form-text-field formControlName="legalInsurerBirthDate" [isReadonly]="isLoading"></app-form-text-field>
          </div>
          <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
            <app-form-text-field formControlName="passportLicense" [isReadonly]="isLoading"></app-form-text-field>
          </div>
          <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
            <app-form-date-field formControlName="legalInsurerPassportDate"
                                 [isReadonly]="isLoading"></app-form-date-field>
          </div>
        }

        <div class="col-12 col-md-7 mb-3 mb-md-4">
          <app-autocomplete
            formControlName="legalInsurerAddress"
            [daDataControlName]="'legalInsurerAddressDaData'"
            [suggestionPart]="daDataSystemName.ADDRESS"
            [isReadonly]="isLoading"></app-autocomplete>
        </div>
        <div class="col-12 col-md-5 mb-3 mb-md-4">
          <app-form-text-field formControlName="legalInsurerHouseNumber" [isReadonly]="isLoading"></app-form-text-field>
        </div>
        <div class="col-12 mb-3 mb-sm-4">
          <app-form-switch formControlName="legalInsurerIsLegalOwner"
                           (switchOnSelect)="onsChangeLegalInsurerIsLegalOwner($event)"
                           [label]="formService.legalInsurer.get('legalInsurerName')?.value ? formService.legalInsurer.get('legalInsurerName')?.value + ' - собственник авто' : 'Страхователь - собственник авто'"
                           [isReadonly]="isLoading"></app-form-switch>
        </div>
      </div>
    }

    <div class="row actions justify-content-end">
      <div class="col-12 col-sm-auto mb-3 mb-sm-4">
        <button type="submit" class="btn btn-primary btn-lg w-100" [disabled]="isLoading">
          @if (isLoading) {
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          } @else {
            <span>Продолжить</span>
          }
        </button>
      </div>
    </div>

  </div>
</form>
