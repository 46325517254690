import {inject, Inject, Injectable} from '@angular/core';
import {Observable, of, tap} from "rxjs";
import {catchError, take} from "rxjs/operators";
import {HttpService} from "./http.service";
import {SettingsService} from "./settings.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environment/environment";
import {FormService} from "./form.service";
import {YandexMetrikaGoalsEnum} from "../enums/yandex-metrika-goals.enum";
import {LoggingService} from "./loggingService";
import {YandexMetrikaService} from "./yandex-metrika.service";

@Injectable({
  providedIn: 'root'
})
export class PriceCalculatorService extends HttpService {
  private ym = inject(YandexMetrikaService);
  private loggingService = inject(LoggingService);
  protected override http = inject(HttpClient);

  constructor(private readonly settingsService: SettingsService,
              private readonly formService: FormService) {
    super()
  }

  // Запрос на поулчение цен подели
  public getPodeliPrice(enginePower: string, hasNoLicencePlate: boolean, regionCode: string): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: this.formService.form.get('applicationId')?.value,
      EnginePower: enginePower,
      HasNoLicensePlate: hasNoLicencePlate,
      RegionCode: regionCode ? regionCode : undefined
    };
    return this.post(environment.apiUrl + 'dictionaries/GetOsagoPreliminaryCost', request)
      .pipe(
        tap((res: any) => {
          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.GetOsagoPreliminaryCostResultError);
          }
        }),
        take(1),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса dictionaries/GetOsagoPreliminaryCost', error);
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetOsagoPreliminaryCost);
          return of(null);
        })
      );
  }
}
