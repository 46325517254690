import {
  Component,
  EventEmitter, forwardRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit, Output,
  ViewChild
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NgControl,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  FormsModule
} from '@angular/forms';
import {Subscription} from 'rxjs';

// Сервисы
import {FormService} from '../../services/form.service';

// Маски
import {MASKS} from '../../constants/masks';

// Интерфейсы
import {ValidationService} from '../../services/validation.service';
import {IMaskDirective} from "angular-imask";
import * as IMask from "imask";
import {LicensePlateService} from "../../services/licensePlate.service";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {NgClass} from "@angular/common";
import {FormFieldErrorComponent} from "../form-field-error/form-field-error.component";

@Component({
  selector: 'app-form-license-field',
  standalone: true,
  templateUrl: './form-license-field.component.html',
  styleUrls: ['./form-license-field.component.scss'],
  imports: [
    BsDropdownModule,
    FormsModule,
    NgClass,
    IMaskDirective,
    FormFieldErrorComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormLicenseFieldComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => FormLicenseFieldComponent),
      multi: true
    }
  ]
})
export class FormLicenseFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @ViewChild('controlElm', {read: IMaskDirective}) controlElm!: IMaskDirective<IMask.MaskedPattern>;
  @ViewChild(IMaskDirective) imaskDirective!: IMaskDirective<IMask.MaskedPattern>;
  // Событие после ввода нового номера
  @Output() changeNewLicense: EventEmitter<boolean> = new EventEmitter<boolean>;
  // Заголовок
  @Input() label!: string;
  // Только для чтения
  @Input() isReadonly!: boolean;
  @Input() disabled: boolean = false;
  // Placeholder
  @Input() placeholder!: string;

  // Контрол изменяемого input
  public value: string = '';
  // Ппредыдущее значение input
  private previousValue: string | null = null;
  // Контрол изменяемого input
  public inputControl: UntypedFormControl = new UntypedFormControl(null);
  // Контрол
  public control!: UntypedFormControl;
  // Имя контрола
  public name!: string;
  // Тип
  public type = 'text';
  // Подписка на контрол
  private subscription: Subscription = new Subscription();
  // Сообщения валидации
  public messages: { [key: string]: any } = {};
  // Флаг, идет загрузка списка
  public isLoading = false;
  // Маска
  public mask = {
    mask: /.*/,
    lazy: true,
    placeholderChar: '_',
    autofix: true,
    overwrite: false,
  };
  // Маска для чистого значения
  public clearMask: any;
  // Показываем маску при заполнении
  public showMask = false;
  // Показываем клавиатуру "только цифры" в мобильных устройствах
  public isNumberKeyboard!: boolean;
  // Показываем клавиатуру, текст или только цифры
  public inputmode: string = 'text';


  // Вызовем когда значение изменится
  public onTouched: any = () => {
  };

  // Вызовем при любом действии пользователя с контроллом
  public onChange: any = () => {
  };

  constructor(@Inject(Injector) private injector: Injector,
              public readonly formService: FormService,
              private readonly validationService: ValidationService,
              public readonly licensePlateService: LicensePlateService) {
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    const injectedControl = this.injector.get(NgControl);
    this.name = injectedControl.name + 'controlName';

    switch (injectedControl.constructor) {
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as UntypedFormControl;
        break;
      }
    }

    // Применяем параметры контрола
    if (injectedControl.name) {
      this.initPropertyControl(injectedControl.name.toString());
    }
  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // --------------------------------------------------------------------------

  // Применяем параметры контролла
  public initPropertyControl(injectedControlName: string): void {
    const propertyControl = this.formService.propertyControls[injectedControlName];
    if (propertyControl?.validation?.messages) {
      this.messages = propertyControl?.validation?.messages;
    }
    if (propertyControl?.label) {
      this.label = propertyControl.label;
    }
    if (propertyControl?.showMask) {
      this.showMask = propertyControl?.showMask;
    }
    if (propertyControl?.type) {
      this.type = propertyControl?.type;
    }
    if (propertyControl?.placeholder) {
      this.placeholder = propertyControl?.placeholder;
    }
    if (propertyControl?.inputmode) {
      this.inputmode = propertyControl?.inputmode;
    }
    if (propertyControl?.mask) {
      this.controlElm?.maskRef?.updateOptions(propertyControl?.mask);
    }
    if (propertyControl?.maskName) {
      MASKS.filter((item: any) => item.systemName === propertyControl?.maskName)
        .map((item) => this.mask = item.mask);
    }

    this.validationService.setControlValidation(propertyControl, this.control);

    return;
  }

  // Вызовет форма, если значение изменилось извне
  public writeValue(value: any): void {
    if (value !== undefined) {
      this.value = value;
      this.previousValue = value;
    }
  }

  // Сохраняем обратный вызов для изменений
  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  // Сохраняем обратный вызов для "касаний"
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Установка состояния disabled
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public validate(c: UntypedFormControl): any {
    if (!this.inputControl) {
      return null;
    }
  }

  public updateValue(value: string): void {
    this.value = value;
    this.onChange(value);
  }

  // Событие при выходи из контрола
  public blur(event: any): void {
    this.onChange(this.value);
    this.onTouched();

    // Выполняем emit только если значение изменилось
    if (this.value !== this.previousValue) {
      this.changeNewLicense.emit(this.control.valid);
      this.previousValue = this.value; // Обновляем предыдущее значение
    }
  }
}
