<div class="card mt-2 mt-sm-4 border-0 h-auto shadow-none custom-rounded offer opacity-50">
  <div class="card-body p-3 p-sm-4">
    <div class="row align-items-center">
      <div class="col-auto">
        <svg class="bd-placeholder-img rounded-3" width="42" height="42" xmlns="http://www.w3.org/2000/svg"
             role="img" aria-label="Placeholder" preserveAspectRatio="xMidYMid slice" focusable="false"><title>
          Placeholder</title>
          <rect width="100%" height="100%" fill="#868e96"></rect>
        </svg>
      </div>
      <div class="col">
        <p class="card-text placeholder-glow mb-1">
          <span class="placeholder col-6"></span>
        </p>
        <p class="card-text small placeholder-glow">
          <span class="placeholder col-8"></span>
        </p>
      </div>
      <div class="col-12 col-sm-2">
        <a class="btn btn-primary main-btn disabled placeholder w-100 mt-3 mt-sm-0" aria-disabled="true"></a>
      </div>
    </div>
  </div>
</div>
