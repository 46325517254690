export enum ROUTES {

    // Главная страница
    MainPage = '',

    // Страница с данными авто
    Form = 'form',

    // Страница с данными авто
    Offers = 'offers',

    // Страница успеха
    Success = 'success',

}
