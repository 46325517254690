// import { IMask } from '../interface/masks.interface';
import * as IMask from 'imask';
import moment from "moment-mini";

const currentYear = new Date().getFullYear();
const productionYearFormat = 'YYYY год';
const dateFormat = 'DD.MM.YYYY';

// Список всех масок
export const MASKS: any[] = [
  {
    systemName: 'licensePlate',
    mask: {
      mask: 'X YYY ZZ AAA',
      blocks: {
        X: {
          mask: IMask.MaskedEnum,
          enum: ['А', 'В', 'Е', 'К', 'М', 'Н', 'О', 'Р', 'С', 'Т', 'У', 'Х'],
        },

        YYY: {
          mask: '000',
        },
        ZZ: {
          mask: 'aa',
          prepareChar: (str: string) => {
            const isValidChar = /^[АВЕКМНОРСТУХавекмнорстух]$/.test(str);
            return isValidChar ? str.toUpperCase() : '_';
          }
        },
        AAA: {
          mask: '***',
          prepareChar: (str: string) => {
            const isValidChar = /^[0-9]$/.test(str);
            return isValidChar ? str : '';
          }
        }
      },
      prepareChar: (str: string) => {
        return str.toUpperCase();
      },
    }
  },
  {
    systemName: 'productionYear',
    mask: {
      mask: productionYearFormat,
      pattern: productionYearFormat,
      lazy: true,
      min: new Date(1970, 0, 1),
      max: new Date(2030, 0, 1),
      format: (date: moment.MomentInput | undefined) => moment(date).format('YYYY'),
      parse: (str: moment.MomentInput | undefined) => moment(str, 'YYYY'),
      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1970,
          to: currentYear,
        },
      },
    }
  },
  {
    systemName: 'horsePower',
    mask: {
      mask: 'num л.с.',
      lazy: true,
      blocks: {
        num: {
          mask: Number,
          min: 30,
          max: 2000,
          expose: true,
        },
      }
    }
  },
  {
    systemName: 'stsNumber',
    mask: {
      mask: 'XX YY ZZZZCC',
      lazy: true,
      blocks: {
        XX: {
          mask: '00',
        },
        YY: {
          mask: '**',
          prepareChar: (str: string) => {
            const isValidChar = /^[0-9а-яА-ЯёЁ]$/.test(str);
            return isValidChar ? str : '';
          }
        },
        ZZZZ: {
          mask: '0000',
        },
        CC: {
          mask: '**',
          prepareChar: (str: string) => {
            const isValidChar = /^[0-9*]$/.test(str);
            return isValidChar ? str : '';
          }
        }
      },
      prepareChar: (str: string) => {
        return str.toUpperCase();
      },
    }
  },
  {
    systemName: 'ptsNumber',
    mask: {
      mask: 'XX YY ZZZZCC',
      lazy: true,
      blocks: {
        XX: {
          mask: '00',
        },
        YY: {
          mask: 'aa',
          prepareChar: (str: string) => {
            const isValidChar = /^[а-яА-ЯёЁ]$/.test(str);
            return isValidChar ? str.toUpperCase() : '_';
          }
        },
        ZZZZ: {
          mask: '0000',
        },
        CC: {
          mask: '**',
          prepareChar: (str: string) => {
            const isValidChar = /^[0-9*]$/.test(str);
            return isValidChar ? str : '';
          }
        }
      },
      prepareChar: (str: string) => {
        return str.toUpperCase();
      },
    }
  },
  {
    systemName: 'ePtsNumber',
    mask: {
      mask: 'XXYY',
      lazy: true,
      blocks: {
        XX: {
          mask: '00000 00000 000',
        },
        YY: {
          mask: '**',
          prepareChar: (str: string) => {
            const isValidChar = /^[0-9*]$/.test(str);
            return isValidChar ? str : '';
          }
        }
      },
      prepareChar: (str: string) => {
        return str.toUpperCase();
      },
    }
  },
  {
    systemName: 'vinNumber',
    mask: {
      mask: 'XY',
      maxLength: 17,
      lazy: true,
      blocks: {
        X: {
          mask: '***************',
          maxLength: 15,
          prepareChar: (str: string) => {
            const upperStr = str.toUpperCase();
            const isValidChar = /^[0-9A-HJ-NPR-Z]*$/.test(upperStr);
            return isValidChar ? upperStr : '';
          }
        },
        Y: {
          mask: '**',
          maxLength: 2,
          prepareChar: (str: string) => {
            const upperStr = str.toUpperCase();
            const isValidChar = /^[0-9A-HJ-NPR-Z*]*$/.test(upperStr);
            return isValidChar ? upperStr : '';
          }
        },
      },
      prepareChar: (str: string) => {
        return str.toUpperCase();
      },
    }
  },
  {
    systemName: 'bodyNumber',
    mask: {
      mask: /^[A-HJ-NPR-Za-hj-npr-z0-9*]{1,17}$/,
      prepareChar: (str: string) => {
        return str.toUpperCase();
      },
      commit: (value: string, masked: { _value: any; }) => {
        // Убираем возможные ведущие '*' для всего, кроме последних двух символов
        if (value.length > 2) {
          const lastTwo = value.slice(-2);
          const rest = value.slice(0, -2).replace(/\*/g, '');
          masked._value = rest + lastTwo;
        }
      },
      maxLength: 17,
    }
  },
  {
    systemName: 'chassisNumber',
    mask: {
      mask: /^[A-HJ-NPR-Za-hj-npr-z0-9*]{1,17}$/,
      prepareChar: (str: string) => {
        return str.toUpperCase();
      },
      commit: (value: string, masked: { _value: any; }) => {
        // Убираем возможные ведущие '*' для всего, кроме последних двух символов
        if (value.length > 2) {
          const lastTwo = value.slice(-2);
          const rest = value.slice(0, -2).replace(/\*/g, '');
          masked._value = rest + lastTwo;
        }
      },
      maxLength: 17,
    }
  },
  {
    systemName: 'documentDate',
    mask: {
      mask: Date,
      pattern: dateFormat,
      lazy: true,
      min: new Date(1970, 0, 1),
      max: new Date(),
      overwrite: false,

      format: (date: moment.MomentInput | undefined) => moment(date).format(dateFormat),
      parse: (str: moment.MomentInput | undefined) => moment(str, dateFormat),

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1970,
          to: moment().get('year')
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31
        }
      }
    }
  },
  {
    systemName: 'experienceYear',
    mask: {
      mask: productionYearFormat,
      pattern: productionYearFormat,
      lazy: true,
      min: new Date(1960, 0, 1),
      max: new Date(),
      format: (date: moment.MomentInput | undefined) => moment(date).format('YYYY'),
      parse: (str: moment.MomentInput | undefined) => moment(str, 'YYYY'),
      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1960,
          to: currentYear,
        },
      },
    }
  },
  {
    systemName: 'driverLicense',
    mask: {
      mask: 'X Y Z',
      lazy: true,
      blocks: {
        X: {
          mask: '00',
        },
        Y: {
          mask: '**',
          prepareChar: (str: string) => {
            const isValidChar = /^[0-9a-zA-Zа-яА-ЯёЁ]$/.test(str);
            return isValidChar ? str : '';
          }
        },
        Z: {
          mask: '000000',
        },
      },
      prepareChar: (str: string) => {
        return str.toUpperCase();
      },
    }
  },
  {
    systemName: 'passportLicense',
    mask: {
      mask: '00 00 000000',
      lazy: true,
    }
  },
  {
    systemName: 'driverBirthDate',
    mask: {
      mask: Date,
      pattern: dateFormat,
      lazy: true,
      min: new Date(1940, 0, 1),
      max: new Date(),

      format: (date: moment.MomentInput | undefined) => moment(date).format(dateFormat),
      parse: (str: moment.MomentInput | undefined) => moment(str, dateFormat),

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1940,
          to: moment().get('year')
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31
        }
      }
    }
  },
  {
    systemName: 'experienceDate',
    mask: {
      mask: Date,
      pattern: dateFormat,
      lazy: true,
      min: new Date(1960, 0, 1),
      max: new Date(),

      format: (date: moment.MomentInput | undefined) => moment(date).format(dateFormat),
      parse: (str: moment.MomentInput | undefined) => moment(str, dateFormat),

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1940,
          to: moment().get('year')
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31
        }
      }
    }
  },
  {
    systemName: 'driverPassportDate',
    mask: {
      mask: Date,
      pattern: dateFormat,
      lazy: true,
      min: new Date(1960, 0, 1),
      max: new Date(),

      format: (date: moment.MomentInput | undefined) => moment(date).format(dateFormat),
      parse: (str: moment.MomentInput | undefined) => moment(str, dateFormat),

      blocks: {
        YYYY: {
          mask: IMask.MaskedRange,
          from: 1940,
          to: moment().get('year')
        },
        MM: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 12
        },
        DD: {
          mask: IMask.MaskedRange,
          from: 1,
          to: 31
        }
      }
    }
  },
  {
    systemName: 'houseNumber',
    mask: {
      mask: /^[0-9]*$/,
      min: 1,
      max: 9999,
      radix: '', // Убираем возможность ввода дробной части
      scale: 0, // Устанавливаем число знаков после запятой (0 для целых чисел)
      signed: false, // Отключаем возможность ввода отрицательных чисел
      normalizeZeros: false, // Не убираем ведущие нули (можно оставить true, если нужно убрать)
      padFractionalZeros: false // Не добавляем нули после запятой
    }
  },
  {
    systemName: 'innNumber',
    mask: {
      mask: '0000000000{0}{0}',
      lazy: false,
      placeholderChar: ' ',
    },
  },
  {
    systemName: 'innNumberIp',
    mask: {
      mask: '0000000000}',
      lazy: false,
      placeholderChar: ' ',
    },
  },
  {
    systemName: 'email',
    mask: {
      mask: /^\S*@?\S*$/,
      lazy: false,
      placeholderChar: ' ',
    }
  },
  {
    systemName: 'phone',
    mask: {
      mask: '+{7} (000) 000-00-00',
      lazy: true,
    }
  }
];
