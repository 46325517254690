<form [formGroup]="formOwner" (ngSubmit)="onSubmit()" novalidate autocomplete="off">
  <div class="owner">

    <div class="alert alert-primary mb-3 mb-sm-4">
      {{contentService.content.formComponent.ownerComponent.alert1}}
    </div>

    @if (formService.drivers.value?.length >= 1 && getDriverTitle(formService.form.get('drivers')?.value[0])) {
      <div class="row driver-selector">
        <div class="col-auto mb-3 mb-sm-4" *ngFor="let driver of formService.form.get('drivers')?.value; let i = index">
          <button (click)="selectDriver(i)"
                  [class]="driver.driverIsOwner ? 'btn-primary' : 'btn-outline-primary'"
                  type="button"
                  class="btn btn-sm">{{ getDriverTitle(driver) }}</button>
        </div>
        <div class="col-auto mb-3 mb-sm-4">
          <button (click)="selectDriver(-1)"
                  [class]="formService.ownerIsOtherDriver ? 'btn-primary' : 'btn-outline-primary'"
                  type="button"
                  class="btn btn-sm">{{contentService.content.formComponent.ownerComponent.otherDriverBtn}}
          </button>
        </div>
      </div>
    }

    <div class="row mb-3 mb-sm-4">
      <!--1-->
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="ownerLastName"
          [suggestionPart]="daDataSystemName.SURNAME"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!--2-->
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="ownerFirstName"
          [suggestionPart]="daDataSystemName.NAME"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!--3-->
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="ownerMiddleName"
          [suggestionPart]="daDataSystemName.PATRONYMIC"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!--4-->
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-form-text-field formControlName="ownerBirthDate" [isReadonly]="isLoading"></app-form-text-field>
      </div>
      <!--5-->
      @if (formOwner.get('passportLicense')?.enabled) {
        <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
          <app-form-text-field formControlName="passportLicense" [isReadonly]="isLoading"></app-form-text-field>
        </div>
      }
      <!--6-->
      @if (formOwner.get('ownerPassportDate')?.enabled) {
        <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
          <app-form-date-field formControlName="ownerPassportDate" [isReadonly]="isLoading"></app-form-date-field>
        </div>
      }
      <!--7-->
      <div class="col-12 col-md-7 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="ownerCity"
          [daDataControlName]="'ownerAddressDaData'"
          [suggestionPart]="daDataSystemName.ADDRESS"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!--8-->
      <div class="col-12 col-md-5 mb-3 mb-md-4">
        <app-form-text-field formControlName="ownerHouseNumber" [isReadonly]="isLoading"></app-form-text-field>
      </div>
      <!--9-->
      <div class="col-12">
        <app-form-switch [formControl]="formService.ownerIsInsured"
                         [label]="contentService.content.formComponent.ownerComponent.ownerIsInsurer + getOwnerNameTitle"
                         (switchOnSelect)="changeOwnerIsInsurer($event)"
                         [isReadonly]="isLoading"></app-form-switch>
      </div>
    </div>

    <div class="row actions justify-content-end">
      <div class="col-12 col-sm-auto mb-3 mb-sm-4">
        <button type="submit" class="btn btn-primary btn-lg w-100" [disabled]="isLoading">
          @if (isLoading) {
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          } @else {
            <span>{{contentService.content.formComponent.ownerComponent.submitBtn}}</span>
          }
        </button>
      </div>
    </div>

  </div>
</form>
