<div class="row">
  <div class="col-12 mb-3 mb-sm-4">
    <app-form-switch [formControl]="formService.isAutopark" [label]="'У меня автопарк (более 5 авто)'" [isReadonly]="isLoading"></app-form-switch>
  </div>
</div>

<div class="alert alert-primary border-light mb-3 mb-sm-4" role="alert"
     [innerHTML]="contentService.content.formComponent.autoparkComponent.alert1"></div>

<div class="alert alert-secondary border-light mb-3 mb-sm-4" role="alert"
     [innerHTML]="contentService.content.formComponent.autoparkComponent.alert2">
</div>

@if (form) {
  <div class="row" [formGroup]="form">
    <div class="col-12 col-md-6 mb-3 mb-sm-4">
<!--      <app-form-text-field formControlName="firstName" [isReadonly]="isLoading"></app-form-text-field>-->
      <app-autocomplete
        formControlName="firstName"
        [suggestionPart]="daDataSystemName.NAME"
        [isReadonly]="isLoading"></app-autocomplete>
    </div>
    <div class="col-12 col-md-6 mb-3 mb-sm-4">
      <app-form-text-field formControlName="companyName" [isReadonly]="isLoading"></app-form-text-field>
    </div>
    <div class="col-12 col-md-4 mb-3 mb-sm-4">
      <app-form-text-field formControlName="INN" [isReadonly]="isLoading"></app-form-text-field>
    </div>
    <div class="col-12 col-md-4 mb-3 mb-sm-4">
      <app-form-text-field formControlName="email" [isReadonly]="isLoading"></app-form-text-field>
    </div>
    <div class="col-12 col-md-4 mb-3 mb-sm-4">
      <app-form-text-field formControlName="phone" [isReadonly]="isLoading"></app-form-text-field>
    </div>
  </div>

  <div class="row justify-content-end">
    <div class="col-12 col-sm-auto mb-3 mb-sm-4">
      <button [disabled]="isLoading" type="button" class="btn btn-primary btn-lg w-100" (click)="onSubmit()">
        @if (isLoading) {
          <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
        } @else {
          Отправить заявку
        }
      </button>
    </div>
  </div>
}

