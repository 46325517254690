<div class="dropdown" dropdown [insideClick]="true">
  <div class="has-validation" [class.form-floating]="label" dropdownToggle>
    <input #controlElm
           [(ngModel)]="value"
           (ngModelChange)="updateValue($event)"
           [ngClass]="{
            'is-invalid': control.invalid && control.touched
           }"
           [class.form-control-lg]="!label"
           class="form-control"
           [readOnly]="isReadonly"
           [disabled]="disabled"
           [type]="type"
           autocomplete="off"
           [imask]="mask"
           [unmask]="true"
           [attr.inputmode]="inputmode"
           [name]="name"
           [placeholder]="placeholder"
           aria-label="Введите номер авто"
           (blur)="blur($event)">
    @if (control.invalid && control.touched) {
      <app-form-field-error
        [controlErrors]="control.errors"
        [messages]="messages">
      </app-form-field-error>
    }
  </div>
</div>
