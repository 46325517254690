import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {Observable, of, Subscription, timer, Subject} from "rxjs";
import {environment} from "@environment/environment";
import {FormService} from "../../../shared/services/form.service";
import {NavigationService} from "../../../shared/services/navigation.service";
import {YandexMetrikaService} from "../../../shared/services/yandex-metrika.service";
import {IOffer} from "../../../shared/interface/offers.interface";
import {OsagoService} from "../../../shared/services/osago.service";
import {SettingsService} from "../../../shared/services/settings.service";
import {AppService} from "../../../shared/services/app.service";
import {RatingModule} from "ngx-bootstrap/rating";
import {FormsModule} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {PriceComponent} from "./price/price.component";
import {RemoveFocusDirective} from "../../../shared/directives/remove-focus.directive";
import {SendPaymentSMSComponent} from "./send-payment-sms/send-payment-sms.component";
import {UpsalesComponent} from "./upsales/upsales.component";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {GiftItemComponent} from "./gift-item/gift-item.component";
import moment from "moment-mini";
import {IUrlParams} from "../../../shared/interface/url-params.interface";
import {concatMap, tap, expand, takeWhile, delayWhen, finalize, takeUntil} from "rxjs/operators";

@Component({
  selector: 'app-offer-item',
  standalone: true,
  templateUrl: './offer-item.component.html',
  styleUrls: ['./offer-item.component.scss'],
  imports: [
    RatingModule,
    FormsModule,
    NgForOf,
    PriceComponent,
    NgClass,
    RemoveFocusDirective,
    SendPaymentSMSComponent,
    NgIf,
    UpsalesComponent,
    CollapseModule,
    GiftItemComponent
  ]
})
export class OfferItemComponent implements OnInit, AfterViewInit, OnDestroy {

  // Данные оффера
  @Input() offer!: IOffer;
  // Если ищем офферов которые можно оплатить
  @Input() isApproveOffer: boolean = false;
  // Если повторно выбираем оффера после того как предыдущий отказал
  @Input() isRepeatOffers: boolean = false;
  @Output() triggerNewGetOffers: EventEmitter<boolean> = new EventEmitter<boolean>();

  public settingsService = inject(SettingsService);
  public appService = inject(AppService);

  urlParams!: IUrlParams;
  public errorMessage: string | null = null;
  public isLoading: boolean = false; // Track loading state
  public hasError: boolean = false; // Track error state

  constructor(public readonly formService: FormService,
              public readonly osagoService: OsagoService,
              private readonly navigationService: NavigationService,
              private readonly cd: ChangeDetectorRef,
              private readonly ym: YandexMetrikaService,) {
  }

  public isPaymentCountdownStarted = false;
  public paymentCountdown = '02:00';
  private countdownInterval: any;
  // Документ
  public agreement = true;

  // Системное имя партнера
  public partnerSystemName = environment.partnerSystemName;

  // Добавляем статическое свойство для отслеживания состояния загрузки
  static isProcessing = false;

  // Геттер для проверки состояния загрузки
  get isProcessing(): boolean {
    return OfferItemComponent.isProcessing;
  }

  // Добавляем Subject для отмены подписки
  private destroy$ = new Subject<void>();

  // Method to select an offer and handle Rosgosstrah logic
  public selectOffer(offer: IOffer): void {
    let newWindow: Window | null = null;
    if (environment.partnerSystemName === 'rgs-iframe' && offer.insurerType !== 'Rosgosstrah') {
      // Открываем новое окно сразу при клике
      newWindow = window.open('/loading', '_blank');
    }

    OfferItemComponent.isProcessing = true;
    this.isLoading = true;
    this.hasError = false;
    this.osagoService.selectedOffer = offer;

    this.subscriptions.push(
      this.osagoService.selectOffer()
        .subscribe({
          next: () => {
            if (environment.partnerSystemName === 'rgs-iframe' && offer.insurerType !== 'Rosgosstrah') {

              this.osagoService.getDataCheckingLink().pipe(
                tap((dataCheckingLinkResponse) => {

                  if (dataCheckingLinkResponse?.result && dataCheckingLinkResponse?.value?.url) {
                    // Перенаправляем новое окно на нужный URL
                    if (newWindow) {
                      newWindow.location.href = dataCheckingLinkResponse.value.url;
                    } else {
                      // Если по какой-то причине окно не открылось (newWindow == null)
                      window.open(dataCheckingLinkResponse.value.url, '_blank');
                    }
                  }
                })
              ).subscribe();

              this.osagoService.paymentLink = '';
              offer.isSelected = true;
              this.osagoService.selectedOffer = offer;
              timer(1000).pipe(
                concatMap(() => this.osagoService.getPaymentLinkRepeatedly(offer.offerId)),
                takeUntil(this.destroy$)
              ).subscribe(paymentLinkResponse => {

                if (paymentLinkResponse?.result && paymentLinkResponse?.value?.osagoPaymentLink) {
                  this.isLoading = false;
                  OfferItemComponent.isProcessing = false;

                } else if (!paymentLinkResponse?.result) {
                  this.isLoading = false;
                  this.hasError = true;
                  OfferItemComponent.isProcessing = false;
                  this.errorMessage = "Оформление полиса в данной страховой компании сейчас недоступно. Пожалуйста, попробуйте позже или выберите предложение от другой страховой компании";
                }
              });
            } else {
              OfferItemComponent.isProcessing = false;
              this.isLoading = false;
              this.navigationService.navigate('data-checking');
            }
          },
          error: () => {
            this.isLoading = false;
            this.hasError = true;
            this.errorMessage = "Ошибка при выборе предложения. Пожалуйста, попробуйте позже или выберите предложение от другой страховой компании";
          }
        })
    );
  }


  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit() {
    if (this.isApproveOffer) {
      this.startPaymentCountdown();
      this.getMortgagePaymentLink(this.offer.offerId);
    }

    this.urlParams = this.appService.getUrlParams();
  }

  public ngAfterViewInit() {

  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.destroy$.next();
    this.destroy$.complete();
  }

  // --------------------------------------------------------------------------

  // Счетчик
  public countdown = 120;
  // Подписка
  private subscriptions: Subscription[] = [];
  // Показываем подарки
  public isShowGifts = false;


  isClicked = false;

  toggleClickState() {
    this.isClicked = !this.isClicked;
  }

  get getCreditRemainAmount(): string {
    return '';
  }

  // Получаем ссылку на оплату
  public getMortgagePaymentLink(offerId: string): void {
  }

  // Оплатить
  public payment(): void {
  }

  // Открыть черновик
  public openDraft(): void {

  }

  // Открыть черновик
  public openAdditionalDraft(): void {

  }

  // Счетчик
  public startPaymentCountdown() {
    this.isPaymentCountdownStarted = true;

    // начинаем обратный отсчет
    this.countdownInterval = setInterval(() => {
      const timeParts = this.paymentCountdown.split(':');
      let minutes = +timeParts[0];
      let seconds = +timeParts[1];

      if (minutes === 0 && seconds === 0) {
        clearInterval(this.countdownInterval);
        this.isPaymentCountdownStarted = false;
        // Вставляем событие клика для ссылок на документы
        this.setClickForDocs();
        return;
      }

      if (seconds === 0) {
        minutes--;
        seconds = 59;
      } else {
        seconds--;
      }

      this.cd.detectChanges();

      this.paymentCountdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }, 1000);
  }

  // Вставляем событие клика для ссылок на документы
  public setClickForDocs(): void {
    setTimeout(() => {
      // В этом методе контент [innerHTML]="item" уже доступен
      const elements = document.querySelectorAll('.form-check-label a');
      elements.forEach((element: any) => {
        element.addEventListener('click', (event: Event) => {
          event.preventDefault();

          if (!element.data) {
            element.data = element.href;
          }

          const initialState = {
            src: element.data,
          };


          element.href = 'javascript:void(0)';
          element.target = '_self';
        });

      });
    }, 100)
  }

  // Проверяем наличие бейджей пролонгации
  get checkProlongationBadge(): boolean {
    return this.offer.badges.find((badge: any) => badge.title === 'Ваша текущая страховая') === null;
  }

  // Проверяем если валидна пролонгация
  get getProlongtaionValidity(): boolean {
    if (this.osagoService.prolongationOffer?.endDate) {
      const policyDate = moment(this.osagoService.prolongationOffer?.endDate!); // ISO формат обрабатывается моментом автоматически
      const currentDate = moment().startOf('day');
      const maxDate = moment().startOf('day').add(59, 'days');

      // показываем кнопку "продлить"
      if (policyDate.isValid() && policyDate.isBefore(currentDate)) {
        return true;
      } else if (this.osagoService.prolongationOffer?.insurerType === this.offer.insurerType && policyDate.isValid() && policyDate.isAfter(maxDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  protected readonly environment = environment;

  // Добавляем метод для отмены подписки
  public cancelPaymentLinkRequest(): void {
    this.destroy$.next();
    this.isLoading = false;
    this.hasError = false;
    OfferItemComponent.isProcessing = false;
  }
}
