<div class="gift p-3 p-sm-3" [style.background-color]="gift.color">
  <div class="row flex-column justify-content-between h-100">
    <div class="col-12">
      <img [src]="gift.imageSrc" [alt]="gift.promocodeTitle" class="company-logo">
    </div>
    <div class="col-12 align-items-end">
      <div class="promocodeTitle">{{gift.promocodeTitle}}</div>
      @if (gift.promocodeSubtitle) {
        <div class="promocodeSubtitle">{{gift.promocodeSubtitle}}</div>
      }
    </div>
    @if (gift.promocodeDescription) {
      <div class="col-12 align-items-end">
        <div class="promocodeDescription">{{gift.promocodeDescription}}</div>
      </div>
    }
  </div>

<!--  {{gift|json}}-->
</div>
