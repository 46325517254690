import {Component, inject, OnInit, signal} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";
import {Router, RouterLink} from "@angular/router";
import {ROUTES} from "../../enums/routes.enum";
import {NavigationService} from "../../services/navigation.service";
import {ProlongationFormService} from "../../services/prolongationForm.service";
import {Validators} from "@angular/forms";

@Component({
  selector: 'app-error-message',
  standalone: true,
  imports: [
    RouterLink
  ],
  templateUrl: './error-message.component.html',
  styleUrl: './error-message.component.scss'
})
export class ErrorMessageComponent implements OnInit {
  public modalRef = inject(BsModalRef);
  private navigationService = inject(NavigationService);
  public prolongationFormService = inject(ProlongationFormService);

  title?: string;
  message?: string;
  isShowBtnNewPolicy?: boolean = false;
  isInvalidForm?: boolean = false;
  isRedirectProcessing?: boolean = false;
  isShowBtnGosNumber?: boolean = false;
  isShowBtnPolicyNumber?: boolean = false;
  isShowBtnNewChatMessage?: boolean = false;
  isShowContinueButton?: boolean = true;

  ngOnInit(): void {
    this.modalRef.setClass('modal-dialog-centered');
  }

  // Переход на главную
  public navigateToMainPage(): void {
    this.modalRef.hide();
    this.isRedirectProcessing = true;
    window.location.href = 'https://alfa-strah.com/forma-osago';
    setTimeout(() => {
      this.isRedirectProcessing = false;
    }, 4000);
  }
  // Переход на форму
  public navigateToFormPage(): void {
    this.modalRef.hide();
    this.navigationService.navigate(ROUTES.Form, { fromFastProlongation: true });
  }

  completeGosNumber(): void {
    this.modalRef.hide();
    this.prolongationFormService.form.get('licensePlate')?.setValue(null);
    this.prolongationFormService.form.get('prolongationFormBirthDate')?.setValue(null);
    this.prolongationFormService.form.get('phone')?.setValue(null);
    this.prolongationFormService.form.get('email')?.setValue(null);
    this.prolongationFormService.form.markAsPristine();
    this.prolongationFormService.form.markAsUntouched();
    this.prolongationFormService.form.get('isNumberGos')?.setValue(true);
  }

  completePolicyNumber(): void {
    this.modalRef.hide();
    this.prolongationFormService.form.get('prolongationFormBirthDate')?.setValue(null);
    this.prolongationFormService.form.get('previousPolicySeries')?.setValue(null);
    this.prolongationFormService.form.get('previousPolicyNumber')?.setValue(null);
    this.prolongationFormService.form.get('phone')?.setValue(null);
    this.prolongationFormService.form.get('email')?.setValue(null);
    this.prolongationFormService.form.markAsPristine();
    this.prolongationFormService.form.markAsUntouched();
    this.prolongationFormService.form.get('isNumberGos')?.setValue(false);
  }
}
