<div class="card h-auto shadow-0 border-0 p-3 p-sm-4 custom-rounded">
  <div class="card-body p-0">
    <div class="row align-items-center">
      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
        <div class="main-title-item mb-0">{{contentService.content.fastProlongationComponent.title}}</div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="row justify-content-end d-flex">
          <div class="col-12 col-sm-6 col-sm-auto mb-2 mb-sm-0">
            <button type="button" class="btn legal-btn w-100" (click)="onChangeIsNumberGos(false)"
                    [ngClass]="!form.get('isNumberGos')?.value ? 'btn-primary' : 'btn-outline-primary'">
              {{contentService.content.fastProlongationComponent.policyNumber}}
            </button>
          </div>
          <div class="col-12 col-sm-6 col-sm-auto">
            <button type="button" class="btn legal-btn w-100" (click)="onChangeIsNumberGos(true)"
                    [ngClass]="form.get('isNumberGos')?.value ? 'btn-primary' : 'btn-outline-primary'">
              {{contentService.content.fastProlongationComponent.gosNumber}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
