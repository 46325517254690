import {IUserAlfaId} from "../interface/user-alfa-id";
import {FormControl, FormGroup} from "@angular/forms";

export function setDriverFromAlfaId(driverData: IUserAlfaId, driverFormGroup: FormGroup, ownerIsInsured: FormControl): void {
  const {
    driverFirstName,
    driverLastName,
    driverMiddleName,
    driverBirthDate,
    driverIsOwner,
    driverIsInsurer,
    driverPassportLicense,
    driverCity,
    driverHouseNumber,
    rememberOnlyYear,
    experienceDate,
    experienceYear,
    licenseWasChanged,
    oldLicense,
    oldDriverLastName,
    driverPassportDate,
    driverAddressDaData,
  } = (driverFormGroup)?.controls;

  rememberOnlyYear.setValue(false, { emitEvent: true });
  experienceDate.reset(null, { emitEvent: false });
  experienceYear.reset(null, { emitEvent: false });
  licenseWasChanged.setValue(false, { emitEvent: false });
  oldLicense.reset(null, { emitEvent: false });
  oldDriverLastName.reset(null, { emitEvent: false });


  driverIsOwner.setValue(true, { emitEvent: true });
  driverIsInsurer.setValue(true, { emitEvent: true });
  ownerIsInsured?.setValue(true, { emitEvent: false });

  driverFirstName.setValue(driverData.firstName, { emitEvent: true });
  driverLastName.setValue(driverData.lastName, { emitEvent: true });
  driverMiddleName.setValue(driverData.middleName!, { emitEvent: true });
  driverBirthDate.setValue(formatDateFromUserAlfaId(driverData.birthDate!), { emitEvent: true });
  driverPassportLicense.setValue(driverData.passportSeries! + driverData.passportNumber!, { emitEvent: true });
  driverPassportDate.setValue(driverData.passportIssueDate, { emitEvent: true });

  if (driverData.regAddressDadata) {
    const { addressPart, apartmentPart } = splitAddress(driverData.regAddress!);

    driverCity.setValue(addressPart, { emitEvent: true });
    driverHouseNumber.setValue(apartmentPart, { emitEvent: true });

    driverAddressDaData.setValue(driverData.regAddressDadata, { emitEvent: true });
  }

}

export function setContactsFromAlfaId(phoneValue: string, emailValue: string, contactsFormGroup: FormGroup): void {
  const {
    phone,
    email,
  } = contactsFormGroup?.controls;
  phone.setValue(phoneValue, { emitEvent: false });
  email.setValue(emailValue, { emitEvent: false });
}

function formatDateFromUserAlfaId(inputDate: string): string {
  const [year, month, day] = inputDate.split('-');
  return `${day}.${month}.${year}`;
}

function splitAddress(address: string): { addressPart: string; apartmentPart: string } {
  const regex = /(.*д\s*\d+.*?),\s*кв\s*(\d+)/;
  const match = address.match(regex);

  if (match) {
    return {
      addressPart: match[1],
      apartmentPart: match[2]
    };
  } else {
    // Если регулярное выражение не находит совпадения, вернуть исходный адрес как есть
    return {
      addressPart: address,
      apartmentPart: ''
    };
  }
}
