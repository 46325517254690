import {ElementRef, inject, Injectable, QueryList} from '@angular/core';
import {AccordionPanelComponent} from "ngx-bootstrap/accordion";
import {FormService} from "./form.service";
import {YandexMetrikaService} from "./yandex-metrika.service";

@Injectable({
  providedIn: 'root'
})
export class AccordionService {
  public ym = inject(YandexMetrikaService);
  public formService = inject(FormService);

  public accordionGroups!: QueryList<AccordionPanelComponent>;
  public accordionElementRef!: ElementRef;

  // Перешли на следующий элемен аккордиона
  public isOpenAccordionItem(isNext: boolean, currentPanelClass: string, nextPanelClass: string): void {
    let prevPanelClass = '';
    const currentAccordionItem = this.accordionGroups.find((item: AccordionPanelComponent, index) => {
      if (!isNext && item.panelClass === currentPanelClass) {
        prevPanelClass = this.accordionGroups.get(index-1)?.panelClass!;
        this.ym.onYandexReachGoal(prevPanelClass);
        return item.panelClass === prevPanelClass
      } else {
        this.ym.onYandexReachGoal(currentPanelClass);
        return item.panelClass === currentPanelClass;
      }
    });
    if (currentAccordionItem) {
      currentAccordionItem!.isDisabled = false;
    }

    const nextAccordionItem = this.accordionGroups.find((item: AccordionPanelComponent) => prevPanelClass ? item.panelClass === prevPanelClass : item.panelClass === nextPanelClass);
    nextAccordionItem!.isOpen = true;

    const accordionElement: HTMLElement = this.accordionElementRef?.nativeElement;
    const accordionGroupElements: HTMLElement[] = Array.from(accordionElement?.querySelectorAll('accordion-group'));
    accordionGroupElements.forEach((groupElement, index) => {
      if (groupElement && groupElement.getAttribute('ng-reflect-panel-class') === (isNext ? nextPanelClass : prevPanelClass)) {
        setTimeout(() => {
          groupElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }, 100);
      }
    });
  }

  // Событие клика по элементу аккордиона
  public isOpenChange(currentPanelClass: string): void {
    // this.formService.form.markAsUntouched();
    const accordionElement: HTMLElement = this.accordionElementRef?.nativeElement;
    const accordionGroupElements: HTMLElement[] = Array.from(accordionElement?.querySelectorAll('accordion-group'));

    let disableFollowing = false; // Флаг для отключения последующих элементов

    accordionGroupElements.forEach((groupElement, index) => {
      if (groupElement.getAttribute('ng-reflect-panel-class') === currentPanelClass) {
        this.accordionGroups.get(index)!.isDisabled = false;
        if (this.accordionGroups.get(index)?.isOpen) {
          disableFollowing = true; // Устанавливаем флаг, если условие выполнено
        } else if (currentPanelClass.includes('driverGroup')) {
          // console.log('Сработало driverGroup');
          // Если мы были на вкладке одного из водителей,
          // нам нужно пройтись по всем водителям, найти водителя,
          // который является собственником авто и применить его данные к форме собственника,
          // а так же найти водителя, который является страхователем и изменить контрол ownerIsInsured
          // this.formService.checkDriversForOwnerForm();
        } else if (currentPanelClass.includes('ownerGroup')) {
          // console.log('Сработало ownerGroup');
          // Берем даныне собственника, проходимся по всем водителям и если собственник не выбран как "Другой",
          // то для выбраного водителя меняем контрол driverIsOwner = true и driverIsInsurer = true, в случае если
          // собственник так же является и страхователем
          // this.formService.checkOwnerOrInsurerForDriverForm('owner');
          // this.formService.checkInsurerForDriverForm();
        } else if (currentPanelClass.includes('insurerGroup')) {
          // console.log('Сработало insurerGroup');
          // Берем даныне страхователя, проходимся по всем водителям и если страхователь не выбран как "Другой",
          // то для выбраного водителя меняем контрол driverIsOwner = true и driverIsInsurer = true, в случае если
          // собственник так же является и страхователем, так же данные страхователя оновляем у собственника
          // если у них один водитель
          // this.formService.checkInsurerForDriverForm();
        }
      }

      if (disableFollowing && this.accordionGroups.get(index)?.panelClass !== 'panel-default') {
        this.accordionGroups.get(index)!.isDisabled = true;
      }
    });
  }
}
