import {Component, inject, OnInit} from '@angular/core';
import {JsonPipe, NgOptimizedImage} from "@angular/common";
import {OsagoService} from "../../../../../../shared/services/osago.service";
import {IUpsales} from "../../../../../../shared/interface/offers.interface";

@Component({
  selector: 'app-error-upsale',
  standalone: true,
  imports: [
    NgOptimizedImage,
    JsonPipe
  ],
  templateUrl: './error-upsale.component.html',
  styleUrl: './error-upsale.component.scss'
})
export class ErrorUpsaleComponent implements OnInit {
  public osagoService = inject(OsagoService);
  public selectedUpsale!: IUpsales | undefined;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.selectedUpsale = this.osagoService.selectedOffer?.upsales?.find((upsale: IUpsales) => upsale.isSelected);
  }
  // --------------------------------------------------------------------------

  get isShowCrossError(): boolean {
    this.selectedUpsale = this.osagoService.selectedOffer?.upsales?.find((upsale) => upsale.isSelected);
    return (this.selectedUpsale && this.selectedUpsale.isApproved === false)!;
  }

}
