import {inject, Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot} from "@angular/router";
import {FormService} from "./form.service";

@Injectable({
  providedIn: 'root'
})
export class GuardService {
  private router = inject(Router);
  private formService = inject(FormService);

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (state.url.includes('/success')) {
      return true;
    }
    if (state.url.includes('/prolongation')) {
      return true;
    }
    if (state.url.includes('/data-checking')) {
      return true;
    }
    const applicationId = this.formService.form.get('applicationId')?.value;
    //your logic goes here
    return !!applicationId;
  }
}

export const AuthGuard: CanActivateFn = (next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean => {
  return inject(GuardService).canActivate(next, state);
}
