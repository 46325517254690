import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class LicensePlateService {

  layoutSymbol = {
    'default': [
      'А В Е К',
      'М Н О Р',
      'С Т У Х',
      '{bksp}',
    ]
  }
  layoutNumber = {
    'default': [
      '1 2 3',
      '4 5 6',
      '7 8 9',
      '{bksp} 0 ',
    ]
  }
  layoutComplete = {
    'default': [
    ]
  }
}
