import {Component, EventEmitter, inject, OnDestroy, OnInit, Output} from '@angular/core';
import {FormGroup, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {EMPTY, Subject, Subscription} from 'rxjs';

// Сервисы
import {FormService} from "../../../shared/services/form.service";
import {AppService} from "../../../shared/services/app.service";
import {CacheService} from "../../../shared/services/cache.service";

// Перечисления
import {DaData} from "../../../shared/enums/da-data.enum";
import {scrollToFirstError} from "../../../shared/functions/scrollToFirstError";
import {ContentService} from "../../../shared/services/content.service";
import {debounceTime, distinctUntilChanged, switchMap, takeUntil, tap} from "rxjs/operators";
import {DadataService} from "../../../shared/services/dadata.service";
import {
  resetLegalInsurer,
  resetLegalOwner,
  resetLegalOwnerIsNotLegal,
  setLegalInsurerFromDaData,
  setLegalOwnerFromLegalInsurer
} from "../../../shared/functions/setFormDataFromForm";
import {TypeOrganisation} from "../../../shared/enums/type-organisation";
import {AutocompleteComponent} from "../../../shared/components/autocomplete/autocomplete.component";
import {FormTextFieldComponent} from "../../../shared/components/form-text-field/form-text-field.component";
import {FormSwitchComponent} from "../../../shared/components/form-switch/form-switch.component";
import {FormDateFieldComponent} from "../../../shared/components/form-date-field/form-date-field.component";
import {formatQuerySuggestionAddress} from "../../../shared/functions/da-data";

// Интерфейсы

// Хелперы

@Component({
  selector: 'app-legal-insurer',
  templateUrl: './legal-insurer.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AutocompleteComponent,
    FormTextFieldComponent,
    FormSwitchComponent,
    FormDateFieldComponent
  ],
  styleUrls: ['./legal-insurer.component.scss']
})
export class LegalInsurerComponent implements OnInit, OnDestroy {
  @Output() submitInsurerForm: EventEmitter<boolean> = new EventEmitter<boolean>;

  public contentService = inject(ContentService);

  constructor(public readonly formService: FormService,
              public readonly appService: AppService,
              private readonly cacheService: CacheService,
              private readonly daDataService: DadataService) {
  }

  protected readonly TypeOrganisation = TypeOrganisation;
  // Форма страхователя
  public formLegalOwner = (this.formService.legalOwner as UntypedFormGroup);
  // Форма страховщика
  public formLegalInsurer = (this.formService.legalInsurer as UntypedFormGroup);
  // Флаг страхователь является собственником авто
  public ownerIsInsured = (this.formService.form.get('ownerIsInsured') as UntypedFormControl);
  // Policy
  public policy = (this.formService.form.get('policy') as UntypedFormControl);
  // Индикатор загрузки
  public isLoading = false;
// DaData системные имена
  public daDataSystemName = DaData;
  private destroy$ = new Subject<void>()
  // Подписка на контрол
  private subscription: Subscription = new Subscription();

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    // При заходе на роут, делаем плавный переход вверх
    window.scrollTo({top: 0, behavior: 'smooth'});

    // setLegalInsurerFromDaData(this.formLegalInsurer, this.formLegalOwner);

    this.subscribeIsInsurerControl();
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  // Отправка формы
  public onSubmit(): void {
    this.formLegalInsurer.markAllAsTouched();
    scrollToFirstError();

    if (this.formLegalInsurer.invalid) {
        return;
    }

    this.submitInsurerForm.emit(true);

    this.subscription.add(
      this.cacheService.saveCache().subscribe()
    );
  }

  // При смене значения контрола - страхователь является собственником авто
  private subscribeIsInsurerControl(): void {
    this.subscription.add(
      this.ownerIsInsured.valueChanges
        .subscribe(() => {
          this.formLegalInsurer.markAsUntouched();
        })
    );
  }

  // Выбрали страхователь явялется собственником
  public onsChangeLegalInsurerIsLegalOwner(event: any): void {
    if (event) {
      this.formLegalOwner?.get('legalOwnerIsJuridical')?.setValue(true, { emitEvent: true });
      setLegalOwnerFromLegalInsurer(this.formLegalInsurer.value, this.formLegalOwner.get('legalOwnerIsLegal') as FormGroup);
    } else {
      this.formLegalOwner.get('legalOwnerIsLegal')?.get('legalOwnerValue')?.reset(null, { emitEvent: false });
      this.formLegalOwner.get('legalOwnerIsLegal')?.get('legalOwnerType')?.reset(null, { emitEvent: false });
      setTimeout(() => {
        resetLegalOwner(this.formLegalOwner.get('legalOwnerIsLegal') as FormGroup);
        resetLegalOwnerIsNotLegal(this.formLegalOwner.get('legalOwnerIsNotLegal') as FormGroup);
      }, 100)
    }
  }

  // Выбрали компанию собственника
  public onChangeLegalInsurer(event: any): void {
    resetLegalInsurer(this.formLegalInsurer);
    const addressValue = event?.item?.data?.address?.value;
    this.daDataService.suggestionAddress(
      formatQuerySuggestionAddress(addressValue),
      DaData.ADDRESS_FULL
    ).subscribe((res) => {
      const value = res[0]?.value;
      const data = res[0]?.data;
      const daData = {value, ...data};
      this.formLegalInsurer.get('legalInsurerAddressDaData')?.setValue(daData);
      setLegalInsurerFromDaData(this.formLegalInsurer, this.formLegalOwner);
    });
  }

}
