import { CarIdentificatorsEnum } from '../enums/car-identificators.enum';
import { ICarIdentificatorsSelectList } from "../interface/osago-application.interface";

// Список всех идентификаторов авто
export const CAR_IDENTIFICATORS: ICarIdentificatorsSelectList[] = [
    {
        systemName: CarIdentificatorsEnum.VIN,
        value: 'VIN'
    },
    {
        systemName: CarIdentificatorsEnum.ChassisNumber,
        value: 'Номер шасси'
    },
    {
        systemName: CarIdentificatorsEnum.BodyNumber,
        value: 'Номер кузова'
    }
];
