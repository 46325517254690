import {Component, ViewEncapsulation} from '@angular/core';
import {faqData} from "../../constants/faq";

@Component({
  selector: 'app-faq',
  standalone: true,
  templateUrl: './faq.component.html',
  styleUrl: './faq.component.scss',
  encapsulation: ViewEncapsulation.Emulated
})
export class FaqComponent {
  faqData = faqData;
  selectedCategoryIndex = 0;

  selectCategory(index: number): void {
    this.selectedCategoryIndex = index;
    this.closeAllAccordions();
  }

  closeAllAccordions(): void {
    const openAccordions = document.querySelectorAll('.accordion-collapse.show');
    openAccordions.forEach((accordion) => {
      accordion.classList.remove('show');
    });

    // Убираем фокус и активное состояние с кнопок аккордеона
    const activeButtons = document.querySelectorAll('.accordion-button:not(.collapsed)');
    activeButtons.forEach((button) => {
      button.classList.add('collapsed');
      button.setAttribute('aria-expanded', 'false');
      (button as HTMLElement).blur();
    });

    // Убираем активное состояние с заголовков аккордеона
    const activeHeaders = document.querySelectorAll('.accordion-header');
    activeHeaders.forEach((header) => {
      header.classList.remove('active');
    });
  }
}
