import {FormGroup} from "@angular/forms";
import {CarDocumentTypesEnum} from "../enums/car-document-types.enum";
import {CarIdentificatorsEnum} from "../enums/car-identificators.enum";
import {PurposeOfUseEnum} from "../enums/purpose-of-use.enum";
import {IOsagoCache} from "../interface/cache.interface";
import {setIndividualFormDataFromCache} from "./setIndividualFormDataFromCache";
import {setLegalFormDataFromCache} from "./setLegalFormDataFromCache";
import {IOsago} from "../interface/osago-application.interface";
import {setFormDataFromCacheByAppId} from "./setFormDataFromCacheByAppId";
import moment from "moment-mini";

export function setFormDataFromCache(cacheData: IOsagoCache, form: FormGroup, fromFastProlongation: boolean = false): Promise<void> {
  return new Promise<void>(async (resolve) => {
    form.get('carCharacteristicsRequestId')?.setValue(cacheData.carCharacteristicsRequestId);

    if (cacheData.policyParameters.policyStartDate) {
      checkPolicyStartDate(cacheData, fromFastProlongation);
      form.get('policyStartDate')?.setValue(cacheData.policyParameters.policyStartDate);
    }
    if (cacheData.cpaClientUid) {
      form.get('cpaClientUid')?.setValue(cacheData.cpaClientUid);
    }

    const cacheOwner = cacheData.owner;
    const cacheInsurer = cacheData.insured;

    // Применяем данные авто
    const carData = form.get('carData') as FormGroup;
    const cacheCarData = cacheData.carData;
    setCarData(cacheCarData, carData, cacheData.carCharacteristicsRequestId);

    form.get('driversWithoutRestriction')?.setValue(!cacheData.hasDriversRestriction);

    const ownerIsInsured = cacheData.owner.ownerIsInsured;
    const legalInsurerFormGroup = form.get('legalInsurer') as FormGroup;
    legalInsurerFormGroup?.get('legalInsurerIsLegalOwner')?.setValue(ownerIsInsured)

    // Собственник является страхователем
    form.get('ownerIsInsured')?.setValue(cacheData.owner?.ownerIsInsured);

    // Проверяем, если анкета физ или юр лицо
    const isJuridical = cacheData?.owner?.isJuridical
      || (cacheData?.owner?.juridicalOpfType >= 0 && cacheData?.insured?.juridicalOpfType >= 0)
      || cacheData?.insured?.isJuridical
      || (cacheData.owner.ownerIsInsured ? !!cacheData.owner?.legalPersonData : !!cacheData.insured?.legalPersonData);

    const isLegalDataFromCacheByAppId = (cacheData as unknown as IOsago).ownerCity !== undefined;

    // Если анкета физ или юр лицо
    isJuridical
      ? isLegalDataFromCacheByAppId
        ? await setFormDataFromCacheByAppId(cacheData, form, isJuridical)
        : await setLegalFormDataFromCache(cacheData, form)
      : await setIndividualFormDataFromCache(cacheData, form);

    if (cacheOwner && (cacheOwner.phone || cacheOwner.email) || cacheInsurer && (cacheInsurer.phone || cacheInsurer.email)) {
      const phone = cacheOwner.phone ? cacheOwner.phone : cacheInsurer.phone;
      const email = cacheOwner.email ? cacheOwner.email : cacheInsurer.email;

      // Применяем данные контактов
      const contactsFormGroup = form.get('contacts') as FormGroup;
      setContacts(phone, email, contactsFormGroup);
    }
    resolve();
  });
}

// Применяем данные авто
export function setCarData(cacheCarData: any, carData: FormGroup, carCharacteristicsRequestId?: string): void {
  console.log(cacheCarData);
  const {
    hasNoLicensePlate,
    licensePlate,
    modelId,
    modelName,
    brandId,
    brandName,
    productionYear,
    horsePower,
    horsePowerKw,
    carDocument,
    carIdentificators,
    carWithPrince,
    purposeOfUse,
    horsePowerSwitch
  } = carData.controls;

  hasNoLicensePlate.setValue(!cacheCarData.licensePlate, { emitEvent: false });
  licensePlate.setValue(cacheCarData.licensePlate, { emitEvent: false });

  brandId.setValue(cacheCarData.brandId);
  modelName.setValue(cacheCarData.modelName, { emitEvent: true });
  modelId.setValue(cacheCarData.modelId, { emitEvent: true });
  brandName.setValue(cacheCarData.brandName, { emitEvent: false });
  productionYear.setValue(cacheCarData.productionYear?.toString(), { emitEvent: true });
  horsePower.setValue(cacheCarData.carPower?.toString(), { emitEvent: false });
  if (!cacheCarData.carPower && cacheCarData.carPowerKw) {
    horsePowerSwitch.setValue(true, { emitEvent: true });
  }
  horsePowerKw.setValue(cacheCarData.carPowerKw?.toString(), { emitEvent: false });

  carWithPrince.setValue(cacheCarData.hasTrailer, { emitEvent: false });
  purposeOfUse.setValue(cacheCarData.purposeOfUseType && typeof cacheCarData.purposeOfUseType === 'number'
    ? PurposeOfUseEnum.Personal
    : cacheCarData.purposeOfUseType
      ? cacheCarData.purposeOfUseType
      : PurposeOfUseEnum.Personal);

  // Применяем данные документа авто
  setCarDocuments(cacheCarData.carDocument, carDocument as FormGroup, carCharacteristicsRequestId);

  // Применяем данные vin, номер кузова или номер шасси
  setCarIdentificators(cacheCarData.carIdentificators, carIdentificators as FormGroup, carCharacteristicsRequestId);
}

// Применяем данные документа авто
export function setCarDocuments(carDocument: any, carDocumentFormGroup: FormGroup, carCharacteristicsRequestId?: string): any {
  const {
    documentIssueDate,
    documentNumber,
    documentSeries,
    documentType,
    stsNumber,
    stsDate,
    ptsNumber,
    ptsDate,
    ePtsNumber,
    ePtsDate
  } = carDocumentFormGroup.controls;

  documentType.setValue(carDocument.documentType, { emitEvent: true });
  documentSeries.setValue(carDocument.documentSeries, { emitEvent: false });
  documentNumber.setValue(carDocument.documentNumber, { emitEvent: false });
  documentIssueDate.setValue(carDocument.documentIssueDate, { emitEvent: false });

  if (carDocument.documentType === CarDocumentTypesEnum.STS) {
    const stsNumberValue = carDocument.documentSeries + carDocument.documentNumber;
    const validateStsNumberValue= checkStarsAndRequestId(stsNumberValue, carCharacteristicsRequestId);
    stsNumber.setValue(validateStsNumberValue, { emitEvent: false });
    stsDate.setValue(carDocument.documentIssueDate, { emitEvent: false });
  } else if (carDocument.documentType === CarDocumentTypesEnum.PTS) {
    const ptsNumberValue = carDocument.documentSeries + carDocument.documentNumber;
    const validatePtsNumberValue= checkStarsAndRequestId(ptsNumberValue, carCharacteristicsRequestId);
    ptsNumber.setValue(validatePtsNumberValue, { emitEvent: false });
    ptsDate.setValue(carDocument.documentIssueDate, { emitEvent: false });
  } else if (carDocument.documentType === CarDocumentTypesEnum.ePTS) {
    const validateEPtsNumberValue= checkStarsAndRequestId(carDocument.documentNumber, carCharacteristicsRequestId);
    ePtsNumber.setValue(validateEPtsNumberValue, { emitEvent: false });
    ePtsDate.setValue(carDocument.documentIssueDate, { emitEvent: false });
  }
}

// Применяем данные vin, номер кузова или номер шасси
export function setCarIdentificators(
  carIdentificators: any,
  carIdentificatorsFormGroup: FormGroup,
  carCharacteristicsRequestId?: string
): any {
  const {
    identifier,
    vinNumber,
    bodyNumber,
    chassisNumber
  } = carIdentificatorsFormGroup.controls;

  if (carIdentificators?.vin) {
    const validateVinNumberValue= checkStarsAndRequestId(carIdentificators.vin, carCharacteristicsRequestId);
    identifier.setValue(CarIdentificatorsEnum.VIN, { emitEvent: true });
    vinNumber.setValue(validateVinNumberValue, { emitEvent: false });
  }

  if (carIdentificators?.bodyNumber) {
    const validateBodyNumberValue= checkStarsAndRequestId(carIdentificators.bodyNumber, carCharacteristicsRequestId, true);
    identifier.setValue(CarIdentificatorsEnum.BodyNumber, { emitEvent: true });
    bodyNumber.setValue(validateBodyNumberValue, { emitEvent: false });
  }

  if (carIdentificators?.chassisNumber) {
    const validateChassisNumberValue= checkStarsAndRequestId(carIdentificators.chassisNumber.toString(), carCharacteristicsRequestId, true);
    identifier.setValue(CarIdentificatorsEnum.ChassisNumber, { emitEvent: true });
    chassisNumber.setValue(validateChassisNumberValue, { emitEvent: true });
  }
}

// Функция для провенрки на наличие звездочек в конце строки и наличие carCharacteristicsRequestId
// Возвращаем пустую строку если клиенту нужно будет зановов ввести значение в контролле - returnNull = true
function checkStarsAndRequestId(value: string, carCharacteristicsRequestId?: string, returnNull: boolean = false): string {
  return value.endsWith('**') && !carCharacteristicsRequestId
    ? returnNull
      ? ''
      : value.slice(0, -2)
    : value;
}

// Применяем контактные данные
export function setContacts(phoneValue: string, emailValue: string, contactsFormGroup: FormGroup): void {
  const {
    phone,
    email,
  } = contactsFormGroup?.controls;
  phone.setValue(phoneValue, { emitEvent: false });
  email.setValue(emailValue, { emitEvent: false });
}

export function checkPolicyStartDate(osago: IOsagoCache, fromFastProlongation: boolean = false) {
  // Преобразовываем дату в случае если дата пришла в прошлом
  const policyStartDate = osago.policyParameters.policyStartDate;

  if (policyStartDate) {
    // Преобразуем дату из формата DD.MM.YYYY в объект moment
    const policyDate = moment(policyStartDate, 'DD.MM.YYYY');
    const currentDate = moment().startOf('day');

    // Проверяем, если policyStartDate меньше или равен текущей дате
    if (policyDate.isSameOrBefore(currentDate)) {
      // Устанавливаем policyStartDate на следующий день
      osago.policyParameters.policyStartDate = currentDate.add(fromFastProlongation ? 1 : 4, 'day').format('DD.MM.YYYY');
    }
  }
}
