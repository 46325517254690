import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormService} from "../../../shared/services/form.service";
import {AppService} from "../../../shared/services/app.service";
import {Subscription} from "rxjs";
import {KbmService} from "../../../shared/services/kbm.service";
import {IDriverForm} from "../../../shared/interface/osago-application.interface";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {JsonPipe} from "@angular/common";
import {environment} from "@environment/environment";

@Component({
  selector: 'app-kbm',
  standalone: true,
  templateUrl: './kbm.component.html',
  imports: [
    CollapseModule,
    JsonPipe
  ],
  styleUrls: ['./kbm.component.scss']
})
export class KbmComponent implements OnInit, OnDestroy {
  constructor(public readonly formService: FormService,
              public readonly appService: AppService,
              private readonly kbmService: KbmService) {
  }

  // Подписка
  private subscription: Subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;
  // Показываем данные формы для редактирования
  public isShowEditInfo = true;
  // Данные водителей
  public drivers: IDriverForm[] = [];
  // Changed the type of kbmResults to IDriver[]
  kbmResults: IDriverForm[] = [];

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.drivers = this.formService.drivers.value;
    if (!environment.onlyLegalForm) {
      this.getDriverKbm();
    }
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  // Получаем данные КБМ для каждого водителя
  private getDriverKbm(): void {
    this.processDrivers();
  }

  processDrivers() {
    this.drivers.forEach(driver => {
      if (driver.driverFirstName && driver.driverLastName && driver.driverBirthDate) {
        this.kbmService.createKbmReport(driver)
          .subscribe(response => {
            const requestId = response?.value?.requestId;
            if (requestId) {
              this.kbmService.pollKbmReport(requestId)
                .subscribe(kbmResponse => {
                  if (kbmResponse.value.status === 'Complete') {
                    const newDriver: IDriverForm = {
                      ...driver,
                      kbm: kbmResponse.value.kbmData.kbm,
                      driverDataNeedCheck: kbmResponse.value.kbmData.driverDataNeedCheck,
                      usedForCalculation: false
                    };
                    this.kbmResults.push(newDriver);
                    if (this.kbmResults.length === this.drivers.length) {
                      this.sortDriversByKbm();
                    }
                  }
                });
            }
          });
      }
    });
  }

  sortDriversByKbm() {
    this.kbmResults.sort((a: IDriverForm, b: IDriverForm) => b.kbm! - a.kbm!);
    this.kbmResults[0].usedForCalculation = true;
  }
}
