<div class="panel">
  <div class="card border-0 mb-3">
    <div class="panel-heading card-header panel-disabled">
      <div class="form-check mb-0">
        <input [formControl]="formService.privacyLicenseFormControl" [class.is-invalid]="formService.privacyLicenseFormControl.invalid" class="form-check-input" type="checkbox" value="" id="defaultCheck1" checked>
        <label class="form-check-label" for="defaultCheck1">
          Я соглашаюсь с <a class="text-decoration-none" href="https://insapp.ru/polzovatelskoe-soglashenie/" target="_blank">правилами предоставления информации</a>
        </label>
        @if (formService.privacyLicenseFormControl.invalid) {
          <div class="invalid-feedback">
            &nbsp;&nbsp;Ознакомьтесь с правилами предоставления информации
          </div>
        }
      </div>
    </div>
  </div>
</div>
