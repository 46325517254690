import {Component, inject, Input} from '@angular/core';
import {FormService} from "../../../../services/form.service";
import {NavigationService} from "../../../../services/navigation.service";
import {conformToMask} from "angular2-text-mask";

@Component({
  selector: 'app-edit-info-legal-owner-is-not-legal',
  standalone: true,
  imports: [],
  templateUrl: './edit-info-legal-owner-is-not-legal.component.html',
  styleUrl: './edit-info-legal-owner-is-not-legal.component.scss'
})
export class EditInfoLegalOwnerIsNotLegalComponent {
  // Параметр fromFastProlongation
  @Input() fromFastProlongation: boolean = false;

  private formService = inject(FormService);
  private navigationService = inject(NavigationService);

  // Данные формы
  public formData = this.formService.form.value;
  public dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
  public passportLicense = [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];

  // Переход по роуту
  public navigate(route: string): void {
    this.navigationService.navigate(route);
  }

  // Возвращаем паспортные данные в формате маски
  public getPassportLicenseMask(value: string): string {
    if (value) {
      const conformedValue = conformToMask(value, this.passportLicense, {});
      return conformedValue.conformedValue;
    } else {
      return '';
    }
  }

  // Возвращаем дату в формате маски
  public getDateMask(value: string): string {
    if (value) {
      const conformedValue = conformToMask(value, this.dateMask, {});
      return conformedValue.conformedValue;
    } else {
      return '';
    }
  }
}
