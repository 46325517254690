import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, RouterLink} from "@angular/router";
import {RedirectLinkComponent} from "./redirect-link/redirect-link.component";
import {Subscription} from "rxjs";
import {YandexMetrikaService} from "../../shared/services/yandex-metrika.service";
import {YandexMetrikaGoalsEnum} from "../../shared/enums/yandex-metrika-goals.enum";
import {AppService} from "../../shared/services/app.service";
import {NavigationService} from "../../shared/services/navigation.service";
import {ROUTES} from "../../shared/enums/routes.enum";

@Component({
  selector: 'app-success',
  standalone: true,
  templateUrl: './success.component.html',
  imports: [
    RouterLink,
    RedirectLinkComponent
  ],
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit, OnDestroy {

  private appService = inject(AppService);
  private navigationService = inject(NavigationService);

  private route = inject(ActivatedRoute);
  private ym = inject(YandexMetrikaService);

  // если пришли из оффлайн формы
  public fromOfflineForm: boolean = false;
  public showPartnerOffer: boolean = false;
  public detailsLink: string = '';
  private _subscription: Subscription = new Subscription();

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.SuccessPage);
    // Получение параметров из URL
    this.route.queryParamMap.subscribe(params => {
      this.fromOfflineForm = params.get('isOffline') === 'true';
      this.showPartnerOffer = params.get('fromOsago') === 'true';

      // Составление URL для detailsLink
      const urlParams = new URLSearchParams(window.location.search);
      this.detailsLink = 'https://kasko.ab.insapp.ru/?' + urlParams;
    });
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }
  // --------------------------------------------------------------------------

  // Вернуться на главную
  public navigateToMainPage(): void {
    this._subscription.add(
      this.appService.loadApp().subscribe(() => {
        this.navigationService.navigate(ROUTES.MainPage)
      })
    );
  }

}
