import { Component } from '@angular/core';

@Component({
  selector: 'app-offer-placeholder',
  standalone: true,
  imports: [],
  templateUrl: './offer-placeholder.component.html',
  styleUrl: './offer-placeholder.component.scss'
})
export class OfferPlaceholderComponent {

}
