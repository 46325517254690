<div class="card gray pre-price-calculator mb-3 mb-sm-4">
  <div class="card-body p-3 p-sm-4">
    <div class="row">
      <div class="col">
        <div class="head-text mb-1 mb-sm-0">
          {{ contentService.content.formComponent.carDataComponent.prePriceCalculatorComponent.title }}
        </div>
      </div>
    </div>
    <div class="secondary-text"
         [innerHTML]="contentService.content.formComponent.carDataComponent.prePriceCalculatorComponent.subTitle"></div>

    <div class="row align-items-end mt-4">
      <div class="col-4 col-sm-auto price-item">
        <div class="label">Мин</div>
        <div class="value">
          {{ price && price.minimalCost ? (price.minimalCost | currencyRU) : '----' }}
        </div>
      </div>
      <div class="col-4 col-sm-auto price-item">
        <div class="label">Сред</div>
        <div class="value">
          {{ price && price.middleCost ? (price.middleCost | currencyRU) : '----' }}
        </div>
      </div>
      <div class="col-4 col-sm-auto price-item">
        <div class="label">Макс</div>
        <div class="value">
          {{ price && price.maximalCost ? (price.maximalCost | currencyRU) : '----' }}
        </div>
      </div>
      <div class="col-12 col-sm text-end mt-3 mt-sm-0">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>
