import {inject, Injectable} from '@angular/core';
import {concatMap, Observable, of, timer} from 'rxjs';

import {SettingsService} from "./settings.service";
import {HttpClient} from '@angular/common/http';
import {environment} from '@environment/environment';
import {ProlongationFormService} from './prolongationForm.service';
import {FormService} from './form.service';
import {getFormatDate} from "../functions/formatDate";

@Injectable({
  providedIn: 'root'
})
export class ProlongationService {

  private formService = inject(FormService);
  private prolongationFormService = inject(ProlongationFormService);
  private http = inject(HttpClient);
  private settingsService = inject(SettingsService);

  public getProlongationPolicy(): Observable<any> {
    const {value} = this.prolongationFormService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: this.formService.form?.get('applicationId')?.value,
      birthDate: getFormatDate(value.policyNumber?.prolongationFormBirthDate),
      email: value.policyNumber.email,
      phone: value.policyNumber.phone,
      previousPolicy: {
        "PreviousPolicySeries": value.policyNumber.previousPolicySeries,
        "PreviousPolicyNumber": value.policyNumber.previousPolicyNumber,
        "PreviousPolicyInsurerType": "Alfa"
      }
    };
    return this.http.post(`${environment.apiUrl}app/EnrichOsagoProlongation`, request)
  }

  public getProlongationPolicyFromOsagoReport(res?: any): Observable<any> {
    const {value} = this.prolongationFormService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: this.formService.form?.get('applicationId')?.value,
      birthDate: getFormatDate(value.gosNumber?.prolongationFormBirthDate),
      email: value.gosNumber.email,
      phone: value.gosNumber.phone,
      previousPolicy: {
        "PreviousPolicySeries": res.value.osagoData.series,
        "PreviousPolicyNumber": res.value.osagoData.number,
        "PreviousPolicyInsurerType": "Alfa"
      }
    };
    return this.http.post(`${environment.apiUrl}app/EnrichOsagoProlongation`, request)
  }

  public createOsagoReport(): Observable<any> {
    const {value} = this.prolongationFormService.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: this.formService.form?.get('applicationId')?.value,
      licensePlate: value.gosNumber?.licensePlate,
      clientId: this.settingsService.clientId
    };
    return this.http.post(environment.hostUrl + 'enrichment/CreateOsagoReport', request)
      .pipe(
        concatMap((res: any) => res.result && res.value?.requestId
          ? this.getLicenseVerificationResultWithRetry(res.value.requestId)
          : of(res)
        ),
      );
  }

  private getLicenseVerificationResultWithRetry(requestId: string): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey,
      requestId
    };

    return this.getLicenseVerificationResult(request).pipe(
      concatMap((res) => {
        if (res?.value?.status === 'Processing') {
          // Если статус 'Processing', повторяем запрос с задержкой в 1 секунду
          return timer(1000).pipe(concatMap(() => this.getLicenseVerificationResultWithRetry(requestId)));
        } else {
          return of(res);
        }
      })
    );
  }

  // Получаем данные авто по ключу
  private getLicenseVerificationResult(request: any): Observable<any> {
    return this.http.post(environment.hostUrl + 'enrichment/GetOsagoReport', request);
  }

}

