import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {
  ReactiveFormsModule,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {concatMap, Subject, Subscription} from 'rxjs';
import {DaData} from "../../../../shared/enums/da-data.enum";
import {FormService} from "../../../../shared/services/form.service";
import {ContentService} from "../../../../shared/services/content.service";
import {ValidationService} from "../../../../shared/services/validation.service";
import {scrollToFirstError} from "../../../../shared/functions/scrollToFirstError";
import {DadataService} from "../../../../shared/services/dadata.service";
import {
  resetOwnerOrInsurerFromDriver,
  setOwnerOrInsurerFromDriver,
} from "../../../../shared/functions/setFormDataFromForm";
import {CacheService} from "../../../../shared/services/cache.service";
import {IDriverForm} from "../../../../shared/interface/osago-application.interface";
import {FormSwitchComponent} from "../../../../shared/components/form-switch/form-switch.component";
import {AutocompleteComponent} from "../../../../shared/components/autocomplete/autocomplete.component";
import {FormTextFieldComponent} from "../../../../shared/components/form-text-field/form-text-field.component";
import {AlfaIdComponent} from "../../../../../../landings/osago-ab/app/alfa-id/alfa-id.component";
import {AppService} from "../../../../shared/services/app.service";
import {WidgetStatuses} from "../../../../shared/enums/widgetStatuses.enum";
import {OsagoService} from "../../../../shared/services/osago.service";
import {environment} from "@environment/environment";
import {IUrlParams} from "../../../../shared/interface/url-params.interface";
import {getFormatDate} from "../../../../shared/functions/formatDate";

// Сервисы

// Перечисления

@Component({
  selector: 'app-driver',
  standalone: true,
  templateUrl: './driver.component.html',
  styleUrl: './driver.component.scss',
    imports: [
        ReactiveFormsModule,
        FormSwitchComponent,
        AutocompleteComponent,
        FormTextFieldComponent,
        AlfaIdComponent
    ]
})
export class DriverComponent implements OnInit, OnDestroy {
  @Output() submitDriversForm: EventEmitter<boolean> = new EventEmitter<boolean>;
  // Форма водителя
  @Input() formDriver!: UntypedFormGroup;
  // Индекс текущего водителя
  @Input() driverIndex!: number;
  // Индикатор зарузки
  @Input() isLoading!: boolean;

  public appService = inject(AppService);

  protected readonly environment = environment;
  // Форма собственника
  public formOwner = (this.formService.form.get('owner') as UntypedFormGroup);
  // Форма страхователя
  public formInsurer = (this.formService.form.get('insurer') as UntypedFormGroup);
  // Флаг страхователь является собственником авто
  public ownerIsInsured = (this.formService.form.get('ownerIsInsured') as UntypedFormControl);
  // Подписка
  private subscription: Subscription = new Subscription();
  // Параметры url
  public urlParams!: IUrlParams;

  // DaData системные имена
  public daDataSystemName = DaData;
  private destroy$ = new Subject<void>();
  // Флаг - водители без ограничений
  public driversWithoutRestriction = (this.formService.form.get('driversWithoutRestriction') as UntypedFormControl);

  constructor(public readonly formService: FormService,
              public readonly contentService: ContentService,
              private readonly validationService: ValidationService,
              public readonly cacheService: CacheService,
              public readonly osagoService: OsagoService) {
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.urlParams = this.appService.getUrlParams();
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  // --------------------------------------------------------------------------

  // Отправка формы
  public onSubmit(): void {
    this.formDriver.updateValueAndValidity()
    this.formDriver.markAllAsTouched();
    this.formOwner.markAsUntouched();
    this.formInsurer.markAsUntouched();
    const isValidCarDataPage = this.validationService.validationCarDataForm;

    if ((!isValidCarDataPage || !this.formDriver.valid) && !this.driversWithoutRestriction?.value) {
      scrollToFirstError();
      return;
    }
    this.checkOtherDriver();
    this.submitDriversForm.emit(true);

    this.appService.isShowBtnAlfaId = false;

    this.subscription.add(
      this.cacheService.saveCache()
        .pipe(
          concatMap(() => this.osagoService.setWidgetStatus(WidgetStatuses.DriversScreen))
        )
        .subscribe()
    );
  }

  // Добавить водителя
  public addDrivers(): void {
    this.formService.addDriver();
    setTimeout(() => {
      this.submitDriversForm.emit(true);
    })
  }

  // Удалить пользователя
  public removeDriver(driverIndex: number): void {
    this.formService.removeDriver(driverIndex);
    this.submitDriversForm.emit(false);
  }

  public checkActiveDriver(driver: IDriverForm): boolean {
    const {value} = this.formDriver;
    return (driver.driverFirstName === value.driverFirstName
      && driver.driverLastName === value.driverLastName
      && driver.driverMiddleName === value.driverMiddleName
      && driver.driverBirthDate === value.driverBirthDate);
  }

  // Выбрали "Водитель является собственником" или "Водитель является страхователем"
  public changeDriverIsOwnerOrInsurer(event: boolean, formGroupName: string): void {
    const formGroup = formGroupName === 'owner' ? this.formOwner : this.formInsurer;
    resetOwnerOrInsurerFromDriver(formGroup, formGroupName);

    if (formGroupName === 'owner') {
      // Если мы выбрали driverIsOwner
      const findDriverIsOwner = this.formService.drivers.value.find((driver: IDriverForm) => driver.driverIsOwner);
      // Если такой водитель не найден, значит это "Другой"
      this.formService.ownerIsOtherDriver = !findDriverIsOwner;
    } else if (formGroupName === 'insurer') {
      // Если мы выбрали driverIsInsurer
      const findDriverIsInsurer = this.formService.drivers.value.find((driver: IDriverForm) => driver.driverIsInsurer);
      // Если такой водитель не найден, значит это "Другой"
      this.formService.insurerIsOtherDriver = !findDriverIsInsurer;
    }

    this.formService.drivers.controls.forEach((driver) => {
      // Получаем текущего активного водителя
      const activeDriver = this.checkActiveDriver(driver.value);
      // Для всех неактивных, отключаем выбарный контрол driverIsOwner или driverIsInsurer
      if (activeDriver) {
        if (formGroupName === 'owner') {
          this.formService.ownerIsInsured.setValue(event && driver.get('driverIsInsurer')?.value, { emitEvent: false });
        } else if (formGroupName === 'insurer') {
          this.formService.ownerIsInsured.setValue(event && driver.get('driverIsOwner')?.value, { emitEvent: false });
        }

        if (event) {
          setOwnerOrInsurerFromDriver(this.formDriver.value, formGroup, formGroupName);
        }
      } else {
        if (formGroupName === 'owner') {
          driver.get('driverIsOwner')?.setValue(false, { emitEvent: true });
        } else if (formGroupName === 'insurer') {
          driver.get('driverIsInsurer')?.setValue(false, { emitEvent: true });
        }

        // TODO тут нужн будет вставлять данные от водителя "Другой", предварительно их сохраняя
      }
    });
  }

  public checkOtherDriver(): void {
    const {value} = this.formOwner;
    const driverIndex = this.formService.drivers.value?.findIndex((driver: IDriverForm) => {
      return (driver.driverFirstName === value.ownerFirstName
        && driver.driverLastName === value.ownerLastName
        && driver.driverMiddleName === value.ownerMiddleName
        && getFormatDate(driver.driverBirthDate) === getFormatDate(value.ownerBirthDate))
    });
    if (driverIndex === -1) {
      this.formService.ownerIsOtherDriver = true;
    }
  }
}
