<h3 class="page-title mt-4 mb-4">Создание полиса</h3>

<app-policy-data [isCollapse]="true"></app-policy-data>

@if (isPaymentLinkResult) {
  <app-selected-offer
    [paymentLink]="osagoService.paymentLink"
    (emitOnChangeCreatePolicy)="getPaymentLink($event)"></app-selected-offer>
} @else {
  <app-error-payment-link></app-error-payment-link>

  @if (environment.partnerSystemName !== 'rgs-iframe') {

    <h3 class="page-title d-block mt-4 mb-4">Другие предложения</h3>

    <app-timer [isLoading]="isLoading" [min]="3" [sec]="30"></app-timer>

    <div class="offers-list">
      @for(offer of osagoService.offers; track offer.offerId) {
        @if (!offer.isSelected) {
          <app-offer-item [offer]="offer"></app-offer-item>
        }
      }
    </div>
  }

  @if (isLoading) {
    <app-offer-placeholder></app-offer-placeholder>
  }

}

<ng-template #templateSuccessPaymentLink>
  <div class="modal-body text-center">
    <div class="main-title-item mb-2 text-wrap">Ваш полис готов</div>
    <div class="second-title-item custom-second-title-item text-wrap">
      На Вашу <strong>электронную почту</strong> отправлено письмо с <strong>образцом</strong> полиса и <strong>ссылкой на оплату</strong>.
      Нажмите <strong>"Перейти к оплате"</strong>, чтобы ознакомиться с образцом полиса и <strong>оплатить сейчас</strong>.
    </div>

    <div class="row mt-4 d-flex justify-content-center">
      <div class="col-12 col-sm-auto">
        <button type="button" class="btn btn-primary btn-lg w-100" (click)="modalRef?.hide()">Продолжить</button>
      </div>
    </div>
  </div>
</ng-template>
