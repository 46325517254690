import {IPropertyControls} from "../interface/property-controls.interface";

export const propertyControls: IPropertyControls = {
  "apiKey": {
    "$id": "apiKey",
    "type": "text",
    "label": "apiKey",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        }
      ],
      "messages": {
        "required": "Отсутствует apiKey"
      }
    }
  },
  "applicationId": {
    "$id": "applicationId",
    "type": "text",
    "label": "applicationId",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        }
      ],
      "messages": {
        "required": "Отсутствует applicationId"
      }
    }
  },
  "licensePlate": {
    "$id": "licensePlate",
    "type": "text",
    "label": "",
    "placeholder": "A 000 AA 000",
    "maskName": "licensePlate",
    "validation": {
      "values": [
      ],
      "messages": {
        "pattern": "Неверный формат номера авто"
      }
    }
  },
  "brandId": {
    "$id": "brandId",
    "type": "autocomplete",
    "label": "Марка",
    "idKeyName": "brandId",
    "typeaheadOptionField": "brandName",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        }
      ],
      "messages": {
        "required": "Выберите марку автомобиля"
      }
    }
  },
  "modelId": {
    "$id": "modelId",
    "type": "autocomplete",
    "label": "Модель",
    "idKeyName": "modelId",
    "typeaheadOptionField": "modelName",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        }
      ],
      "messages": {
        "required": "Выберите модель автомобиля"
      }
    }
  },
  "horsePower": {
    "$id": "horsePower",
    "type": "text",
    "inputmode": "numeric",
    "label": "Мощность (л.с.)",
    "pattern": "[0-9]+",
    "maskName": "horsePower",
    "numericThousandSeparator": " ",
    "numericMaxLength": 4,
    "maxLength": 2000,
    "minLength": 2,
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "min",
          "validValue": 30
        },
        {
          "validName": "max",
          "validValue": 2000
        }
      ],
      "messages": {
        "required": "Укажите мощность Вашего автомобиля в л.с.",
        "max": "Не более 2000 л.с.",
        "min": "Не менее 30 л.с."
      }
    }
  },
  "productionYear": {
    "$id": "productionYear",
    "type": "text",
    "inputmode": "numeric",
    "label": "Год выпуска",
    "pattern": "[0-9]+",
    "maskName": "productionYear",
    "numericMaxLength": 4,
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "min",
          "validValue": 1970
        }
      ],
      "messages": {
        "required": "Заполните год выпуска автомобиля",
        "min": "Не менее 1970г"
      }
    }
  },
  "documentType": {
    "$id": "documentType",
    "type": "select",
    "label": "Документ на автомобиль",
    "idKeyName": "systemName",
    "validation": {
      "messages": {
        "required": "Заполните тип документа"
      }
    }
  },
  "stsNumber": {
    "$id": "stsNumber",
    "type": "text",
    "label": "Серия и номер СТС",
    "maskName": "stsNumber",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/licenseSts.png' alt='' class='license-img' />",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true,
          "validMessage": "Заполните номер СТС"
        },
        {
          "validName": "customPattern",
          "validValue": "^\\d{2}[0-9А-Яа-яЁё]{2}(\\d{6}|\\d{4}\\*{2})$",
          "validMessage": "Неверный формат СТС номера"
        }
      ],
      "messages": {
        "required": "Заполните номер СТС",
        "pattern": "Неверный формат СТС номера"
      }
    }
  },
  "stsDate": {
    "$id": "stsDate",
    "type": "text",
    "inputmode": "numeric",
    "label": "Дата выдачи СТС",
    "maskName": "documentDate",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/licenseStsDate.png' alt='' class='license-img' />",
    "validation": {
      "values": [
        {
          "validName": "stsDate"
        }
      ]
    }
  },
  "ptsNumber": {
    "$id": "ptsNumber",
    "type": "text",
    "label": "ПТС",
    "maskName": "ptsNumber",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/numberPts.png' alt='' class='license-img' />",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true,
          "validMessage": "Заполните номер ПТС"
        },
        {
          "validName": "customPattern",
          "validValue": "[0-9]{2}[0-9A-Za-zА-Яа-яЁё]{2}(\\d{6}|\\d{4}\\*{2})",
          "validMessage": "Неверный формат ПТС номера"
        }
      ],
      "messages": {
        "required": "Заполните номер ПТС",
        "pattern": "Неверный формат ПТС номера"
      }
    }
  },
  "ptsDate": {
    "$id": "ptsDate",
    "type": "text",
    "inputmode": "numeric",
    "label": "Дата выдачи ПТС",
    "maskName": "documentDate",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/licensePtsDate.png' alt='' class='license-img' />",
    "validation": {
      "values": [
        {
          "validName": "ptsDate"
        }
      ]
    }
  },
  "ePtsNumber": {
    "$id": "ePtsNumber",
    "type": "text",
    "label": "ЕПТС",
    "inputmode": "numeric",
    "maskName": "ePtsNumber",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/numberEPts.png' alt='' class='license-img-horizontal' />",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true,
          "validMessage": "Заполните номер ЕПТС"
        },
        {
          "validName": "customPattern",
          "validValue": "[0-9]{15}",
          "validMessage": "Неверный формат ЕПТС номера"
        }
      ],
      "messages": {
        "required": "Заполните номер ЕПТС",
        "pattern": "Неверный формат ЕПТС номера"
      }
    }
  },
  "ePtsDate": {
    "$id": "ePtsDate",
    "type": "text",
    "inputmode": "numeric",
    "label": "Дата выдачи ЕПТС",
    "maskName": "documentDate",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/licenseEPtsDate.png' alt='' class='license-img-horizontal' />",
    "validation": {
      "values": [
        {
          "validName": "ePtsDate"
        }
      ]
    }
  },
  "identifier": {
    "$id": "identifier",
    "type": "text",
    "label": "VIN / Шасси / Кузов",
    "idKeyName": "systemName",
    "validation": {
      "messages": {
        "required": "Заполните тип VIN / Шасси / Кузов"
      }
    }
  },
  "vinNumber": {
    "$id": "vinNumber",
    "type": "text",
    "label": "Номер VIN",
    "maskName": "vinNumber",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/VIN.png' alt='' class='license-img' />",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "[0-9a-jklmnpr-zKLMNPR-ZA-JKLMNPR-Z-*]{17}"
        }
      ],
      "messages": {
        "required": "Заполните VIN номер",
        "pattern": "Неверный формат VIN кода"
      }
    }
  },
  "bodyNumber": {
    "$id": "bodyNumber",
    "type": "text",
    "label": "Номер кузова",
    "maskName": "bodyNumber",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/bodyNumber.png' alt='' class='license-img' />",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "[0-9a-jklmnpr-zKLMNPR-ZA-JKLMNPR-Z-*]{1,17}"
        }
      ],
      "messages": {
        "required": "Заполните номер кузова",
        "pattern": "Неверный формат номера кузова"
      }
    }
  },
  "chassisNumber": {
    "$id": "chassisNumber",
    "type": "text",
    "label": "Номер шасси",
    "maskName": "chassisNumber",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/chassisNumber.png' alt='' class='license-img' />",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "[0-9a-jklmnpr-zKLMNPR-ZA-JKLMNPR-Z-*]{1,17}"
        }
      ],
      "messages": {
        "required": "Заполните номер шасси",
        "pattern": "Неверный формат номера шасси"
      }
    }
  },
  "purposeOfUse": {
    "$id": "purposeOfUse",
    "type": "text",
    "label": "Цель использования автомобиля",
    "idKeyName": "systemName",
    "validation": {
      "messages": {
        "required": "Заполните цель использования автомобиля"
      }
    }
  },
  "carWithPrince": {
    "$id": "carWithPrince",
    "type": "checkbox",
    "label": "Авто с прицепом"
  },
  "hasDriversRestriction": {
    "$id": "hasDriversRestriction",
    "type": "checkbox",
    "label": "Без ограничений"
  },
  "driversWithoutRestriction": {
    "$id": "driversWithoutRestriction",
    "type": "checkbox",
    "label": "Без ограничений"
  },
  "driverFirstName": {
    "$id": "driverFirstName",
    "type": "autocomplete",
    "label": "Имя",
    "placeholder": "Имя",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "mask": "^[А-Яа-яЁё\\s-().,']{2,}$",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните имя водителя",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "driverLastName": {
    "$id": "driverLastName",
    "type": "autocomplete",
    "label": "Фамилия",
    "placeholder": "Фамилия",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните фамилию водителя",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "oldDriverLastName": {
    "$id": "oldDriverLastName",
    "type": "autocomplete",
    "label": "Фамилия в старых правах",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните фамилию в старых правах",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "driverMiddleName": {
    "$id": "middleName",
    "type": "autocomplete",
    "label": "Отчество (если имеется)",
    "placeholder": "Отчество (если имеется)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "driverBirthDate": {
    "$id": "driverBirthDate",
    "type": "text",
    "label": "Дата рождения",
    "inputmode": "numeric",
    "maskName": "driverBirthDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "birthDate"
        }
      ],
      "messages": {
        "required": "Заполните дату рождения водителя"
      }
    }
  },
  "experienceDate": {
    "$id": "experienceDate",
    "type": "text",
    "label": "Дата начала стажа",
    "inputmode": "numeric",
    "maskName": "experienceDate",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/experienceDate.png' alt='' class='license-img-horizontal' />",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "experienceStartDate"
        }
      ],
      "messages": {
        "required": "Заполните дату начала стажа водителя"
      }
    }
  },
  "experienceYear": {
    "$id": "experienceYear",
    "type": "text",
    "label": "Год начала стажа",
    "inputmode": "numeric",
    "maskName": "experienceYear",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/rememberOnlyYear.png' alt='' class='license-img-horizontal' />",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "experienceYear"
        }
      ],
      "messages": {
        "required": "Заполните год начала стажа водителя"
      }
    }
  },
  "driverLicense": {
    "$id": "driverLicense",
    "type": "text",
    "label": "Серия и номер текущего ВУ",
    "maskName": "driverLicense",
    "usePopover": true,
    "popoverContent": "<img src='./assets/images/documents/driverLicense.png' alt='' class='license-img-horizontal' />",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "[0-9]{2}[A-Za-zА-Яа-яЁё0-9]{2}[0-9]{6}"
        }
      ],
      "messages": {
        "required": "Заполните серию и номер текущих прав",
        "pattern": "Серия и номер водительского удостоверения, должен быть 10 знаков"
      }
    }
  },
  "oldLicense": {
    "$id": "oldLicense",
    "type": "text",
    "label": "Серия и номер старого ВУ",
    "maskName": "driverLicense",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "[0-9]{2}[A-Za-zА-Яа-яЁё0-9]{2}[0-9]{6}"
        }
      ],
      "messages": {
        "required": "Заполните серию и номер старых прав",
        "pattern": "Серия и номер старого водительского удостоверения, должен быть 10 знаков"
      }
    }
  },
  "rememberOnlyYear": {
    "$id": "rememberOnlyYear",
    "type": "checkbox",
    "label": "Помню только год"
  },
  "licenseWasChanged": {
    "$id": "licenseWasChanged",
    "type": "checkbox",
    "label": "Права менялись в течение года"
  },
  "driverIsOwner": {
    "$id": "driverIsOwner",
    "type": "checkbox",
    "label": "Водитель является собственником"
  },
  "driverIsInsurer": {
    "$id": "driverIsInsurer",
    "type": "checkbox",
    "label": "Водитель является страхователем"
  },
  "driverPassportLicense": {
    "$id": "driverPassportLicense",
    "type": "text",
    "label": "Серия и номер паспорта",
    "inputmode": "numeric",
    "maskName": "passportLicense",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "[0-9]{10,}"
        }
      ],
      "messages": {
        "required": "Заполните серию и номер паспорта",
        "pattern": "Серия и номер паспорта, должен быть 10 знаков"
      }
    }
  },
  "driverPassportDate": {
    "$id": "driverPassportDate",
    "type": "text",
    "label": "Дата выдачи паспорта",
    "inputmode": "numeric",
    "maskName": "driverPassportDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "driverPassportDate"
        }
      ],
      "messages": {
        "required": "Заполните дату выдачи паспорта"
      }
    }
  },
  "driverCity": {
    "$id": "driverCity",
    "type": "autocomplete",
    "label": "Адрес регистрации (город, улица, дом)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "driverDaDataRequired"
        }
      ],
      "messages": {
        "required": "Укажите адрес регистрации как в паспорте и выбрите свой адрес в списке"
      }
    }
  },
  "driverHouseNumber": {
    "$id": "driverHouseNumber",
    "type": "text",
    "label": "Номер квартиры (если имеется)",
    "maskName": "houseNumber",
    "inputmode": "numeric",
    "pattern": "[0-9]+",
    "validation": {
      "values": [
        {
          "validName": "min",
          "validValue": 1
        },
        {
          "validName": "max",
          "validValue": 9999
        }
      ],
      "messages": {
        "min": "Номер квартиры не может быть равен 0",
        "max": "Номер квартиры слишком большой"
      }
    }
  },
  "ownerFirstName": {
    "$id": "ownerFirstName",
    "type": "autocomplete",
    "label": "Имя",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните имя собственника",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "ownerLastName": {
    "$id": "ownerLastName",
    "type": "autocomplete",
    "label": "Фамилия",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните фамилию собственника",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "ownerMiddleName": {
    "$id": "ownerMiddleName",
    "type": "autocomplete",
    "label": "Отчество (если имеется)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "ownerBirthDate": {
    "$id": "ownerBirthDate",
    "type": "text",
    "label": "Дата рождения собственника",
    "inputmode": "numeric",
    "maskName": "driverBirthDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "ownerBirthDate"
        }
      ],
      "messages": {
        "required": "Заполните дату рождения собственника"
      }
    }
  },
  "passportLicense": {
    "$id": "passportLicense",
    "type": "text",
    "label": "Серия и номер паспорта",
    "inputmode": "numeric",
    "maskName": "passportLicense",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "[0-9]{10,}"
        }
      ],
      "messages": {
        "required": "Заполните серию и номер паспорта",
        "pattern": "Серия и номер паспорта, должен быть 10 знаков"
      }
    }
  },
  "ownerPassportDate": {
    "$id": "ownerPassportDate",
    "type": "text",
    "label": "Дата выдачи паспорта",
    "inputmode": "numeric",
    "maskName": "driverPassportDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "ownerPassportDate"
        }
      ],
      "messages": {
        "required": "Заполните дату выдачи паспорта"
      }
    }
  },
  "ownerCity": {
    "$id": "ownerCity",
    "type": "autocomplete",
    "label": "Адрес регистрации (город, улица, дом)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "ownerDaDataRequired"
        }
      ],
      "messages": {
        "required": "Укажите адрес регистрации как в паспорте и выбрите свой адрес в списке"
      }
    }
  },
  "ownerHouseNumber": {
    "$id": "ownerHouseNumber",
    "type": "text",
    "label": "Номер квартиры (если имеется)",
    "maskName": "houseNumber",
    "inputmode": "numeric",
    "pattern": "[0-9]+",
    "validation": {
      "values": [
        {
          "validName": "min",
          "validValue": 1
        },
        {
          "validName": "max",
          "validValue": 9999
        }
      ],
      "messages": {
        "min": "Номер квартиры не может быть равен 0",
        "max": "Номер квартиры слишком большой"
      }
    }
  },
  "ownerIsInsurer": {
    "$id": "ownerIsInsurer",
    "type": "checkbox"
  },
  "insurerFirstName": {
    "$id": "insurerFirstName",
    "type": "autocomplete",
    "label": "Имя",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните имя страхователя",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "insurerLastName": {
    "$id": "insurerLastName",
    "type": "autocomplete",
    "label": "Фамилия",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните фамилию страхователя",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "insurerMiddleName": {
    "$id": "insurerMiddleName",
    "type": "autocomplete",
    "label": "Отчество (если имеется)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "insurerBirthDate": {
    "$id": "insurerBirthDate",
    "type": "text",
    "label": "Дата рождения страхователя",
    "inputmode": "numeric",
    "maskName": "driverBirthDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "insurerBirthDate"
        }
      ],
      "messages": {
        "required": "Заполните дату рождения страхователя"
      }
    }
  },
  "insurerPassportDate": {
    "$id": "ownerPassportDate",
    "type": "text",
    "label": "Дата выдачи паспорта",
    "inputmode": "numeric",
    "maskName": "driverPassportDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "insurerPassportDate"
        }
      ],
      "messages": {
        "required": "Заполните дату выдачи паспорта"
      }
    }
  },
  "insurerCity": {
    "$id": "insurerCity",
    "type": "autocomplete",
    "label": "Адрес регистрации (город, улица, дом)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "insurerDaDataRequired"
        }
      ],
      "messages": {
        "required": "Укажите адрес регистрации как в паспорте и выбрите свой адрес в списке"
      }
    }
  },
  "insurerHouseNumber": {
    "$id": "insurerHouseNumber",
    "type": "text",
    "label": "Номер квартиры (если имеется)",
    "maskName": "houseNumber",
    "inputmode": "numeric",
    "pattern": "[0-9]+",
    "validation": {
      "values": [
        {
          "validName": "min",
          "validValue": 1
        },
        {
          "validName": "max",
          "validValue": 9999
        }
      ],
      "messages": {
        "min": "Номер квартиры не может быть равен 0",
        "max": "Номер квартиры слишком большой"
      }
    }
  },
  "legalOwnerValue": {
    "$id": "legalOwnerValue",
    "type": "autocomplete",
    "label": "Название, ИНН или ОГРН",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "OwnerDaDataRequired"
        }
      ],
      "messages": {
        "required": "Укажите название, ИНН или ОГРН"
      }
    }
  },
  "legalOwnerName": {
    "$id": "legalOwnerName",
    "type": "text",
    "label": "Наименование",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        }
      ],
      "messages": {
        "required": "Укажите наименование"
      }
    }
  },
  "legalOwnerInn": {
    "$id": "legalOwnerInn",
    "type": "text",
    "label": "ИНН",
    "inputmode": "numeric",
    "maskName": "innNumber",
    "minLength": 10,
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "minLength",
          "validValue": 10
        }
      ],
      "messages": {
        "required": "Укажите ИНН",
        "minLength": "ИНН номер должен быть 10 символов для ИП и 12 для ООО"
      }
    }
  },
  "legalOwnerOgrn": {
    "$id": "legalOwnerOgrn",
    "type": "text",
    "label": "ОГРН",
    "inputmode": "numeric",
    "mask": "0000000000000",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "minLength",
          "validValue": 10
        }
      ],
      "messages": {
        "required": "Укажите ОГРН",
        "minLength": "ОГРН номер должен быть 13 символов"
      }
    }
  },
  "legalOwnerKpp": {
    "$id": "legalOwnerKpp",
    "type": "text",
    "label": "КПП",
    "inputmode": "numeric",
    "mask": "000000000",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "minLength",
          "validValue": 9
        }
      ],
      "messages": {
        "required": "Укажите КПП",
        "minLength": "КПП номер должен быть 9 символов"
      }
    }
  },
  "legalOwnerEgryl": {
    "$id": "legalOwnerEgryl",
    "type": "text",
    "label": "Номер выписки ЕГРЮЛ",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        }
      ],
      "messages": {
        "required": "Укажите номер выписки ЕГРЮЛ"
      }
    }
  },
  "legalOwnerLegalAddress": {
    "$id": "legalOwnerLegalAddress",
    "type": "autocomplete",
    "label": "Адрес регистрации (город, улица, дом)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "legalOwnerDaDataRequired"
        }
      ],
      "messages": {
        "required": "Укажите юридический адрес и выбрите его в списке"
      }
    }
  },
  "legalOwnerLegalHouseNumber": {
    "$id": "legalOwnerLegalHouseNumber",
    "type": "text",
    "label": "Номер офиса (если имеется)",
    "inputmode": "numeric",
    "maskName": "houseNumber",
    "pattern": "[0-9]+",
    "validation": {
      "values": [
        {
          "validName": "min",
          "validValue": 1
        },
        {
          "validName": "max",
          "validValue": 9999
        }
      ],
      "messages": {
        "min": "Номер квартиры не может быть равен 0",
        "max": "Номер квартиры слишком большой"
      }
    }
  },
  "legalOwnerRegCertificate": {
    "$id": "legalOwnerRegCertificate",
    "type": "text",
    "label": "Свидетельство о регистрации",
    "inputmode": "numeric",
    "mask": "00 000000000",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "minLength",
          "validValue": 11
        }
      ],
      "messages": {
        "required": "Заполните cвидетельство о регистрации",
        "minLength": "Свидетельство о регистрации должно быть минимум 11 цифр"
      }
    }
  },
  "legalOwnerRegCertificateDateIssue": {
    "$id": "legalOwnerRegCertificateDateIssue",
    "type": "text",
    "label": "Дата выдачи свидетельства",
    "inputmode": "numeric",
    "maskName": "documentDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "regCertificateDateIssue"
        }
      ],
      "messages": {
        "required": "Заполните дату выдачи свидетельства"
      }
    }
  },
  "legalOwnerFirstName": {
    "$id": "legalOwnerFirstName",
    "type": "autocomplete",
    "label": "Имя",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните имя собственника",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "legalOwnerLastName": {
    "$id": "legalOwnerLastName",
    "type": "autocomplete",
    "label": "Фамилия",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните фамилию собственника",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "legalOwnerMiddleName": {
    "$id": "legalOwnerMiddleName",
    "type": "autocomplete",
    "label": "Отчество (если имеется)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "legalOwnerBirthDate": {
    "$id": "legalOwnerBirthDate",
    "type": "text",
    "label": "Дата рождения собственника",
    "inputmode": "numeric",
    "maskName": "driverBirthDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "ownerBirthDate"
        }
      ],
      "messages": {
        "required": "Заполните дату рождения собственника"
      }
    }
  },
  "legalOwnerPassportLicense": {
    "$id": "legalOwnerPassportLicense",
    "type": "text",
    "label": "Серия и номер паспорта",
    "inputmode": "numeric",
    "maskName": "passportLicense",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "[0-9]{10,}"
        }
      ],
      "messages": {
        "required": "Заполните серию и номер паспорта",
        "pattern": "Серия и номер паспорта, должен быть 10 знаков"
      }
    }
  },
  "legalOwnerPassportDate": {
    "$id": "legalOwnerPassportDate",
    "type": "text",
    "label": "Дата выдачи паспорта",
    "inputmode": "numeric",
    "maskName": "driverPassportDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "ownerPassportDate"
        }
      ],
      "messages": {
        "required": "Заполните дату выдачи паспорта"
      }
    }
  },
  "legalOwnerCity": {
    "$id": "legalOwnerCity",
    "type": "autocomplete",
    "label": "Адрес регистрации (город, улица, дом)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "legalOwnerAddressDaDataRequired"
        }
      ],
      "messages": {
        "required": "Укажите адрес регистрации как в паспорте и выбрите свой адрес в списке"
      }
    }
  },
  "legalOwnerHouseNumber": {
    "$id": "legalOwnerHouseNumber",
    "type": "text",
    "label": "Номер офиса (если имеется)",
    "inputmode": "numeric",
    "maskName": "houseNumber",
    "pattern": "[0-9]+",
    "validation": {
      "values": [
        {
          "validName": "min",
          "validValue": 1
        },
        {
          "validName": "max",
          "validValue": 9999
        }
      ],
      "messages": {
        "min": "Номер квартиры не может быть равен 0",
        "max": "Номер квартиры слишком большой"
      }
    }
  },
  "legalInsurerValue": {
    "$id": "legalInsurerValue",
    "type": "autocomplete",
    "label": "Название, ИНН или ОГРН",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "insurerDaDataRequired"
        }
      ],
      "messages": {
        "required": "Укажите название, ИНН или ОГРН"
      }
    }
  },
  "legalInsurerIsLegalOwner": {
    "$id": "legalInsurerIsLegalOwner",
    "type": "checkbox"
  },
  "legalInsurerName": {
    "$id": "legalInsurerName",
    "type": "text",
    "label": "Наименование",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        }
      ],
      "messages": {
        "required": "Укажите наименование"
      }
    }
  },
  "legalInsurerInn": {
    "$id": "legalInsurerInn",
    "type": "text",
    "label": "ИНН",
    "inputmode": "numeric",
    "maskName": "innNumber",
    "minLength": 10,
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "minLength",
          "validValue": 10
        }
      ],
      "messages": {
        "required": "Укажите ИНН",
        "minLength": "ИНН номер должен быть 10 символов для ИП и 12 для ООО"
      }
    }
  },
  "legalInsurerOgrn": {
    "$id": "legalInsurerOgrn",
    "type": "text",
    "label": "ОГРН",
    "inputmode": "numeric",
    "mask": "0000000000000",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "minLength",
          "validValue": 13
        }
      ],
      "messages": {
        "required": "Укажите ОГРН",
        "minLength": "КПП номер должен быть 13 символов"
      }
    }
  },
  "legalInsurerKpp": {
    "$id": "legalInsurerKpp",
    "type": "text",
    "label": "КПП",
    "inputmode": "numeric",
    "mask": "000000000",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "minLength",
          "validValue": 9
        }
      ],
      "messages": {
        "required": "Укажите КПП",
        "minLength": "КПП номер должен быть 9 символов"
      }
    }
  },
  "legalInsurerEgryl": {
    "$id": "legalInsurerEgryl",
    "type": "text",
    "label": "Номер выписки ЕГРЮЛ",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        }
      ],
      "messages": {
        "required": "Укажите номер выписки ЕГРЮЛ"
      }
    }
  },
  "legalInsurerAddress": {
    "$id": "legalInsurerAddress",
    "type": "autocomplete",
    "label": "Адрес регистрации (город, улица, дом)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "legalInsurerAddressDaDataRequired"
        }
      ],
      "messages": {
        "required": "Укажите юридический адрес и выбрите его в списке"
      }
    }
  },
  "legalInsurerAddressDaData": {
    "$id": "legalInsurerAddressDaData",
    "type": "autocomplete",
    "label": "Адрес регистрации (город, улица, дом)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "legalInsurerAddressDaDataRequired"
        }
      ],
      "messages": {
        "required": "Укажите юридический адрес и выбрите его в списке"
      }
    }
  },
  "legalInsurerHouseNumber": {
    "$id": "legalInsurerHouseNumber",
    "type": "text",
    "label": "Номер офиса (если имеется)",
    "inputmode": "numeric",
    "maskName": "houseNumber",
    "pattern": "[0-9]+",
    "validation": {
      "values": [
        {
          "validName": "min",
          "validValue": 1
        },
        {
          "validName": "max",
          "validValue": 9999
        }
      ],
      "messages": {
        "min": "Номер офиса не может быть равен 0",
        "max": "Номер офиса слишком большой"
      }
    }
  },
  "legalInsurerRegCertificate": {
    "$id": "legalInsurerRegCertificate",
    "type": "text",
    "label": "Свидетельство о регистрации",
    "inputmode": "numeric",
    "mask": "00 000000000",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "minLength",
          "validValue": 11
        }
      ],
      "messages": {
        "required": "Заполните cвидетельство о регистрации",
        "minLength": "Свидетельство о регистрации должно быть минимум 11 цифр"
      }
    }
  },
  "legalInsurerRegCertificateDateIssue": {
    "$id": "legalInsurerRegCertificateDateIssue",
    "type": "text",
    "label": "Дата выдачи свидетельства",
    "inputmode": "numeric",
    "maskName": "documentDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "regCertificateDateIssue"
        }
      ],
      "messages": {
        "required": "Заполните дату выдачи свидетельства"
      }
    }
  },
  "legalInsurerFirstName": {
    "$id": "legalInsurerFirstName",
    "type": "autocomplete",
    "label": "Имя",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните имя страхователя",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "legalInsurerLastName": {
    "$id": "legalInsurerLastName",
    "type": "autocomplete",
    "label": "Фамилия",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните фамилию страхователя",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "legalInsurerMiddleName": {
    "$id": "legalInsurerMiddleName",
    "type": "autocomplete",
    "label": "Отчество (если имеется)",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "legalInsurerBirthDate": {
    "$id": "legalInsurerBirthDate",
    "type": "text",
    "label": "Дата рождения страхователя",
    "inputmode": "numeric",
    "maskName": "driverBirthDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "insurerBirthDate"
        }
      ],
      "messages": {
        "required": "Заполните дату рождения страхователя"
      }
    }
  },
  "legalInsurerPassportDate": {
    "$id": "legalInsurerPassportDate",
    "type": "text",
    "label": "Дата выдачи паспорта",
    "inputmode": "numeric",
    "maskName": "driverPassportDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "insurerPassportDate"
        }
      ],
      "messages": {
        "required": "Заполните дату выдачи паспорта"
      }
    }
  },
  "email": {
    "$id": "email",
    "name": "email",
    "type": "email",
    "label": "E-mail",
    "placeholder": "E-mail",
    "maskName": "email",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "email",
          "validValue": true
        }
      ],
      "messages": {
        "required": "Заполните E-mail - электронную почту",
        "email": "Некорректный адрес почты"
      }
    }
  },
  "phone": {
    "$id": "phone",
    "name": "phone",
    "type": "tel",
    "label": "Номер телефона",
    "placeholder": "Номер телефона",
    "inputmode": "numeric",
    "maskName": "phone",
    "onlyNumberKeyboard": true,
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^7[489][0-9]{9}$"
        }
      ],
      "messages": {
        "required": "Заполните номер телефона",
        "pattern": "Формат номера телефона +7 (000) 000-00-00"
      }
    }
  },
  "policyStartDate": {
    "$id": "policyStartDate",
    "type": "text",
    "label": "Дата начала действия полиса",
    "inputmode": "numeric",
    "isShowCalendar": true,
    "minDate": 1,
    "maxDate": 69,
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "policyStartDate"
        }
      ],
      "messages": {
        "required": "Укажите дату начала действия полиса"
      }
    }
  },
  "firstName": {
    "$id": "firstName",
    "type": "autocomplete",
    "label": "Имя",
    "placeholder": "Имя",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "mask": "^[А-Яа-яЁё\\s-().,']{2,}$",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "^[А-Яа-яЁё][А-Яа-яЁё\\s-().,']{1,}$"
        }
      ],
      "messages": {
        "required": "Заполните имя водителя",
        "pattern": "Допускаются только символы кирилицы от А до Я, пробел, скобки, апостроф (минимум 2 символа)"
      }
    }
  },
  "companyName": {
    "$id": "companyName",
    "type": "text",
    "label": "Название компании",
    "idKeyName": "value",
    "typeaheadOptionField": "value",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        }
      ],
      "messages": {
        "required": "Укажите наименование"
      }
    }
  },
  "INN": {
    "$id": "INN",
    "type": "text",
    "label": "ИНН",
    "inputmode": "numeric",
    "maskName": "innNumberIp",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "minLength",
          "validValue": 10
        },
        {
          "validName": "maxLength",
          "validValue": 10
        }
      ],
      "messages": {
        "required": "Укажите ИНН",
        "minLength": "ИНН номер должен быть 10 символов",
        "max": "ИНН номер должен быть 10 символов"
      }
    }
  },
  "cpaClientUid": {
    "$id": "cpaClientUid",
    "type": "text",
    "label": "Номер карты лояльности",
    "placeholder": "Номер карты лояльности",
    "pattern": "[0-9]+",
    "large": true,
    "mask": "0000000000",
    "numericMaxLength": 10,
    "validation": {
      "values": [
        {
          "validName": "pattern",
          "validValue": "[0-9]{10}$"
        }
      ],
      "messages": {
        "pattern": "Номер карты лояльности равен 10 символов"
      }
    }
  },
  "previousPolicySeries": {
    "$id": "previousPolicySeries",
    "type": "select",
    "label": "Серия полиса",
    "idKeyName": "systemName",
    "validation": {
      "messages": {
        "required": "Выберите серию полиса"
      }
    }
  },
  "previousPolicyNumber": {
    "$id": "previousPolicyNumber",
    "type": "text",
    "label": "Номер полиса",
    "placeholder": "Номер полиса",
    "pattern": "[0-9]+",
    "mask": "0000000000",
    "numericMaxLength": 10,
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "pattern",
          "validValue": "[0-9]{10}$"
        }
      ],
      "messages": {
        "required": "Заполните номер полиса",
        "pattern": "Номер полиса равен 10 символов"
      }
    }
  },
  "prolongationFormBirthDate": {
    "$id": "prolongationFormBirthDate",
    "type": "text",
    "label": "Дата рождения страхователя",
    "inputmode": "numeric",
    "maskName": "driverBirthDate",
    "validation": {
      "values": [
        {
          "validName": "required",
          "validValue": true
        },
        {
          "validName": "birthDate"
        }
      ],
      "messages": {
        "required": "Заполните дату рождения страхователя"
      }
    }
  }
}
