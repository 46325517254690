<div class="card border-0 h-100 p-2 rounded-5">
  <div class="card-body">
    <div class="title my-3 my-md-5 text-center">Часто задаваемые вопросы</div>
    <div class="row">
      <div class="col-12 col-md-1 col-lg-2"></div>
      <div class="col-12 col-md-10 col-lg-8">
        <ul class="nav nav-pills nav-fill row mb-3" id="pills-tab" role="tablist">
          @for (category of faqData; track $index) {
            <li class="nav-item p-2">
              <button
                class="btn btn-sm w-100"
                [class.btn-primary]="selectedCategoryIndex === $index"
                [class.btn-outline-primary]="selectedCategoryIndex !== $index"
                (click)="selectCategory($index)"
                type="button">{{ category.category }}
              </button>
            </li>
          }
        </ul>
        <div class="tab-content" id="pills-tabContent">
          @for (category of faqData; track category; let i = $index) {
            <div class="tab-pane fade"
                 [class.show]="selectedCategoryIndex === i"
                 [class.active]="selectedCategoryIndex === i">
              <div class="accordion accordion-flush" [attr.id]="'accordion' + i">
                @for (item of category.questions; track item; let j = $index) {
                  <div class="accordion-item">
                    <h2 class="accordion-header my-2" [attr.id]="'heading' + i + '-' + j">
                      <button
                        class="accordion-button collapsed px-0"
                        type="button"
                        data-bs-toggle="collapse"
                        [attr.data-bs-target]="'#collapse' + i + '-' + j"
                        aria-expanded="false"
                        [attr.aria-controls]="'collapse' + i + '-' + j"
                        [innerHTML]="item.question"></button>
                    </h2>
                    <div [id]="'collapse' + i + '-' + j"
                         class="accordion-collapse collapse"
                         [attr.data-bs-parent]="'#accordion' + i">
                      <div class="accordion-body px-0" [innerHTML]="item.answer"></div>
                    </div>
                  </div>
                }
              </div>
            </div>
          }
        </div>
      </div>
      <div class="col-12 col-md-1 col-lg-2"></div>
    </div>
  </div>
</div>
