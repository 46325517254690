<div class="has-validation" *ngIf="mask" [class.form-floating]="label">
  <input [(ngModel)]="value"
         (ngModelChange)="updateValue($event)"
         [ngClass]="{
            'is-invalid': control.invalid && control.touched
           }"
         [class.form-control-lg]="!label"
         class="form-control"
         [readOnly]="isReadonly"
         [disabled]="disabled"
         [type]="type"
         [pattern]="pattern"
         autocomplete="on"
         [spellcheck]="false"
         [attr.inputmode]="inputmode"
         [attr.name]="name"
         [placeholder]="placeholder"
         [imask]="mask"
         [unmask]="true"
         (focus)="focus($event)"
         (blur)="blur()">

  @if (usePopover) {
    <button type="button"
            class="info-icon-btn"
            [popover]="popTemplate"
            [outsideClick]="true"
            [adaptivePosition]="true">
      <img class="info-icon" src="./assets/images/input-info-icon.svg" alt="Информация" loading="lazy">
    </button>
  }

  @if (label) {
    <label>{{label}}</label>
  }

  @if (control.invalid && control.touched) {
    <app-form-field-error
      [controlErrors]="control.errors"
      [messages]="messages">
    </app-form-field-error>
  }
</div>

<ng-template #popTemplate>
  <div class="popover-content" [innerHTML]="popoverContent"></div>
</ng-template>
