import { Routes } from '@angular/router';
import {MainComponent} from "./components/main/main.component";
import {FormComponent} from "./components/form/form.component";
import {GuardService} from "./shared/services/guard.service";
import {OffersComponent} from "./components/offers/offers.component";
import {SuccessComponent} from "./components/success/success.component";
import {DataCheckingComponent} from "./components/data-checking/data-checking.component";
import {CreatePolicyComponent} from "./components/create-policy/create-policy.component";
import {FastProlongationComponent} from "./components/fast-prolongation/fast-prolongation.component";
import { LoadingPageComponent } from './components/loading-page/loading-page.component';

export const routes: Routes = [
  {
    path: '',
    component: MainComponent
  },
  {
    path: 'form',
    component: FormComponent,
    canActivate: [GuardService]
  },
  {
    path: 'offers',
    component: OffersComponent,
    canActivate: []
  },
  {
    path: 'success',
    component: SuccessComponent,
    canActivate: [GuardService]
  },
  {
    path: 'data-checking',
    component: DataCheckingComponent,
    canActivate: [GuardService]
  },
  {
    path: 'create-policy',
    component: CreatePolicyComponent,
    canActivate: [GuardService]
  },
  {
    path: 'prolongation',
    component: FastProlongationComponent,
    canActivate: [GuardService]
  },
  { path: 'loading', component: LoadingPageComponent }
];
