<div class="legal-owner-is-legal">
  <!--  {{formLegalOwnerIsLegal?.get('legalOwnerType')?.value}}-->
  @if (legalOwnerIsJuridical) {
    <div class="row">
      <div class="col-12 mb-3 mb-sm-4">
        <app-form-switch [formControl]="legalOwnerIsJuridical"
                         [label]="'Собственник является Юр. лицом'"
                         [isReadonly]="isLoading"></app-form-switch>
      </div>
    </div>
  }

  <div [formGroup]="formLegalOwnerIsLegal">
    @if (legalOwnerIsJuridical.value) {
      <div class="row">
        <div class="col-12 mb-3 mb-sm-4">
          <app-autocomplete
            formControlName="legalOwnerValue"
            (typeaheadOnSelect)="onChangeLegalOwner($event)"
            [daDataControlName]="'legalOwnerDaData'"
            [suggestionPart]="daDataSystemName.LEGAL_NAME"
            [isReadonly]="isLoading"></app-autocomplete>
        </div>
      </div>
      @if (formLegalOwnerIsLegal.get('legalOwnerDaData')?.value) {
        <div class="row">
          <div class="col-12 mb-3 mb-sm-4">
            <app-form-text-field formControlName="legalOwnerName" [isReadonly]="isLoading"></app-form-text-field>
          </div>
          <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
            <app-form-text-field formControlName="legalOwnerInn" [isReadonly]="isLoading"></app-form-text-field>
          </div>

          @if (formLegalOwnerIsLegal.get('legalOwnerType')?.value === TypeOrganisation.LEGAL) {
            <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
              <app-form-text-field formControlName="legalOwnerOgrn" [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
              <app-form-text-field formControlName="legalOwnerKpp" [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 mb-3 mb-sm-4">
              <app-form-switch formControlName="legalOwnerWithoutCertificate" [label]="'Без свидетельства'"
                               [isReadonly]="isLoading"></app-form-switch>
            </div>
            @if (!formLegalOwnerIsLegal.get('legalOwnerWithoutCertificate')?.value) {
              <div class="col-12 col-md-6 mb-3 mb-sm-4">
                <app-form-text-field formControlName="legalOwnerRegCertificate"
                                     [isReadonly]="isLoading"></app-form-text-field>
              </div>
              <div class="col-12 col-md-6 mb-3 mb-sm-4">
                <app-form-text-field formControlName="legalOwnerRegCertificateDateIssue"
                                     [isReadonly]="isLoading"></app-form-text-field>
              </div>
            } @else {
              <div class="col-12 mb-3 mb-sm-4">
                <div class="alert alert-primary border-light mb-0"
                     [innerHTML]="contentService.content.formComponent.legalOwnerComponent.alert1"></div>
              </div>
              <div class="col-12 mb-3 mb-sm-4">
                <app-form-text-field formControlName="legalOwnerEgryl" [isReadonly]="isLoading"></app-form-text-field>
              </div>
            }
          }
        </div>

        @if (formLegalOwnerIsLegal.get('legalOwnerType')?.value === TypeOrganisation.INDIVIDUAL) {
          <div class="row">
            <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
              <app-autocomplete
                formControlName="legalOwnerLastName"
                [suggestionPart]="daDataSystemName.SURNAME"
                [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
              <app-autocomplete
                formControlName="legalOwnerFirstName"
                [suggestionPart]="daDataSystemName.NAME"
                [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
              <app-autocomplete
                formControlName="legalOwnerMiddleName"
                [suggestionPart]="daDataSystemName.PATRONYMIC"
                [isReadonly]="isLoading"></app-autocomplete>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
              <app-form-text-field formControlName="legalOwnerBirthDate" [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
              <app-form-text-field formControlName="legalOwnerPassportLicense"
                                   [isReadonly]="isLoading"></app-form-text-field>
            </div>
            <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
              <app-form-date-field formControlName="legalOwnerPassportDate"
                                   [isReadonly]="isLoading"></app-form-date-field>
            </div>
          </div>
        }

        <div class="row">
          <div class="col-12 col-md-7 mb-3 mb-md-4">
            <app-autocomplete
              formControlName="legalOwnerLegalAddress"
              [daDataControlName]="'legalOwnerLegalAddressDaData'"
              [suggestionPart]="daDataSystemName.ADDRESS"
              [isReadonly]="isLoading"></app-autocomplete>
          </div>
          <div class="col-12 col-md-5 mb-3 mb-md-4">
            <app-form-text-field formControlName="legalOwnerLegalHouseNumber"
                                 [isReadonly]="isLoading"></app-form-text-field>
          </div>
        </div>
      }

    }
  </div>
</div>

@if (!legalOwnerIsJuridical.value) {
  <div class="legal-owner-is-not-legal" [formGroup]="formLegalOwnerIsNotLegal">
    <div class="row">
      <!--1-->
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="legalOwnerLastName"
          [suggestionPart]="daDataSystemName.SURNAME"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!--2-->
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="legalOwnerFirstName"
          [suggestionPart]="daDataSystemName.NAME"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!--3-->
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="legalOwnerMiddleName"
          [suggestionPart]="daDataSystemName.PATRONYMIC"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!--4-->
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-form-text-field formControlName="legalOwnerBirthDate" [isReadonly]="isLoading"></app-form-text-field>
      </div>
      <!--5-->
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-form-text-field formControlName="legalOwnerPassportLicense" [isReadonly]="isLoading"></app-form-text-field>
      </div>
      <!--6-->
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-form-date-field formControlName="legalOwnerPassportDate" [isReadonly]="isLoading"></app-form-date-field>
      </div>
      <!--7-->
      <div class="col-12 col-md-7 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="legalOwnerCity"
          [daDataControlName]="'legalOwnerAddressDaData'"
          [suggestionPart]="daDataSystemName.ADDRESS"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <!--8-->
      <div class="col-12 col-md-5 mb-3 mb-md-4">
        <app-form-text-field formControlName="legalOwnerHouseNumber" [isReadonly]="isLoading"></app-form-text-field>
      </div>
    </div>
  </div>
}

<div class="row actions justify-content-end">
  <div class="col-12 col-sm-auto mb-3 mb-sm-4">
    <button type="submit" class="btn btn-primary btn-lg w-100" (click)="onSubmit()" [disabled]="isLoading">
      @if (isLoading) {
        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
      } @else {
        <span>Продолжить</span>
      }
    </button>
  </div>
</div>
