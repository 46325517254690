// Идентификаторы авто
export enum PurposeOfUseEnum {
    Personal = 'Personal',
    Official = 'Official',
    Taxi = 'Taxi',
    EducationDrive = 'EducationDrive',
    SpecialDrive = 'SpecialDrive',
    RentalDrive = 'RentalDrive',
    EmergencyAndUtilityDrive = 'EmergencyAndUtilityDrive',
    Other = 'Other'
}
