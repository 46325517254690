export const environmentDev = {
  version: '1.0.11',
  production: false,
  mock: false,
  routerLogging: false,
  hostUrl: 'https://test-osago.insapp.ru/',
  apiUrl: 'https://test-api.insapp.pro/',
  carInfoApi: 'https://test-api.insapp.pro/',
  dadataKey: '6e1e70327c0437a3d0d34fe74fd68a767c32d0d4',
  armHost: 'test-arm.insapp.ru',
  yandexMetrika: 0,
  yandexMetrikaPartner: 0,
  apiKey: "b90352835097480fa062713bf706f6b6",
};
