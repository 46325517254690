import {inject, Injectable} from '@angular/core';
import { environment } from "@environment/environment";
import {YandexMetrikaGoalsEnum} from "../enums/yandex-metrika-goals.enum";
import {FormService} from "./form.service";

declare global {
  interface Window {
    ym: any;
  }
}

@Injectable({
  providedIn: 'root'
})
export abstract class YandexMetrikaService {
  protected formService = inject(FormService);

  // Отправка события в яндекс метрику
  public onYandexReachGoal(ymTarget: YandexMetrikaGoalsEnum | string, goalParams?: any): void {
    const params = {
      ...goalParams
    }
    if (window.ym) {
      window.ym(environment.yandexMetrika, 'reachGoal', ymTarget, params);
    }
  }
}
