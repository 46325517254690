import {Component, EventEmitter, Output} from '@angular/core';
import {OsagoService} from "../../../shared/services/osago.service";
import {NavigationService} from "../../../shared/services/navigation.service";

@Component({
  selector: 'app-empty-offers-list',
  standalone: true,
  templateUrl: './empty-offers-list.component.html',
  styleUrls: ['./empty-offers-list.component.scss']
})
export class EmptyOffersListComponent {
  // Нажали на "Изменить данные"
  @Output() changeData: EventEmitter<boolean> = new EventEmitter<boolean>();
  // Нажали на "Изменить дату"
  @Output() changeDate: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(public readonly osagoService: OsagoService,
              public readonly navigationService: NavigationService) {
  }

  // Открыаем данные для редактирования
  public openEditForm(): void {
    this.osagoService.isShowEditInfo = true;
  }

  // Открыаем форму редактирования начала действия полиса
  public openFormStartDatePolicy(): void {
    this.osagoService.isShowFormStartDatePolicy = true;
  }

}
