<button type="button" class="btn btn-primary btn-lg w-100 main-btn" (click)="sendPaymentLink()" [disabled]="isLoading || smsSent">
  @if (isLoading) {
    Загрузка...
  } @else if (smsSent && !offer.errorMessage) {
    СМС отправлено {{ timerDisplay }}
  } @else if (smsSent && offer.errorMessage) {
<!--    Не удалось отправить СМС {{ timerDisplay }}-->
  Статус: Ошибка
  } @else {
    Оформить
  }
</button>
