import {Component, inject, Input} from '@angular/core';

@Component({
  selector: 'app-redirect-link',
  standalone: true,
  templateUrl: './redirect-link.component.html',
  styleUrl: './redirect-link.component.scss'
})
export class RedirectLinkComponent {
  @Input() detailsLink: string = '';

  // Перенгаправляем клиента в каско
  public redirectToKasko(): void {
    window.open(this.detailsLink, '_blank')
  }

}
