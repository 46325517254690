@if (localStorage.getItem('isAdmin')) {
  <app-test></app-test>
}

@if (!settingsService.onlyLegalForm && !settingsService.onlyPersonalForm && !urlParams.agentId) {
  <div class="mt-3 mt-sm-4">
    <app-entity-individual></app-entity-individual>
  </div>
}

<div class="bg-white custom-rounded p-3 p-sm-4 mt-2 mt-sm-4 mb-3 mb-sm-4">
  <div class="head-text">
    {{ contentService.content.mainPage.title }}
  </div>
  <div class="secondary-text">
    {{ contentService.content.mainPage.subTitle }}
  </div>

  <div class="mt-3 mt-sm-4">
    <app-license></app-license>
  </div>
</div>

@if (cacheService.cacheData.osagoPolicies.length) {
  <div class="my-2 my-sm-4">
    <app-cache-list></app-cache-list>
  </div>
}

@if (localStorage.getItem('isAdmin')) {
  <pre>{{ formService.form.value|json }}</pre>
}
