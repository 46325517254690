import {environmentDev} from "@environment/environment.common.dev";

export const environment = {
  logging: {
    debug: true
  },
  cacheService: true,
  partnerSystemName: "yafuel",
  onlyLegalForm: false,
  onlyPersonalForm: true,
  offerWithSms: true,
  ...environmentDev
};
