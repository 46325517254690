import { Component } from '@angular/core';
import {NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-error-payment-link',
  standalone: true,
  imports: [
    NgOptimizedImage
  ],
  templateUrl: './error-payment-link.component.html',
  styleUrl: './error-payment-link.component.scss'
})
export class ErrorPaymentLinkComponent {

}
