@if (!installmentFirstPay) {
  <div class="col-title text-secondary">{{ priceTitle }}</div>
}
<div class="fw-bold price">
  {{ getPremiumAmount }} ₽
</div>
@if (installmentFirstPay) {
  <div class="podeli-price" [popover]="popTemplate" [outsideClick]="true" [adaptivePosition]="true" container="body" boundariesElement="viewport" placement="top">
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7 1C3.68623 1 1 3.68623 1 7C1 10.3138 3.68623 13 7 13C10.3138 13 13 10.3138 13 7C13 3.68623 10.3138 1 7 1ZM7 11.4959C4.5175 11.4959 2.50409 9.4825 2.50409 7C2.50409 4.5175 4.5175 2.50409 7 2.50409C9.4825 2.50409 11.4959 4.5175 11.4959 7C11.4959 9.4825 9.4825 11.4959 7 11.4959Z" fill="black" stroke="black" stroke-width="0.454001"/>
      <path d="M6.99805 1.80551C6.99805 2.1765 7.26843 2.49593 7.6369 2.54875C9.60882 2.82919 11.1682 4.38861 11.4487 6.36053C11.5015 6.729 11.8209 6.99939 12.1919 6.99939C12.6459 6.99939 13.0018 6.59947 12.9377 6.14925C12.5642 3.51584 10.4803 1.432 7.84693 1.05849C7.39796 0.995613 6.99805 1.35151 6.99805 1.80551Z" fill="#FF0000" stroke="#FF0000" stroke-width="0.454001"/>
    </svg>
    {{ installmentFirstPayFormatString }}  x 4
    <svg width="16" height="12" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17316C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8078C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C19.9971 7.34871 18.9426 4.80684 17.0679 2.9321C15.1932 1.05736 12.6513 0.00286757 10 0ZM10 18.3333C8.35183 18.3333 6.74066 17.8446 5.37025 16.9289C3.99984 16.0132 2.93174 14.7117 2.30101 13.189C1.67028 11.6663 1.50525 9.99076 1.82679 8.37425C2.14834 6.75774 2.94201 5.27288 4.10745 4.10744C5.27289 2.94201 6.75774 2.14833 8.37425 1.82679C9.99076 1.50525 11.6663 1.67027 13.189 2.301C14.7118 2.93173 16.0132 3.99984 16.9289 5.37025C17.8446 6.74066 18.3333 8.35182 18.3333 10C18.3309 12.2094 17.4522 14.3276 15.8899 15.8899C14.3276 17.4522 12.2094 18.3309 10 18.3333ZM10 6.66687C10.6904 6.66687 11.25 6.10723 11.25 5.41687C11.25 4.72651 10.6904 4.16687 10 4.16687C9.30964 4.16687 8.75 4.72651 8.75 5.41687C8.75 6.10723 9.30964 6.66687 10 6.66687ZM10.8317 9.16646C10.8317 8.70623 10.4586 8.33313 9.99837 8.33313C9.53813 8.33313 9.16504 8.70623 9.16504 9.16646V14.9998C9.16504 15.46 9.53813 15.8331 9.99837 15.8331C10.4586 15.8331 10.8317 15.46 10.8317 14.9998L10.8317 9.16646Z" fill="#000000"/>
    </svg>

  </div>
}
<ng-template #popTemplate><img class="w-100 podeli-img" src="/assets/images/podeli-alert-img.png" alt="Podeli"></ng-template>
