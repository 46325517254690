import {
  HttpRequest,
  HttpEvent,
  HttpErrorResponse,
  HttpInterceptor,
  HttpClient, HttpInterceptorFn, HttpHandlerFn
} from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {inject, Inject, Injectable, OnDestroy} from '@angular/core';
import { FormService } from './form.service';
import {YandexMetrikaService} from "./yandex-metrika.service";
import {environment} from "@environment/environment";
import {YandexMetrikaGoalsEnum} from "../enums/yandex-metrika-goals.enum";

@Injectable({
    providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor, OnDestroy {
    private _subscription = new Subscription();
    private ym = inject(YandexMetrikaService);
    private http = inject(HttpClient);
    private formService = inject(FormService);

    // --------------------------------------------------------------------------
    // Инициализация
    public ngOnDestroy(): void {
        this._subscription.unsubscribe();
    }
    // --------------------------------------------------------------------------

    private handleError(error: HttpErrorResponse): void {
        this._subscription.add(
            this.postLocal('log', {
                applicationId: this.formService.form?.get('applicationId')?.value,
                message: JSON.stringify(error)
            })
                .subscribe({
                    next: () => {
                    },
                    error: () => {
                    }
                })
        );
    }

  private postLocal(url: string, body: any): Observable<any> {
    return this.http.post(environment.apiUrl + `${url}`, body);
  }

  public intercept(request: HttpRequest<any>, next: any): Observable<HttpEvent<any>> {
    return next(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.error('ОШИБКА', error);
        if (error != null && error.url != null && error.url.toLowerCase().indexOf('/log') === -1) {
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.InternalError);
          // this.handleError(error);
        }
        return throwError(error);
      })
    );
  }
}

export const httpErrorInterceptor: HttpInterceptorFn = (req: HttpRequest<any>, next: HttpHandlerFn): Observable<HttpEvent<any>> => {
  const interceptorService = new HttpErrorInterceptor(); // замените на фактические зависимости
  return interceptorService.intercept(req, next);
};
