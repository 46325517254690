import {FormControl, FormGroup, Validators} from "@angular/forms";
import {extractNumber} from "./extractNumber";
import {TypeOrganisation} from "../enums/type-organisation";

export function setOwnerOrInsurerFromDriver(driverData: any, ownerOrInsurerFormGroup: FormGroup, formGroupName: string): void {
  ownerOrInsurerFormGroup.get(formGroupName + 'FirstName')?.setValue(driverData?.driverFirstName, { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'LastName')?.setValue(driverData?.driverLastName, { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'MiddleName')?.setValue(driverData?.driverMiddleName, { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'BirthDate')?.setValue(driverData?.driverBirthDate, { emitEvent: false });

  ownerOrInsurerFormGroup.get('passportLicense')?.setValue(driverData?.driverPassportLicense, { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'PassportDate')?.setValue(driverData?.driverPassportDate, { emitEvent: false });
  //
  ownerOrInsurerFormGroup.get(formGroupName + 'City')?.setValue(driverData?.driverCity, { emitEvent: false });
  // Данные даДата адреса
  const driverAddressDaData = driverData?.driverAddressDaData;
  // Номер квартиры
  const flat = driverData?.driverAddressDaData?.flat;
  ownerOrInsurerFormGroup.get(formGroupName + 'HouseNumber')?.setValue(flat, { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'AddressDaData')?.setValue(driverAddressDaData, { emitEvent: false });
}

export function resetOwnerOrInsurerFromDriver(ownerOrInsurerFormGroup: FormGroup, formGroupName: string): void {
  ownerOrInsurerFormGroup.get(formGroupName + 'FirstName')?.reset(null, { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'LastName')?.reset(null, { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'MiddleName')?.reset(null, { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'BirthDate')?.reset('', { emitEvent: false });
  ownerOrInsurerFormGroup.get('passportLicense')?.reset(null, { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'PassportDate')?.reset('', { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'City')?.reset(null, { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'HouseNumber')?.reset(null, { emitEvent: false });
  ownerOrInsurerFormGroup.get(formGroupName + 'AddressDaData')?.reset(null, { emitEvent: false });
}

export function setInsurerFromOwner(ownerData: any, insurerFormGroup: FormGroup): void {
  insurerFormGroup.get('insurerFirstName')?.setValue(ownerData?.ownerFirstName);
  insurerFormGroup.get('insurerLastName')?.setValue(ownerData?.ownerLastName);
  insurerFormGroup.get('insurerMiddleName')?.setValue(ownerData?.ownerMiddleName);
  insurerFormGroup.get('insurerBirthDate')?.setValue(ownerData?.ownerBirthDate);
  insurerFormGroup.get('passportLicense')?.setValue(ownerData?.passportLicense);
  insurerFormGroup.get('insurerPassportDate')?.setValue(ownerData?.ownerPassportDate);
  insurerFormGroup.get('insurerCity')?.setValue(ownerData?.ownerCity);
  insurerFormGroup.get('insurerHouseNumber')?.setValue(ownerData?.ownerHouseNumber);
  insurerFormGroup.get('insurerAddressDaData')?.setValue(ownerData?.ownerAddressDaData);
}

export function resetInsurerFromOwner(insurerFormGroup: FormGroup): void {
  insurerFormGroup.get('insurerFirstName')?.reset(null);
  insurerFormGroup.get('insurerLastName')?.reset(null);
  insurerFormGroup.get('insurerMiddleName')?.reset(null);
  insurerFormGroup.get('insurerBirthDate')?.reset(null);
  insurerFormGroup.get('passportLicense')?.reset(null);
  insurerFormGroup.get('insurerPassportDate')?.reset(null);
  insurerFormGroup.get('insurerCity')?.reset(null);
  insurerFormGroup.get('insurerHouseNumber')?.reset(null);
  insurerFormGroup.get('insurerAddressDaData')?.reset(null);
}

export function setLegalInsurerFromDaData(legalInsurerFormGroup: FormGroup, legalOwnerFormGroup: FormGroup): void {
  console.log('setLegalInsurerFromDaData', legalInsurerFormGroup);
  const {
    legalInsurerType,
    legalInsurerDaData,
    legalInsurerName,
    legalInsurerInn,
    legalInsurerOgrn,
    legalInsurerKpp,
    legalInsurerAddress,
    legalInsurerAddressDaData,
    legalInsurerHouseNumber,
    legalInsurerFirstName,
    legalInsurerLastName,
    legalInsurerMiddleName,
  } = (legalInsurerFormGroup as FormGroup).controls;

  const legalOwnerIsJuridical = (legalOwnerFormGroup.get('legalOwnerIsJuridical') as FormControl);

  const type = legalInsurerDaData?.value?.type;
  const daData = legalInsurerDaData.value;
  const addressDaData = legalInsurerAddressDaData.value;
  const name = legalInsurerDaData.value?.name?.full_with_opf;
  const inn = legalInsurerDaData.value?.inn;
  const ogrn = legalInsurerDaData.value?.ogrn;
  const kpp = legalInsurerDaData.value?.kpp;
  const firstName = legalInsurerDaData.value?.fio?.name;
  const lastName = legalInsurerDaData.value?.fio?.surname;
  const middleName = legalInsurerDaData.value?.fio?.patronymic;

  // Если Страхователь является Собственником, то emitEvent = true, что бы данные применились и к Собственнику
  legalInsurerDaData.setValue(daData, {emitEvent: true});
  legalInsurerType.setValue(type, {emitEvent: true});
  if (type === TypeOrganisation.LEGAL) {
    legalInsurerOgrn.setValue(ogrn, {emitEvent: true});
    legalInsurerKpp.setValue(kpp, {emitEvent: true});
  } else if (type === TypeOrganisation.INDIVIDUAL) {
    legalInsurerFirstName.setValue(firstName, {emitEvent: true});
    legalInsurerLastName.setValue(lastName, {emitEvent: true});
    legalInsurerMiddleName.setValue(middleName, {emitEvent: true});
  }

  // if (legalInsurerIsLegalOwner.value) {
  //   legalOwnerIsJuridical.setValue(type === TypeOrganisation.LEGAL, {emitEvent: legalInsurerIsLegalOwner.value});
  // }

  legalInsurerName.setValue(name, {emitEvent: true});
  legalInsurerInn.setValue(inn, {emitEvent: true});
  const flat = addressDaData?.flat;
  const flatType = addressDaData?.flat_type;
  const flatAsNumber = flat ? extractNumber(flat) : null;
  const addressWithoutFlat = addressDaData?.value?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

  legalInsurerAddressDaData.setValue(addressDaData, {emitEvent: true});

  // Если есть номер офиса (в данном случае это номер квартиры)
  // То применяем данные адреса и номера квартиры,
  // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
  legalInsurerHouseNumber.setValue(flatAsNumber?.toString(), {emitEvent: false});

  legalInsurerAddress.setValue(addressWithoutFlat, {emitEvent: true});

  legalInsurerAddress.markAsTouched();
}

export function resetLegalInsurer(legalInsurerFormGroup: FormGroup): void {
  const {
    legalInsurerType,
    legalInsurerDaData,
    legalInsurerName,
    legalInsurerInn,
    legalInsurerOgrn,
    legalInsurerKpp,
    legalInsurerEgryl,
    legalInsurerWithoutCertificate,
    legalInsurerRegCertificateDateIssue,
    legalInsurerRegCertificate,
    legalInsurerAddress,
    legalInsurerHouseNumber,
    legalInsurerAddressDaData,
    legalInsurerIsLegalOwner,
    legalInsurerFirstName,
    legalInsurerLastName,
    legalInsurerMiddleName,
    legalInsurerBirthDate,
    passportLicense,
    legalInsurerPassportDate,
  } = (legalInsurerFormGroup as FormGroup).controls;

  // legalInsurerType?.reset(null, { emitEvent: false });
  // legalInsurerDaData?.reset(null, { emitEvent: false });
  legalInsurerName?.reset(null, { emitEvent: false });
  legalInsurerInn?.reset('', { emitEvent: false });
  legalInsurerOgrn?.reset('', { emitEvent: false });
  legalInsurerKpp?.reset('', { emitEvent: false });
  // legalInsurerEgryl?.reset(null, { emitEvent: false });
  legalInsurerWithoutCertificate?.reset(true, { emitEvent: false });
  // legalInsurerRegCertificateDateIssue?.reset(null, { emitEvent: false });
  // legalInsurerRegCertificate?.reset(null, { emitEvent: false });
  legalInsurerAddress?.reset(null, { emitEvent: false });
  legalInsurerHouseNumber?.reset(null, { emitEvent: false });
  legalInsurerAddressDaData?.reset(null, { emitEvent: false });
  legalInsurerFirstName?.reset(null, { emitEvent: false });
  legalInsurerLastName?.reset(null, { emitEvent: false });
  legalInsurerMiddleName?.reset(null, { emitEvent: false });
  legalInsurerBirthDate?.reset(null, { emitEvent: false });
  passportLicense?.reset(null, { emitEvent: false });
  legalInsurerPassportDate?.reset(null, { emitEvent: false });
}

export function setLegalOwnerFromDaData(legalOwnerFormGroup: FormGroup): void {
  const {
    legalOwnerType,
    legalOwnerDaData,
    legalOwnerName,
    legalOwnerInn,
    legalOwnerOgrn,
    legalOwnerKpp,
    legalOwnerLegalAddress,
    legalOwnerLegalAddressDaData,
    legalOwnerLegalHouseNumber,
    legalOwnerFirstName,
    legalOwnerLastName,
    legalOwnerMiddleName,
  } = (legalOwnerFormGroup  as FormGroup).controls;

  const type = legalOwnerDaData?.value?.type;
  const daData = legalOwnerDaData.value;
  const addressDaData = legalOwnerLegalAddressDaData.value;
  const name = legalOwnerDaData.value?.name?.full_with_opf;
  const inn = legalOwnerDaData.value?.inn;
  const ogrn = legalOwnerDaData.value?.ogrn;
  const kpp = legalOwnerDaData.value?.kpp;
  const firstName = legalOwnerDaData.value?.fio?.name;
  const lastName = legalOwnerDaData.value?.fio?.surname;
  const middleName = legalOwnerDaData.value?.fio?.patronymic;

  legalOwnerDaData.setValue(daData, {emitEvent: true});

  legalOwnerType.setValue(type, {emitEvent: true});
  legalOwnerName.setValue(name, { emitEvent: false });
  legalOwnerInn.setValue(inn, { emitEvent: false });

  if (type === TypeOrganisation.LEGAL) {
    legalOwnerOgrn.setValue(ogrn, { emitEvent: false });
    legalOwnerKpp.setValue(kpp, { emitEvent: false });
  } else if (type === TypeOrganisation.INDIVIDUAL) {
    legalOwnerFirstName.setValue(firstName, {emitEvent: false});
    legalOwnerLastName.setValue(lastName, {emitEvent: false});
    legalOwnerMiddleName.setValue(middleName, {emitEvent: false});
  }

  const flat = addressDaData?.flat;
  const flatType = addressDaData?.flat_type;
  const flatAsNumber = flat ? extractNumber(flat) : null;
  const addressWithoutFlat = addressDaData?.value?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

  legalOwnerLegalAddressDaData.setValue(addressDaData, {emitEvent: true});

  // Если есть номер офиса (в данном случае это номер квартиры)
  // То применяем данные адреса и номера квартиры,
  // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
  legalOwnerLegalHouseNumber.setValue(flatAsNumber?.toString(), {emitEvent: false});

  legalOwnerLegalAddress.setValue(addressWithoutFlat, {emitEvent: true});

  legalOwnerLegalAddress.updateValueAndValidity();
}

export function resetLegalOwner(legalOwnerFormGroup: FormGroup): void {
  const {
    legalOwnerType,
    legalOwnerValue,
    legalOwnerDaData,
    legalOwnerName,
    legalOwnerInn,
    legalOwnerOgrn,
    legalOwnerKpp,
    legalOwnerEgryl,
    legalOwnerWithoutCertificate,
    legalOwnerRegCertificateDateIssue,
    legalOwnerRegCertificate,
    legalOwnerLegalAddress,
    legalOwnerLegalHouseNumber,
    legalOwnerLegalAddressDaData,
    legalOwnerFirstName,
    legalOwnerLastName,
    legalOwnerMiddleName,
    legalOwnerBirthDate,
    legalOwnerPassportLicense,
    legalOwnerPassportDate,
  } = (legalOwnerFormGroup  as FormGroup).controls;

  // legalOwnerValue?.reset(null, { emitEvent: true });
  // legalOwnerType?.reset(null, { emitEvent: true });
  legalOwnerDaData?.reset(null, { emitEvent: false });
  legalOwnerName?.reset(null, { emitEvent: false });
  legalOwnerInn?.reset('', { emitEvent: false });
  legalOwnerOgrn?.reset('', { emitEvent: false });
  legalOwnerKpp?.reset('', { emitEvent: false });
  legalOwnerEgryl?.reset(null, { emitEvent: false });
  legalOwnerWithoutCertificate?.reset(true, { emitEvent: false });
  legalOwnerRegCertificateDateIssue?.reset(null, { emitEvent: false });
  legalOwnerRegCertificate?.reset(null, { emitEvent: false });
  legalOwnerLegalAddress?.reset(null, { emitEvent: false });
  legalOwnerLegalHouseNumber?.reset(null, { emitEvent: false });
  legalOwnerLegalAddressDaData?.reset(null, { emitEvent: false });
  legalOwnerFirstName?.reset(null, { emitEvent: false });
  legalOwnerLastName?.reset(null, { emitEvent: false });
  legalOwnerMiddleName?.reset(null, { emitEvent: false });
  legalOwnerBirthDate?.reset(null, { emitEvent: false });
  legalOwnerPassportLicense?.reset(null, { emitEvent: false });
  legalOwnerPassportDate?.reset(null, { emitEvent: false });
}

export function resetLegalOwnerIsNotLegal(legalOwnerIsNotLegalFormGroup: FormGroup): void {
  const {
    legalOwnerFirstName,
    legalOwnerLastName,
    legalOwnerMiddleName,
    legalOwnerBirthDate,
    legalOwnerPassportLicense,
    legalOwnerPassportDate,
    legalOwnerCity,
    legalOwnerHouseNumber,
    legalOwnerAddressDaData,
  } = (legalOwnerIsNotLegalFormGroup  as FormGroup).controls;

  // legalOwnerValue?.reset(null, { emitEvent: true });
  // legalOwnerType?.reset(null, { emitEvent: true });
  legalOwnerFirstName?.reset(null, { emitEvent: false });
  legalOwnerLastName?.reset(null, { emitEvent: false });
  legalOwnerMiddleName?.reset(null, { emitEvent: false });
  legalOwnerBirthDate?.reset(null, { emitEvent: false });
  legalOwnerPassportLicense?.reset(null, { emitEvent: false });
  legalOwnerPassportDate?.reset(null, { emitEvent: false });
  legalOwnerCity?.reset(null, { emitEvent: false });
  legalOwnerHouseNumber?.reset(null, { emitEvent: false });
  legalOwnerAddressDaData?.reset(null, { emitEvent: false });
}

export function setLegalOwnerFromLegalInsurer(legalInsurerData: any, legalOwnerFormGroup: FormGroup): void {
  console.log('setLegalOwnerFromLegalInsurer', legalInsurerData);
  const {
    legalOwnerType,
    legalOwnerValue,
    legalOwnerDaData,
    legalOwnerName,
    legalOwnerInn,
    legalOwnerOgrn,
    legalOwnerKpp,
    legalOwnerEgryl,
    legalOwnerWithoutCertificate,
    legalOwnerRegCertificateDateIssue,
    legalOwnerRegCertificate,
    legalOwnerLegalAddress,
    legalOwnerLegalHouseNumber,
    legalOwnerLegalAddressDaData,
    legalOwnerFirstName,
    legalOwnerLastName,
    legalOwnerMiddleName,
    legalOwnerBirthDate,
    legalOwnerPassportLicense,
    legalOwnerPassportDate,
  } = (legalOwnerFormGroup  as FormGroup).controls;

  legalOwnerType.setValue(legalInsurerData?.legalInsurerType, { emitEvent: true });
  legalOwnerValue.setValue(legalInsurerData?.legalInsurerValue, { emitEvent: false });
  legalOwnerDaData.setValue(legalInsurerData?.legalInsurerDaData, { emitEvent: false });
  legalOwnerName.setValue(legalInsurerData?.legalInsurerName, { emitEvent: false });
  legalOwnerInn.setValue(legalInsurerData?.legalInsurerInn, { emitEvent: false });
  legalOwnerOgrn.setValue(legalInsurerData?.legalInsurerOgrn, { emitEvent: false });
  legalOwnerKpp.setValue(legalInsurerData?.legalInsurerKpp, { emitEvent: false });
  legalOwnerEgryl.setValue(legalInsurerData?.legalInsurerEgryl, { emitEvent: false });
  if (!legalInsurerData?.legalInsurerWithoutCertificate) {
    legalOwnerWithoutCertificate.setValue(false);
    legalOwnerRegCertificateDateIssue.setValue(legalInsurerData?.legalInsurerRegCertificateDateIssue);
    legalOwnerRegCertificate.setValue(legalInsurerData?.legalInsurerRegCertificate);
  }
  legalOwnerLegalAddress.setValue(legalInsurerData?.legalInsurerAddress, { emitEvent: true });
  legalOwnerLegalHouseNumber.setValue(legalInsurerData?.legalInsurerHouseNumber, { emitEvent: false });
  legalOwnerLegalAddressDaData.setValue(legalInsurerData?.legalInsurerAddressDaData, { emitEvent: false });

  // Данные собственник адля ИП
  legalOwnerFirstName.setValue(legalInsurerData?.legalInsurerFirstName, { emitEvent: false });
  legalOwnerLastName.setValue(legalInsurerData?.legalInsurerLastName, { emitEvent: false });
  legalOwnerMiddleName.setValue(legalInsurerData?.legalInsurerMiddleName, { emitEvent: false });
  legalOwnerBirthDate.setValue(legalInsurerData?.legalInsurerBirthDate, { emitEvent: false });
  legalOwnerPassportLicense.setValue(legalInsurerData?.passportLicense, { emitEvent: false });
  legalOwnerPassportDate.setValue(legalInsurerData?.legalInsurerPassportDate, { emitEvent: false });
}
