import {Component, inject} from '@angular/core';
import {OsagoService} from "../../../../shared/services/osago.service";
import {IOffer} from "../../../../shared/interface/offers.interface";
import {DatePipe} from "@angular/common";

@Component({
  selector: 'app-form-policy-alert',
  standalone: true,
  imports: [
    DatePipe
  ],
  templateUrl: './form-policy-alert.component.html',
  styleUrl: './form-policy-alert.component.scss'
})
export class FormPolicyAlertComponent {

  public osagoService = inject(OsagoService);
  // Оффер который нашли у клиента isProlongation
  public prolongationOffer!: IOffer | undefined;

  get isProlongationText(): boolean {
    this.prolongationOffer = this.osagoService.offers.find((offer: IOffer) => offer.isProlongation);
    return !!this.prolongationOffer;
  }

}
