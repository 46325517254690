import {FormGroup} from "@angular/forms";
import {CarDocumentTypesEnum} from "../enums/car-document-types.enum";
import {CarIdentificatorsEnum} from "../enums/car-identificators.enum";

export function resetForm(form: FormGroup): void {
  resetCarData(form.get('carData') as FormGroup);
}

// Сбрасываем данные авто
export function resetCarData(carDataFormGroup: FormGroup): void {
  const {
    modelId,
    modelName,
    brandId,
    brandName,
    productionYear,
    horsePower,
    carDocument,
    carIdentificators,
  } = carDataFormGroup.controls;
  modelId?.reset();
  modelName?.reset();
  brandId?.reset();
  brandName?.reset();
  productionYear?.reset();
  horsePower?.reset();
  carDocument?.get('documentIssueDate')?.reset();
  carDocument?.get('documentNumber')?.reset();
  carDocument?.get('documentSeries')?.reset();
  carDocument?.get('stsNumber')?.reset();
  carDocument?.get('ptsNumber')?.reset();
  carDocument?.get('stsDate')?.reset();
  carDocument?.get('ptsNumber')?.reset();
  carDocument?.get('ptsDate')?.reset();
  carDocument?.get('ePtsNumber')?.reset();
  carDocument?.get('ePtsDate')?.reset();
  carDocument?.get('documentType')?.setValue(CarDocumentTypesEnum.STS);
  carIdentificators?.get('vinNumber')?.reset();
  carIdentificators?.get('bodyNumber')?.reset();
  carIdentificators?.get('chassisNumber')?.reset();
  carIdentificators?.get('identifier')?.setValue(CarIdentificatorsEnum.VIN);

  carDataFormGroup.markAsUntouched();
}
