import {inject, Inject, Injectable} from '@angular/core';
import {HttpService} from "./http.service";
import {HttpClient} from "@angular/common/http";
import {environment} from "@environment/environment";
import {SettingsService} from "./settings.service";
import {FormService} from "./form.service";
import {interval, Observable, switchMap, takeWhile} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class DraftService extends HttpService {

  private readonly settingsService = inject(SettingsService);
  private readonly formService = inject(FormService);
  protected override http = inject(HttpClient);

  // Получаем данные черновичка полиса
  public getDraft(offerId: string): Observable<any> {
    return interval(2000).pipe(
      switchMap(() => {
        const { value } = this.formService.form;
        const request = {
          apiKey: this.settingsService.apiKey,
          applicationId: value?.applicationId,
          offerId
        };
        return this.http.post(environment.apiUrl + 'app/GetDraft', request);
      }),
      takeWhile((response: any) => response.result && response.value.status === 1, true),
    );
  }

}
