<div class="alert alert-primary mb-3 mb-sm-4">
  Введите номер полиса, который хотите продлить
</div>

<div [formGroup]="formPolicyNumber" class="row">
  <div class="col-12 col-md-4 mb-3 mb-sm-4">
    <app-autocomplete
      formControlName="previousPolicySeries"
      [isReadonly]="isLoading"
      [typeaheadList]="appService.policySeries"
      [typeaheadOptionsLimit]="appService.policySeries.length"
      [isDropdown]="true"
    ></app-autocomplete>
  </div>

  <div class="col-12 col-md-8 mb-3 mb-sm-4">
    <app-form-text-field
      formControlName="previousPolicyNumber"
      [isReadonly]="isLoading"
    ></app-form-text-field>
  </div>

  <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
    <app-form-text-field
      formControlName="prolongationFormBirthDate"
      [isReadonly]="isLoading"
    ></app-form-text-field>
  </div>

  <div class="col-12 col-md-4 mb-3 mb-sm-4">
    <app-form-text-field
      formControlName="phone"
      [isReadonly]="isLoading"
    ></app-form-text-field>
  </div>

  <div class="col-12 col-md-4 mb-3 mb-sm-4">
    <app-autocomplete
      formControlName="email"
      [suggestionPart]="daDataSystemName.EMAIL"
      [isReadonly]="isLoading"></app-autocomplete>
  </div>
</div>

<div class="row justify-content-end">
  <div class="col-12 col-sm-auto mb-3 mb-sm-4">
    <button [disabled]="isLoading" type="button" class="btn btn-primary btn-lg w-100" (click)="onSubmit()">
      @if (isLoading) {
        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
      } @else {
        Получить полис
      }
    </button>
  </div>
</div>

