import {Component, inject, Input, OnDestroy} from '@angular/core';
import {AlfaIdService} from "./alfa-id.service";
import {Subscription} from "rxjs";
import {FormService} from "../../../../src/app/shared/services/form.service";
import {AppService} from "../../../../src/app/shared/services/app.service";

@Component({
  selector: 'app-alfa-id',
  standalone: true,
  imports: [],
  templateUrl: './alfa-id.component.html',
  styleUrl: './alfa-id.component.scss'
})
export class AlfaIdComponent implements OnDestroy {
  @Input() public isLoadApp!: boolean;
  @Input() public forProlongation: boolean = false;
  public alfaIdService = inject(AlfaIdService);
  public formService = inject(FormService);
  public appService = inject(AppService);
  // Подписка
  private subscription = new Subscription();

  // --------------------------------------------------------------------------
  // Инициализация

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  // Получаем AlfaID url для авторизации
  public getAlfaIdUrl(): void {
    this.alfaIdService.isLoading = true;
    this.subscription.add(
      this.alfaIdService.getAlfaIdUrl(this.forProlongation)
        .subscribe((res: any) => {
          if (res.result) {
            const { value } = this.formService.form;

            // Проверка, поддерживается ли localStorage
            if (this.isLocalStorageSupported()) {
              localStorage.setItem('alfaIdLicensePlate', value.carData?.licensePlate);
            } else {
              console.warn('localStorage is not supported in this browser.');
              this.alfaIdService.isLoading = false;
            }

            window.location.href = res.value;
          } else {
            this.alfaIdService.isLoading = false; // Исправлено на false, чтобы остановить загрузку
          }
        })
    );
  }


  private isLocalStorageSupported(): boolean {
    try {
      const testKey = 'localStorageTest';
      localStorage.setItem(testKey, 'test');
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }
}
