// Список всех идентификаторов авто
import {IPolicySeries} from "../interface/osago-application.interface";
import {PolicySeries} from "../enums/policy-series";

export const POLICY_SERIES: IPolicySeries[] = [
  {
    systemName: PolicySeries.XXX,
    value: 'ХХХ'
  },
  {
    systemName: PolicySeries.TTT,
    value: 'ТТТ'
  },
  {
    systemName: PolicySeries.AAH,
    value: 'ААН'
  },
  {
    systemName: PolicySeries.AAM,
    value: 'ААМ'
  },
  {
    systemName: PolicySeries.AAK,
    value: 'ААК'
  }
];
