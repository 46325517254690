export enum DaData {
    ADDRESS = 'address',
    ADDRESS_FULL = 'addressFull',
    NAME = 'name',
    SURNAME = 'surname',
    PATRONYMIC = 'patronymic',
    FMS_UNIT = 'fms_unit',
    LEGAL_NAME = 'legal_name',
    EMAIL = 'email'
}
