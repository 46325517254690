import {inject, Inject} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {environment} from "@environment/environment";

export abstract class HttpService {
  private _baseUrl: string | null = '';
  private _hostUrl: string | null = environment.hostUrl;
  protected _errorMessages: string[] = [];

  protected http = inject(HttpClient);

  public get baseUrl(): string | null {
    return this._baseUrl;
  }

  public set baseUrl(value: string | null) {
    this._baseUrl = value;
  }

  protected get options(): any {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      responseType: 'json'
    };
  }

  protected get(relativeUrl: string): Observable<any> {
    const url = this.baseUrl + relativeUrl;

    return this.http.get(url, this.options)
      .pipe(
        tap({
          next: (response: any) => this.logResponse(response, url),
          error: (response: any) => this.logResponse(response, url, true)
        })
      ) as Observable<any>;
  }

  protected getWithOptions(relativeUrl: string, options: any): Observable<any> {
    const url = this.baseUrl + relativeUrl;

    return this.http.get(url, options)
      .pipe(
        tap({
          next: (response: any) => this.logResponse(response, url),
          error: (response: any) => this.logResponse(response, url, true)
        })
      ) as Observable<any>;
  }

  protected post(relativeUrl: string, data: any): Observable<any> {
    const url = this.baseUrl + relativeUrl;

    return this.http.post(url, data, this.options)
      .pipe(
        tap({
          next: (response: any) => this.logResponse(response, url),
          error: (response: any) => this.logResponse(response, url, true)
        })
      ) as Observable<any>;
  }

  protected getLocal(relativeUrl: string): Observable<any> {
    const url = this._hostUrl + relativeUrl;

    return this.http.get(url, this.options)
      .pipe(
        tap({
          next: (response: any) => this.logResponse(response, url),
          error: (response: any) => this.logResponse(response, url, true)
        })
      ) as Observable<any>;
  }

  protected getWithOptionsLocal(relativeUrl: string, options: any): Observable<any> {
    const url = this._hostUrl + relativeUrl;

    return this.http.get(url, options)
      .pipe(
        tap({
          next: (response: any) => this.logResponse(response, url),
          error: (response: any) => this.logResponse(response, url, true)
        })
      ) as Observable<any>;
  }

  protected postLocal(relativeUrl: string, data: any): Observable<any> {
    const url = this._hostUrl + relativeUrl;

    return this.http.post(url, data, this.options)
      .pipe(
        tap({
          next: (response: any) => this.logResponse(response, url),
          error: (response: any) => this.logResponse(response, url, true)
        })
      ) as Observable<any>;
  }

  private logResponse(response: any, url: string, isError = false): void {
    const message = response != null && response.error != null && response.error.message != null
      ? response.error.message
      : '';

    if ((message === '' && !isError) || message === 'Кеш для данного ключа не найден') {
      return;
    }

    const params = 'API call to ' + url + ' failed. ' + message;
  }
}
