<div class="card h-auto shadow-0 border-0 custom-rounded">
  <div class="card-body p-3 p-sm-4">
    <div class="row align-items-center">
      <div class="col text-center">
        <div class="main-title-item mb-2 text-wrap">{{ title }}</div>
        <div class="second-title-item text-wrap" [innerHTML]="message"></div>
      </div>
    </div>
    <div class="row justify-content-center mt-3 mt-sm-4">
      @if (!isShowBtnGosNumber && !isShowBtnPolicyNumber && isShowContinueButton) {
        <div class="col-12 col-sm-6" [class.col-sm-auto]="!isShowBtnNewPolicy">
          <button
            type="button"
            class="btn btn-primary w-100"
            (click)="modalRef.hide()">
            Продолжить
          </button>
        </div>
      }
      @if (isShowBtnNewChatMessage) {
        <div class="col-12 col-sm-6" [class.col-sm-auto]="!isShowBtnNewPolicy">
          <button
            type="button"
            class="btn btn-primary w-100"
            (click)="modalRef.hide()">
            Написать в чат
          </button>
        </div>
      }
      @if (isShowBtnGosNumber) {
        <div class="col-12 mb-2">
          <button
            type="button"
            class="btn btn-primary w-100"
            (click)="completeGosNumber()">
            ПРОДОЛЖИТЬ по ГОСНОМЕРУ
          </button>
        </div>
      }
      @if (isShowBtnPolicyNumber) {
        <div class="col-12 mb-2">
          <button
            type="button"
            class="btn btn-primary w-100"
            (click)="completePolicyNumber()">
            ПРОДОЛЖИТЬ по НОМЕРУ ПОЛИСА
          </button>
        </div>
      }
      @if (isShowBtnNewPolicy) {
        <div class="col-12" [class.col-sm-6]="!isShowBtnGosNumber && !isShowBtnPolicyNumber">

          <button
            type="button"
            class="btn btn-outline-primary w-100 mt-2 mt-sm-0"
            (click)="navigateToMainPage()">
            @if (isRedirectProcessing) {
              <div class="spinner-border payment-loader" role="status">
                <span class="visually-hidden">Загрузка...</span>
              </div>
            } @else {
              Купить новый
            }
          </button>
        </div>
      }
      @if (isInvalidForm) {
        <div class="col-12" [class.col-sm-6]="!isShowBtnGosNumber && !isShowBtnPolicyNumber">
          <button
            type="button"
            class="btn btn-outline-primary w-100 mt-2 mt-sm-0"
            (click)="navigateToFormPage()">
            Обновите данные
          </button>
        </div>
      }
    </div>
  </div>
</div>
