import {TextMaskHelper} from "../helpers/textmaskHelper";
import moment from "moment-mini";
import {CarDocumentTypesEnum} from "../enums/car-document-types.enum";
import {IDriverForm, IOsago} from "../interface/osago-application.interface";
import {getFormatDate} from "./formatDate";
import {TypeOrganisation} from "../enums/type-organisation";
import {
  ICarData,
  ICarDocument,
  IDriverLicense,
  IInsured,
  ILegalInsured,
  ILegalOwner,
  IOldDriverLicense,
  IOwner,
  IPassport,
  IRegistrationAddressData,
  ISendOsagoApplication,
  IDriver
} from "../interface/application.interface";
import {IDaDataAddress} from "../interface/da-data";

// Собираем данные осаго для отправки
export function getApplicationSendRequest(value: IOsago): ISendOsagoApplication {
  // Анкета физ или юр лицо
  const isJuridical = value.isJuridical;
  const legalOwnerIsJuridical = value.legalOwner?.legalOwnerIsJuridical;
  // Является ли собственник страхователем у юр лица
  const ownerIsInsured = value.isJuridical ? value.legalInsurer.legalInsurerIsLegalOwner : value.ownerIsInsured;
  const insurerIsIndividual = value.legalInsurer?.legalInsurerType === TypeOrganisation.INDIVIDUAL;
  const insurerIsLegal = value.legalInsurer?.legalInsurerType === TypeOrganisation.LEGAL;

  return {
    apiKey: value?.apiKey,
    applicationId: value?.applicationId,
    carData: getCarData(value?.carData),
    cpaClientUid: value?.cpaClientUid ? value.cpaClientUid : undefined,
    carCharacteristicsRequestId: value?.carData?.hasNoLicensePlate ? undefined : value.carCharacteristicsRequestId,
    hasDriversRestriction: value.isJuridical && insurerIsIndividual ? !value.driversWithoutRestriction : insurerIsLegal ? false : !value.driversWithoutRestriction,
    drivers: (!value.isJuridical && !value.driversWithoutRestriction) || (value.isJuridical && insurerIsIndividual) ? getDrivers(value.drivers) : undefined,
    owner: {
      ...getContacts(value?.contacts),
      ...getOwner(value.owner, value.legalOwner, isJuridical, legalOwnerIsJuridical, ownerIsInsured, value.legalInsurer)
    },
    insured: !ownerIsInsured ? {
      ...getContacts(value?.contacts),
      ...getInsurer(value.insurer, value.legalInsurer, value.isJuridical)
    } : undefined,
    policyParameters: {
      policyStartDate: value.policyStartDate,
      osagoUtilisationPeriod: 12
    },
  }
}

// Данные авто
function getCarData(carData: any): ICarData {
  return {
    licensePlate: carData?.hasNoLicensePlate ? undefined : carData?.licensePlate,
    carPower: carData?.horsePower,
    diagnosticCard: {hasNoCard: false},
    brandId: carData?.brandId,
    brandName: carData?.brandName,
    modelId: carData?.modelId,
    modelName: carData?.modelName,
    productionYear: carData?.productionYear,
    purposeOfUseType: carData?.purposeOfUse,
    hasNoLicensePlate: carData?.hasNoLicensePlate,
    hasTrailer: carData?.carWithPrince,
    carDocument: getCarDocuments(carData),
    carIdentificators: {
      vin: carData?.carIdentificators?.vinNumber
        ? carData?.carIdentificators?.vinNumber.toString()
        : undefined,
      chassisNumber: carData?.carIdentificators?.chassisNumber
        ? carData?.carIdentificators?.chassisNumber.toString()
        : undefined,
      bodyNumber: carData?.carIdentificators?.bodyNumber
        ? carData?.carIdentificators?.bodyNumber.toString()
        : undefined
    },
  }
}

// Получаем данные водителей
function getDrivers(driversData: IDriverForm[]): IDriver[] | [] {
  if (!driversData) return [];

  return driversData.map((driver: IDriverForm) => {
    const experienceStartDate = driver.rememberOnlyYear
      ? driver.experienceYear ? getExperienceYear(driver.experienceYear) : ''
      : driver.experienceDate ? getFormatDate(driver.experienceDate) : '';

    return {
      firstName: driver.driverFirstName,
      lastName: driver.driverLastName,
      middleName: driver.driverMiddleName,
      birthDate: getFormatDate(driver.driverBirthDate),
      driverExperience: moment().diff(moment(getFormatDate(experienceStartDate), 'DD.MM.YYYY', false), 'years'),
      driverAge: moment().diff(moment(getFormatDate(driver.driverBirthDate), 'DD.MM.YYYY', false), 'years'),
      driverLicense: getDriverLicense(driver),
      gender: 'Male',
      rememberOnlyYear: driver.rememberOnlyYear,
      experienceStartDate,
      oldDriverLicense: driver.licenseWasChanged ? getOldDriverLicense(driver) : undefined,
    };
  });
}

// Получаем данные контактов
function getContacts(contacts: any): any {
  return {
    phone: contacts?.phone?.replace('+', ''),
    email: contacts?.email,
  }
}

// Получаем данные собственника
function getOwner(owner: any, legalOwner: any, isJuridical: boolean, legalOwnerIsJuridical: boolean, ownerIsInsured: boolean, legalInsurer: any): IOwner | ILegalOwner {
  const ownerData = isJuridical
    ? getLegalOwner(legalOwner, isJuridical, ownerIsInsured)
    : getIndividualOwner(owner, ownerIsInsured);
  return {
    isJuridical,
    ...ownerData
  }
}

// Получаем данные собственника физ лицо
function getIndividualOwner(owner: any, ownerIsInsured: boolean): IOwner {
  return {
    ownerIsInsured,
    firstName: owner?.ownerFirstName,
    lastName: owner?.ownerLastName,
    middleName: owner?.ownerMiddleName,
    birthDate: getFormatDate(owner?.ownerBirthDate),
    passport: getPassport(owner, true),
  };
}

// Получаем данные собственника юр лицо
function getLegalOwner(legalOwner: any, isJuridical: boolean, ownerIsInsured: boolean): any {
  if (legalOwner?.legalOwnerIsLegal?.legalOwnerType === TypeOrganisation.LEGAL || legalOwner?.legalOwnerType === TypeOrganisation.LEGAL) {
    return {
      ownerIsInsured,
      isJuridical: true,
      legalFormType: legalOwner?.legalOwnerIsLegal?.legalOwnerType,
      legalPersonData: {
        legalName: legalOwner?.legalOwnerIsLegal?.legalOwnerName,
        INN: legalOwner?.legalOwnerIsLegal?.legalOwnerInn,
        OGRN: legalOwner?.legalOwnerIsLegal?.legalOwnerOgrn,
        KPP: legalOwner?.legalOwnerIsLegal?.legalOwnerKpp,
        EGRYL: legalOwner?.legalOwnerIsLegal?.legalOwnerEgryl,
        ...getLegalCertificate(legalOwner?.legalOwnerIsLegal?.legalOwnerRegCertificate, legalOwner?.legalOwnerIsLegal?.legalOwnerRegCertificateDateIssue),
        legalOwnerDaData: legalOwner?.legalOwnerIsLegal?.legalOwnerDaData,
        registrationAddressData: getRegistrationAddressData(legalOwner?.legalOwnerIsLegal.legalOwnerLegalAddressDaData, legalOwner?.legalOwnerIsLegal.legalOwnerLegalHouseNumber)
      }
    }
  } else if (legalOwner?.legalOwnerIsLegal?.legalOwnerType === TypeOrganisation.INDIVIDUAL || legalOwner?.legalOwnerType === TypeOrganisation.INDIVIDUAL) {
    return {
      ownerIsInsured,
      isJuridical: false,
      legalFormType: legalOwner?.legalOwnerIsLegal?.legalOwnerType,
      firstName: legalOwner?.legalOwnerIsLegal?.legalOwnerFirstName,
      lastName: legalOwner?.legalOwnerIsLegal?.legalOwnerLastName,
      middleName: legalOwner.legalOwnerIsLegal?.legalOwnerMiddleName,
      birthDate: legalOwner?.legalOwnerIsLegal?.legalOwnerBirthDate ? getFormatDate(legalOwner?.legalOwnerIsLegal?.legalOwnerBirthDate) : '',
      passport: getPassport(legalOwner),
      legalPersonData: {
        legalName: legalOwner?.legalOwnerIsLegal?.legalOwnerName,
        inn: legalOwner?.legalOwnerIsLegal?.legalOwnerInn,
        legalOwnerDaData: legalOwner?.legalOwnerIsLegal?.legalOwnerDaData,
        registrationAddressData: getRegistrationAddressData(legalOwner?.legalOwnerIsLegal.legalOwnerLegalAddressDaData, legalOwner?.legalOwnerIsLegal.legalOwnerLegalHouseNumber)
      }
    }
  } else {
    const passportData = {
      ownerPassportDate: legalOwner?.legalOwnerIsNotLegal?.legalOwnerPassportDate,
      passportLicense: legalOwner?.legalOwnerIsNotLegal?.legalOwnerPassportLicense,
      ownerHouseNumber: legalOwner?.legalOwnerIsNotLegal?.legalOwnerHouseNumber,
      ownerAddressDaData: legalOwner?.legalOwnerIsNotLegal?.legalOwnerAddressDaData,
    }
    return {
      ownerIsInsured,
      isJuridical: false,
      firstName: legalOwner?.legalOwnerIsNotLegal?.legalOwnerFirstName,
      lastName: legalOwner?.legalOwnerIsNotLegal?.legalOwnerLastName,
      middleName: legalOwner?.legalOwnerIsNotLegal?.legalOwnerMiddleName,
      birthDate: legalOwner?.legalOwnerIsNotLegal?.legalOwnerBirthDate ? getFormatDate(legalOwner?.legalOwnerIsNotLegal?.legalOwnerBirthDate) : '',
      passport: getPassport(passportData, true, false)
    }
  }
}

// Получаем данные страхователя
function getInsurer(insurer: any, legalInsurer: any, isJuridical: boolean): IInsured | ILegalInsured {
  const insurerData = isJuridical ? getLegalInsurer(legalInsurer, isJuridical) : getIndividualInsurer(insurer);
  return {
    ...insurerData
  }
}

// Получаем данные страхователя физ лицо
function getIndividualInsurer(insurer: any): any {
  return {
    firstName: insurer?.insurerFirstName,
    lastName: insurer?.insurerLastName,
    middleName: insurer?.insurerMiddleName,
    birthDate: getFormatDate(insurer?.insurerBirthDate),
    passport: getPassport(insurer, false),
  };
}

// Получаем данные страхователя юр лицо
function getLegalInsurer(legalInsurer: any, isJuridical: boolean): any {
  if (isJuridical && legalInsurer.legalInsurerType === TypeOrganisation.LEGAL) {
    return {
      isJuridical,
      legalFormType: legalInsurer.legalInsurerType,
      legalPersonData: {
        legalName: legalInsurer?.legalInsurerName,
        INN: legalInsurer?.legalInsurerInn,
        OGRN: legalInsurer?.legalInsurerOgrn,
        KPP: legalInsurer?.legalInsurerKpp,
        EGRYL: legalInsurer?.legalInsurerEgryl,
        ...getLegalCertificate(legalInsurer?.legalInsurerRegCertificate, legalInsurer?.legalInsurerRegCertificateDateIssue),
        legalInsurerDaData: legalInsurer?.legalInsurerDaData,
        registrationAddressData: getRegistrationAddressData(legalInsurer?.legalInsurerAddressDaData, legalInsurer?.legalInsurerHouseNumber)
      }
    }
  } else if (isJuridical && legalInsurer.legalInsurerType === TypeOrganisation.INDIVIDUAL) {
    return {
      isJuridical: false,
      legalFormType: legalInsurer.legalInsurerType,
      firstName: legalInsurer?.legalInsurerFirstName,
      lastName: legalInsurer?.legalInsurerLastName,
      middleName: legalInsurer?.legalInsurerMiddleName,
      birthDate: legalInsurer?.legalInsurerBirthDate ? getFormatDate(legalInsurer?.legalInsurerBirthDate) : '',
      passport: getPassport(legalInsurer, false),
      legalPersonData: {
        legalName: legalInsurer?.legalInsurerName,
        INN: legalInsurer?.legalInsurerInn,
        legalInsurerDaData: legalInsurer?.legalInsurerDaData,
        registrationAddressData: getRegistrationAddressData(legalInsurer?.legalInsurerAddressDaData, legalInsurer?.legalInsurerHouseNumber)
      }
    }
  }
}

// Получение данных документа авто
function getCarDocuments(carData: any): ICarDocument {
  switch (carData?.carDocument?.documentType) {
    case CarDocumentTypesEnum.STS:
      return {
        documentType: 'STS',
        documentSeries: carData?.carDocument?.stsNumber?.toString().slice(0, 4),
        documentNumber: carData?.carDocument?.stsNumber?.toString().slice(4, carData?.carDocument?.stsNumber?.length + 1),
        documentIssueDate: getFormatDate(carData?.carDocument?.stsDate)
      };
    case CarDocumentTypesEnum.PTS:
      return {
        documentType: 'PTS',
        documentSeries: carData?.carDocument?.ptsNumber?.toString().slice(0, 4),
        documentNumber: carData?.carDocument?.ptsNumber?.toString().slice(4, carData?.carDocument?.ptsNumber?.length + 1),
        documentIssueDate: getFormatDate(carData?.carDocument?.ptsDate)
      };
    case CarDocumentTypesEnum.ePTS:
      return {
        documentType: 'ePTS',
        documentNumber: carData?.carDocument?.ePtsNumber,
        documentIssueDate: getFormatDate(carData?.carDocument?.ePtsDate)
      };
    default:
      return {
        documentType: 'STS',
        documentSeries: carData?.carDocument?.stsNumber?.toString().slice(0, 4),
        documentNumber: carData?.carDocument?.stsNumber?.toString().slice(4, carData?.carDocument?.stsNumber?.length + 1),
        documentIssueDate: getFormatDate(carData?.carDocument?.stsDate)
      };
  }
}

// Получаем данные паспорта
function getPassport(ownerOrInsurer: any, isOwner: boolean = true, isLegal: boolean = false): IPassport {
  let passportLicense = ownerOrInsurer?.passportLicense;
  let passportSeries = null;
  let passportNumber = null;
  let passportIssueDate = null;
  let houseNumber = null;
  let daDataAddress = null;
  let addressAsString = null;

  // Если анкета юр лицо
  if ((ownerOrInsurer?.legalOwnerIsLegal?.legalOwnerType === TypeOrganisation.LEGAL
      || ownerOrInsurer?.legalOwnerIsLegal?.legalOwnerType === TypeOrganisation.INDIVIDUAL)
    || (ownerOrInsurer?.legalInsurerType === TypeOrganisation.LEGAL
      || ownerOrInsurer?.legalInsurerType === TypeOrganisation.INDIVIDUAL)) {

    houseNumber = isOwner ? ownerOrInsurer?.legalOwnerIsLegal?.legalOwnerLegalHouseNumber : ownerOrInsurer?.legalInsurerHouseNumber;
    daDataAddress = isOwner ? ownerOrInsurer?.legalOwnerIsLegal?.legalOwnerLegalAddressDaData : ownerOrInsurer?.legalInsurerAddressDaData;

    daDataAddress.flat_type = houseNumber ? 'офис' : null;
    const addressValue = daDataAddress?.value?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '') || daDataAddress?.result?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '');
    addressAsString = hasApartmentNumber(addressValue)
      ? addressValue
      : addressValue + `${houseNumber ? ', ' + daDataAddress.flat_type + ' ' + houseNumber : ''}`;

    if (daDataAddress) {
      daDataAddress.value = addressAsString;
    }

    passportLicense = isOwner ? ownerOrInsurer?.legalOwnerIsLegal?.legalOwnerPassportLicense : ownerOrInsurer?.passportLicense;

    passportSeries = passportLicense ? passportLicense?.toString().slice(0, 4) : undefined;
    passportNumber = passportLicense ? passportLicense?.toString().slice(4, passportLicense.length + 1) : undefined;
    passportIssueDate = getFormatDate(isOwner ? ownerOrInsurer?.legalOwnerIsLegal?.legalOwnerPassportDate : ownerOrInsurer?.legalInsurerPassportDate);
  } else {
    houseNumber = ownerOrInsurer
      && ownerOrInsurer[isOwner
        ? isLegal
          ? 'legalOwnerHouseNumber'
          : 'ownerHouseNumber'
        : isLegal
          ? 'legalInsurerHouseNumber'
          : 'insurerHouseNumber']!;
    daDataAddress = ownerOrInsurer
      && ownerOrInsurer[isOwner
        ? isLegal
          ? 'legalOwnerLegalAddress'
          : 'ownerAddressDaData'
        : isLegal
          ? 'legalInsurerAddress'
          : 'insurerAddressDaData'];

    if (daDataAddress) {
      daDataAddress.flat_type = houseNumber ? 'кв' : null;
      daDataAddress.flat = houseNumber ? houseNumber : null;
    }


    const addressValue = daDataAddress?.value?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '') || daDataAddress?.result?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '');

    addressAsString = hasApartmentNumber(addressValue)
      ? addressValue
      : addressValue + `${houseNumber ? ', ' + daDataAddress?.flat_type + ' ' + houseNumber : ''}`;

    if (daDataAddress) {
      daDataAddress.value = addressAsString;
    }

    if (passportLicense && passportLicense?.length === 10) {
      passportSeries = passportLicense ? passportLicense?.toString().slice(0, 4) : undefined;
      passportNumber = passportLicense ? passportLicense?.toString().slice(4, ownerOrInsurer?.passportLicense?.length + 1) : undefined;
    }

    passportIssueDate = getFormatDate(isOwner ? ownerOrInsurer?.ownerPassportDate : ownerOrInsurer?.insurerPassportDate);
  }
  return {
    passportSeries,
    passportNumber,
    passportIssueDate,
    registrationAddressData: {
      addressAsString,
      daDataAddress,
      hasNoApartment: !!houseNumber
    }
  };
}

function getRegistrationAddressData(daDataAddress: IDaDataAddress, officeNumber?: string): IRegistrationAddressData {
  daDataAddress.flat_type = officeNumber ? 'офис' : null;
  daDataAddress.flat = officeNumber ? officeNumber : null;

  const addressValue = daDataAddress?.value || daDataAddress?.result;
  const addressAsString = daDataAddress.value && hasApartmentNumber(daDataAddress?.value!)
    ? addressValue?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
    : addressValue?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '') + `${officeNumber ? ', ' + daDataAddress.flat_type + ' ' + officeNumber : ''}`;

  if (daDataAddress) {
    daDataAddress.value = addressAsString;
  }

  return {
    daDataAddress,
    addressAsString,
    hasNoApartment: !!officeNumber
  }
}

// Получаем данные водительских прав
export function getDriverLicense(driver: IDriverForm): IDriverLicense {
  const licenseIssueDate = driver.rememberOnlyYear
    ? driver.experienceYear ? getExperienceYear(driver.experienceYear) : ''
    : driver.experienceDate ? getFormatDate(driver.experienceDate) : '';
  return {
    isForeignLicense: false,
    licenseIssueDate: licenseIssueDate,
    licenseNumber: TextMaskHelper?.removeMask(driver.driverLicense || '', 10)?.substr(4),
    licenseSeries: TextMaskHelper?.removeMask(driver.driverLicense || '', 10)?.substr(0, 4).toUpperCase()
  }
}

// Получаем данные старых прав
export function getOldDriverLicense(driver: IDriverForm): IOldDriverLicense {
  return {
    lastNameInOldLicense: driver.oldDriverLastName,
    licenseNumber: TextMaskHelper?.removeMask(driver.oldLicense || '', 10).substr(4),
    licenseSeries: TextMaskHelper?.removeMask(driver.oldLicense || '', 10).substr(0, 4).toUpperCase()
  };
}

// Получаем данные сертификата
function getLegalCertificate(certificateNumber: string, certificateDateIssue: string): any {
  return (certificateNumber && certificateDateIssue) && {
    regCertificate: certificateNumber ? certificateNumber.toString().slice(2, certificateNumber.length + 1) : null,
    regCertificateSeries: certificateNumber ? certificateNumber.toString().slice(0, 2) : null,
    documentDateIssue: getFormatDate(certificateDateIssue),
  }
}

// Получаем дату если выбрали "помню только год"
function getExperienceYear(experienceYear: string): string {
  // Получаем текущий год.
  const currentYear = new Date().getFullYear();

  // Сравниваем текущий год с переданным годом опыта.
  if (parseInt(experienceYear) === currentYear) {
    // Если года совпадают, возвращаем начало года.
    return `01.01.${experienceYear}`;
  } else {
    // В противном случае возвращаем конец года.
    return `31.12.${experienceYear}`;
  }
}

// Проверяем наличие квартиры в адресе
function hasApartmentNumber(address: string): boolean {
  // Регулярное выражение для поиска ", кв " и за ним следует любое количество цифр
  const regexAppartment = /, кв \d+/;
  const regexOffice = /, офис \d+/;
  return regexAppartment.test(address) || regexOffice.test(address);
}
