
@if (fromOfflineForm) {
  <div class="container">

    <div class="row justify-content-center">
      <div class="col-12 col-md-9 col-lg-7 col-xxl-7">
        <div class="success-block mt-4 m-auto">
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="72" height="72" rx="24"/>
            <path d="M48.0043 26.3588L31.8835 43.2056C31.7751 43.3193 31.6463 43.4095 31.5045 43.4711C31.3626 43.5327 31.2106 43.5644 31.057 43.5644C30.9034 43.5644 30.7513 43.5327 30.6095 43.4711C30.4676 43.4095 30.3388 43.3193 30.2305 43.2056L23.9963 36.6841C23.8879 36.5704 23.7591 36.4802 23.6173 36.4186C23.4755 36.357 23.3234 36.3253 23.1698 36.3253C23.0162 36.3253 22.8642 36.357 22.7223 36.4186C22.5805 36.4802 22.4517 36.5704 22.3433 36.6841C22.2345 36.7974 22.1482 36.932 22.0892 37.0802C22.0303 37.2285 22 37.3874 22 37.5479C22 37.7084 22.0303 37.8673 22.0892 38.0156C22.1482 38.1638 22.2345 38.2984 22.3433 38.4117L28.5798 44.9283C29.2376 45.6146 30.1292 46 31.0587 46C31.9882 46 32.8798 45.6146 33.5377 44.9283L49.6573 28.0852C49.7659 27.9719 49.8521 27.8374 49.9109 27.6893C49.9697 27.5411 50 27.3823 50 27.222C50 27.0616 49.9697 26.9028 49.9109 26.7547C49.8521 26.6066 49.7659 26.472 49.6573 26.3588C49.5489 26.2451 49.4201 26.1548 49.2783 26.0933C49.1364 26.0317 48.9843 26 48.8308 26C48.6772 26 48.5251 26.0317 48.3833 26.0933C48.2414 26.1548 48.1126 26.2451 48.0043 26.3588Z" fill="white"/>
          </svg>

          <div class="title">
            Ваша заявка отправлена
          </div>

          <div class="second-title text-center">
            Спасибо за обращение. Мы работаем с 10:00 - 18:00<br>
            по Московскому времени. В ближайшее время<br>
            мы свяжемся с вами для оформления полиса
          </div>

          <button class="btn btn-outline-primary w-100" (click)="navigateToMainPage()">Вернуться на главную</button>

        </div>
      </div>
    </div>

  </div>
} @else {
  <div class="container">

    <div class="row justify-content-center">
      <div class="col-12 col-md-9 col-lg-7 col-xxl-7">
        <div class="success-block mt-4 m-auto">
          <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="72" height="72" rx="24"/>
            <path d="M48.0043 26.3588L31.8835 43.2056C31.7751 43.3193 31.6463 43.4095 31.5045 43.4711C31.3626 43.5327 31.2106 43.5644 31.057 43.5644C30.9034 43.5644 30.7513 43.5327 30.6095 43.4711C30.4676 43.4095 30.3388 43.3193 30.2305 43.2056L23.9963 36.6841C23.8879 36.5704 23.7591 36.4802 23.6173 36.4186C23.4755 36.357 23.3234 36.3253 23.1698 36.3253C23.0162 36.3253 22.8642 36.357 22.7223 36.4186C22.5805 36.4802 22.4517 36.5704 22.3433 36.6841C22.2345 36.7974 22.1482 36.932 22.0892 37.0802C22.0303 37.2285 22 37.3874 22 37.5479C22 37.7084 22.0303 37.8673 22.0892 38.0156C22.1482 38.1638 22.2345 38.2984 22.3433 38.4117L28.5798 44.9283C29.2376 45.6146 30.1292 46 31.0587 46C31.9882 46 32.8798 45.6146 33.5377 44.9283L49.6573 28.0852C49.7659 27.9719 49.8521 27.8374 49.9109 27.6893C49.9697 27.5411 50 27.3823 50 27.222C50 27.0616 49.9697 26.9028 49.9109 26.7547C49.8521 26.6066 49.7659 26.472 49.6573 26.3588C49.5489 26.2451 49.4201 26.1548 49.2783 26.0933C49.1364 26.0317 48.9843 26 48.8308 26C48.6772 26 48.5251 26.0317 48.3833 26.0933C48.2414 26.1548 48.1126 26.2451 48.0043 26.3588Z" fill="white"/>
          </svg>

          <div class="title">
            Полис успешно оформлен
          </div>

          <div class="second-title">
            Он поступит Вам на почту в течение 20 минут
          </div>

          @if (showPartnerOffer) {
            <app-redirect-link [detailsLink]="detailsLink"></app-redirect-link>
          } @else {
            <button class="btn btn-outline-primary w-100" (click)="navigateToMainPage()">Вернуться на главную</button>
          }

        </div>
      </div>
    </div>

  </div>
}
