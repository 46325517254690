import { Injectable } from '@angular/core';
import { Params, QueryParamsHandling, Router, ActivatedRoute } from '@angular/router';

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    constructor(private readonly router: Router,
                private readonly route: ActivatedRoute) { }

    // Общий метод перехода по роуту
    // По умолчанию роутер мержит параметры
    public navigate(route: string, params?: Params, queryParamsHandlingValue: QueryParamsHandling = 'preserve'): void {
        this.router.navigate([route], {
          relativeTo: this.route,
          queryParams: params,
          queryParamsHandling: 'merge'
        }).then(r => {
        });
     }

    // Вставляем в url параметры
    public changeQueryParams(params: Params): void {
        this.router.navigate([], {queryParams: params, queryParamsHandling: 'merge'}).then(r => {});
    }

}
