import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {FormService} from "../../../shared/services/form.service";
import {NavigationService} from "../../../shared/services/navigation.service";
import {ValidationService} from "../../../shared/services/validation.service";
import {OsagoService} from "../../../shared/services/osago.service";
import {concatMap, finalize} from "rxjs/operators";
import {ContentService} from "../../../shared/services/content.service";
import {WidgetStatuses} from "../../../shared/enums/widgetStatuses.enum";
import {scrollToFirstError} from "../../../shared/functions/scrollToFirstError";
import {ROUTES} from "../../../shared/enums/routes.enum";
import {AppService} from "../../../shared/services/app.service";
import {Subscription} from "rxjs";
import {FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {resetCarData} from "../../../shared/functions/resetForm";
import {FormLicenseFieldComponent} from "../../../shared/components/form-license-field/form-license-field.component";

@Component({
  selector: 'app-license',
  standalone: true,
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
  imports: [
    ReactiveFormsModule,
    FormLicenseFieldComponent
  ]
})
export class LicenseComponent implements OnInit, OnDestroy {

  private appService = inject(AppService);

  constructor(public readonly formService: FormService,
              private readonly validationService: ValidationService,
              private readonly navigationService: NavigationService,
              private readonly osagoService: OsagoService,
              public readonly contentService: ContentService) {
  }

  // Индикатор загрузки
  public isLoading = false;
  public isLoadingWithoutLicense = false;
  // Форма
  public licenseFormGroup = this.formService.licenseFormGroup;
  // Подписка
  private subscription: Subscription = new Subscription();

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    setTimeout(() => {
      this.formService.licenseFormGroup.get('licensePlate')?.setValidators([Validators.required, Validators.pattern('[А-Яа-яЁё]{1}[0-9]{3}[А-Яа-яЁё]{2}[0-9]{2,3}')]);
      this.formService.licenseFormGroup.get('licensePlate')?.updateValueAndValidity();
    }, 100)
  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // --------------------------------------------------------------------------

  public licenseComplete(): void {
    const licenseControl = this.licenseFormGroup?.get('licensePlate');
    licenseControl?.markAsTouched();
    licenseControl?.updateValueAndValidity();
    const licenseValue = this.licenseFormGroup?.get('licensePlate')?.value;

    licenseControl?.markAsTouched();
    if (licenseControl?.invalid) return;

    if (!licenseValue) {
      this.navigateToCarData();
      return;
    }

    this.isLoading = true;

    // Проверяем валидность номера авто
    if (!this.validationService.validationLicensePage()) {
      this.formService.form.get('license')?.markAsTouched();
      return;
    }
    this.formService.form.markAsUntouched();

    this.formService.resetForm();

    // Номер авто
    this.licenseFormGroup?.get('hasNoLicensePlate')?.setValue(false);

    this.osagoService.setWidgetStatus(WidgetStatuses.CalculateWithLicense)
      .pipe(
        concatMap(() => this.osagoService.startLicenseVerification()),
        finalize(() => {
          scrollToFirstError();
          this.isLoading = false;
          this.navigationService.navigate('/form');
        })
      )
      .subscribe(() => {
      });

  }

  // Переходим на страницу данных авто после нажатия кнопки "без номера"
  public navigateToCarData(): void {
    this.formService.licenseFormGroup.get('licensePlate')?.reset();
    this.formService.form.get('carCharacteristicsRequestId')?.reset();
    const carDataFormGroup = this.formService.form.get('carData')! as FormGroup;
    resetCarData(carDataFormGroup);
    this.formService.resetForm();

    this.subscription.add(
      this.appService.createApplication()
        .pipe(
          concatMap(() => this.osagoService.setWidgetStatus(WidgetStatuses.CalculateWithoutLicense)),
          finalize(() =>  {
            this.isLoadingWithoutLicense = false;
            this.licenseFormGroup?.get('hasNoLicensePlate')?.setValue(true);
            this.formService.licenseFormGroup.get('licensePlate')?.clearValidators();
            this.formService.licenseFormGroup.get('licensePlate')?.markAsTouched();
            this.formService.licenseFormGroup?.updateValueAndValidity();
            this.navigationService.navigate(ROUTES.Form);
          })
        )
        .subscribe(() => {

        })
    );
  }
}
