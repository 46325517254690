import {Component, inject} from '@angular/core';
import {ContentService} from "../../../shared/services/content.service";
import {NgClass} from "@angular/common";
import { ProlongationFormService } from 'src/app/shared/services/prolongationForm.service';

@Component({
  selector: 'app-number-gos-or-policy',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './number-gos-or-policy.component.html',
  styleUrl: './number-gos-or-policy.component.scss'
})
export class NumberGosOrPolicyComponent {
  // Сервисы
  public prolongationFormService = inject(ProlongationFormService);
  public contentService = inject(ContentService);

  // Форма
  public form = this.prolongationFormService.form;

  // Выбираем Юр или Физ лицо
  public onChangeIsNumberGos(isNumberGos: boolean): void {
    this.prolongationFormService.form.get('isNumberGos')?.setValue(isNumberGos);
  }
}
