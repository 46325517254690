import {FormArray, FormGroup} from "@angular/forms";
import {CarDocumentTypesEnum} from "../enums/car-document-types.enum";
import {CarIdentificatorsEnum} from "../enums/car-identificators.enum";
import {IDriverForm, IInsured, IOwner} from "../interface/osago-application.interface";
import {extractNumber} from "./extractNumber";
import {TypeOrganisation} from "../enums/type-organisation";
import {PurposeOfUseEnum} from "../enums/purpose-of-use.enum";
import {IOsagoCache} from "../interface/cache.interface";
import moment from "moment-mini";

export function setFormDataFromCacheByAppId(cacheData: any, form: FormGroup, fromFastProlongation: boolean = false, isJuridicalFromCache: boolean = false): Promise<void> {
  console.log('cacheDate = ', cacheData);
  return new Promise<void>((resolve) => {
    form.get('carCharacteristicsRequestId')?.setValue(cacheData.carCharacteristicsRequestId);
    if (cacheData.policyParameters.policyStartDate) {
      checkPolicyStartDate(cacheData, fromFastProlongation);
      form.get('policyStartDate')?.setValue(cacheData.policyParameters.policyStartDate);
    }
    if (cacheData.cpaClientUid) {
      form.get('cpaClientUid')?.setValue(cacheData.cpaClientUid);
    }

    // Применяем данные авто
    const carData = form.get('carData') as FormGroup;
    const cacheCarData = cacheData.carData;
    setCarData(cacheCarData, carData);

    // Применяем данные водителей
    const drivers = form.get('drivers') as FormArray;
    const driversData = cacheData.drivers;
    form.get('driversWithoutRestriction')?.setValue(!cacheData.hasDriversRestriction);

    // Проверяем, если анкета физ или юр лицо
    const isJuridical = isJuridicalFromCache
      || cacheData?.owner?.isJuridical
      || cacheData?.insured?.isJuridical
      || (cacheData.owner?.inn !== null && cacheData.insured?.inn !== undefined);

    if (!isJuridical) {
      form.get('isJuridical')?.setValue(false);
      // Если анкета от физ лица

      // Если есть водители
      if (driversData) {
        // Применяем данные собственника
        const ownerFormGroup = form.get('owner') as FormGroup;
        const cacheOwner = cacheData.owner;
        setOwner(cacheOwner, ownerFormGroup);
        // Собственник является страхователем
        form.get('ownerIsInsured')?.setValue(cacheData.owner?.ownerIsInsured);

        // Применяем данные страхователя
        const insurerFormGroup = form.get('insurer') as FormGroup;
        const cacheInsurer = cacheData.insured;
        if (cacheData.owner?.ownerIsInsured) {
          // TODO ТУТ ДЖОЛЖНА БЫТЬ СИНХРОНИЗАЦИЯ ВОДИТЕЛЯ И СОБСТВЕННИКА
          setInsurer(cacheData?.owner, insurerFormGroup);
        } else {
          setInsurer(cacheInsurer, insurerFormGroup);
        }

        //   // Применяем данные страхователя
        //   const insurerFormGroup = form.get('insurer') as FormGroup;
        //   const cacheInsurer = cacheData.insurer;
        //   setInsurer(cacheInsurer, insurerFormGroup);
      } else {
        // Применяем данные собственника
        const ownerFormGroup = form.get('owner') as FormGroup;
        const cacheOwner = cacheData.owner;
        setOwner(cacheOwner, ownerFormGroup);

        // Применяем данные страхователя
        const insurerFormGroup = form.get('insurer') as FormGroup;
        const cacheInsurer = cacheData.insured;
        setInsurer(cacheData?.owner, insurerFormGroup);
      }

      // Если есть водители
      if (driversData) {
        driversData.forEach((driver: IDriverForm, index: number) => {
          setDriver(driver, drivers.at(index) as FormGroup);
          // Проверяем, является ли водитель собственником
          const driverIsOwner = compareDriverIsOwner(driver, cacheData?.owner);
          if (driverIsOwner) {
            setDriverIsOwner(cacheData?.owner, drivers.at(index) as FormGroup);
          }
          // Проверяем, является ли водитель страхователем
          const driverIsInsurer = compareDriverIsInsurer(driver, cacheData?.insured);
          if (driverIsInsurer && cacheData?.insured) {
            setDriverIsInsurer(cacheData?.insured, drivers.at(index) as FormGroup);
          }
        });
      }
    } else {
      form.get('isJuridical')?.setValue(true);

      // Если собственник является страхователем,
      // тогда достаточно применить данные в legalOwner с triggerEmit = true
      // таким образом данные из legalOwner синхронизируются с legalInsurer
      // иначе нужно применить данные в обе формы по отдельности
      const legalOwnerFormGroup = form.get('legalOwner') as FormGroup;
      const cacheOwner = cacheData.owner;
      setLegalOwner(cacheOwner, legalOwnerFormGroup, false);

      // Является ли собственник страхователем у юр лица
      const ownerIsInsured = cacheData.owner.ownerIsInsured;
      const legalInsurerFormGroup = form.get('legalInsurer') as FormGroup;
      legalInsurerFormGroup?.get('legalInsurerIsLegalOwner')?.setValue(ownerIsInsured);

      if (ownerIsInsured) {
        setLegalInsurerFromOwner(cacheOwner, legalInsurerFormGroup, false);
      } else {
        // Применяем значение где страхователь не является союственником,
        // по этому данные применяются отдельно
        legalInsurerFormGroup?.get('legalInsurerIsLegalOwner')?.setValue(ownerIsInsured);
        const cacheInsurer = cacheData.insured;
        setLegalInsurerByAppId(cacheInsurer, legalInsurerFormGroup, false);
      }

      // Если есть водители
      if (driversData && driversData.length) {
        driversData.forEach((driver: IDriverForm, index: number) => {
          setDriver(driver, drivers.at(index) as FormGroup);
        });
      }
    }

    const cacheOwner = cacheData.owner;
    const contactsFormGroup = form.get('contacts') as FormGroup;
    // Применяем данные контактов
    setContacts(cacheOwner.phone, cacheOwner.email, contactsFormGroup);

    resolve();
  });
}

// Применяем данные авто
function setCarData(cacheCarData: any, carData: FormGroup): void {
  const {
    hasNoLicensePlate,
    licensePlate,
    modelId,
    modelName,
    brandId,
    brandName,
    productionYear,
    horsePower,
    carDocument,
    carIdentificators,
    carWithPrince,
    purposeOfUse
  } = carData.controls;

  hasNoLicensePlate.setValue(!cacheCarData.licensePlate, {emitEvent: false});
  licensePlate.setValue(cacheCarData.licensePlate, {emitEvent: false});

  brandId.setValue(cacheCarData.brandId);
  brandName.setValue(cacheCarData.brandName, {emitEvent: false});
  setTimeout(() => {
    modelId.setValue(cacheCarData.modelId, {emitEvent: true});
    modelName.setValue(cacheCarData.modelName, {emitEvent: true});
  }, 3000);
  productionYear.setValue(cacheCarData.productionYear?.toString(), {emitEvent: false});
  horsePower.setValue(cacheCarData.carPower?.toString(), {emitEvent: false});
  carWithPrince.setValue(cacheCarData.hasTrailer, {emitEvent: false});
  purposeOfUse.setValue(cacheCarData.purposeOfUseType && typeof cacheCarData.purposeOfUseType === 'number'
    ? PurposeOfUseEnum.Personal
    : cacheCarData.purposeOfUseType
      ? cacheCarData.purposeOfUseType
      : PurposeOfUseEnum.Personal);

  // Применяем данные документа авто
  setCarDocuments(cacheCarData.carDocument, carDocument as FormGroup);

  // Применяем данные vin, номер кузова или номер шасси
  setCarIdentificators(cacheCarData.carIdentificators, carIdentificators as FormGroup);
}

// Применяем данные документа авто
function setCarDocuments(carDocument: any, carDocumentFormGroup: FormGroup): any {
  const {
    documentIssueDate,
    documentNumber,
    documentSeries,
    documentType,
    stsNumber,
    stsDate,
    ptsNumber,
    ptsDate,
    ePtsNumber,
    ePtsDate
  } = carDocumentFormGroup.controls;

  documentType.setValue(carDocument.documentType, {emitEvent: true});
  documentSeries.setValue(carDocument.documentSeries, {emitEvent: false});
  documentNumber.setValue(carDocument.documentNumber, {emitEvent: false});
  documentIssueDate.setValue(carDocument.documentIssueDate, {emitEvent: false});

  if (carDocument.documentType === CarDocumentTypesEnum.STS) {
    stsNumber.setValue(carDocument.documentSeries + carDocument.documentNumber, {emitEvent: false});
    stsDate.setValue(carDocument.documentIssueDate, {emitEvent: false});
  } else if (carDocument.documentType === CarDocumentTypesEnum.PTS) {
    ptsNumber.setValue(carDocument.documentSeries + carDocument.documentNumber, {emitEvent: false});
    ptsDate.setValue(carDocument.documentIssueDate, {emitEvent: false});
  } else if (carDocument.documentType === CarDocumentTypesEnum.ePTS) {
    ePtsNumber.setValue(carDocument.documentNumber, {emitEvent: false});
    ePtsDate.setValue(carDocument.documentIssueDate, {emitEvent: false});
  }
}

// Применяем данные vin, номер кузова или номер шасси
function setCarIdentificators(carIdentificators: any, carIdentificatorsFormGroup: FormGroup): any {
  const {
    identifier,
    vinNumber,
    bodyNumber,
    chassisNumber
  } = carIdentificatorsFormGroup.controls;

  if (carIdentificators?.vin) {
    identifier.setValue(CarIdentificatorsEnum.VIN, {emitEvent: true});
    vinNumber.setValue(carIdentificators?.vin, {emitEvent: false});
  }

  if (carIdentificators?.bodyNumber) {
    identifier.setValue(CarIdentificatorsEnum.BodyNumber, {emitEvent: true});
    bodyNumber.setValue(carIdentificators?.bodyNumber, {emitEvent: false});
  }

  if (carIdentificators?.chassisNumber) {
    identifier.setValue(CarIdentificatorsEnum.ChassisNumber, {emitEvent: true});
    chassisNumber.setValue(carIdentificators?.chassisNumber.toString(), {emitEvent: true});
  }

}

// Применяем данные водителя
function setDriver(driverData: any, driverFormGroup: FormGroup): void {
  console.log('driverData = ', driverData);
  const {
    driverFirstName,
    driverLastName,
    oldDriverLastName,
    driverMiddleName,
    driverBirthDate,
    rememberOnlyYear,
    driverLicense,
    oldLicense,
    experienceDate,
    experienceYear,
    driverIsOwner,
    driverIsInsurer
  } = (driverFormGroup)?.controls;

  driverIsOwner.setValue(false, {emitEvent: true});
  driverIsInsurer.setValue(false, {emitEvent: true});

  driverFirstName.setValue(driverData.firstName, {emitEvent: false});
  driverLastName.setValue(driverData.lastName, {emitEvent: false});
  driverMiddleName.setValue(driverData.middleName!, {emitEvent: false});
  oldDriverLastName.setValue(driverData.oldLastName, {emitEvent: false});
  driverBirthDate.setValue(driverData.birthDate, {emitEvent: false});
  rememberOnlyYear.setValue(driverData.rememberOnlyYear, {emitEvent: false});

  setTimeout(() => {
    if (driverData.rememberOnlyYear) {
      const experienceYearValue = driverData.experienceStartDate?.split('.')[2]!;
      experienceYear.setValue(experienceYearValue ? experienceYearValue : null, {emitEvent: false});
    } else {
      experienceDate.setValue(driverData.experienceStartDate, {emitEvent: false});
    }

    console.log(experienceDate);
  }, 100)

  setDriverLicense(driverData.driverLicense, driverFormGroup);
  const oldDriverLicense = driverData.oldDriverLicense ? driverData.driverLicense : driverData.driverLicense;
  setOldDriverLicense(oldDriverLicense, driverFormGroup);
}

// Применяем данные собственника к форме водителя, т.к. найденный водитель является собственнимком авто
function setDriverIsOwner(ownerData: any, driverFormGroup: FormGroup): void {
  const {
    driverIsOwner,
    driverIsInsurer,
    driverPassportLicense,
    driverPassportDate,
    driverCity,
    driverHouseNumber,
    driverAddressDaData,
  } = (driverFormGroup)?.controls;

  driverIsOwner.setValue(true);
  // Если страхователь является собственником, то у эго водителя должен быть выбран свич "Является страхователем"

  if (ownerData?.passport?.passportSeries && ownerData?.passport?.passportNumber) {
    driverPassportLicense.setValue(ownerData?.passport?.passportSeries + ownerData?.passport?.passportNumber, {emitEvent: false});
  }
  driverPassportDate.setValue(ownerData?.passport?.passportIssueDate, {emitEvent: false});

  // Применяем данные адреса
  const daDataAddress = ownerData?.passport?.registrationAddressData?.daDataAddress;
  // Если в адресе есть номер квартиры
  if (daDataAddress?.flat) {
    const addressWithoutFlat = ownerData?.passport?.registrationAddressData?.addressAsString
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, кв ${daDataAddress.flat}`, '');
    driverCity.setValue(addressWithoutFlat, {emitEvent: false});
    driverHouseNumber.setValue(daDataAddress.flat, {emitEvent: false});
  } else {
    driverCity.setValue(ownerData?.passport?.registrationAddressData?.addressAsString, {emitEvent: false});
  }

  driverAddressDaData.setValue(daDataAddress, {emitEvent: false});

  setTimeout(() => {
    driverIsInsurer.setValue(ownerData.ownerIsInsured);
  }, 100)

}

// Применяем данные страхователя к форме водителя, т.к. найденный водитель является страхователем авто
function setDriverIsInsurer(insurerData: any, driverFormGroup: FormGroup): void {
  const {
    driverIsOwner,
    driverIsInsurer,
    driverPassportLicense,
    driverPassportDate,
    driverCity,
    driverHouseNumber,
    driverAddressDaData,
  } = (driverFormGroup)?.controls;

  driverIsInsurer.setValue(true);
  // Если страхователь является собственником, то у эго водителя должен быть выбран свич "Является страхователем"

  if (insurerData?.passport?.passportSeries && insurerData?.passport?.passportNumber) {
    driverPassportLicense.setValue(insurerData?.passport?.passportSeries + insurerData?.passport?.passportNumber, {emitEvent: false});
  }
  driverPassportDate.setValue(insurerData?.passport?.passportIssueDate, {emitEvent: false});

  // Применяем данные адреса
  const daDataAddress = insurerData?.passport?.registrationAddressData?.daDataAddress;
  // Если в адресе есть номер квартиры
  if (daDataAddress?.flat) {
    const addressWithoutFlat = insurerData?.passport?.registrationAddressData?.addressAsString
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, кв ${daDataAddress.flat}`, '');
    driverCity.setValue(addressWithoutFlat, {emitEvent: false});
    driverHouseNumber.setValue(daDataAddress.flat, {emitEvent: false});
  } else {
    driverCity.setValue(insurerData?.passport?.registrationAddressData?.addressAsString, {emitEvent: false});
  }

  driverAddressDaData.setValue(daDataAddress, {emitEvent: false});
}

// Применяем данные водительских прав
function setDriverLicense(driverLicenseData: any, driverFormGroup: FormGroup): void {
  const {
    driverLicense,
    experienceDate,
  } = driverFormGroup?.controls;

  driverLicense.setValue(driverLicenseData.licenseSeries + driverLicenseData.licenseNumber, {emitEvent: false});
  experienceDate.setValue(driverLicenseData.licenseIssueDate, {emitEvent: false});
}

// Применяем данные старых водительских прав
function setOldDriverLicense(driverLicenseData: any, driverFormGroup: FormGroup): void {
  const {
    licenseWasChanged,
    oldLicense,
    oldDriverLastName,
  } = driverFormGroup?.controls;

  if (driverLicenseData?.licenseSeries && driverLicenseData?.licenseNumber) {
    oldLicense.setValue(driverLicenseData?.licenseSeries + driverLicenseData?.licenseNumber, {emitEvent: false});
  }
  if (driverLicenseData?.lastNameInOldLicense) {
    oldDriverLastName.setValue(driverLicenseData?.lastNameInOldLicense, {emitEvent: false});
  }
  if (driverLicenseData?.licenseSeries && driverLicenseData?.licenseNumber && driverLicenseData?.lastNameInOldLicense && driverLicenseData?.lastNameInOldLicense) {
    licenseWasChanged.setValue(true);
  }
}

// Применяем данные собственника
function setOwner(cacheOwner: any, ownerFormGroup: FormGroup): void {
  const {
    ownerFirstName,
    ownerLastName,
    ownerMiddleName,
    ownerBirthDate,
    passportLicense,
    ownerPassportDate,
    ownerCity,
    ownerHouseNumber,
    ownerAddressDaData,
    ownerIsJuridical,
  } = ownerFormGroup?.controls;

  ownerFirstName?.setValue(cacheOwner?.firstName, {emitEvent: false});
  ownerLastName?.setValue(cacheOwner?.lastName, {emitEvent: false});
  ownerMiddleName?.setValue(cacheOwner?.middleName, {emitEvent: false});
  ownerBirthDate?.setValue(cacheOwner?.birthDate, {emitEvent: false});

  const passportLicenseValue = cacheOwner?.passport?.passportSeries + '' + cacheOwner?.passport?.passportNumber;
  passportLicense?.setValue(passportLicenseValue, {emitEvent: false});
  ownerPassportDate?.setValue(cacheOwner?.passport?.passportIssueDate, {emitEvent: false});

  // Применяем данные адреса
  const daDataAddress = cacheOwner?.passport?.registrationAddressData?.daDataAddress;
  // Если в адресе есть номер квартиры
  if (daDataAddress?.flat) {
    const addressWithoutFlat = cacheOwner?.passport?.registrationAddressData?.addressAsString
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, кв ${daDataAddress.flat}`, '');
    ownerCity.setValue(addressWithoutFlat, {emitEvent: false});
    ownerHouseNumber.setValue(daDataAddress.flat, {emitEvent: false});
  } else {
    ownerCity.setValue(cacheOwner?.passport?.registrationAddressData?.addressAsString, {emitEvent: false});
  }

  ownerAddressDaData?.setValue(cacheOwner?.passport?.registrationAddressData?.daDataAddress, {emitEvent: false});
}

// Применяем данные срахователя юр лицо
function setLegalInsurer(cacheInsurer: any, legalInsurerFormGroup: FormGroup, triggerEmit: boolean = false): void {
  const {
    legalInsurerValue,
    legalInsurerType,
    legalInsurerDaData,
    legalInsurerName,
    legalInsurerInn,
    legalInsurerOgrn,
    legalInsurerKpp,
    legalInsurerEgryl,
    legalInsurerWithoutCertificate,
    legalInsurerRegCertificateDateIssue,
    legalInsurerRegCertificate,
    legalInsurerAddress,
    legalInsurerAddressDaData,
    legalInsurerHouseNumber,

    legalInsurerFirstName,
    legalInsurerLastName,
    legalInsurerMiddleName,
    legalInsurerBirthDate,
    passportLicense,
    legalInsurerPassportDate
  } = legalInsurerFormGroup?.controls;

  legalInsurerValue?.setValue(cacheInsurer?.legalPersonData.legalInsurerDaData?.value, {emitEvent: true});
  legalInsurerType?.setValue(cacheInsurer?.legalPersonData.legalInsurerDaData?.type, {emitEvent: true});
  legalInsurerDaData?.setValue(cacheInsurer?.legalPersonData.legalInsurerDaData, {emitEvent: triggerEmit});
  legalInsurerName?.setValue(cacheInsurer?.legalPersonData.legalName, {emitEvent: triggerEmit});
  legalInsurerInn?.setValue(cacheInsurer?.legalPersonData.inn || cacheInsurer?.legalPersonData.INN, {emitEvent: triggerEmit});
  legalInsurerOgrn?.setValue(cacheInsurer?.legalPersonData.ogrn || cacheInsurer?.legalPersonData.OGRN, {emitEvent: triggerEmit});
  legalInsurerKpp?.setValue(cacheInsurer?.legalPersonData.kpp || cacheInsurer?.legalPersonData.KPP, {emitEvent: triggerEmit});
  legalInsurerEgryl?.setValue(cacheInsurer?.legalPersonData.EGRYL, {emitEvent: triggerEmit});

  if (cacheInsurer?.legalPersonData.regCertificateSeries && cacheInsurer?.legalPersonData.regCertificate && cacheInsurer?.legalPersonData.documentDateIssue) {
    legalInsurerRegCertificate?.setValue(`${cacheInsurer?.legalPersonData.regCertificateSeries}${cacheInsurer?.legalPersonData.regCertificate}`, {emitEvent: triggerEmit});
    legalInsurerRegCertificateDateIssue?.setValue(cacheInsurer?.legalPersonData.documentDateIssue, {emitEvent: triggerEmit});

    // Применяем флаг если есть данные сертификата, иначе используем номер Егрюл
    legalInsurerWithoutCertificate?.setValue(false);
  } else {
    legalInsurerWithoutCertificate?.setValue(true);
  }

  const flat = cacheInsurer?.legalPersonData.registrationAddressData?.daDataAddress?.flat;
  const flatType = cacheInsurer?.legalPersonData.registrationAddressData?.daDataAddress?.flat_type;
  const flatAsNumber = flat ? extractNumber(flat) : null;
  const addressWithoutFlat = cacheInsurer?.legalPersonData.registrationAddressData?.daDataAddress?.value
    ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
    ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

  // Если есть номер офиса (в данном случае это номер квартиры)
  // То применяем данные адреса и номера квартиры,
  // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
  setTimeout(() => {
    if (flatAsNumber) {
      legalInsurerAddress.setValue(addressWithoutFlat, {emitEvent: triggerEmit});
      legalInsurerHouseNumber.setValue(flatAsNumber.toString(), {emitEvent: triggerEmit});
    } else {
      legalInsurerAddress.setValue(addressWithoutFlat, {emitEvent: triggerEmit});
    }

    legalInsurerAddressDaData.setValue(cacheInsurer?.legalPersonData.registrationAddressData?.daDataAddress, {emitEvent: triggerEmit});
  }, 400);

  // Если юр лицо или индивидуальный предприниматель
  if (cacheInsurer?.legalPersonData?.legalInsurerDaData?.type === TypeOrganisation.LEGAL
    || cacheInsurer?.legalInsurerType === TypeOrganisation.LEGAL) {
    legalInsurerOgrn?.setValue(cacheInsurer?.legalPersonData?.ogrn || cacheInsurer?.legalPersonData?.OGRN, {emitEvent: triggerEmit});
    legalInsurerKpp?.setValue(cacheInsurer?.legalPersonData?.kpp || cacheInsurer?.legalPersonData?.KPP, {emitEvent: triggerEmit});
    legalInsurerEgryl?.setValue(cacheInsurer?.legalPersonData?.EGRYL, {emitEvent: triggerEmit});
  } else if (cacheInsurer?.legalPersonData?.legalInsurerDaData?.type === TypeOrganisation.INDIVIDUAL
    || cacheInsurer?.legalInsurerType === TypeOrganisation.INDIVIDUAL) {
    legalInsurerFirstName?.setValue(cacheInsurer?.firstName, {emitEvent: triggerEmit});
    legalInsurerLastName?.setValue(cacheInsurer?.lastName, {emitEvent: triggerEmit});
    legalInsurerMiddleName?.setValue(cacheInsurer?.middleName, {emitEvent: true});
    legalInsurerBirthDate?.setValue(cacheInsurer?.birthDate, {emitEvent: triggerEmit});

    const passportLicenseValue = cacheInsurer?.passport?.passportSeries + '' + cacheInsurer?.passport?.passportNumber;
    passportLicense?.setValue(passportLicenseValue, {emitEvent: triggerEmit});
    legalInsurerPassportDate?.setValue(cacheInsurer?.passport?.passportIssueDate, {emitEvent: triggerEmit});
  }
}

function setLegalInsurerByAppId(cacheInsurer: IInsured, legalInsurerFormGroup: FormGroup, triggerEmit: boolean = false): void {

  const {
    legalInsurerValue,
    legalInsurerType,
    legalInsurerDaData,
    legalInsurerName,
    legalInsurerInn,
    legalInsurerOgrn,
    legalInsurerKpp,
    legalInsurerEgryl,
    legalInsurerWithoutCertificate,
    legalInsurerRegCertificateDateIssue,
    legalInsurerRegCertificate,
    legalInsurerAddress,
    legalInsurerAddressDaData,
    legalInsurerHouseNumber,

    legalInsurerFirstName,
    legalInsurerLastName,
    legalInsurerMiddleName,
    legalInsurerBirthDate,
    passportLicense,
    legalInsurerPassportDate
  } = legalInsurerFormGroup?.controls;

  legalInsurerValue?.setValue(cacheInsurer?.shortLegalName ? cacheInsurer?.shortLegalName : cacheInsurer?.legalInsurerDaData?.name?.short_with_opf, {emitEvent: true});
  if (cacheInsurer?.juridicalOpfTypeId === 1 || cacheInsurer?.legalInsurerDaData?.type === TypeOrganisation.LEGAL
    || cacheInsurer?.legalInsurerType === TypeOrganisation.LEGAL) {
    legalInsurerType?.setValue(TypeOrganisation.LEGAL, {emitEvent: true});
  } else {
    legalInsurerType?.setValue(TypeOrganisation.INDIVIDUAL, {emitEvent: true});
  }

  legalInsurerDaData?.setValue(cacheInsurer?.legalInsurerDaData, {emitEvent: triggerEmit});
  legalInsurerName?.setValue(cacheInsurer?.legalInsurerDaData?.name?.full_with_opf, {emitEvent: triggerEmit});
  legalInsurerInn?.setValue(cacheInsurer?.inn, {emitEvent: triggerEmit});
  legalInsurerOgrn?.setValue(cacheInsurer?.ogrn, {emitEvent: triggerEmit});
  legalInsurerKpp?.setValue(cacheInsurer?.kpp, {emitEvent: triggerEmit});
  legalInsurerEgryl?.setValue(cacheInsurer?.egryl, {emitEvent: triggerEmit});

  if (cacheInsurer?.regCertificateSeries && cacheInsurer.regCertificate && cacheInsurer.documentDateIssue) {
    legalInsurerRegCertificate?.setValue(`${cacheInsurer?.regCertificateSeries}${cacheInsurer?.regCertificate}`, {emitEvent: triggerEmit});
    legalInsurerRegCertificateDateIssue?.setValue(cacheInsurer?.documentDateIssue, {emitEvent: triggerEmit});

    // Применяем флаг если есть данные сертификата, иначе используем номер Егрюл
    legalInsurerWithoutCertificate?.setValue(false);
  } else {
    legalInsurerWithoutCertificate?.setValue(true);
  }

  const flat = cacheInsurer?.passport.registrationAddressData?.daDataAddress?.flat;
  const flatType = cacheInsurer?.passport.registrationAddressData?.daDataAddress?.flat_type;
  const flatAsNumber = flat ? extractNumber(flat) : null;
  const addressWithoutFlat = cacheInsurer?.passport.registrationAddressData?.addressAsString
    ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
    ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

  // Если есть номер офиса (в данном случае это номер квартиры)
  // То применяем данные адреса и номера квартиры,
  // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
  setTimeout(() => {
    if (flatAsNumber) {
      legalInsurerAddress.setValue(addressWithoutFlat, {emitEvent: triggerEmit});
      legalInsurerHouseNumber.setValue(flatAsNumber.toString(), {emitEvent: triggerEmit});
    } else {
      legalInsurerAddress.setValue(addressWithoutFlat, {emitEvent: triggerEmit});
    }

    legalInsurerAddressDaData.setValue(cacheInsurer?.passport?.registrationAddressData?.daDataAddress, {emitEvent: triggerEmit});
  }, 400);

  // Если юр лицо или индивидуальный предприниматель
  if (cacheInsurer?.juridicalOpfTypeId === 1 || cacheInsurer?.legalInsurerDaData?.type === TypeOrganisation.LEGAL
    || cacheInsurer?.legalInsurerType === TypeOrganisation.LEGAL) {

    legalInsurerOgrn?.setValue(cacheInsurer?.ogrn, {emitEvent: triggerEmit});
    legalInsurerKpp?.setValue(cacheInsurer?.kpp, {emitEvent: triggerEmit});
    legalInsurerEgryl?.setValue(cacheInsurer?.egryl, {emitEvent: triggerEmit});
  } else {

    legalInsurerFirstName?.setValue(cacheInsurer?.firstName, {emitEvent: triggerEmit});
    legalInsurerLastName?.setValue(cacheInsurer?.lastName, {emitEvent: triggerEmit});
    legalInsurerMiddleName?.setValue(cacheInsurer?.middleName, {emitEvent: true});
    legalInsurerBirthDate?.setValue(cacheInsurer?.birthDate, {emitEvent: triggerEmit});

    const passportLicenseValue = cacheInsurer?.passport?.passportSeries + '' + cacheInsurer?.passport?.passportNumber;
    passportLicense?.setValue(passportLicenseValue, {emitEvent: triggerEmit});
    legalInsurerPassportDate?.setValue(cacheInsurer?.passport?.passportIssueDate, {emitEvent: triggerEmit});
  }
}

// Применяем данные страхователя юр лицо из данных собственнкиа, если он является страхователем
function setLegalInsurerFromOwner(cacheOwner: any, legalInsurerFormGroup: FormGroup, triggerEmit: boolean = false): void {
  const {
    legalInsurerValue,
    legalInsurerType,
    legalInsurerDaData,
    legalInsurerName,
    legalInsurerInn,
    legalInsurerOgrn,
    legalInsurerKpp,
    legalInsurerEgryl,
    legalInsurerWithoutCertificate,
    legalInsurerRegCertificateDateIssue,
    legalInsurerRegCertificate,
    legalInsurerAddress,
    legalInsurerAddressDaData,
    legalInsurerHouseNumber,

    legalInsurerFirstName,
    legalInsurerLastName,
    legalInsurerMiddleName,
    legalInsurerBirthDate,
    passportLicense,
    legalInsurerPassportDate
  } = legalInsurerFormGroup?.controls;

  legalInsurerValue?.setValue(cacheOwner?.shortLegalName ? cacheOwner?.shortLegalName : cacheOwner?.legalOwnerDaData?.name?.short_with_opf, {emitEvent: triggerEmit});
  legalInsurerType?.setValue(cacheOwner?.legalOwnerType, {emitEvent: true});
  legalInsurerDaData?.setValue(cacheOwner?.legalOwnerDaData, {emitEvent: true});
  legalInsurerName?.setValue(cacheOwner?.legalName, {emitEvent: triggerEmit});
  legalInsurerInn?.setValue(cacheOwner?.inn, {emitEvent: triggerEmit});
  legalInsurerOgrn?.setValue(cacheOwner?.ogrn, {emitEvent: triggerEmit});
  legalInsurerKpp?.setValue(cacheOwner?.kpp, {emitEvent: triggerEmit});
  legalInsurerEgryl?.setValue(cacheOwner?.egryl, {emitEvent: triggerEmit});

  if (cacheOwner?.regCertificateSeries && cacheOwner?.regCertificate && cacheOwner?.documentDateIssue) {
    legalInsurerRegCertificate?.setValue(`${cacheOwner?.regCertificateSeries}${cacheOwner?.regCertificate}`, {emitEvent: triggerEmit});
    legalInsurerRegCertificateDateIssue?.setValue(cacheOwner?.documentDateIssue, {emitEvent: triggerEmit});

    // Применяем флаг если есть данные сертификата, иначе используем номер Егрюл
    legalInsurerWithoutCertificate?.setValue(false);
  } else {
    legalInsurerWithoutCertificate?.setValue(true);
  }

  // Если юр лицо или индивидуальный предприниматель
  if (cacheOwner?.legalOwnerType === TypeOrganisation.LEGAL) {

    const flat = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.flat;
    const flatType = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.flat_type;
    const flatAsNumber = flat ? extractNumber(flat) : null;
    const addressWithoutFlat = cacheOwner?.passport?.registrationAddressData?.addressAsString
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

    // Если есть номер офиса (в данном случае это номер квартиры)
    // То применяем данные адреса и номера квартиры,
    // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
    if (flatAsNumber) {
      legalInsurerAddress?.setValue(addressWithoutFlat, {emitEvent: true});
      legalInsurerHouseNumber?.setValue(flatAsNumber.toString(), {emitEvent: triggerEmit});
    } else {
      legalInsurerAddress?.setValue(addressWithoutFlat, {emitEvent: true});
    }

    legalInsurerAddressDaData?.setValue(cacheOwner?.passport?.registrationAddressData, {emitEvent: triggerEmit});

  } else if (cacheOwner?.legalOwnerType === TypeOrganisation.INDIVIDUAL) {

    const flat = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.flat;
    const flatType = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.flat_type;
    const flatAsNumber = flat ? extractNumber(flat) : null;
    const addressWithoutFlat = cacheOwner?.passport?.registrationAddressData?.addressAsString
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

    // Если есть номер офиса (в данном случае это номер квартиры)
    // То применяем данные адреса и номера квартиры,
    // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
    if (flatAsNumber) {
      legalInsurerAddress?.setValue(addressWithoutFlat, {emitEvent: true});
      legalInsurerHouseNumber?.setValue(flatAsNumber.toString(), {emitEvent: triggerEmit});
    } else {
      legalInsurerAddress?.setValue(addressWithoutFlat, {emitEvent: true});
    }

    legalInsurerAddressDaData?.setValue(cacheOwner?.passport?.registrationAddressData?.daDataAddress, {emitEvent: triggerEmit});

    legalInsurerFirstName?.setValue(cacheOwner?.firstName, {emitEvent: triggerEmit});
    legalInsurerLastName?.setValue(cacheOwner?.lastName, {emitEvent: triggerEmit});
    legalInsurerMiddleName?.setValue(cacheOwner?.middleName, {emitEvent: true});
    legalInsurerBirthDate?.setValue(cacheOwner?.birthDate, {emitEvent: triggerEmit});

    const passportLicenseValue = cacheOwner?.passport?.passportSeries + '' + cacheOwner?.passport?.passportNumber;
    passportLicense?.setValue(passportLicenseValue, {emitEvent: triggerEmit});
    legalInsurerPassportDate?.setValue(cacheOwner?.passport?.passportIssueDate, {emitEvent: triggerEmit});
  }
}

// Применяем данные собственника юр лицо
// triggerEmit нужен для того что бы вставить значения без синхронизации с полями с формы legalInsurer и наоборот
// Это делается для того, что бы вставить значения отдельно и в legalOwner и в legalInsurer
// в случае если Страхователь не является собственником
function setLegalOwner(cacheOwner: any, legalOwnerFormGroup: FormGroup, triggerEmit: boolean = false): void {
  legalOwnerFormGroup?.get('legalOwnerIsJuridical')?.setValue(cacheOwner?.isJuridical || cacheOwner?.inn !== null);
  // Если собственник является юр лицом, то применяем данные в форму legalOwnerIsLegal, иначе legalOwnerIsNotLegal
  if (cacheOwner?.isJuridical || cacheOwner?.inn) {
    setLegalOwnerIsLegalByAppId(cacheOwner, legalOwnerFormGroup, triggerEmit);
  } else {
    setLegalOwnerIsNotLegal(cacheOwner, legalOwnerFormGroup, triggerEmit);
  }
}

// Применяем данные для собственника юр лицо
function setLegalOwnerIsLegal(cacheOwner: any, legalOwnerFormGroup: FormGroup, triggerEmit: boolean = false): void {
  const {
    legalOwnerValue,
    legalOwnerType,
    legalOwnerDaData,
    legalOwnerName,
    legalOwnerInn,
    legalOwnerOgrn,
    legalOwnerKpp,
    legalOwnerEgryl,
    legalOwnerWithoutCertificate,
    legalOwnerRegCertificateDateIssue,
    legalOwnerRegCertificate,
    legalOwnerLegalAddress,
    legalOwnerLegalAddressDaData,
    legalOwnerLegalHouseNumber,

    legalOwnerFirstName,
    legalOwnerLastName,
    legalOwnerMiddleName,
    legalOwnerBirthDate,
    legalOwnerPassportLicense,
    legalOwnerPassportDate,
  } = (legalOwnerFormGroup?.get('legalOwnerIsLegal') as FormGroup)?.controls;

  const passportLicenseValue = cacheOwner?.passport?.passportSeries + '' + cacheOwner?.passport?.passportNumber;
  legalOwnerPassportLicense?.setValue(passportLicenseValue, {emitEvent: triggerEmit});
  legalOwnerPassportDate?.setValue(cacheOwner?.passport?.passportIssueDate, {emitEvent: triggerEmit});

  legalOwnerValue?.setValue(cacheOwner?.legalPersonData?.legalOwnerDaData ? cacheOwner?.legalPersonData?.legalOwnerDaData?.value : cacheOwner?.legalPersonData?.legalInsurerDaData?.value, {emitEvent: triggerEmit});

  legalOwnerDaData?.setValue(cacheOwner?.legalPersonData?.legalOwnerDaData ? cacheOwner?.legalPersonData?.legalOwnerDaData : cacheOwner?.legalPersonData?.legalInsurerDaData, {emitEvent: triggerEmit});
  legalOwnerName?.setValue(cacheOwner?.legalPersonData?.legalName, {emitEvent: triggerEmit});
  legalOwnerInn?.setValue(cacheOwner?.legalPersonData?.inn || cacheOwner?.legalPersonData?.INN, {emitEvent: triggerEmit});

  if (cacheOwner?.legalPersonData?.regCertificateSeries && cacheOwner?.legalPersonData?.regCertificate && cacheOwner?.legalPersonData?.documentDateIssue) {
    legalOwnerRegCertificate?.setValue(cacheOwner?.legalPersonData?.regCertificateSeries! + '' + cacheOwner?.legalPersonData?.regCertificate!, {emitEvent: triggerEmit});
    legalOwnerRegCertificateDateIssue?.setValue(cacheOwner?.legalPersonData?.documentDateIssue, {emitEvent: triggerEmit});
    // TODO временное решение, контрол недоступен после триггера
    setTimeout(() => {
      legalOwnerWithoutCertificate?.setValue(false);
    }, 400)
  } else {
    legalOwnerWithoutCertificate?.setValue(true);
  }

  const flat = cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress?.flat;
  const flatType = cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress?.flat_type;
  const flatAsNumber = flat ? extractNumber(flat) : null;
  const addressWithoutFlat = cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress?.value
    ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
    ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

  // Если есть номер офиса (в данном случае это номер квартиры)
  // То применяем данные адреса и номера квартиры,
  // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
  if (flatAsNumber) {
    legalOwnerLegalAddress.setValue(addressWithoutFlat, {emitEvent: triggerEmit});
    legalOwnerLegalHouseNumber.setValue(flatAsNumber.toString(), {emitEvent: triggerEmit});
  } else {
    legalOwnerLegalAddress.setValue(addressWithoutFlat, {emitEvent: triggerEmit});
  }

  legalOwnerLegalAddressDaData.setValue(cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress, {emitEvent: triggerEmit});

  legalOwnerType?.setValue(cacheOwner?.legalPersonData?.legalInsurerDaData ? cacheOwner?.legalPersonData?.legalInsurerDaData?.type : cacheOwner?.legalPersonData?.legalOwnerDaData?.type);

  // Если юр лицо или индивидуальный предприниматель
  setTimeout(() => {
    if (cacheOwner?.legalPersonData?.legalInsurerDaData?.type === TypeOrganisation.LEGAL || cacheOwner?.legalPersonData?.legalOwnerDaData?.type === TypeOrganisation.LEGAL) {
      legalOwnerOgrn?.setValue(cacheOwner?.legalPersonData?.ogrn || cacheOwner?.legalPersonData?.OGRN, {emitEvent: triggerEmit});
      legalOwnerKpp?.setValue(cacheOwner?.legalPersonData?.kpp || cacheOwner?.legalPersonData?.KPP, {emitEvent: triggerEmit});
      legalOwnerEgryl?.setValue(cacheOwner?.legalPersonData?.EGRYL, {emitEvent: triggerEmit});
    } else if (cacheOwner?.legalPersonData?.legalInsurerDaData?.type === TypeOrganisation.INDIVIDUAL || cacheOwner?.legalPersonData?.legalOwnerDaData?.type === TypeOrganisation.INDIVIDUAL) {
      legalOwnerFirstName?.setValue(cacheOwner?.firstName, {emitEvent: triggerEmit});
      legalOwnerLastName?.setValue(cacheOwner?.lastName, {emitEvent: triggerEmit});
      legalOwnerMiddleName?.setValue(cacheOwner?.middleName, {emitEvent: true});
      legalOwnerBirthDate?.setValue(cacheOwner?.birthDate, {emitEvent: triggerEmit});
      legalOwnerPassportLicense.setValue(passportLicenseValue, {emitEvent: false});
      legalOwnerPassportDate.setValue(cacheOwner?.passport?.passportIssueDate, {emitEvent: false});
    }
  }, 0);


}

function setLegalOwnerIsLegalByAppId(cacheOwner: IOwner, legalOwnerFormGroup: FormGroup, triggerEmit: boolean = false): void {
  const {
    legalOwnerValue,
    legalOwnerType,
    legalOwnerDaData,
    legalOwnerName,
    legalOwnerInn,
    legalOwnerOgrn,
    legalOwnerKpp,
    legalOwnerEgryl,
    legalOwnerWithoutCertificate,
    legalOwnerRegCertificateDateIssue,
    legalOwnerRegCertificate,
    legalOwnerLegalAddress,
    legalOwnerLegalAddressDaData,
    legalOwnerLegalHouseNumber,

    legalOwnerFirstName,
    legalOwnerLastName,
    legalOwnerMiddleName,
    legalOwnerBirthDate,
    legalOwnerPassportLicense,
    legalOwnerPassportDate,
  } = (legalOwnerFormGroup?.get('legalOwnerIsLegal') as FormGroup)?.controls;

  const passportLicenseValue = cacheOwner?.passport?.passportSeries + '' + cacheOwner?.passport?.passportNumber;
  legalOwnerPassportLicense?.setValue(passportLicenseValue, {emitEvent: triggerEmit});
  legalOwnerPassportDate?.setValue(cacheOwner?.passport?.passportIssueDate, {emitEvent: triggerEmit});

  legalOwnerValue?.setValue(cacheOwner?.shortLegalName ? cacheOwner?.shortLegalName : cacheOwner?.legalOwnerDaData?.name?.short_with_opf, {emitEvent: triggerEmit});

  legalOwnerDaData?.setValue(cacheOwner?.legalOwnerDaData, {emitEvent: triggerEmit});
  legalOwnerName?.setValue(cacheOwner?.legalOwnerDaData?.name?.full_with_opf, {emitEvent: triggerEmit});
  legalOwnerInn?.setValue(cacheOwner?.inn, {emitEvent: triggerEmit});

  if (cacheOwner?.regCertificateSeries && cacheOwner?.regCertificate && cacheOwner?.documentDateIssue) {
    legalOwnerRegCertificate?.setValue(cacheOwner?.regCertificateSeries! + '' + cacheOwner?.regCertificate!, {emitEvent: triggerEmit});
    legalOwnerRegCertificateDateIssue?.setValue(cacheOwner?.documentDateIssue, {emitEvent: triggerEmit});
    // TODO временное решение, контрол недоступен после триггера
    setTimeout(() => {
      legalOwnerWithoutCertificate?.setValue(false);
    }, 400)
  } else {
    legalOwnerWithoutCertificate?.setValue(true);
  }

  const flat = cacheOwner?.passport.registrationAddressData?.daDataAddress?.flat;
  const flatType = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.flat_type;
  const flatAsNumber = flat ? extractNumber(flat) : null;
  const addressWithoutFlat = cacheOwner?.passport?.registrationAddressData?.addressAsString
    ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
    ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

  // Если есть номер офиса (в данном случае это номер квартиры)
  // То применяем данные адреса и номера квартиры,
  // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
  if (flatAsNumber) {
    legalOwnerLegalAddress.setValue(addressWithoutFlat, {emitEvent: triggerEmit});
    legalOwnerLegalHouseNumber.setValue(flatAsNumber.toString(), {emitEvent: triggerEmit});
  } else {
    legalOwnerLegalAddress.setValue(addressWithoutFlat, {emitEvent: triggerEmit});
  }

  legalOwnerLegalAddressDaData.setValue(cacheOwner?.passport?.registrationAddressData?.daDataAddress, {emitEvent: triggerEmit});

  // legalOwnerType?.setValue(cacheOwner?.legalOwnerType);
  if (cacheOwner?.inn && cacheOwner?.ogrn && cacheOwner?.kpp) {
    legalOwnerType?.setValue(TypeOrganisation.LEGAL, {emitEvent: true});
  } else {
    legalOwnerType?.setValue(TypeOrganisation.INDIVIDUAL, {emitEvent: true});
  }

  // Если юр лицо или индивидуальный предприниматель
  setTimeout(() => {
    if (cacheOwner?.inn && cacheOwner?.ogrn && cacheOwner?.kpp) {
      // legalOwnerType?.setValue(TypeOrganisation.LEGAL, { emitEvent: triggerEmit });
      legalOwnerOgrn?.setValue(cacheOwner?.ogrn, {emitEvent: triggerEmit});
      legalOwnerKpp?.setValue(cacheOwner?.kpp, {emitEvent: triggerEmit});
      legalOwnerEgryl?.setValue(cacheOwner?.egryl, {emitEvent: triggerEmit});
    } else {
      // legalOwnerType?.setValue(TypeOrganisation.INDIVIDUAL, { emitEvent: triggerEmit });
      legalOwnerFirstName?.setValue(cacheOwner?.firstName, {emitEvent: triggerEmit});
      legalOwnerLastName?.setValue(cacheOwner?.lastName, {emitEvent: triggerEmit});
      legalOwnerMiddleName?.setValue(cacheOwner?.middleName, {emitEvent: true});
      legalOwnerBirthDate?.setValue(cacheOwner?.birthDate, {emitEvent: triggerEmit});
      legalOwnerPassportLicense.setValue(passportLicenseValue, {emitEvent: false});
      legalOwnerPassportDate.setValue(cacheOwner?.passport?.passportIssueDate, {emitEvent: false});
    }

  }, 0);
}

// Применяем данные для собственника юр лицо
function setLegalOwnerIsNotLegal(cacheOwner: any, legalOwnerFormGroup: FormGroup, triggerEmit: boolean = false): void {
  const {
    legalOwnerFirstName,
    legalOwnerLastName,
    legalOwnerMiddleName,
    legalOwnerBirthDate,
    legalOwnerPassportLicense,
    legalOwnerPassportDate,
    legalOwnerCity,
    legalOwnerHouseNumber,
    legalOwnerAddressDaData,
  } = (legalOwnerFormGroup?.get('legalOwnerIsNotLegal') as FormGroup)?.controls;

  legalOwnerFirstName?.setValue(cacheOwner?.firstName, {emitEvent: triggerEmit});
  legalOwnerLastName?.setValue(cacheOwner?.lastName, {emitEvent: triggerEmit});
  legalOwnerMiddleName?.setValue(cacheOwner?.middleName, {emitEvent: triggerEmit});
  legalOwnerBirthDate?.setValue(cacheOwner?.birthDate, {emitEvent: triggerEmit});
  if (cacheOwner?.passport?.passportSeries && cacheOwner?.passport?.passportNumber) {
    legalOwnerPassportLicense.setValue(cacheOwner?.passport?.passportSeries + cacheOwner?.passport?.passportNumber, {emitEvent: false});
  }
  legalOwnerPassportDate?.setValue(cacheOwner?.passport?.passportIssueDate, {emitEvent: false});

  const flat = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.flat;
  const flatType = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.flat_type;
  const flatAsNumber = flat ? extractNumber(flat) : null;
  const addressWithoutFlat = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.value
    ? cacheOwner?.passport?.registrationAddressData?.daDataAddress?.value
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '')
    : cacheOwner?.passport?.registrationAddressData?.addressAsString
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

  // Если есть номер офиса (в данном случае это номер квартиры)
  // То применяем данные адреса и номера квартиры,
  // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
  if (flatAsNumber) {
    legalOwnerCity.setValue(addressWithoutFlat, {emitEvent: triggerEmit});
    legalOwnerHouseNumber.setValue(flatAsNumber.toString(), {emitEvent: triggerEmit});
  } else {
    legalOwnerCity.setValue(addressWithoutFlat, {emitEvent: triggerEmit});
  }

  // const daDataAddress = cacheOwner?.passport?.registrationAddressData?.daDataAddress;
  // // Если в адресе есть номер квартиры
  // console.log('daDataAddress?.flat = ', daDataAddress?.flat);
  // if (daDataAddress?.flat) {
  //   const addressWithoutFlat = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.value?.replace(`, кв ${daDataAddress.flat}`, '');
  //   console.log('addressWithoutFlat = ', addressWithoutFlat);
  //   legalOwnerCity.setValue(addressWithoutFlat, {emitEvent: false});
  //   legalOwnerHouseNumber.setValue(daDataAddress.flat, {emitEvent: false});
  // } else {
  //   legalOwnerHouseNumber.setValue(cacheOwner?.passport?.registrationAddressData?.addressAsString, {emitEvent: false});
  // }

  legalOwnerAddressDaData?.setValue(cacheOwner?.passport?.registrationAddressData?.daDataAddress, {emitEvent: false});
}

// Применяем данные страхователя
function setInsurer(cacheInsurer: any, insurerFormGroup: FormGroup): void {
  const {
    insurerFirstName,
    insurerLastName,
    insurerMiddleName,
    insurerBirthDate,
    passportLicense,
    insurerPassportDate,
    insurerCity,
    insurerHouseNumber,
    insurerAddressDaData,
    insurerIsJuridical,
  } = insurerFormGroup?.controls;

  insurerFirstName?.setValue(cacheInsurer?.firstName, {emitEvent: false});
  insurerLastName?.setValue(cacheInsurer?.lastName, {emitEvent: false});
  insurerMiddleName?.setValue(cacheInsurer?.middleName, {emitEvent: false});
  insurerBirthDate?.setValue(cacheInsurer?.birthDate, {emitEvent: false});

  setTimeout(() => {
    if (cacheInsurer?.passport?.passportSeries && cacheInsurer?.passport?.passportNumber) {
      passportLicense.setValue(cacheInsurer?.passport?.passportSeries + cacheInsurer?.passport?.passportNumber, {emitEvent: false});
    }
    insurerPassportDate?.setValue(cacheInsurer?.passport?.passportIssueDate, {emitEvent: false});

    // Применяем данные адреса
    const daDataAddress = cacheInsurer?.passport?.registrationAddressData?.daDataAddress;
    // Если в адресе есть номер квартиры
    if (daDataAddress?.flat) {
      const addressWithoutFlat = cacheInsurer?.passport?.registrationAddressData?.addressAsString
        ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
        ?.replace(`, кв ${daDataAddress.flat}`, '');
      insurerCity.setValue(addressWithoutFlat, {emitEvent: false});
      insurerHouseNumber.setValue(daDataAddress.flat, {emitEvent: false});
    } else {
      insurerCity.setValue(cacheInsurer?.passport?.registrationAddressData?.addressAsString, {emitEvent: false});
    }

    insurerAddressDaData?.setValue(cacheInsurer?.passport?.registrationAddressData?.daDataAddress, {emitEvent: false});
  }, 0);
}

// Применяем контактные данные
function setContacts(phoneValue: string, emailValue: string, contactsFormGroup: FormGroup): void {
  const {
    phone,
    email,
  } = contactsFormGroup?.controls;
  phone.setValue(phoneValue, {emitEvent: false});
  email.setValue(emailValue, {emitEvent: false});
}

// Сравниваем водителя с собственником, если совпадает, то он являеся собственником
function compareDriverIsOwner(driverData: any, ownerData: any): boolean {
  return driverData?.firstName
    && driverData?.lastName
    && driverData?.birthDate
    && (driverData?.firstName === ownerData?.firstName
      && driverData?.lastName === ownerData?.lastName
      && driverData?.middleName === ownerData?.middleName
      && driverData?.birthDate === ownerData?.birthDate);
}

// Сравниваем собственника и страхователя, если совпадает, то собственник является страхователем
function compareOwnerIsInsurer(ownerData: any, insurerData: any): boolean {
  return ownerData?.firstName
    && ownerData?.lastName
    && ownerData?.birthDate
    && (ownerData?.firstName === insurerData?.firstName
      && ownerData?.lastName === insurerData?.lastName
      && ownerData?.middleName === insurerData?.middleName
      && ownerData?.birthDate === insurerData?.birthDate
      && ownerData?.passport?.passportSeries === insurerData?.passport?.passportSeries
      && ownerData?.passport?.passportNumber === insurerData?.passport?.passportNumber
      && ownerData?.passport?.passportIssueDate === insurerData?.passport?.passportIssueDate);
}

// Сравниваем водителя с страхователем, если совпадает, то он являеся страхователем
function compareDriverIsInsurer(driverData: any, insurerData: any): boolean {
  return driverData?.firstName
    && driverData?.lastName
    && driverData?.birthDate
    && (driverData?.firstName === insurerData?.firstName
      && driverData?.lastName === insurerData?.lastName
      && driverData?.middleName === insurerData?.middleName
      && driverData?.birthDate === insurerData?.birthDate);
}

export function checkPolicyStartDate(osago: IOsagoCache, fromFastProlongation: boolean = false) {
  // Преобразовываем дату в случае если дата пришла в прошлом
  const policyStartDate = osago.policyParameters.policyStartDate;

  if (policyStartDate) {
    // Преобразуем дату из формата DD.MM.YYYY в объект moment
    const policyDate = moment(policyStartDate, 'DD.MM.YYYY');
    const currentDate = moment().startOf('day');

    // Проверяем, если policyStartDate меньше или равен текущей дате
    if (policyDate.isSameOrBefore(currentDate)) {
      // Устанавливаем policyStartDate на следующий день
      osago.policyParameters.policyStartDate = currentDate.add(fromFastProlongation ? 1 : 4, 'day').format('DD.MM.YYYY');
    }
  }
}
