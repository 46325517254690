import {
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-company-swiper',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: 'company-swiper.component.html',
  styleUrl: 'company-swiper.component.scss',
  imports: [
    NgForOf
  ],
})
export class CompanySwiperComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('scrollContent') scrollContent!: ElementRef;

  // Данные с логотипами компаний
  public blocks = [
    { src: '../../../../assets/images/partner-logo-slider/absolut.png', alt: 'Абсолют' },
    { src: '../../../../assets/images/partner-logo-slider/alfa.png', alt: 'Альфастрахование' },
    { src: '../../../../assets/images/partner-logo-slider/ingos.png', alt: 'Ингосстрах' },
    { src: '../../../../assets/images/partner-logo-slider/renessans.png', alt: 'Ренессанс' },
    { src: '../../../../assets/images/partner-logo-slider/energogarant.png', alt: 'Энергогарант' },
    { src: '../../../../assets/images/partner-logo-slider/soglasie.png', alt: 'Согласие' },
    { src: '../../../../assets/images/partner-logo-slider/tinkoff.png', alt: 'Тинькофф' },
    { src: '../../../../assets/images/partner-logo-slider/zetta.png', alt: 'Зетта' },
    { src: '../../../../assets/images/partner-logo-slider/vsk.svg', alt: 'ВСК' },
    { src: '../../../../assets/images/partner-logo-slider/maks.png', alt: 'МАКС' },
    { src: '../../../../assets/images/partner-logo-slider/ugoria.svg', alt: 'ЮГОРИЯ' },
    { src: '../../../../assets/images/partner-logo-slider/sber.png', alt: 'СБЕР' },
    { src: '../../../../assets/images/partner-logo-slider/rosgos.png', alt: 'Росгосстрах' },
    { src: '../../../../assets/images/partner-logo-slider/absolut.png', alt: 'Абсолют' },
    { src: '../../../../assets/images/partner-logo-slider/alfa.png', alt: 'Альфастрахование' },
    { src: '../../../../assets/images/partner-logo-slider/ingos.png', alt: 'Ингосстрах' },
    { src: '../../../../assets/images/partner-logo-slider/renessans.png', alt: 'Ренессанс' },
    { src: '../../../../assets/images/partner-logo-slider/energogarant.png', alt: 'Энергогарант' },
    { src: '../../../../assets/images/partner-logo-slider/soglasie.png', alt: 'Согласие' },
    { src: '../../../../assets/images/partner-logo-slider/tinkoff.png', alt: 'Тинькофф' },
    { src: '../../../../assets/images/partner-logo-slider/zetta.png', alt: 'Зетта' },
    { src: '../../../../assets/images/partner-logo-slider/vsk.svg', alt: 'ВСК' },
    { src: '../../../../assets/images/partner-logo-slider/maks.png', alt: 'МАКС' },
    { src: '../../../../assets/images/partner-logo-slider/ugoria.svg', alt: 'ЮГОРИЯ' },
    { src: '../../../../assets/images/partner-logo-slider/sber.png', alt: 'СБЕР' },
    { src: '../../../../assets/images/partner-logo-slider/rosgos.png', alt: 'Росгосстрах' },
  ];

  visibleBlocks: Array<{ src: string, alt: string }> = [];
  isDragging = false;
  startX = 0;
  scrollLeft = 0;
  blockWidth = 240; // ширина блока + отступы
  animationFrameId: number | null = null;
  scrollSpeed = 1;

  // --------------------------------------------------------------------------
  // Инициализация
  ngOnInit(): void {
    this.visibleBlocks = [...this.blocks, ...this.blocks, ...this.blocks]; // Начальная инициализация
  }

  ngAfterViewInit(): void {
    this.resetScrollPosition();
    this.startAnimation();
  }

  // Уничтожение
  ngOnDestroy(): void {
    this.stopAnimation();
  }
  // --------------------------------------------------------------------------

  public startAnimation(): void {
    const step = () => {
      const scrollContainer = this.scrollContent.nativeElement.parentElement;
      scrollContainer.scrollLeft += this.scrollSpeed;

      // Проверка для добавления блоков в конец
      if (scrollContainer.scrollLeft >= this.blockWidth * (this.visibleBlocks.length - this.blocks.length)) {
        this.addBlocksToEnd();
        this.removeBlocksFromStart();
        scrollContainer.scrollLeft -= this.blockWidth * this.blocks.length;
      }

      // Проверка для добавления блоков в начало
      if (scrollContainer.scrollLeft <= 0) {
        this.addBlocksToStart();
        this.removeBlocksFromEnd();
        scrollContainer.scrollLeft += this.blockWidth * this.blocks.length;
      }

      this.animationFrameId = requestAnimationFrame(step);
    };

    this.animationFrameId = requestAnimationFrame(step);
  }

  stopAnimation(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  onDragStart(event: MouseEvent): void {
    this.isDragging = true;
    this.startX = event.pageX;
    const scrollContainer = event.currentTarget as HTMLElement;
    this.scrollLeft = scrollContainer.scrollLeft;

    // Останавливаем анимацию
    this.stopAnimation();

    // Добавляем глобальные обработчики событий мыши
    document.addEventListener('mousemove', this.onDrag);
    document.addEventListener('mouseup', this.onDragEnd);
  }

  onDragEnd = (): void => {
    this.isDragging = false;

    // Удаляем глобальные обработчики событий мыши
    document.removeEventListener('mousemove', this.onDrag);
    document.removeEventListener('mouseup', this.onDragEnd);

    // Возобновляем анимацию
    this.startAnimation();
  }

  onDrag = (event: MouseEvent): void => {
    if (!this.isDragging) return;
    event.preventDefault();
    const scrollContainer = this.scrollContent.nativeElement.parentElement;
    const x = event.pageX - this.startX;
    scrollContainer.scrollLeft = this.scrollLeft - x;

    // Если прокрутили до начала, добавляем блоки в начало и удаляем с конца
    if (scrollContainer.scrollLeft <= 0) {
      this.addBlocksToStart();
      this.removeBlocksFromEnd();
      scrollContainer.scrollLeft += this.blockWidth * this.blocks.length;
    }
    // Если прокрутили до конца, добавляем блоки в конец и удаляем с начала
    else if (scrollContainer.scrollLeft >= this.blockWidth * (this.visibleBlocks.length - this.blocks.length)) {
      this.addBlocksToEnd();
      this.removeBlocksFromStart();
      scrollContainer.scrollLeft -= this.blockWidth * this.blocks.length;
    }
  }

  onDragLeave(): void {
    // Эта функция не нужна, т.к. мы обрабатываем mouseup глобально
  }

  resetScrollPosition(): void {
    const scrollContainer = this.scrollContent.nativeElement.parentElement;
    scrollContainer.scrollLeft = this.blockWidth * this.blocks.length;
  }

  addBlocksToEnd(): void {
    this.visibleBlocks.push(...this.blocks);
    this.removeBlocksFromStart();
  }

  addBlocksToStart(): void {
    this.visibleBlocks.unshift(...this.blocks);
    this.removeBlocksFromEnd();
  }

  removeBlocksFromStart(): void {
    if (this.visibleBlocks.length > this.blocks.length * 3) {
      this.visibleBlocks.splice(0, this.blocks.length);
    }
  }

  removeBlocksFromEnd(): void {
    if (this.visibleBlocks.length > this.blocks.length * 3) {
      this.visibleBlocks.splice(-this.blocks.length, this.blocks.length);
    }
  }
}
