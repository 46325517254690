export enum WidgetStatuses {
    CalculateFromCache = "OsagoCalculateFromCache",
    CalculateWithoutLicense = "OsagoCalculateWithoutLicense",
    CalculateWithLicense = "OsagoCalculateWithLicense",
    CarScreen = "OsagoCarScreen",
    DriversScreen = "OsagoDriversScreen",
    OwnerScreen = "OsagoOwnerScreen",
    InsuredScreen = "OsagoInsuredScreen",
    ContactsScreen = "OsagoСontactsScreen"
}
