import {Component, forwardRef, Inject, Injector, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl, FormsModule
} from '@angular/forms';
import {Subscription} from 'rxjs';
import {PopoverConfig, PopoverModule} from "ngx-bootstrap/popover";

// Сервисы
import {FormService} from '../../services/form.service';
import {ValidationService} from '../../services/validation.service';

// Маски
import {MASKS} from '../../constants/masks';

// Интерфейсы
import * as IMask from 'imask';
import {IMaskDirective} from 'angular-imask';
import {NgClass, NgIf} from "@angular/common";
import {FormFieldErrorComponent} from "../form-field-error/form-field-error.component";

export function getPopoverConfig(): PopoverConfig {
  return Object.assign(new PopoverConfig(), {});
}

@Component({
  selector: 'app-form-text-field',
  standalone: true,
  templateUrl: './form-text-field.component.html',
  styleUrls: ['./form-text-field.component.scss'],
  imports: [
    FormsModule,
    NgClass,
    IMaskDirective,
    PopoverModule,
    FormFieldErrorComponent,
    NgIf
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormTextFieldComponent),
      multi: true,
    },
    {
      provide: PopoverConfig,
      useFactory: getPopoverConfig
    }
  ]
})
export class FormTextFieldComponent implements ControlValueAccessor, OnInit, OnDestroy {

  // @ViewChild('controlElm', {read: IMaskDirective}) controlElm!: IMaskDirective<IMask.MaskedPattern>;
  @ViewChild(IMaskDirective) imaskDirective!: IMaskDirective<IMask.MaskedPattern>;
  // Заголовок
  @Input() label!: string;
  // Только для чтения
  @Input() isReadonly!: boolean;
  // Placeholder
  @Input() placeholder!: string;
  @Input() disabled: boolean = false;

  // Контрол изменяемого input
  public value: string = '';
  public inputControl: UntypedFormControl = new UntypedFormControl(null);
  // Контрол
  public control!: UntypedFormControl;
  // Имя контрола
  public name!: string;
  // Тип
  public type = 'text';
  // Подписка на контрол
  private subscription: Subscription = new Subscription();
  // Сообщения валидации
  public messages: { [key: string]: any } = {};
  // Флаг, идет загрузка списка
  public isLoading = false;
  // Маска
  public mask = {
    mask: /.*/,
    lazy: true,
    placeholderChar: '_',
    autofix: true,
    overwrite: false,
  };
  // Показываем клавиатуру, текст или только цифры
  public inputmode: string = 'text';
  // Используется popover
  public usePopover = false;
  // Контент для popover
  public popoverContent = ``;
  // Паттерн
  public pattern = '';

  // Вызовем когда значение изменится
  public onTouched: any = () => {
  };

  // Вызовем при любом действии пользователя с контроллом
  public onChange: any = () => {
  };

  constructor(@Inject(Injector) private injector: Injector,
              public readonly formService: FormService,
              private readonly validationService: ValidationService) {
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    const injectedControl = this.injector.get(NgControl);

    switch (injectedControl.constructor) {
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as UntypedFormControl;
        break;
      }
    }

    // Применяем параметры контрола
    if (injectedControl.name) {
      this.initPropertyControl(injectedControl.name.toString());
    }
  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  // --------------------------------------------------------------------------

  // Применяем параметры контролла
  public initPropertyControl(injectedControlName: string): void {
    const propertyControl = this.formService.propertyControls[injectedControlName];
    if (propertyControl?.validation?.messages) {
      this.messages = propertyControl?.validation?.messages;
    }
    if (propertyControl?.name) {
      this.name = propertyControl.name;
    }
    if (propertyControl?.label) {
      this.label = propertyControl.label;
    }
    if (propertyControl?.type) {
      this.type = propertyControl?.type;
    }
    if (propertyControl?.placeholder) {
      this.placeholder = propertyControl?.placeholder;
    }
    if (propertyControl?.inputmode) {
      this.inputmode = propertyControl?.inputmode;
    }
    if (propertyControl?.usePopover) {
      this.usePopover = propertyControl?.usePopover;
      this.popoverContent = propertyControl?.popoverContent!;
    }
    if (propertyControl?.pattern) {
      this.pattern = propertyControl?.pattern;
    }
    if (propertyControl?.maskName) {
      MASKS.filter((item: any) => item.systemName === propertyControl?.maskName)
        .map((item) => this.mask = item.mask);
    }
    if (propertyControl?.mask) {
      this.mask.mask = propertyControl?.mask;
      if (this.imaskDirective) {
        this.imaskDirective.maskRef?.updateOptions(propertyControl?.mask);
      }
    }

    this.validationService.setControlValidation(propertyControl, this.control);
  }

  // Вызовет форма, если значение изменилось извне
  public writeValue(value: any): void {
    this.value = value;
  }

  // Сохраняем обратный вызов для изменений
  registerOnChange(onChange: (value: any) => void): void {
    this.onChange = onChange;
  }

  // Сохраняем обратный вызов для "касаний"
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public updateValue(value: string): void {
    this.value = value;
    this.onChange(value);
    // this.onTouched();
  }

  // Установка состояния disabled
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  // Событие фокуса
  public focus(event: any): void {
    if (this.imaskDirective) {
      this.imaskDirective.maskRef?.updateOptions({
        lazy: false
      });
    }
  }

  // Событие при выходи из контрола
  public blur(): void {
    this.onChange(this.value);
    this.onTouched();
    if (this.imaskDirective) {
      this.imaskDirective.maskRef?.updateOptions({
        lazy: true
      });
    }
  }

}
