<div class="mt-3 mt-sm-4">
  <app-number-gos-or-policy></app-number-gos-or-policy>
</div>

<section class="mt-1 mt-sm-2">
  <div
    class="card h-auto shadow-0 border-0 p-0 info-filter-block custom-rounded mb-2 mb-sm-2">
    <div class="card-body p-0">
      <div class="row p-3 p-sm-4 align-items-center">
        <div class="col-auto">
          <img
            src="./assets/images/offers-icons/policy-data.svg"
            alt=""
            height="48"
            width="48"
          />
        </div>
        <div
          class="col px-0 px-sm-2 d-flex flex-column justify-content-center align-items-start text-wrap"
        >
          <div class="main-title-item mb-0 text-wrap">Данные полиса</div>
          <div class="second-title-item text-wrap">Заполните данные</div>
        </div>
      </div>
      <div id="collapseBasic" class="px-3 px-sm-4">
        @if (prolongationFormService.form.get('isNumberGos')?.value) {
          <app-gos-number-form></app-gos-number-form>
        } @else {
          <app-policy-number-form></app-policy-number-form>
        }

      </div>
    </div>
  </div>
</section>
