import {inject, Injectable} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {environment} from '@environment/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  private route = inject(ActivatedRoute);
  // apiKey
  public apiKey = environment.apiKey;
  // clientId
  public clientId = '';
  // Only personal form
  public onlyPersonalForm = environment.onlyPersonalForm;
  // Only personal form
  public onlyLegalForm = environment.onlyLegalForm;
  // sessionId
  public sessionId = '';

  constructor() {
    this.checkJuridicalSwitchState();
  }

  private checkJuridicalSwitchState(): void {
    this.route.queryParams.subscribe(params => {
      const dataOnlyJuridcalOsago = params['dataOnlyJuridcalOsago'];
      const dataOnlyPersonalOsago = params['dataOnlyPersonalOsago'];

      if (dataOnlyJuridcalOsago !== undefined) {
        this.onlyPersonalForm = dataOnlyPersonalOsago === 'true';
      }

      if (dataOnlyPersonalOsago !== undefined) {
        this.onlyLegalForm = dataOnlyJuridcalOsago === 'true';
      }
    });
  }
}
