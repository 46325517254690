<div class="form-floating has-validation">
  <input [formControl]="searchControl"
         [id]="injectedControlName"
         [ngClass]="{
            'is-invalid': control.invalid && control.touched
           }"
         type="text"
         class="form-control"
         [placeholder]="placeholder"
         autocomplete="off"
         [readOnly]="isReadonly || isDropdown"
         [typeahead]="typeaheadAsync ? asyncTypeaheadList : typeaheadList"
         [typeaheadAsync]="typeaheadAsync"
         [typeaheadOptionField]="typeaheadOptionField"
         [typeaheadScrollable]="typeaheadScrollable"
         [typeaheadOptionsInScrollableView]="typeaheadOptionsInScrollableView"
         [typeaheadOptionsLimit]="typeaheadOptionsLimit"
         [typeaheadMinLength]="typeaheadMinLength"
         [typeaheadSelectFirstItem]="true"
         [typeaheadIsFirstItemActive]="true"
         [optionsListTemplate]="isDropdown ? dropdownListTemplate : isCustomDropdown ? customDropdownListTemplate : undefined"
         (blur)="blurEvent($event)"
         (focus)="focusEvent()"
         (typeaheadOnSelect)="selectItem($event)">
  @if (!this.suggestionPart) {
    <svg class="icon-chevron" width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M1.5 0.5L7 5.99994L12.5 0.5L14 2L7 8.99994L0 2L1.5 0.5Z"
            fill="#0B1F35"/>
    </svg>
  }

  <label>{{ label }}</label>

  @if (isLoading) {
    <div class="spinner-block spinner-grow spinner-grow-sm text-secondary" role="status">
      <span class="visually-hidden">Loading...</span>
    </div>
  }

</div>

<ng-template #dropdownListTemplate class="custom-list-group">
  <ul class="custom-list-group" [style.height]="typeaheadList.length > 7 ? 7 * 46 + 'px' : 'auto'">
    @for (match of typeaheadList; track match; let i = $index) {
      <li
        (click)="selectItem(match)" href="#">
        <a class="dropdown-item" [class.active]="match.value === searchControl.value">
          {{ match.value }}
          @if (match.description) {
            <div class="description">{{ match.description }}</div>
          }
        </a>
      </li>
    }
  </ul>
</ng-template>

<ng-template #customDropdownListTemplate class="custom-list-group">
  <ul class="custom-list-group" [style.height]="getHeightDropdownList">
    @for (match of typeaheadList; track match; let i = $index) {
      <li
        (click)="selectItem(match)" href="#">
        <a class="dropdown-item" [class.active]="match.value === searchControl.value">
          {{ match.modelName }}
          @if (match.carCategoryId) {
            <div class="description">{{ getModelCategoryNameByModelId(match.modelId, typeaheadList) }}</div>
          }
        </a>
      </li>
    }
  </ul>
</ng-template>

@if (control.touched) {
  <app-form-field-error
    [controlErrors]="control.errors"
    [messages]="messages">
  </app-form-field-error>
}
