import {Component, inject} from '@angular/core';
import {OsagoService} from "../../../../shared/services/osago.service";
import {IOffer} from "../../../../shared/interface/offers.interface";
import {DatePipe} from "@angular/common";
import moment from "moment-mini";

@Component({
  selector: 'app-form-policy-alert',
  standalone: true,
  imports: [
    DatePipe
  ],
  templateUrl: './form-policy-alert.component.html',
  styleUrl: './form-policy-alert.component.scss'
})
export class FormPolicyAlertComponent {

  public osagoService = inject(OsagoService);
  // Оффер который нашли у клиента isProlongation
  public prolongationOffer!: IOffer | undefined;

  currentDate = new Date();

  get isProlongationText(): boolean {
    this.prolongationOffer = this.osagoService.offers.find((offer: IOffer) => offer.isProlongation);
    return !!this.prolongationOffer || this.osagoService.isShowProlongationAlert;
  }

  get getProlongationOfferDate(): string {
    const prolongationDate = moment(this.osagoService.prolongationOffer?.endDate)?.add(1, 'days').format('DD.MM.YYYY');
    return prolongationDate ? prolongationDate : '';
  }

}
