import {inject, Inject, Injectable} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {HttpClient} from "@angular/common/http";
import {concatMap, interval, Observable, switchMap, takeWhile} from "rxjs";
import {environment} from "@environment/environment";
import {HttpService} from "./http.service";
import {SettingsService} from "./settings.service";
import {FormService} from "./form.service";
import {IApplicationRequest, IApplicationResponse} from "../interface/application.interface";
import {map} from "rxjs/operators";
import {AppService} from "./app.service";

@Injectable({
  providedIn: 'root'
})
export class OfflineFormService extends HttpService {

  private readonly appService = inject(AppService);
  private readonly settingsService = inject(SettingsService);
  private readonly formService = inject(FormService);
  protected override http = inject(HttpClient);

  // Форма
  public form: FormGroup = new FormGroup({
    firstName: new FormControl('', [Validators.required]),
    companyName: new FormControl('', [Validators.required]),
    INN: new FormControl('', [Validators.required]),
    email: new FormControl('', [Validators.required]),
    phone: new FormControl('', [Validators.required])
  });

  // Создаем заявку и отправляем оффлайн форму
  public createApplicationAndSendOfflineForm(): Observable<IApplicationResponse> {
    return this.appService.createApplication('OfflineOSAGO')
      .pipe(
        concatMap(() => this.sendOfflineForm()),
      );
  }

  // Отправляем оффлай заявку
  public sendOfflineForm(): Observable<any> {
    const { value } = this.form;
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: this.formService.form.get('applicationId')?.value,
      firstName: value?.firstName,
      companyName: value?.companyName,
      INN: value?.inn,
      email: value?.email,
      phoneNumber: value?.phone
    };
    return this.http.post(environment.apiUrl + 'app/SaveOfflineOsagoApplication', request);
  }


}
