import {inject, Injectable} from '@angular/core';
import {
  FormGroup,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators
} from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ProlongationFormService {

  private fb = inject(UntypedFormBuilder);

  // Форма
  public form: UntypedFormGroup = this.getFormGroup();


  public getFormGroup(): FormGroup {
    return this.fb.group({
      isNumberGos: [false],
      policyNumber: this.fb.group({
        prolongationFormBirthDate: [null, [Validators.required]],
        previousPolicySeries: [null, [Validators.required]],
        previousPolicyNumber: [null, [Validators.required]],
        PreviousPolicyInsurerType: ['Alfa', [Validators.required]],
        phone: [null, [Validators.required]],
        email: [null, [Validators.required]],
      }),
      // policyNumber: this.fb.group({
      //   prolongationFormBirthDate: ['24.11.1989', [Validators.required]],
      //   previousPolicySeries: ['ХХХ', [Validators.required]],
      //   previousPolicyNumber: ['0265208042', [Validators.required]],
      //   PreviousPolicyInsurerType: ['Alfa', [Validators.required]],
      //   phone: ['79061150225', [Validators.required]],
      //   email: ['sadykov@insapp.ru', [Validators.required]],
      // }),
      // policyNumber: this.fb.group({
      //   prolongationFormBirthDate: ['09.11.1981', [Validators.required]],
      //   previousPolicySeries: ['ХХХ', [Validators.required]],
      //   previousPolicyNumber: ['0345659238', [Validators.required]],
      //   PreviousPolicyInsurerType: ['Alfa', [Validators.required]],
      //   phone: ['79061150225', [Validators.required]],
      //   email: ['sadykov@insapp.ru', [Validators.required]],
      // }),
      // policyNumber: this.fb.group({
      //   prolongationFormBirthDate: ['16.12.1966', [Validators.required]],
      //   previousPolicySeries: ['ХХХ', [Validators.required]],
      //   previousPolicyNumber: ['0324035152', [Validators.required]],
      //   PreviousPolicyInsurerType: ['Alfa', [Validators.required]],
      //   phone: ['79061150225', [Validators.required]],
      //   email: ['sadykov@insapp.ru', [Validators.required]],
      // }),
      // policyNumber: this.fb.group({
      //   prolongationFormBirthDate: ['12.07.1985', [Validators.required]],
      //   previousPolicySeries: ['ХХХ', [Validators.required]],
      //   previousPolicyNumber: ['0345664513', [Validators.required]],
      //   PreviousPolicyInsurerType: ['Alfa', [Validators.required]],
      //   phone: ['79061150225', [Validators.required]],
      //   email: ['sadykov@insapp.ru', [Validators.required]],
      // }),
      // policyNumber: this.fb.group({
      //   prolongationFormBirthDate: ['15.06.1984', [Validators.required]],
      //   previousPolicySeries: ['ХХХ', [Validators.required]],
      //   previousPolicyNumber: ['0337117136', [Validators.required]],
      //   PreviousPolicyInsurerType: ['Alfa', [Validators.required]],
      //   phone: ['79061150225', [Validators.required]],
      //   email: ['sadykov@insapp.ru', [Validators.required]],
      // }),
      gosNumber: this.fb.group({
        prolongationFormBirthDate: [null, [Validators.required]],
        licensePlate: [null, [Validators.required]],
        PreviousPolicyInsurerType: ['Alfa', [Validators.required]],
        phone: [null, [Validators.required]],
        email: [null, [Validators.required]],
      }),
      // gosNumber: this.fb.group({
      //   prolongationFormBirthDate: ['09.11.1981', [Validators.required]],
      //   licensePlate: ['Е806ВО15', [Validators.required]],
      //   PreviousPolicyInsurerType: ['Alfa', [Validators.required]],
      //   phone: ['79061150225', [Validators.required]],
      //   email: ['sadykov@insapp.ru', [Validators.required]],
      // }),
      // gosNumber: this.fb.group({
      //   prolongationFormBirthDate: ['15.06.1984', [Validators.required]],
      //   licensePlate: ['Р658СМ26', [Validators.required]],
      //   PreviousPolicyInsurerType: ['Alfa', [Validators.required]],
      //   phone: ['79061150225', [Validators.required]],
      //   email: ['sadykov@insapp.ru', [Validators.required]],
      // }),
    })
  }


}
