{
  "mainPage": {
    "title": "Введите номер авто",
    "subTitle": "Мы автоматически заполним ваши данные"
  },
  "entityIndividualComponent": {
    "title": "Для кого нужен полис?",
    "individual": "Физ. лицо",
    "juristic": "ИП / Юр. лицо"
  },
  "licenseComponent": {
    "calculateBtn": "Рассчитать",
    "withoutLicenseBtn": "Рассчитать без номера"
  },
  "formComponent": {
    "title": "Оформление полиса",
    "autoparkDataTitle": "Для оформления полиса оставьте заявку",
    "autoparkDataSubTitle": "Заполните данные",
    "carDataTitle": "Данные авто",
    "carDataSubTitle": "Заполните данные",
    "driverDataTitle": "Водитель",
    "driverDataSubTitle": "Данные водителя",
    "driverDataWithoutDriverSubTitle": "Без ограничений",
    "driverDataAddDriverSubTitle": "Добавить водителя",
    "ownerDataTitle": "Собственник",
    "ownerDataSubTitle": "Данные собственника",
    "insurerDataTitle": "Страхователь",
    "insurerDataSubTitle": "Данные страхователя",
    "contactsDataTitle": "Ваши контакты",
    "contactsDataSubTitle": "Для подтверждения расчета",
    "carDataComponent": {
      "alert1": "Заполните информацию ниже, она формирует предварительную стоимость ОСАГО<br class='d-none d-sm-block'>\n для вашего автомобиля с учетом города регистрации",
      "alert2": "Если вы только что купили автомобиль и планируете сменить номер при постановке на учет, обязательно укажите документ — ПТС. После получения нового регистрационного знака вы сможете вписать\n    его в полис. Если хотите оставить номер прежнего собственника, можете указать любой тип документа",
      "alertWarn": "Данные заполнены автоматические, проверьте все параметры вашего автомобиля<br class='d-none d-sm-block'>\n - это может влиять на стоимость полиса",
      "prePriceCalculatorComponent": {
        "title": "Предварительная цена",
        "subTitle": "Для точного расчета и покупки полиса потребуется сведения<br>\n по водительским правам и паспорту собственника"
      }
    },
    "driversComponent": {
      "unlimitedDriversTrueAlert": "Заполните информацию ниже, она формирует предварительную стоимость ОСАГО<br>\n для вашего автомобиля с учетом города регистрации",
      "unlimitedDriversFalseAlert": "Управлять ТС может любой водитель. Полис без ограничений обычно стоит значительно дороже\n полиса с ограниченным списком водителей, допущенных к управлению",
      "licenseWasChangedAlert": "Если вы меняли за последний год, укажите данные предыдущих прав, их можно найти\n на обороте новых прав, либо в прошлом полисе ОСАГО",
      "removeDriverBtn": "Удалить водителя",
      "addDriverBtn": "Добавить водителя",
      "submitBtn": "Продолжить"
    },
    "ownerComponent": {
      "alert1": "Эти данные мы передаем в страховую компанию для подтверждения цены с учетом всех скидок. Проверьте их несколько раз.",
      "otherDriverBtn": "Другой",
      "submitBtn": "Продолжить",
      "ownerIsInsurer": "Собственник является страхователем авто "
    },
    "insurerComponent": {
      "otherDriverBtn": "Другой",
      "submitBtn": "Продолжить"
    },
    "legalOwnerComponent": {
      "alert1": "Как получить номер выписки ЕГРЮЛ:<br>1. На сайте <a href='https://egrul.nalog.ru/' target='_blank'>egrul.nalog.ru</a> укажите ИНН вашей организации.<br>2. Скачайте полученную выписку.<br>3. Скопируйте номер выписки и укажите его при оформлении ОСАГО."
    },
    "autoparkComponent": {
      "alert1": "Для данного продукта необходимо оформление с помощью менеджера",
      "alert2": "<ol class='olList' style='margin-bottom: 0'><li style='margin-bottom: 5px'>С вами свяжется наш менеджер для уточнения деталей</li><li style='margin-bottom: 5px'>После согласования условий, вы получите ссылку на оплату полиса напрямую от страховой компании</li><li style='margin-bottom: 5px'>Полисы поступят на вашу электронную почту сразу же после оплаты</li></ol>"
    }
  },
  "fastProlongationComponent": {
    "title": "Продлить полис ОСАГО",
    "policyNumber": "По номеру полиса",
    "gosNumber": "По гос. номеру"
  }

}
