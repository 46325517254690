<div class="card mt-2 border-light h-auto shadow-none custom-rounded offer">
  <div class="card-body p-3 p-sm-4">
    <div class="row align-items-center">
      <div class="col-auto">
        <div class="m-0 d-flex align-items-center">
          <img src="./assets/images/partner-logo/{{osagoService.selectedOffer?.insurerType}}-logo.svg"
               class="insurance-company-logo" [alt]="osagoService.selectedOffer?.insurerName">
        </div>
      </div>
      <div class="col p-0">
        <div class="col px-0 px-sm-2 d-flex flex-column justify-content-center align-items-start">
          <div class="insurance-company-title mb-0 d-inline-block text-truncate">
            @if (paymentLink) {
              Полис готов к оплате
            } @else {
              {{ osagoService.selectedOffer?.insurerName }}
            }
          </div>
          <div class="insurance-company-second-title text-secondary gray-string">
            @if (paymentLink) {
              Мы отправим его вам на почту
            } @else {
              Компания осуществляет проверку данных
            }
          </div>
        </div>
      </div>
      @if (paymentLink) {
        <div class="col-auto">
          <app-price [premiumAmount]="osagoService.selectedOffer?.premiumAmount!"
                     [installmentFirstPay]="osagoService.selectedOffer?.installmentFirstPay!"></app-price>
        </div>
      } @else {
        <div class="col-auto">
          <app-price [premiumAmount]="osagoService.selectedOffer?.premiumAmount!"
                     [installmentFirstPay]="osagoService.selectedOffer?.installmentFirstPay!"></app-price>
        </div>
      }
      <div class="col-12 mt-3 mt-lg-0 col-lg-auto d-block d-lg-flex text-end">
        <div class="row">
          <!--          TODO вернуть после того как сделаем возврат клиент на стейт оффер -->
          @if (environment.partnerSystemName !== 'yafuel'
          && environment.partnerSystemName !== 'alfa'
          && environment.partnerSystemName !== 'ipSemenov') {
            <div class="col-12 col-lg">
              <app-draft></app-draft>
            </div>
          }

          @if ((((osagoService.selectedOffer?.insurerType !== 'MAKS' && osagoService.selectedOffer?.insurerType !== 'Rosgosstrah')
            || useUpsales)) || environment.partnerSystemName !== 'alfa') {
            <div class="col-12 col-lg-auto mt-3 mt-lg-0">
              @if (paymentLink) {
                <a [href]="osagoService.paymentLink" target="_blank"
                   class="btn btn-primary offers-btn w-100 d-flex justify-content-center align-items-center"
                   (click)="pay()">
                  @if (isShowPaymentBtn) {
                    Перейти к оплате
                  } @else {
                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  }
                </a>
              } @else {
                @if (fromFastProlongation && !isPaymentCountdownStarted) {
                  <button type="button"
                          (click)="onCreatePolicy()"
                          class="btn btn-primary offers-btn w-100 d-flex justify-content-center align-items-center">
                    Создать полис
                  </button>
                } @else {
                  <button type="button"
                          class="btn btn-primary offers-btn w-100 d-flex justify-content-center align-items-center"
                          [disabled]="true">
                    Создаем полис
                    <span class="countdown-block">{{ paymentCountdown }}</span>
                  </button>
                }
              }

            </div>
          }

        </div>
      </div>
    </div>

    @if ((osagoService.selectedOffer?.insurerType === 'MAKS'
      || osagoService.selectedOffer?.insurerType === 'Rosgosstrah')
    && environment.partnerSystemName === 'alfa'
    && !useUpsales) {
      <div class="row mt-3 d-flex justify-content-center justify-content-md-end">

        @if (paymentMethod === 'paymentCardMethod' || !paymentMethod) {
          <div class="col-12 col-md-auto">

            @if (paymentLink) {
              <a [href]="osagoService.paymentLink" target="_blank"
                 class="btn btn-outline-primary offers-btn payment-card-btn w-100 d-flex justify-content-center align-items-center"
                 (click)="pay()">
                @if (isShowPaymentBtn) {
                  <svg class="payment-card-icon" width="24" height="18" viewBox="0 0 24 18" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.99999 -7.62939e-06H19C20.3256 0.00158023 21.5964 0.528874 22.5338 1.46621C23.4711 2.40355 23.9984 3.6744 24 4.99999V13C23.9984 14.3256 23.4711 15.5964 22.5338 16.5338C21.5964 17.4711 20.3256 17.9984 19 18H4.99999C3.6744 17.9984 2.40355 17.4711 1.46621 16.5338C0.528874 15.5964 0.00158023 14.3256 -7.62939e-06 13V4.99999C0.00158023 3.6744 0.528874 2.40355 1.46621 1.46621C2.40355 0.528874 3.6744 0.00158023 4.99999 -7.62939e-06ZM19 1.99999H4.99999C4.20434 1.99999 3.44128 2.31606 2.87867 2.87867C2.31606 3.44128 1.99999 4.20434 1.99999 4.99999H22C22 4.20434 21.6839 3.44128 21.1213 2.87867C20.5587 2.31606 19.7956 1.99999 19 1.99999ZM4.99999 16H19C19.7956 16 20.5587 15.6839 21.1213 15.1213C21.6839 14.5587 22 13.7956 22 13V6.99999H1.99999V13C1.99999 13.7956 2.31606 14.5587 2.87867 15.1213C3.44128 15.6839 4.20434 16 4.99999 16ZM7.00007 12.5001C7.00007 13.3286 6.3285 14.0001 5.50007 14.0001C4.67165 14.0001 4.00007 13.3286 4.00007 12.5001C4.00007 11.6717 4.67165 11.0001 5.50007 11.0001C6.3285 11.0001 7.00007 11.6717 7.00007 12.5001Z"/>
                  </svg>

                  Оплатить картой
                } @else {
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                }
              </a>
            } @else {
              @if (!isPaymentCountdownStarted) {
                <button type="button"
                        (click)="onCreatePolicy('paymentCardMethod')"
                        class="btn btn-outline-primary offers-btn w-100 d-flex justify-content-center align-items-center">
                  <svg class="payment-card-icon" width="24" height="18" viewBox="0 0 24 18" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.99999 -7.62939e-06H19C20.3256 0.00158023 21.5964 0.528874 22.5338 1.46621C23.4711 2.40355 23.9984 3.6744 24 4.99999V13C23.9984 14.3256 23.4711 15.5964 22.5338 16.5338C21.5964 17.4711 20.3256 17.9984 19 18H4.99999C3.6744 17.9984 2.40355 17.4711 1.46621 16.5338C0.528874 15.5964 0.00158023 14.3256 -7.62939e-06 13V4.99999C0.00158023 3.6744 0.528874 2.40355 1.46621 1.46621C2.40355 0.528874 3.6744 0.00158023 4.99999 -7.62939e-06ZM19 1.99999H4.99999C4.20434 1.99999 3.44128 2.31606 2.87867 2.87867C2.31606 3.44128 1.99999 4.20434 1.99999 4.99999H22C22 4.20434 21.6839 3.44128 21.1213 2.87867C20.5587 2.31606 19.7956 1.99999 19 1.99999ZM4.99999 16H19C19.7956 16 20.5587 15.6839 21.1213 15.1213C21.6839 14.5587 22 13.7956 22 13V6.99999H1.99999V13C1.99999 13.7956 2.31606 14.5587 2.87867 15.1213C3.44128 15.6839 4.20434 16 4.99999 16ZM7.00007 12.5001C7.00007 13.3286 6.3285 14.0001 5.50007 14.0001C4.67165 14.0001 4.00007 13.3286 4.00007 12.5001C4.00007 11.6717 4.67165 11.0001 5.50007 11.0001C6.3285 11.0001 7.00007 11.6717 7.00007 12.5001Z"/>
                  </svg>
                  Оплатить картой
                </button>
              } @else {
                <button type="button"
                        class="btn btn-outline-primary offers-btn w-100 d-flex justify-content-center align-items-center"
                        [disabled]="true">
                  <svg class="payment-card-icon" width="24" height="18" viewBox="0 0 24 18" fill="none"
                       xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M4.99999 -7.62939e-06H19C20.3256 0.00158023 21.5964 0.528874 22.5338 1.46621C23.4711 2.40355 23.9984 3.6744 24 4.99999V13C23.9984 14.3256 23.4711 15.5964 22.5338 16.5338C21.5964 17.4711 20.3256 17.9984 19 18H4.99999C3.6744 17.9984 2.40355 17.4711 1.46621 16.5338C0.528874 15.5964 0.00158023 14.3256 -7.62939e-06 13V4.99999C0.00158023 3.6744 0.528874 2.40355 1.46621 1.46621C2.40355 0.528874 3.6744 0.00158023 4.99999 -7.62939e-06ZM19 1.99999H4.99999C4.20434 1.99999 3.44128 2.31606 2.87867 2.87867C2.31606 3.44128 1.99999 4.20434 1.99999 4.99999H22C22 4.20434 21.6839 3.44128 21.1213 2.87867C20.5587 2.31606 19.7956 1.99999 19 1.99999ZM4.99999 16H19C19.7956 16 20.5587 15.6839 21.1213 15.1213C21.6839 14.5587 22 13.7956 22 13V6.99999H1.99999V13C1.99999 13.7956 2.31606 14.5587 2.87867 15.1213C3.44128 15.6839 4.20434 16 4.99999 16ZM7.00007 12.5001C7.00007 13.3286 6.3285 14.0001 5.50007 14.0001C4.67165 14.0001 4.00007 13.3286 4.00007 12.5001C4.00007 11.6717 4.67165 11.0001 5.50007 11.0001C6.3285 11.0001 7.00007 11.6717 7.00007 12.5001Z"/>
                  </svg>
                  Создаем полис
                  <span class="countdown-block">{{ paymentCountdown }}</span>
                </button>
              }
            }

          </div>
        }
        @if ((paymentMethod === 'paymentSBPMethod' || !paymentMethod) && environment.partnerSystemName === 'alfa') {
          <div class="col-12 col-md-auto mt-3 mt-md-0">

            @if (paymentLink) {
              <a [href]="osagoService.paymentLink" target="_blank"
                 class="btn btn-primary offers-btn w-100 d-flex justify-content-center align-items-center"
                 (click)="pay()">
                @if (isShowPaymentBtn) {
                  <img src="./assets/images/offers-icons/sbp.svg"
                       class="mx-2" alt="Оплатить через СБП">
                  Оплатить через СБП
                } @else {
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                }
              </a>
            } @else {
              @if (!isPaymentCountdownStarted) {
                <button type="button"
                        (click)="onCreatePolicy('paymentSBPMethod')"
                        class="btn btn-primary offers-btn w-100 d-flex justify-content-center align-items-center">
                  <img src="./assets/images/offers-icons/sbp.svg"
                       class="mx-2" alt="Оплатить через СБП">
                  Оплатить через СБП
                </button>
              } @else {
                <button type="button"
                        class="btn btn-primary offers-btn w-100 d-flex justify-content-center align-items-center"
                        [disabled]="true">
                  <img src="./assets/images/offers-icons/sbp.svg"
                       class="mx-2" alt="Оплатить через СБП">
                  Создаем полис
                  <span class="countdown-block">{{ paymentCountdown }}</span>
                </button>
              }
            }
          </div>
        }
      </div>
    }

    @if (isPaymentCountdownStarted && !paymentLink) {
      <div class="alert alert-primary m-0 mt-3">
        Мы формируем ссылку для оплаты, пожалуйста, подождите.
      </div>
    }

    @if (useUpsales || fromFastProlongation) {
      <app-upsales [offer]="osagoService.selectedOffer!"
                   [isDisabled]="!fromFastProlongation || isPaymentCountdownStarted"></app-upsales>
    }

  </div>
</div>

<app-error-upsale></app-error-upsale>

<!--<pre>{{osagoService.selectedOffer | json}}</pre>-->
