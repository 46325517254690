import {Inject, inject, Injectable} from "@angular/core"
import {environment} from "@environment/environment";
import moment from "moment-mini";
import {IApiError} from "../interface/api-error.interface";
import {YandexMetrikaGoalsEnum} from "../enums/yandex-metrika-goals.enum";
import {take} from "rxjs/operators";
import {YandexMetrikaService} from "./yandex-metrika.service";
import {HttpClient} from "@angular/common/http";
import {SettingsService} from "./settings.service";
import {FormService} from "./form.service";
import {CacheService} from "./cache.service";
import {NavigationService} from "./navigation.service";
import {HttpService} from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class LoggingService extends HttpService {
  private ym = inject(YandexMetrikaService);
  private settingsService = inject(SettingsService);
  private formService = inject(FormService);
  protected override http = inject(HttpClient);
  protected messages: any = [];

  public debug(message?: any, ...optionalParams: any[]) {
    const logMessage = moment().format('HH:mm:ss.SSS') + ' ' + message;

    if (environment.logging.debug) {
      console.debug(message, optionalParams);
    }

    this.messages.push(logMessage);
  }

  public trace(component: string, method: string, message: string = '') {
    const debugMessage = component + '::' + method + ':: ' + message;
    this.debug(debugMessage);
  }

  public get journal(): string {
    return JSON.stringify(this.messages);
  }

  public getApiErrorDescription(apiError: IApiError | undefined) {
    let result = '';

    if (apiError != null) {
      if (apiError.type != null) {
        result += apiError.type;
      }

      if (apiError.message != null) {
        result += apiError.message;
      }
    }

    return result;
  }

  // Переходим на страницу с ошибкой
  public error(message: string | null = null, logged = false): void {
    // this.trace(message);

    if (!logged) {
      this.log(message);
    }

    console.log('тут раньше в осаго был переход на страницу ошибки');

  }

  public internalError(message: string | null = null) {
    // this.trace(message);
    this.log(message);
    this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.InternalError);
  }

  protected log(source: string | null = null) {
    let message = source || '';

    this.postLocal("log", {
      apiKey: this.settingsService.apiKey,
      applicationId: this.formService.form?.get('applicationId')?.value,
      sessionId: this.settingsService.sessionId,
      message: message + '; trace: ' + this._errorMessages.join(', ') + '; debug: ' + this.journal
    })
      .pipe(take(1))
      .subscribe({
        next: () => {
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorPage);
        },
        error: () => {
        }
      });
  }

  // public trace(message: string | null = null) {
  //   if (message != null) {
  //     var datetime = (new Date()).toString();
  //     this._errorMessages.push(datetime + ': ' + message)
  //   }
  // }

  getErrorDescription(source: any): string | null {
    try {
      if (source == null)
        return null;

      var result = '';

      try {
        result = JSON.stringify(source);
      } catch {
      }

      if (result != null && result.length > 0)
        return result;

      return source.toString();
    } catch {
      return null;
    }
  }
}
