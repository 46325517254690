import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {OsagoService} from "../../shared/services/osago.service";
import {concatMap, EMPTY, filter, iif, Observable, of, Subject, Subscription} from "rxjs";
import {catchError, distinctUntilChanged, finalize, switchMap, tap} from "rxjs/operators";
import {AppService} from "../../shared/services/app.service";
import {FormService} from "../../shared/services/form.service";
import {TimerService} from "../../shared/services/timer.service";
import {JsonPipe, ViewportScroller} from "@angular/common";
import {NavigationEnd, Router} from "@angular/router";
import {YandexMetrikaService} from "../../shared/services/yandex-metrika.service";
import {YandexMetrikaGoalsEnum} from "../../shared/enums/yandex-metrika-goals.enum";
import {PolicyDataComponent} from "../../shared/components/policy-data/policy-data.component";
import {FormPolicyDateComponent} from "./form-policy-date/form-policy-date.component";
import {KbmComponent} from "./kbm/kbm.component";
import {TimerComponent} from "../../shared/components/timer/timer.component";
import {OfferItemComponent} from "./offer-item/offer-item.component";
import {OfferPlaceholderComponent} from "../../shared/components/offer-placeholder/offer-placeholder.component";
import {EmptyOffersListComponent} from "./empty-offers-list/empty-offers-list.component";
import {ROUTES} from "../../shared/enums/routes.enum";
import {NavigationService} from "../../shared/services/navigation.service";
import {getWebMasterId} from "../../shared/functions/webMaster";
import {getTelegramWebApp} from "../../shared/functions/getTelegramWebApp";
import {EditInfoComponent} from "../../shared/components/policy-data/edit-info/edit-info.component";
import {ProlongationService} from "../../shared/services/prolongation.service";
import moment from "moment-mini";
import {getAdjustedPolicyDate} from "../../shared/functions/policyDate";
import {LoggingService} from "../../shared/services/loggingService";
import {log} from "@angular-devkit/build-angular/src/builders/ssr-dev-server";

@Component({
  selector: 'app-offers',
  standalone: true,
  templateUrl: './offers.component.html',
  imports: [
    PolicyDataComponent,
    FormPolicyDateComponent,
    KbmComponent,
    TimerComponent,
    OfferItemComponent,
    OfferPlaceholderComponent,
    EmptyOffersListComponent,
    JsonPipe,
    EditInfoComponent
  ],
  styleUrls: ['./offers.component.scss']
})
export class OffersComponent implements OnInit, OnDestroy {
  private viewportScroller = inject(ViewportScroller);
  private navigationService = inject(NavigationService)
  private router = inject(Router);
  private prolongationService = inject(ProlongationService);

  constructor(public readonly osagoService: OsagoService,
              private readonly appService: AppService,
              public readonly formService: FormService,
              public readonly timerService: TimerService,
              private readonly ym: YandexMetrikaService) {
    osagoService.offers = [];
    osagoService.selectedOffer = null;
  }

  // Подписка
  private subscription: Subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;
  // Стартуем поиск офферов
  public getOffersFinish!: boolean;
  private policyStartDateChanged = new Subject<string>();
  isShowProlongation = false;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    if (this.formService.form.invalid) {
      this.navigationService.navigate(ROUTES.MainPage);
    } else {
      this.subscription.add(
        this.checkProlongation()
          .subscribe(() => {
            this.formService.form.get('policyStartDate')?.updateValueAndValidity({emitEvent: true});
          })
      );

      this.loadOffers();
    }
    this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.OffersPage);

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => this.viewportScroller.scrollToPosition([0, 0]));
  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.policyStartDateChanged.complete();
  }

  // --------------------------------------------------------------------------

  // Выбрали дату
  public changePolicyStartDate(event: string): void {
    this.policyStartDateChanged.next(event);
  }

  private checkProlongation(): Observable<void> {
    const licensePlate = this.formService.form.get('carData')?.get('licensePlate')?.value;
    return this.prolongationService.createOsagoReport(licensePlate).pipe(
      tap((res) => {
        this.osagoService.prolongationOffer = res?.value?.osagoData;

        // Преобразуем endDate из ISO в объект moment
        const endDate = res?.value?.osagoData?.endDate;

        if (endDate) {
          const policyDate = moment(endDate); // ISO формат обрабатывается моментом автоматически
          const currentDate = moment().startOf('day');
          const tomorrow = moment().startOf('day').add(1, 'day');
          const maxDate = moment().startOf('day').add(59, 'days');

          // Проверяем, что дата валидна, и что она лежит в допустимом диапазоне
          if (!policyDate.isValid() || policyDate.isBefore(currentDate) || policyDate.isAfter(maxDate)) {
          } else {
            this.osagoService.isShowProlongationAlert = true;
            this.osagoService.isShowFormStartDatePolicy = true;

            const formattedDate = policyDate.add(1, 'day').format('DD.MM.YYYY'); // Добавляем 1 день и форматируем
            this.formService.form
              .get('policyStartDate')
              ?.setValue(getAdjustedPolicyDate(formattedDate), { emitEvent: false });
          }
        }
      }),
      catchError((error) => {
        console.error('Ошибка при создании отчета ОСАГО:', error);
        return of(null); // Возвращаем Observable для продолжения выполнения
      }),
      concatMap(() => of(void 0)) // Возвращаем void Observable для последовательного вызова
    );
  }


  private loadOffers(): void {
    const licensePlate = this.formService.form.get('carData')?.get('licensePlate')?.value;
    this.subscription.add(
      this.formService.form.get('policyStartDate')?.valueChanges.pipe(
        distinctUntilChanged(),
        switchMap((newDate) => {
          console.log(newDate);
          this.timerService.startTimer();
          this.osagoService.offers = [];
          this.getOffersFinish = false;
          this.isLoading = true;

          return iif(
            () => !this.appService.setWidgetDisplayedStatus,
            this.appService.createApplication(),
            of(EMPTY)
          ).pipe(
            concatMap(() => this.osagoService.sendOsagoApplication()),
            filter((res) => {
              if (res && !res.result) {
                this.navigationService.navigate(ROUTES.Form);
              }
              return res;
            }),
            concatMap(() =>
              this.osagoService.sendToInsurersGetOffers().pipe(
                finalize(() => {
                  this.isLoading = false;
                  if (this.osagoService.offers && this.osagoService.offers.length === 0) {
                    this.getOffersFinish = true;
                  }
                })
              )
            )
          );
        })
      ).subscribe({
        next: (response) => {
          if (response.result === false) {
            // Обработка некорректного результата
          }
        },
        error: () => {
          // Общая обработка ошибок
        }
      })
    );
  }

  get getTelegramWebApp(): boolean {
    return getTelegramWebApp();
  }
}
