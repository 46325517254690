import { Injectable } from '@angular/core';

// Данные контента
import * as Content from '../../../assets/data/content.json';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  public content = Content;
}
