<div class="modal-body">
  <div id="outerContainer">
    <div class="pdf-container">
      <pdf-viewer
        [src]="src"
        [rotation]="0"
        [original-size]="true"
        [show-all]="true"
        [fit-to-page]="false"
        [zoom]="1"
        [zoom-scale]="'page-width'"
        [stick-to-page]="false"
        [render-text]="false"
        [autoresize]="false"
        [show-borders]="false"
        style="width: 100%; height: 70vh;"
      ></pdf-viewer>
    </div>
  </div>
</div>
@if (bsModalRef) {
  <div class="modal-footer">
    <div class="row">
      <div class="col-12 col-sm-auto">
        <button type="button" class="btn btn-outline-secondary btn-lg w-100 mb-2 mb-sm-0" (click)="bsModalRef.hide()">Закрыть</button>
      </div>
    </div>
  </div>
}

