<!--<button type="button" class="btn btn-outline-primary offers-btn draft-btn w-100" (click)="openDraft()">-->
<!--  @if (isLoading) {-->
<!--    <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>-->
<!--  } @else {-->
<!--    <svg class="align-top" width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--      <path class="draft-icon" d="M15 14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8946 14.2652 15 14 15H6C5.73478 15 5.48043 14.8946 5.29289 14.7071C5.10536 14.5196 5 14.2652 5 14C5 13.7348 5.10536 13.4804 5.29289 13.2929C5.48043 13.1053 5.73478 13 6 13H14C14.2652 13 14.5196 13.1053 14.7071 13.2929C14.8946 13.4804 15 13.7348 15 14ZM11 17H6C5.73478 17 5.48043 17.1053 5.29289 17.2929C5.10536 17.4804 5 17.7348 5 18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19H11C11.2652 19 11.5196 18.8946 11.7071 18.7071C11.8946 18.5196 12 18.2652 12 18C12 17.7348 11.8946 17.4804 11.7071 17.2929C11.5196 17.1053 11.2652 17 11 17ZM20 10.485V19C19.9984 20.3256 19.4711 21.5964 18.5338 22.5338C17.5964 23.4711 16.3256 23.9984 15 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19V4.99999C0.00158786 3.6744 0.528882 2.40355 1.46622 1.46621C2.40356 0.528874 3.67441 0.00158039 5 -7.4689e-06H9.515C10.4346 -0.00237439 11.3456 0.17758 12.1952 0.529452C13.0449 0.881323 13.8163 1.39813 14.465 2.04999L17.949 5.53599C18.6012 6.18423 19.1184 6.95545 19.4704 7.80497C19.8225 8.65448 20.0025 9.56542 20 10.485ZM13.051 3.46399C12.7363 3.15915 12.3829 2.89692 12 2.68399V6.99999C12 7.26521 12.1054 7.51956 12.2929 7.7071C12.4804 7.89464 12.7348 7.99999 13 7.99999H17.316C17.103 7.61718 16.8404 7.26414 16.535 6.94999L13.051 3.46399ZM18 10.485C18 10.32 17.968 10.162 17.953 9.99999H13C12.2044 9.99999 11.4413 9.68392 10.8787 9.12131C10.3161 8.5587 10 7.79564 10 6.99999V2.04699C9.838 2.03199 9.679 1.99999 9.515 1.99999H5C4.20435 1.99999 3.44129 2.31606 2.87868 2.87867C2.31607 3.44128 2 4.20434 2 4.99999V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H15C15.7956 22 16.5587 21.6839 17.1213 21.1213C17.6839 20.5587 18 19.7956 18 19V10.485Z" fill="#025BFF"/>-->
<!--    </svg>-->
<!--  }-->
<!--  Черновик полиса-->
<!--</button>-->

@if (!isLoading && policyDraftUrl) {
  <button type="button" class="btn btn-outline-primary offers-btn draft-btn w-100" (click)="openDraft()">
    <svg class="align-top" width="20" height="24" viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path class="draft-icon" d="M15 14C15 14.2652 14.8946 14.5196 14.7071 14.7071C14.5196 14.8946 14.2652 15 14 15H6C5.73478 15 5.48043 14.8946 5.29289 14.7071C5.10536 14.5196 5 14.2652 5 14C5 13.7348 5.10536 13.4804 5.29289 13.2929C5.48043 13.1053 5.73478 13 6 13H14C14.2652 13 14.5196 13.1053 14.7071 13.2929C14.8946 13.4804 15 13.7348 15 14ZM11 17H6C5.73478 17 5.48043 17.1053 5.29289 17.2929C5.10536 17.4804 5 17.7348 5 18C5 18.2652 5.10536 18.5196 5.29289 18.7071C5.48043 18.8946 5.73478 19 6 19H11C11.2652 19 11.5196 18.8946 11.7071 18.7071C11.8946 18.5196 12 18.2652 12 18C12 17.7348 11.8946 17.4804 11.7071 17.2929C11.5196 17.1053 11.2652 17 11 17ZM20 10.485V19C19.9984 20.3256 19.4711 21.5964 18.5338 22.5338C17.5964 23.4711 16.3256 23.9984 15 24H5C3.67441 23.9984 2.40356 23.4711 1.46622 22.5338C0.528882 21.5964 0.00158786 20.3256 0 19V4.99999C0.00158786 3.6744 0.528882 2.40355 1.46622 1.46621C2.40356 0.528874 3.67441 0.00158039 5 -7.4689e-06H9.515C10.4346 -0.00237439 11.3456 0.17758 12.1952 0.529452C13.0449 0.881323 13.8163 1.39813 14.465 2.04999L17.949 5.53599C18.6012 6.18423 19.1184 6.95545 19.4704 7.80497C19.8225 8.65448 20.0025 9.56542 20 10.485ZM13.051 3.46399C12.7363 3.15915 12.3829 2.89692 12 2.68399V6.99999C12 7.26521 12.1054 7.51956 12.2929 7.7071C12.4804 7.89464 12.7348 7.99999 13 7.99999H17.316C17.103 7.61718 16.8404 7.26414 16.535 6.94999L13.051 3.46399ZM18 10.485C18 10.32 17.968 10.162 17.953 9.99999H13C12.2044 9.99999 11.4413 9.68392 10.8787 9.12131C10.3161 8.5587 10 7.79564 10 6.99999V2.04699C9.838 2.03199 9.679 1.99999 9.515 1.99999H5C4.20435 1.99999 3.44129 2.31606 2.87868 2.87867C2.31607 3.44128 2 4.20434 2 4.99999V19C2 19.7956 2.31607 20.5587 2.87868 21.1213C3.44129 21.6839 4.20435 22 5 22H15C15.7956 22 16.5587 21.6839 17.1213 21.1213C17.6839 20.5587 18 19.7956 18 19V10.485Z"/>
    </svg>
    Черновик полиса
  </button>
}
