import {Component, inject, Input, OnDestroy, OnInit} from '@angular/core';
import {BsModalRef} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-pdf-doc-view',
  standalone: true,
  templateUrl: './pdf-doc-view.component.html',
  imports: [
  ],
  styleUrls: ['./pdf-doc-view.component.scss']
})
export class PdfDocViewComponent {
  public src!: string;

  public readonly bsModalRef = inject(BsModalRef)

  // constructor(public readonly modalService: ModalService) {
  // }


}
