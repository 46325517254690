<div class="form-floating has-validation" [class.form-floating]="label">
  <input #controlElm
         [ngClass]="{
            'is-invalid': control.invalid && control.touched
           }"
         [(ngModel)]="value"
         (ngModelChange)="updateValue($event)"
         class="form-control"
         [readonly]="isReadonly"
         [disabled]="disabled"
         type="text"
         autocomplete="off"
         [attr.inputmode]="inputmode"
         [name]="name"
         [imask]="mask"
         [unmask]="true"
         [placeholder]="label"
         (click)="datepicker?.show()"
         (focus)="focusDateControl($event)"
         (blur)="blurControl($event)">
  <button *ngIf="usePopover"
          type="button"
          class="info-icon-btn"
          [popover]="popTemplate"
          [outsideClick]="true"
          [adaptivePosition]="true">
    <img class="info-icon" src="./assets/images/input-info-icon.svg" alt="Информация" loading="lazy">
  </button>
  <div class="calendar-icon-btn" *ngIf="isShowCalendar">
    <img class="info-icon" ngSrc="./assets/images/form-icons/calendar.svg" alt="Информация" loading="lazy" height="32"
         width="32">
  </div>
  <label>{{label}}</label>
  <app-form-field-error
    *ngIf="control?.invalid && control?.touched"
    [controlErrors]="control.errors"
    [messages]="messages">
  </app-form-field-error>

  <input *ngIf="isShowCalendar && !isReadonly" #inputDate
         style="height: 0; min-height: 0; width: 100%; pointer-events: none; padding: 0; opacity: 0;"
         type="text"
         class="form-control-plaintext"
         bsDatepicker
         [bsConfig]="bsConfig"
         (bsValueChange)="changeDate($event)">
</div>

<ng-template #popTemplate>
  <div class="popover-content" [innerHTML]="popoverContent"></div>
</ng-template>

