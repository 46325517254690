import { Injectable } from '@angular/core';
import {Subject, Subscription, timer} from "rxjs";
import {map, takeUntil} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class TimerService {
  constructor() { }

  public countdown!: Subscription;
  private stop$ = new Subject<void>();
  public displayTime!: string;
  public totalTime!: number;
  public timeLeft!: number;
  private startTime!: number;
  private rafId!: number;

  public startTimer(): void {
    if (this.rafId) {
      cancelAnimationFrame(this.rafId); // Отмена предыдущего requestAnimationFrame, если он был запущен
    }

    this.stop$.next();
    this.timeLeft = this.totalTime;
    this.startTime = Date.now();

    const updateTimer = () => {
      const elapsedTime = Date.now() - this.startTime;
      this.timeLeft = this.totalTime - elapsedTime;

      if (this.timeLeft <= 0) {
        this.stopTimer();
        this.displayTime = '00:00';
      } else {
        const totalSeconds = Math.floor(this.timeLeft / 1000);
        const minutes = Math.floor(totalSeconds / 60);
        const seconds = totalSeconds % 60;
        this.displayTime = `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        this.rafId = requestAnimationFrame(updateTimer);
      }
    };

    this.rafId = requestAnimationFrame(updateTimer);
  }

  public stopTimer(): void {
    if (this.rafId) {
      cancelAnimationFrame(this.rafId);
    }
    this.stop$.next();
  }
}
