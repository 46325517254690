import {AbstractControl, FormControl} from "@angular/forms";

// Функция для синхронизации 2х контролов
// При изменении первого, значение применяется и у второго
export function syncControls(control: AbstractControl, secondControl: AbstractControl, isSetValue = true, emitEvent = false): any {
  return control.valueChanges.subscribe(value => {
    if (secondControl?.value !== value && isSetValue) {
      secondControl?.setValue(value, { emitEvent: emitEvent });
    }
  })
}

export function syncControlsValue(control: AbstractControl, secondControl: AbstractControl, isSetValue: AbstractControl): any {
  return control.valueChanges.subscribe(value => {
    if (secondControl?.value !== value && isSetValue?.value) {
      secondControl?.setValue(value, { emitEvent: false });
    }
  })
}
