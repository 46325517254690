import {Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {CollapseModule} from "ngx-bootstrap/collapse";
import {transformDescription} from "../../../../../shared/functions/transformDescription";
import {JsonPipe} from "@angular/common";
import {IOffer, IUpsales} from "../../../../../shared/interface/offers.interface";
import {OsagoService} from "../../../../../shared/services/osago.service";
import {ErrorUpsaleComponent} from "./error-upsale/error-upsale.component";

@Component({
  selector: 'app-upsale-item',
  standalone: true,
  imports: [
    CollapseModule,
    JsonPipe,
    ErrorUpsaleComponent
  ],
  templateUrl: './upsale-item.component.html',
  styleUrl: './upsale-item.component.scss'
})
export class UpsaleItemComponent {
  // Выбранный оффер
  @Input() offer!: IOffer;
  // Блокируем выбор кроса (для страницы перехода на оплату)
  @Input() isDisabled: boolean = false;
  // Список кросов
  @Input() upsaleItem!: IUpsales;

  public isCollapsed = true;

  @Input() checked: boolean = false;
  @Output() checkedChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public osagoService = inject(OsagoService);

  onCheckboxChange(event: Event): void {
    this.checked = (event.target as HTMLInputElement).checked;
    this.upsaleItem.isSelected = this.checked;
    if (this.upsaleItem.isSelected) {

      this.offer.premiumAmount = this.offer?.premiumAmount + this.upsaleItem.price;

    } else {

      this.offer.premiumAmount = this.offer?.premiumAmount - this.upsaleItem.price;
    }
    this.checkedChange.emit(this.checked);
  }

  protected readonly transformDescription = transformDescription;
}
