export enum YandexMetrikaGoalsEnum {
  MainPage = 'main_page',
  CarDataPage = 'carDataGroup',
  DriversPage = 'drivers',
  Driver1 = 'driverGroup0',
  Driver2 = 'driverGroup1',
  Driver3 = 'driverGroup2',
  Driver4 = 'driverGroup3',
  Driver5 = 'driverGroup4',
  Owner = 'ownerGroup',
  Insurer = 'insurerGroup',
  LegalOwner = 'legalOwnerGroup',
  LegalInsurer = 'legalInsurerGroup',
  Contacts = 'contactsGroup',
  OffersPage = 'offers_page',
  OfferSelectionPage = 'offer_selection_page',
  // Любая ошибка
  InternalError = 'internal_error',
  // Ошибка при которой попадали на страницу с ошибкой
  ErrorPage = 'error_page',
  PaymentLinkPage = 'payment_link_page',
  SuccessPage = 'success_page',
  OfflineFormAutopark = 'autopark',
  // Ошибка если Альфа ID не вернула анкету
  AlfaIdError = 'error_alfaid',
  // Ошибки при получении марок авто
  ErrorGetCarBrands = 'error_get_car_brands',
  // Ошиби при получении моделей авто
  ErrorGetCarModels = 'error_get_car_models',
  // Ошибки запроса SetStatusWidgetDisplayed
  ErrorSetStatusWidgetDisplayed = 'error_set_status_widget_displayed',
  // Ошибки запроса new
  ErrorAppNew = 'error_app_new',
  // Ошибки в запросе GetOsagoPreliminaryCost сервис подели
  ErrorGetOsagoPreliminaryCost = 'error_get_osago_preliminary_cost',
  // Ошибки в запросе UpdateCache сервис кэша
  ErrorResetCache = 'error_reset_cache',
  // Ошибки в запросе UpdateCache сервис кэша
  ErrorUpdateCache = 'error_update_cache',
  // Ошибки в запросе CreateKbmReport
  ErrorCreateKbmReport = 'error_create_kbm_report',
  // Ошибки в запросе GetKbmReport
  ErrorGetKbmReport = 'error_get_kbm_report',
  // Ошибки в запросе SetWidgetStatus
  ErrorSetWidgetStatus = 'error_set_widget_status',
  // Ошибки в запросе GetOffers
  ErrorGetOffers = 'error_get_offers',
  // Ошибки в запросе GetOsagoPaymentLink
  ErrorGetOsagoPaymentLink = 'error_get_osago_payment_link',
  // Ошибки в запросе SelectOffer
  ErrorSelectOffer = 'error_select_offer',
  // Ошибки в запросе SendOsagoApplication
  ErrorSendOsagoApplication = 'error_send_osago_application',
  // Ошибки в запросе SendToInsurers
  ErrorSendToInsurers = 'error_send_to_insurers',
  // Ошибки в запросе GetApplicationInfo
  ErrorGetApplicationInfo = 'error_get_application_info',
  // Ошибки в запросе GetCarCharacteristicsReport
  ErrorGetCarCharacteristicsReport = 'error_get_car_characteristics_report',
  // Ошибки в запросе GetOsagoCacheByApplicationId
  ErrorGetOsagoCacheByApplicationId = 'error_get_osago_cache_by_application_id',
  // Ошибки в запросе GetOsagoFromLocalStorage
  ErrorGetOsagoFromLocalStorage = 'error_get_osago_from_local_storage',
  // Ошибки в запросе Alfaid
  ErrorAlfaid = 'error_alfaid',
  // Ошибки в запросе GetAlfaIdUrl
  ErrorGetAlfaIdUrl = 'error_get_alfa_id_url',

  NewResultError = 'new_result_error',
  GetOsagoCacheByApplicationIdResultError = 'get_osago_cache_by_application_id_result_error',
  GetOsagoCacheByPartnerApplicationIdResultError = 'get_osago_cache_by_partner_application_id_result_error',
  SendOsagoApplicationResultError = 'send_osago_application_result_error',
  SendToInsurersResultError = 'send_to_insurers_result_error',
  SelectOfferResultError = 'select_offer_result_error',
  GetOffersResultError = 'get_offers_result_error',
  GetOsagoPaymentLinkResultError = 'get_osago_payment_link_result_error',
  GetCarBrandsResultError = 'get_car_brands_result_error',
  GetCarModelsResultError = 'get_car_models_result_error',
  SetStatusWidgetDisplayedResultError = 'set_status_widget_displayed_result_error',
  GetOsagoPreliminaryCostResultError = 'get_osago_preliminary_cost_result_error',
  UpdateCacheResultError = 'update_cache_result_error',
  CreateKbmReportResultError = 'create_kbm_report_result_error',
  GetKbmReportResultError = 'get_kbm_report_result_error',
  SetWidgetStatusResultError = 'set_widget_status_result_error',
  GetCarCharacteristicsReportResultError = 'get_car_characteristics_report_result_error',
  GetOsagoFromLocalStorageResultError = 'get_osago_from_local_storage_result_error',
  GetAlfaIdUrlResultError = 'get_alfa_id_url_result_error',
  AlfaidResultError = 'alfaid_result_error',
  SendOsagoPaymentLinkResultError = 'send_osago_payment_link_result_error',
  GetNewOsagoPaymentLinkResultError = 'get_new_osago_payment_link_result_error',
  SetStatusBuyButtonPressedResultError = 'set_status_buy_button_pressed_result_error',
  CommontResultError = 'commont_result_error',
}

