import {Component, inject} from '@angular/core';
import {FormService} from "../../services/form.service";
import {BsDropdownModule} from "ngx-bootstrap/dropdown";
import {OsagoService} from "../../services/osago.service";
import {RouterLink} from "@angular/router";
import {AccordionService} from "../../services/accordion.service";
import {FormGroup} from "@angular/forms";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-test',
  standalone: true,
  imports: [
    BsDropdownModule,
    RouterLink
  ],
  templateUrl: './test.component.html',
  styleUrl: './test.component.scss'
})
export class TestComponent {

  private formService = inject(FormService);
  private accordionService = inject(AccordionService);

  private http = inject(HttpClient);

  // Добавялем водителя
  public addDriver(driverNumber: number): void {
    const openGroup = this.accordionService.accordionGroups?.find(group => group?.isOpen === true);

    if (openGroup) {
      const panelClass = openGroup.panelClass;
      const match = panelClass.match(/driverGroup(\d+)/);
      if (match && match[1]) {
        const driverIndex = parseInt(match[1], 10);
        if (this.formService.drivers.at(driverIndex)) {
          const driverControl = this.formService.drivers.at(driverIndex);
          const {
            driverFirstName,
            driverLastName,
            oldDriverLastName,
            driverMiddleName,
            driverBirthDate,
            rememberOnlyYear,
            driverLicense,
            oldLicense,
            experienceDate,
            experienceYear,
            driverIsOwner,
            driverIsInsurer
          } = (driverControl as FormGroup)?.controls;

          if (driverNumber === 1) {
            driverLastName.setValue('Иванов', { emitEvent: true });
            driverFirstName.setValue('Иван', { emitEvent: true });
            driverMiddleName.setValue('Иванович', { emitEvent: true });
            driverBirthDate.setValue('01.01.1991', { emitEvent: true });
            experienceDate.setValue('01.01.2011', { emitEvent: true });
            driverLicense.setValue('1111111111', { emitEvent: true });
          }

          if (driverNumber === 2) {
            driverLastName.setValue('Петров', { emitEvent: true });
            driverFirstName.setValue('Петр', { emitEvent: true });
            driverMiddleName.setValue('Петрович', { emitEvent: true });
            driverBirthDate.setValue('02.02.1992', { emitEvent: true });
            experienceDate.setValue('02.02.2012', { emitEvent: true });
            driverLicense.setValue('2222222222', { emitEvent: true });
          }

          if (driverNumber === 3) {
            driverLastName.setValue('Сидоров', { emitEvent: true });
            driverFirstName.setValue('Сидр', { emitEvent: true });
            driverMiddleName.setValue('Сидорович', { emitEvent: true });
            driverBirthDate.setValue('03.03.1993', { emitEvent: true });
            experienceDate.setValue('03.03.2013', { emitEvent: true });
            driverLicense.setValue('3333333333', { emitEvent: true });
          }

          if (driverNumber === 4) {
            driverLastName.setValue('Михайлов', { emitEvent: true });
            driverFirstName.setValue('Михаил', { emitEvent: true });
            driverMiddleName.setValue('Михайлович', { emitEvent: true });
            driverBirthDate.setValue('04.04.1994', { emitEvent: true });
            experienceDate.setValue('04.04.2014', { emitEvent: true });
            driverLicense.setValue('4444444444', { emitEvent: true });
          }

          if (driverNumber === 4) {
            driverLastName.setValue('Соколов', { emitEvent: true });
            driverFirstName.setValue('Валентин', { emitEvent: true });
            driverMiddleName.setValue('Владимирович', { emitEvent: true });
            driverBirthDate.setValue('05.05.1995', { emitEvent: true });
            experienceDate.setValue('05.05.2015', { emitEvent: true });
            driverLicense.setValue('5555555555', { emitEvent: true });
          }

          console.log(`Данные водителя с индексом ${driverIndex} добавлены`);
        } else {
          console.error(`Группа водителей с индексом ${driverIndex} не найдена`);
        }
      }
    }
  }

  public generateCarNumber() {
    const mask = 'X YYY ZZ AAA';
    const blocks = {
      X: {
        enum: ['А', 'В', 'Е', 'К', 'М', 'Н', 'О', 'Р', 'С', 'Т', 'У', 'Х'],
      },
      YYY: {
        mask: '000',
      },
      ZZ: {
        enum: ['А', 'В', 'Е', 'К', 'М', 'Н', 'О', 'Р', 'С', 'Т', 'У', 'Х'],
      },
      AAA: {
        mask: '***',
      }
    };

    let carNumber = mask;

    // Заменяем X
    carNumber = carNumber.replace('X', blocks.X.enum[Math.floor(Math.random() * blocks.X.enum.length)]);

    // Заменяем YYY
    carNumber = carNumber.replace('YYY', () => Math.floor(100 + Math.random() * 900).toString());

    // Заменяем ZZ
    carNumber = carNumber.replace('ZZ', () => {
      return blocks.ZZ.enum[Math.floor(Math.random() * blocks.ZZ.enum.length)] +
        blocks.ZZ.enum[Math.floor(Math.random() * blocks.ZZ.enum.length)];
    });

    // Заменяем AAA
    carNumber = carNumber.replace('AAA', () => '799');

    this.formService.licenseFormGroup?.get('licensePlate')?.setValue(carNumber);
  }

  generatePdf() {
    const htmlContent = `<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Insurance Policy Draft</title>
    <style>
        /* Сброс отступов и бордеров */
        * {
            margin: 0;
            padding: 0;
            box-sizing: border-box;
        }

        /* Настройка размера страницы */
        body, html {
            height: 100%;
            font-family: Arial, sans-serif;
        }

        /* Контейнер для полиса */
        .policy-container {
            position: relative;
            width: 100%;
            height: 100%;
            background: url('https://content.revizorro.ru/storage/app/media/uploaded-files/1.jpg') no-repeat center center;
            background-size: cover;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 1vw; /* Адаптивный размер шрифта */
        }

        /* Секция полиса */
        .policy {
        position: absolute;
        left: 0;
        top: 0;
            padding: 20px;
            border-radius: 10px;
            max-width: 800px;
            width: 100%;
            text-align: center;
            box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        }

        /* Заголовок полиса */
        .policy h1 {
            font-size: 2em;
            margin-bottom: 20px;
        }

        /* Секции информации */
        .policy-section {
            margin-bottom: 20px;
        }

        .policy-section h2 {
            font-size: 1.5em;
            margin-bottom: 10px;
        }

        .policy-section p {
            font-size: 1em;
            line-height: 1.5;
        }
    </style>
</head>
<body>
    <div class="policy-container">
    <img src="https://content.revizorro.ru/storage/app/media/uploaded-files/1.jpg" alt="Image">
        <div class="policy">
            <h1>Insurance Policy Draft</h1>
            <div class="policy-section">
                <h2>Policyholder Information</h2>
                <p>Name: John Doe</p>
                <p>Address: 123 Main St, Cityville, Country</p>
                <p>Phone: (123) 456-7890</p>
            </div>
            <div class="policy-section">
                <h2>Policy Details</h2>
                <p>Policy Number: 9876543210</p>
                <p>Start Date: January 1, 2024</p>
                <p>End Date: December 31, 2024</p>
                <p>Premium: $1,200.00</p>
            </div>
            <div class="policy-section">
                <h2>Covered Risks</h2>
                <p>• Fire Damage</p>
                <p>• Water Damage</p>
                <p>• Theft</p>
                <p>• Vandalism</p>
            </div>
            <div class="policy-section">
                <h2>Signatures</h2>
                <p>Policyholder: ____________________</p>
                <p>Date: ____________________</p>
            </div>
        </div>
    </div>
</body>
</html>
`;

    this.http.post('https://angular2.ru/api/generate-pdf', { html: htmlContent }, { responseType: 'blob' })
      .subscribe(response => {
        // Создаем URL из Blob и открываем его в новой вкладке
        const blob = new Blob([response], { type: 'application/pdf' });
        const url = URL.createObjectURL(blob);
        window.open(url, '_blank');
      }, error => {
        console.error('Error generating PDF:', error);
      });
  }

}
