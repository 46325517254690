import {IOsagoCache} from "../interface/cache.interface";
import {FormArray, FormGroup} from "@angular/forms";
import {IDriverForm} from "../interface/osago-application.interface";
import {setDriver} from "./setDriversFormDataFromCache";
import {extractNumber} from "./extractNumber";
import {TypeOrganisation} from "../enums/type-organisation";
import {getAddressWithoutFlat} from "./setIndividualFormDataFromCache";

export function setLegalFormDataFromCache(cacheData: IOsagoCache, form: FormGroup): Promise<void> {
  return new Promise<void>((resolve) => {
    form.get('isJuridical')?.setValue(true);
    // Является ли собственник страхователем у юр лица
    const legalInsurerFormGroup = form.get('legalInsurer') as FormGroup;

    const legalOwnerFormGroup = form.get('legalOwner') as FormGroup;

    const ownerIsInsured = cacheData.owner.ownerIsInsured;

    const cacheOwner = cacheData.owner;
    const cacheInsurer = cacheData.insured;

    // Если собственник является страхователем,
    // тогда достаточно применить данные в legalOwner с triggerEmit = true
    // таким образом данные из legalOwner синхронизируются с legalInsurer
    // иначе нужно применить данные в обе формы по отдельности

    if (ownerIsInsured) {
      setLegalOwner(cacheOwner, legalOwnerFormGroup, false);
      setLegalInsurerFromOwner(cacheOwner, legalInsurerFormGroup, false);
    } else {
      // Применяем значение где страхователь не является союственником,
      // по этому данные применяются отдельно

      setLegalOwner(cacheOwner, legalOwnerFormGroup, false);
      setLegalInsurer(cacheInsurer, legalInsurerFormGroup, false);
    }

    // Применяем данные водителей
    const drivers = form.get('drivers') as FormArray;
    const driversData = cacheData.drivers;

    // Если есть водители
    if (driversData && driversData.length) {
      driversData.forEach((driver: IDriverForm, index: number) => {
        setDriver(driver, drivers.at(index) as FormGroup);
      });
    }
    resolve();
  });
}

// Применяем данные собственника юр лицо
// triggerEmit нужен для того что бы вставить значения без синхронизации с полями с формы legalInsurer и наоборот
// Это делается для того, что бы вставить значения отдельно и в legalOwner и в legalInsurer
// в случае если Страхователь не является собственником
function setLegalOwner(cacheOwner: any, legalOwnerFormGroup: FormGroup, triggerEmit: boolean = false): void {
  legalOwnerFormGroup?.get('legalOwnerIsJuridical')?.setValue(cacheOwner?.legalOwnerIsJuridical);
  // Если собственник является юр лицом, то применяем данные в форму legalOwnerIsLegal, иначе legalOwnerIsNotLegal
  if (cacheOwner?.legalOwnerIsJuridical || (cacheOwner?.legalFormType === TypeOrganisation.LEGAL || cacheOwner?.legalFormType === TypeOrganisation.INDIVIDUAL)) {
    legalOwnerFormGroup?.get('legalOwnerIsJuridical')?.setValue(true);
    setLegalOwnerIsLegal(cacheOwner, legalOwnerFormGroup, triggerEmit);
  } else {
    legalOwnerFormGroup?.get('legalOwnerIsJuridical')?.setValue(false);
    setLegalOwnerIsNotLegal(cacheOwner, legalOwnerFormGroup, triggerEmit);
  }
}

// Применяем данные для собственника юр лицо
function setLegalOwnerIsLegal(cacheOwner: any, legalOwnerFormGroup: FormGroup, triggerEmit: boolean = false): void {
  const {
    legalOwnerValue,
    legalOwnerType,
    legalOwnerDaData,
    legalOwnerName,
    legalOwnerInn,
    legalOwnerOgrn,
    legalOwnerKpp,
    legalOwnerEgryl,
    legalOwnerWithoutCertificate,
    legalOwnerRegCertificateDateIssue,
    legalOwnerRegCertificate,
    legalOwnerLegalAddress,
    legalOwnerLegalAddressDaData,
    legalOwnerLegalHouseNumber,

    legalOwnerFirstName,
    legalOwnerLastName,
    legalOwnerMiddleName,
    legalOwnerBirthDate,
    legalOwnerPassportLicense,
    legalOwnerPassportDate,
  } = (legalOwnerFormGroup?.get('legalOwnerIsLegal') as FormGroup)?.controls;

  const passportLicenseValue = cacheOwner?.passport?.passportSeries + '' + cacheOwner?.passport?.passportNumber;
  legalOwnerPassportLicense?.setValue(passportLicenseValue, { emitEvent: triggerEmit });
  legalOwnerPassportDate?.setValue(cacheOwner?.passport?.passportIssueDate, { emitEvent: triggerEmit });

  legalOwnerValue?.setValue(cacheOwner?.legalPersonData?.legalOwnerDaData?.name?.short_with_opf, { emitEvent: true });

  legalOwnerDaData?.setValue(cacheOwner?.legalPersonData?.legalOwnerDaData ? cacheOwner?.legalPersonData?.legalOwnerDaData : cacheOwner?.legalPersonData?.legalInsurerDaData, { emitEvent: triggerEmit });
  legalOwnerName?.setValue(cacheOwner?.legalPersonData?.legalName, { emitEvent: triggerEmit });
  legalOwnerInn?.setValue(cacheOwner?.legalPersonData?.inn || cacheOwner?.legalPersonData?.INN, { emitEvent: triggerEmit });

  if (cacheOwner?.legalPersonData?.regCertificateSeries && cacheOwner?.legalPersonData?.regCertificate && cacheOwner?.legalPersonData?.documentDateIssue) {
    legalOwnerRegCertificate?.setValue(cacheOwner?.legalPersonData?.regCertificateSeries! + '' + cacheOwner?.legalPersonData?.regCertificate!, { emitEvent: triggerEmit });
    legalOwnerRegCertificateDateIssue?.setValue(cacheOwner?.legalPersonData?.documentDateIssue, { emitEvent: triggerEmit });
    // TODO временное решение, контрол недоступен после триггера
    setTimeout(() => {
      legalOwnerWithoutCertificate?.setValue(false);
    }, 400)
  } else {
    legalOwnerWithoutCertificate?.setValue(true);
  }

  const flat = cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress?.flat;
  const flatType = cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress?.flat_type;
  const flatAsNumber = flat ? extractNumber(flat) : null;
  const addressWithoutFlat = cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress?.value
    ? cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress?.value
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '')
    : cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress?.result
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

  // Если есть номер офиса (в данном случае это номер квартиры)
  // То применяем данные адреса и номера квартиры,
  // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
  if (flatAsNumber) {
    legalOwnerLegalHouseNumber.setValue(flatAsNumber.toString(), { emitEvent: triggerEmit });
  }
  legalOwnerLegalAddress.setValue(getAddressWithoutFlat(addressWithoutFlat, flat, flatType), { emitEvent: triggerEmit });

  legalOwnerLegalAddressDaData.setValue(cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress, { emitEvent: triggerEmit });

  legalOwnerType?.setValue(cacheOwner?.legalPersonData?.legalInsurerDaData ? cacheOwner?.legalPersonData?.legalInsurerDaData?.type : cacheOwner?.legalPersonData?.legalOwnerDaData?.type);

  // Если юр лицо или индивидуальный предприниматель
  setTimeout(() => {
    if (cacheOwner?.legalPersonData?.legalInsurerDaData?.type === TypeOrganisation.LEGAL || cacheOwner?.legalPersonData?.legalOwnerDaData?.type === TypeOrganisation.LEGAL) {
      legalOwnerOgrn?.setValue(cacheOwner?.legalPersonData?.ogrn || cacheOwner?.legalPersonData?.OGRN, { emitEvent: triggerEmit });
      legalOwnerKpp?.setValue(cacheOwner?.legalPersonData?.kpp || cacheOwner?.legalPersonData?.KPP, { emitEvent: triggerEmit });
      legalOwnerEgryl?.setValue(cacheOwner?.legalPersonData?.EGRYL, { emitEvent: triggerEmit });
    } else if (cacheOwner?.legalPersonData?.legalInsurerDaData?.type === TypeOrganisation.INDIVIDUAL || cacheOwner?.legalPersonData?.legalOwnerDaData?.type === TypeOrganisation.INDIVIDUAL) {
      legalOwnerFirstName?.setValue(cacheOwner?.firstName, { emitEvent: triggerEmit });
      legalOwnerLastName?.setValue(cacheOwner?.lastName, { emitEvent: triggerEmit });
      legalOwnerMiddleName?.setValue(cacheOwner?.middleName, { emitEvent: true });
      legalOwnerBirthDate?.setValue(cacheOwner?.birthDate, { emitEvent: triggerEmit });
      legalOwnerPassportLicense.setValue(passportLicenseValue, { emitEvent: false });
      legalOwnerPassportDate.setValue(cacheOwner?.passport?.passportIssueDate, { emitEvent: false });
    }
  }, 0);


}

// Применяем данные для собственника юр лицо
function setLegalOwnerIsNotLegal(cacheOwner: any, legalOwnerFormGroup: FormGroup, triggerEmit: boolean = false): void {
  const {
    legalOwnerFirstName,
    legalOwnerLastName,
    legalOwnerMiddleName,
    legalOwnerBirthDate,
    legalOwnerPassportLicense,
    legalOwnerPassportDate,
    legalOwnerCity,
    legalOwnerHouseNumber,
    legalOwnerAddressDaData,
  } = (legalOwnerFormGroup?.get('legalOwnerIsNotLegal') as FormGroup)?.controls;

  legalOwnerFirstName?.setValue(cacheOwner?.firstName, { emitEvent: triggerEmit });
  legalOwnerLastName?.setValue(cacheOwner?.lastName, { emitEvent: triggerEmit });
  legalOwnerMiddleName?.setValue(cacheOwner?.middleName, { emitEvent: triggerEmit });
  legalOwnerBirthDate?.setValue(cacheOwner?.birthDate, { emitEvent: triggerEmit });
  if (cacheOwner?.passport?.passportSeries && cacheOwner?.passport?.passportNumber) {
    legalOwnerPassportLicense.setValue(cacheOwner?.passport?.passportSeries + cacheOwner?.passport?.passportNumber, { emitEvent: false });
  }
  legalOwnerPassportDate?.setValue(cacheOwner?.passport?.passportIssueDate, { emitEvent: false });

  const daDataAddress = cacheOwner?.passport?.registrationAddressData?.daDataAddress;
  // Если в адресе есть номер квартиры
  const addressWithoutFlat = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.value
    ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
    ?.replace(`, кв ${daDataAddress.flat}`, '');
  if (daDataAddress?.flat) {
    legalOwnerCity.setValue(addressWithoutFlat, { emitEvent: false });
    legalOwnerHouseNumber.setValue(daDataAddress.flat, { emitEvent: false });
  } else {
    legalOwnerCity.setValue(addressWithoutFlat, { emitEvent: false });
  }

  legalOwnerAddressDaData?.setValue(cacheOwner?.passport?.registrationAddressData?.daDataAddress, { emitEvent: false });
}

// Применяем данные срахователя юр лицо
function setLegalInsurer(cacheInsurer: any, legalInsurerFormGroup: FormGroup, triggerEmit: boolean = false): void {
  console.log('setLegalInsurer', cacheInsurer);
  const {
    legalInsurerValue,
    legalInsurerType,
    legalInsurerDaData,
    legalInsurerName,
    legalInsurerInn,
    legalInsurerOgrn,
    legalInsurerKpp,
    legalInsurerEgryl,
    legalInsurerWithoutCertificate,
    legalInsurerRegCertificateDateIssue,
    legalInsurerRegCertificate,
    legalInsurerAddress,
    legalInsurerAddressDaData,
    legalInsurerHouseNumber,

    legalInsurerFirstName,
    legalInsurerLastName,
    legalInsurerMiddleName,
    legalInsurerBirthDate,
    passportLicense,
    legalInsurerPassportDate
  } = legalInsurerFormGroup?.controls;

  legalInsurerValue?.setValue(cacheInsurer?.legalPersonData.legalInsurerDaData?.name?.short_with_opf, { emitEvent: true });
  legalInsurerType?.setValue(cacheInsurer?.legalPersonData.legalInsurerDaData?.type, { emitEvent: true });
  legalInsurerDaData?.setValue(cacheInsurer?.legalPersonData.legalInsurerDaData, { emitEvent: triggerEmit });
  legalInsurerName?.setValue(cacheInsurer?.legalPersonData.legalName, { emitEvent: triggerEmit });
  legalInsurerInn?.setValue(cacheInsurer?.legalPersonData.inn || cacheInsurer?.legalPersonData.INN, { emitEvent: triggerEmit });
  legalInsurerOgrn?.setValue(cacheInsurer?.legalPersonData.ogrn || cacheInsurer?.legalPersonData.OGRN, { emitEvent: triggerEmit });
  legalInsurerKpp?.setValue(cacheInsurer?.legalPersonData.kpp || cacheInsurer?.legalPersonData.KPP, { emitEvent: triggerEmit });
  legalInsurerEgryl?.setValue(cacheInsurer?.legalPersonData.EGRYL, { emitEvent: triggerEmit });

  if (cacheInsurer?.legalPersonData.regCertificateSeries && cacheInsurer?.legalPersonData.regCertificate && cacheInsurer?.legalPersonData.documentDateIssue) {
    legalInsurerRegCertificate?.setValue(`${cacheInsurer?.legalPersonData.regCertificateSeries}${cacheInsurer?.legalPersonData.regCertificate}`, { emitEvent: triggerEmit });
    legalInsurerRegCertificateDateIssue?.setValue(cacheInsurer?.legalPersonData.documentDateIssue, { emitEvent: triggerEmit });

    // Применяем флаг если есть данные сертификата, иначе используем номер Егрюл
    legalInsurerWithoutCertificate?.setValue(false);
  } else {
    legalInsurerWithoutCertificate?.setValue(true);
  }

  const flat = cacheInsurer?.legalPersonData.registrationAddressData?.daDataAddress?.flat;
  const flatType = cacheInsurer?.legalPersonData.registrationAddressData?.daDataAddress?.flat_type;
  const flatAsNumber = flat ? extractNumber(flat) : null;
  const addressWithoutFlat = cacheInsurer?.legalPersonData.registrationAddressData?.daDataAddress?.value
    ? cacheInsurer?.legalPersonData?.registrationAddressData?.daDataAddress?.value
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '')
    : cacheInsurer?.legalPersonData?.registrationAddressData?.daDataAddress?.result
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

  // Если есть номер офиса (в данном случае это номер квартиры)
  // То применяем данные адреса и номера квартиры,
  // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
  setTimeout(() => {
    if (flatAsNumber) {
      legalInsurerHouseNumber.setValue(flatAsNumber.toString(), { emitEvent: triggerEmit });
    }
    legalInsurerAddress.setValue(getAddressWithoutFlat(addressWithoutFlat, flat, flatType), { emitEvent: triggerEmit });

    legalInsurerAddressDaData.setValue(cacheInsurer?.legalPersonData.registrationAddressData?.daDataAddress, { emitEvent: triggerEmit });
  }, 400);

  // Если юр лицо или индивидуальный предприниматель
  if (cacheInsurer?.legalPersonData?.legalInsurerDaData?.type === TypeOrganisation.LEGAL) {
    legalInsurerOgrn?.setValue(cacheInsurer?.legalPersonData?.ogrn || cacheInsurer?.legalPersonData?.OGRN, { emitEvent: triggerEmit });
    legalInsurerKpp?.setValue(cacheInsurer?.legalPersonData?.kpp || cacheInsurer?.legalPersonData?.KPP, { emitEvent: triggerEmit });
    legalInsurerEgryl?.setValue(cacheInsurer?.legalPersonData?.EGRYL, { emitEvent: triggerEmit });
  } else if (cacheInsurer?.legalPersonData?.legalInsurerDaData?.type === TypeOrganisation.INDIVIDUAL) {
    legalInsurerFirstName?.setValue(cacheInsurer?.firstName, { emitEvent: triggerEmit });
    legalInsurerLastName?.setValue(cacheInsurer?.lastName, { emitEvent: triggerEmit });
    legalInsurerMiddleName?.setValue(cacheInsurer?.middleName, { emitEvent: true });
    legalInsurerBirthDate?.setValue(cacheInsurer?.birthDate, { emitEvent: triggerEmit });

    const passportLicenseValue = cacheInsurer?.passport?.passportSeries + '' + cacheInsurer?.passport?.passportNumber;
    passportLicense?.setValue(passportLicenseValue, { emitEvent: triggerEmit });
    legalInsurerPassportDate?.setValue(cacheInsurer?.passport?.passportIssueDate, { emitEvent: triggerEmit });
  }
}

// Применяем данные страхователя юр лицо из данных собственнкиа, если он является страхователем
function setLegalInsurerFromOwner(cacheOwner: any, legalInsurerFormGroup: FormGroup, triggerEmit: boolean = false): void {
  const {
    legalInsurerValue,
    legalInsurerType,
    legalInsurerDaData,
    legalInsurerName,
    legalInsurerInn,
    legalInsurerOgrn,
    legalInsurerKpp,
    legalInsurerEgryl,
    legalInsurerWithoutCertificate,
    legalInsurerRegCertificateDateIssue,
    legalInsurerRegCertificate,
    legalInsurerAddress,
    legalInsurerAddressDaData,
    legalInsurerHouseNumber,

    legalInsurerFirstName,
    legalInsurerLastName,
    legalInsurerMiddleName,
    legalInsurerBirthDate,
    passportLicense,
    legalInsurerPassportDate
  } = legalInsurerFormGroup?.controls;

  legalInsurerValue?.setValue(cacheOwner?.legalPersonData?.legalOwnerDaData?.value, { emitEvent: triggerEmit });
  legalInsurerType?.setValue(cacheOwner?.legalPersonData?.legalOwnerDaData?.type, { emitEvent: true });
  legalInsurerDaData?.setValue(cacheOwner?.legalPersonData?.legalOwnerDaData, { emitEvent: triggerEmit });
  legalInsurerName?.setValue(cacheOwner?.legalPersonData?.legalName, { emitEvent: triggerEmit });
  legalInsurerInn?.setValue(cacheOwner?.legalPersonData?.inn || cacheOwner?.legalPersonData?.INN, { emitEvent: triggerEmit });
  legalInsurerOgrn?.setValue(cacheOwner?.legalPersonData?.ogrn || cacheOwner?.legalPersonData?.OGRN, { emitEvent: triggerEmit });
  legalInsurerKpp?.setValue(cacheOwner?.legalPersonData?.kpp, { emitEvent: triggerEmit });
  legalInsurerEgryl?.setValue(cacheOwner?.legalPersonData?.EGRYL, { emitEvent: triggerEmit });

  if (cacheOwner?.legalPersonData?.regCertificateSeries && cacheOwner?.legalPersonData?.regCertificate && cacheOwner?.legalPersonData?.documentDateIssue) {
    legalInsurerRegCertificate?.setValue(`${cacheOwner?.legalPersonData?.regCertificateSeries}${cacheOwner?.legalPersonData?.regCertificate}`, { emitEvent: triggerEmit });
    legalInsurerRegCertificateDateIssue?.setValue(cacheOwner?.legalPersonData?.documentDateIssue, { emitEvent: triggerEmit });

    // Применяем флаг если есть данные сертификата, иначе используем номер Егрюл
    legalInsurerWithoutCertificate?.setValue(false);
  } else {
    legalInsurerWithoutCertificate?.setValue(true);
  }

  // Если юр лицо или индивидуальный предприниматель
  if (cacheOwner?.legalPersonData?.legalOwnerDaData?.type === TypeOrganisation.LEGAL) {

    const flat = cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress?.flat;
    const flatType = cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress?.flat_type;
    const flatAsNumber = flat ? extractNumber(flat) : null;
    const addressWithoutFlat = cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress?.value
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

    // Если есть номер офиса (в данном случае это номер квартиры)
    // То применяем данные адреса и номера квартиры,
    // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
    if (flatAsNumber) {
      legalInsurerAddress?.setValue(getAddressWithoutFlat(addressWithoutFlat, flat, flatType), { emitEvent: triggerEmit });
      legalInsurerHouseNumber?.setValue(flatAsNumber.toString(), { emitEvent: triggerEmit });
    } else {
      legalInsurerAddress?.setValue(getAddressWithoutFlat(addressWithoutFlat, flat, flatType), { emitEvent: triggerEmit });
    }

    legalInsurerAddressDaData?.setValue(cacheOwner?.legalPersonData?.registrationAddressData?.daDataAddress, { emitEvent: triggerEmit });

    legalInsurerOgrn?.setValue(cacheOwner?.legalPersonData?.ogrn || cacheOwner?.legalPersonData?.OGRN, { emitEvent: true });
    legalInsurerKpp?.setValue(cacheOwner?.legalPersonData?.kpp || cacheOwner?.legalPersonData?.KPP, { emitEvent: true });
    legalInsurerEgryl?.setValue(cacheOwner?.legalPersonData?.EGRYL, { emitEvent: true });
  } else if (cacheOwner?.legalPersonData?.legalOwnerDaData?.type === TypeOrganisation.INDIVIDUAL) {

    const flat = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.flat;
    const flatType = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.flat_type;
    const flatAsNumber = flat ? extractNumber(flat) : null;
    const addressWithoutFlat = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.value
      ?.replace(/,\s*офис\s*\d+/, '')?.replace(/,\s*кв\s*\d+/, '')
      ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '');

    // Если есть номер офиса (в данном случае это номер квартиры)
    // То применяем данные адреса и номера квартиры,
    // так что бы сделать тригер на номер квартиры с последующим запросом в дадату
    if (flatAsNumber) {
      legalInsurerAddress?.setValue(getAddressWithoutFlat(addressWithoutFlat, flat, flatType), { emitEvent: triggerEmit });
      legalInsurerHouseNumber?.setValue(flatAsNumber.toString(), { emitEvent: triggerEmit });
    } else {
      legalInsurerAddress?.setValue(getAddressWithoutFlat(addressWithoutFlat, flat, flatType), { emitEvent: triggerEmit });
    }

    legalInsurerAddressDaData?.setValue(cacheOwner?.passport?.registrationAddressData?.daDataAddress, { emitEvent: triggerEmit });

    legalInsurerFirstName?.setValue(cacheOwner?.firstName, { emitEvent: triggerEmit });
    legalInsurerLastName?.setValue(cacheOwner?.lastName, { emitEvent: triggerEmit });
    legalInsurerMiddleName?.setValue(cacheOwner?.middleName, { emitEvent: true });
    legalInsurerBirthDate?.setValue(cacheOwner?.birthDate, { emitEvent: triggerEmit });

    const passportLicenseValue = cacheOwner?.passport?.passportSeries + '' + cacheOwner?.passport?.passportNumber;
    passportLicense?.setValue(passportLicenseValue, { emitEvent: triggerEmit });
    legalInsurerPassportDate?.setValue(cacheOwner?.passport?.passportIssueDate, { emitEvent: triggerEmit });
  }
}
