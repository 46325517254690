import moment from "moment-mini";

export function getAdjustedPolicyDate(date: string): string {
  const policyDate = moment(date, 'DD.MM.YYYY');
  const currentDate = moment().startOf('day');
  const maxDate = currentDate.clone().add(59, 'days');

  // Если дата равна текущему дню или в прошлом, либо дата больше чем на 60 дней от текущего дня
  if (!policyDate.isValid() || policyDate.isSameOrBefore(currentDate) || policyDate.isAfter(maxDate)) {
    return currentDate.add(4, 'day').format('DD.MM.YYYY');
  }
  return policyDate.format('DD.MM.YYYY');
}
