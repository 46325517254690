<div class="card mb-4 h-auto border-0 custom-rounded p-0 p-sm-2">
  <div class="card-body">
    <div class="row">
      <div class="col-auto">
        <img alt="" height="48" width="48"
             loading="lazy"
             ngSrc="./assets/images/offers-icons/empty-offers.svg">
      </div>
      <div class="col">
        <div class="main-title-item mb-3">Страховая не ответила</div>
        <div class="second-title-item">Компания не подтвердила создание полиса. Причины, к сожалению, нам неизвестны. Вы можете выбрать другие предложения. Вам не придется заново заполнять данные. Для вашего удобства
          мы их сохранили</div>
      </div>
    </div>
  </div>
</div>
