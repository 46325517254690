import {Component, inject} from '@angular/core';
import {NumberGosOrPolicyComponent} from "./number-gos-or-policy/number-gos-or-policy.component";
import {PolicyNumberFormComponent} from "./policy-number-form/policy-number-form.component";
import {GosNumberFormComponent} from "./gos-number-form/gos-number-form.component";
import { ProlongationFormService } from 'src/app/shared/services/prolongationForm.service';
import { JsonPipe } from '@angular/common';
import { ErrorMessageComponent } from "../../shared/components/error-message/error-message.component";
import {FormService} from "../../shared/services/form.service";

@Component({
  selector: 'app-fast-prolongation',
  standalone: true,
  imports: [
    NumberGosOrPolicyComponent,
    PolicyNumberFormComponent,
    GosNumberFormComponent,
    JsonPipe,
    ErrorMessageComponent
],
  templateUrl: './fast-prolongation.component.html',
  styleUrl: './fast-prolongation.component.scss'
})
export class FastProlongationComponent {
  public prolongationFormService = inject(ProlongationFormService);
  public formService = inject(FormService);
}
