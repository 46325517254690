import {Component, Input} from '@angular/core';
import {IPromocode} from "../../../../shared/interface/offers.interface";
import {JsonPipe, NgOptimizedImage} from "@angular/common";

@Component({
  selector: 'app-gift-item',
  standalone: true,
  imports: [
    JsonPipe,
    NgOptimizedImage
  ],
  templateUrl: './gift-item.component.html',
  styleUrl: './gift-item.component.scss'
})
export class GiftItemComponent {

  @Input() gift!: IPromocode;

}
