import {inject, Inject, Injectable} from '@angular/core';
import {concatMap, delay, forkJoin, from, Observable, of} from 'rxjs';
import {environment} from '@environment/environment';
import {catchError, map, switchMap, take, tap} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';

// Сервисы
import {FormService} from './form.service';
import {HttpService} from './http.service';

import {YandexMetrikaService} from './yandex-metrika.service';
import {SettingsService} from './settings.service';

// Интерфейсы
import {IUrlParams} from '../interface/url-params.interface';
import {ICache, ICacheResponse, IOsagoCache} from "../interface/cache.interface";
import {getApplicationSendRequest} from "../functions/applicationSendRequest";
import {IApplicationData, IDriverForm, IOsago, OsagoPolicy} from "../interface/osago-application.interface";
import {setFormDataFromCache} from "../functions/setFormDataFromCache";
import {DadataService} from "./dadata.service";
import {DaData} from "../enums/da-data.enum";
import {setFormDataFromCacheByAppId} from "../functions/setFormDataFromCacheByAppId";
import {FormControl, FormGroup} from "@angular/forms";
import {IUserAlfaId} from "../interface/user-alfa-id";
import {setContactsFromAlfaId, setDriverFromAlfaId} from "../functions/setFormDataFromAlfaId";
import {YandexMetrikaGoalsEnum} from "../enums/yandex-metrika-goals.enum";
import {LoggingService} from "./loggingService";
import moment from "moment-mini";
import {ActivatedRoute} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class CacheService extends HttpService {
  private ym = inject(YandexMetrikaService);
  private loggingService = inject(LoggingService);
  protected override http = inject(HttpClient);

  constructor(private readonly settingsService: SettingsService,
              private readonly daDataService: DadataService,
              private readonly formService: FormService,
              private readonly route: ActivatedRoute) {
    super();
  }

  // Данные из кэша
  public cacheData: ICache = {
    applications: [],
    osagoPolicies: [],
    kaskoPolicies: []
  };

  // Сохранить данные формы в кэш
  public saveCache(): Observable<any> {
    if (!environment.cacheService) {
      return of(null);
    }
    // ApplicationID
    const applicationId = this.formService.form.get('applicationId')?.value;
    // Номер авто
    const license = this.formService.form.get('carData')?.get('licensePlate')?.value;
    // Данные формы
    const formValue: any = this.formService.form.value;

    // Если нет номера авто, нет смысла обновлять кэш,
    // только если клиент заполняет анкету "Без номера"
    const hasNoLicensePlate = this.formService.form.get('carData')?.get('hasNoLicensePlate')?.value;
    if ((!license && hasNoLicensePlate)
      || (hasNoLicensePlate && !formValue.carData?.brandId && !formValue.carData?.modelId)) {
      return of({});
    }

    // Ищем дубликат в кэше с текущим applicationId
    // Если нашли, то обновляем license, если нет, то пушим новый элемент массива
    const findApplicationsItemIndex = this.cacheData?.applications?.findIndex((item) => item.applicationId === applicationId);
    if (findApplicationsItemIndex >= 0) {
      this.cacheData.applications[findApplicationsItemIndex].license = license;
    } else {
      this.cacheData?.applications?.push({
        applicationId,
        license
      });
    }

    const saveFormObj = Object.assign({}, getApplicationSendRequest(formValue));
    // saveFormObj.policyParameters.policyStartDate = '19.08.2024';

    // Удаляем applicationId, т.к. он не должен сохраняться в данных формы осаго
    // delete saveFormObj.applicationId;
    // Ищем дубликат в кэше с таким же номером авто
    // Если нашли, то обновляем данные формы, если нет, то пушим текущие данные формы
    if (this.cacheData?.osagoPolicies && this.cacheData?.osagoPolicies.length) {
      // Если анкета заполняласт "Без номера", то ищем совпадения в brandId и modelId, иначе в номере авто license
      const formItemIndex = this.cacheData?.osagoPolicies?.findIndex((item) =>
        hasNoLicensePlate
          ? item.osago?.carData?.brandId === formValue.carData?.brandId
          && item.osago?.carData?.modelId === formValue.carData?.modelId
          : item.license === license);

      if (formItemIndex >= 0) {
        this.cacheData.osagoPolicies[formItemIndex] = {
          license: saveFormObj.carData?.licensePlate || '',
          osago: saveFormObj
        };
      } else {
        this.cacheData?.osagoPolicies?.push({
          license: saveFormObj.carData?.licensePlate || '',
          osago: saveFormObj
        });
      }
    } else {
      this.cacheData.osagoPolicies = [{
        license: saveFormObj.carData?.licensePlate || '',
        osago: saveFormObj
      }];
    }

    const request = {
      apiKey: this.settingsService.apiKey,
      clientId: this.settingsService.clientId,
      data: JSON.stringify(this.cacheData)
    };

    localStorage.setItem('insappApp', JSON.stringify(request));
    return this.post(environment.carInfoApi + 'client/UpdateCache', request)
      .pipe(
        tap((res: any) => {
          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.UpdateCacheResultError);
          }
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса client/UpdateCache в функции saveCache', error);
          this.loggingService.error('Ошиба запроса client/UpdateCache в функции saveCache');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorUpdateCache);
          return of(error);
        })
      );
  }

  // Очистить кэш
  public resetCache(): Observable<any> {
    this.cacheData.osagoPolicies = [];
    this.cacheData.kaskoPolicies = [];
    this.cacheData.applications = [];
    const request = {
      apiKey: this.settingsService.apiKey,
      clientId: this.settingsService.clientId,
      data: JSON.stringify(this.cacheData)
    };
    localStorage.setItem('insappApp', JSON.stringify(request));
    return this.post(environment.carInfoApi + 'client/UpdateCache', request)
      .pipe(
        catchError(error => {
          this.loggingService.trace('Ошиба запроса client/UpdateCache в функции resetCache', error);
          this.loggingService.error('Ошиба запроса client/UpdateCache в функции resetCache');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorResetCache);
          return of(error);
        })
      );
  }

  getOsagoCacheByApplicationId(urlParams: IUrlParams, applicationId?: string): Observable<ICacheResponse | null> {
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: applicationId ? applicationId : urlParams.applicationId,
      partnerApplicationId: urlParams.alfaOfferId
    }

    const cacheUrl = urlParams.alfaOfferId
      ? environment.apiUrl + 'Client/GetOsagoCacheByPartnerApplicationId'
      : environment.apiUrl + 'Client/GetOsagoCacheByApplicationId';

    return this.http.post<ICacheResponse>(cacheUrl, request)
      .pipe(
        tap((cacheResponse: ICacheResponse) => {

          if (urlParams.alfaOfferId) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.GetOsagoCacheByPartnerApplicationIdResultError);
          } else {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.GetOsagoCacheByApplicationIdResultError);
          }

          if (!cacheResponse.value?.osagoPolicies[0]?.osago) {
            this.loggingService.trace('CacheService', cacheUrl, 'Ошибка запроса кэша osagoPolicies[0]?.osago, по запросу GetOsagoCacheByApplicationId не было найдено анкеты клиента');
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetOsagoCacheByApplicationId);
            return of(cacheResponse);
          }
          // Берем данные кэша из localStorage
          const localStorageCache = localStorage.getItem('insappApp');
          this.cacheData = localStorageCache ? JSON.parse(JSON.parse(localStorageCache).data) : {osagoPolicies: []};

          if (cacheResponse.result && cacheResponse.value) {
            const osago: IApplicationData = cacheResponse.value?.osagoPolicies[0]?.osago;
            const license = osago.carData.licensePlate;

            if (this.cacheData.osagoPolicies) {
              const index = this.cacheData.osagoPolicies.findIndex(x => x.license === license);
              if (index !== -1) {
                this.cacheData.osagoPolicies[index].osago = osago;
              } else {
                if (osago && license) {
                  this.cacheData.osagoPolicies.push({
                    license,
                    osago
                  });
                }
              }
            } else if (license) {
              this.cacheData = {
                applications: this.cacheData.applications,
                osagoPolicies: [{
                  osago,
                  license
                }],
                kaskoPolicies: this.cacheData.kaskoPolicies
              };
            }
            // setFormDataFromCache(osago, this.formService.form);
            // setFormDataFromCacheByAppId(osago, this.formService.form);
          }
          return of(cacheResponse);
        }),
        switchMap((cacheResponse: ICacheResponse) => {
          const osago = cacheResponse.value?.osagoPolicies[0]?.osago as any;
          const requests = [];

          if (!osago) {
            return of(cacheResponse);
          }

          // ДЛЯ ФИЗ ЛИЦА

          if (
            osago &&
            osago.owner?.passport.registrationAddressData &&
            !osago.owner.passport.registrationAddressData.daDataAddress?.fias_level
          ) {
            requests.push(
              this.daDataService.suggestionAddress(
                osago.owner.passport.registrationAddressData.addressAsString,
                DaData.ADDRESS_FULL
              ).pipe(
                tap(data => {
                  if (data && data[0] && data[0].data) {
                    // Perform assignment only if registrationAddressData exists
                    if (osago.owner && osago.owner.passport && osago.owner.passport.registrationAddressData) {
                      osago.owner.passport.registrationAddressData.daDataAddress = data[0].data as any;
                    }
                  }
                })
              )
            );
          }

          if (
            osago &&
            osago.insured?.passport.registrationAddressData &&
            !osago.insured.passport.registrationAddressData.daDataAddress?.fias_level
          ) {
            requests.push(
              this.daDataService.suggestionAddress(
                osago.insured.passport.registrationAddressData.addressAsString,
                DaData.ADDRESS_FULL
              ).pipe(
                tap(data => {
                  if (data && data[0] && data[0].data) {
                    // Perform assignment only if registrationAddressData exists
                    if (osago.insured && osago.insured.passport && osago.insured.passport.registrationAddressData) {
                      osago.insured.passport.registrationAddressData.daDataAddress = data[0].data as any;
                    }
                  }
                })
              )
            );
          }

          // ДЛЯ ЮРЛИЦА

          if (osago && osago.insured?.isJuridical! || osago && osago.insured?.inn!) {
            requests.push(this.daDataService.suggestionAddress(osago.insured.inn, DaData.LEGAL_NAME).pipe(
              tap(data => {
                osago.insured.legalInsurerType = data[0]?.data.type;
                osago.insured.legalInsurerDaData = data[0]?.data;
              })
            ));
          }

          if (osago && osago.owner?.isJuridical! || osago && osago.owner?.inn!) {
            requests.push(this.daDataService.suggestionAddress(osago.owner.inn, DaData.LEGAL_NAME).pipe(
              tap(data => {
                osago.owner.legalOwnerType = data[0]?.data.type;
                osago.owner.legalOwnerDaData = data[0]?.data;
              })
            ));
          }

          if (requests.length > 0) {
            return forkJoin(requests).pipe(map(() => cacheResponse));
          } else {
            return of(cacheResponse);
          }
        }),
        tap((cacheResponse: ICacheResponse): any => {
          const osago: IOsagoCache = cacheResponse.value?.osagoPolicies[0]?.osago;

          const driversData = osago?.drivers;

          if (osago && osago.hasDriversRestriction) {
            this.formService.drivers.clear();
          }

          driversData?.forEach((driver: IDriverForm, index: number) => {
            this.formService.drivers.push(this.formService.newDriver());
            this.formService.subscribeFormDriver(this.formService.drivers.at(index) as FormGroup);
          });

          this.checkPolicyStartDate(osago);

          if (urlParams.code || osago && osago.insured.inn || osago) {
            // setFormDataFromCacheByAppId(osago, this.formService.form);
            return from(setFormDataFromCache(osago, this.formService.form)).pipe(
              delay(0)
            );
          }
        }),
        delay(1000),
        take(1),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса cacheUrl ' + cacheUrl, error);
          this.loggingService.error('Ошиба запроса ' + cacheUrl);
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorResetCache);
          return of(error);
        })
      );
  }

  getOsagoFromLocalStorage(urlParams: IUrlParams, alfaIdData?: IUserAlfaId): Observable<ICache> {
    const alfaIdLicensePlate = localStorage.getItem('alfaIdLicensePlate');
    return of(JSON.parse(JSON.stringify(this.cacheData)))
      .pipe(
        switchMap((cacheResponse: any) => {
          const osago = cacheResponse.osagoPolicies.find((value: OsagoPolicy) => value.osago.carData.licensePlate === alfaIdLicensePlate)?.osago;
          if (!osago) {
            this.loggingService.trace('CacheService', 'getOsagoFromLocalStorage', 'Не было онаружено анкеты клиента в localStorage');
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetOsagoFromLocalStorage);
            return of(osago);
          }
          const requests = [];

          if (osago.insured?.isJuridical || osago.insured?.inn) {
            requests.push(this.daDataService.suggestionAddress(osago.insured.inn, DaData.LEGAL_NAME).pipe(
              tap(data => {
                osago.insured.legalInsurerType = data[0]?.data.type;
                osago.insured.legalInsurerDaData = data[0]?.data;
              })
            ));
          }

          if (osago.owner?.isJuridical || osago.owner?.inn) {
            requests.push(this.daDataService.suggestionAddress(osago.owner.inn, DaData.LEGAL_NAME).pipe(
              tap(data => {
                osago.owner.legalOwnerType = data[0]?.data.type;
                osago.owner.legalOwnerDaData = data[0]?.data;
              })
            ));
          }

          if (alfaIdData && alfaIdData?.regAddress) {
            requests.push(this.daDataService.suggestionAddress(alfaIdData?.regAddress, DaData.ADDRESS_FULL).pipe(
              tap(data => {
                if (data && data[0] && data[0]?.data) {
                  alfaIdData.regAddressDadata = data[0]?.data;
                  alfaIdData.regAddressDadata!.value = data[0]?.value;
                }
              })
            ));
          }

          if (requests.length > 0) {
            return forkJoin(requests).pipe(map(() => cacheResponse));
          } else {
            return of(cacheResponse);
          }
        }),
        tap((cacheResponse: ICache) => {
          const osago = cacheResponse.osagoPolicies.find((value: OsagoPolicy) => value.osago.carData.licensePlate === alfaIdLicensePlate)?.osago;

          this.checkPolicyStartDate(osago);

          if (urlParams.code || osago.insured.inn) {
            setFormDataFromCacheByAppId(osago, this.formService.form, false);
            setTimeout(() => {
              const ownerIsInsured = this.formService.form.get('ownerIsInsured') as FormControl;
              const driverFormGroup = this.formService.drivers.at(this.formService.drivers.length - 1) as FormGroup;
              setDriverFromAlfaId(alfaIdData!, driverFormGroup, ownerIsInsured);
              setContactsFromAlfaId(alfaIdData?.phone!, alfaIdData?.email!, this.formService.contacts as FormGroup);
            }, 10);
          } else {
            return from(setFormDataFromCache(osago, this.formService.form)).pipe(
              delay(0)
            );
          }
          return cacheResponse
        }),
        take(1)
      );
  }

  private checkPolicyStartDate(osago: IOsagoCache) {
    // Получаем дату начала полиса
    const policyStartDate = osago.policyParameters.policyStartDate;

    // Проверяем, если вызов происходит из быстрого продления
    const fromFastProlongation = this.route.snapshot.queryParamMap.get('fromFastProlongation') === 'true';

    if (policyStartDate) {
      // Преобразуем дату из формата DD.MM.YYYY в объект moment
      const policyDate = moment(policyStartDate, 'DD.MM.YYYY', true); // true для строгой проверки формата
      const currentDate = moment().startOf('day');
      const tomorrow = moment().startOf('day').add(1, 'day');

      // Проверяем, что дата валидна и что policyStartDate раньше следующего дня
      if (!policyDate.isValid() || policyDate.isBefore(tomorrow)) {
        // Устанавливаем policyStartDate на текущий день + 4 дня или +1 день в случае быстрого продления
        osago.policyParameters.policyStartDate = currentDate.add(fromFastProlongation ? 1 : 4, 'day').format('DD.MM.YYYY');
      }
    } else {
      // Если policyStartDate отсутствует, устанавливаем на текущий день + 4 дня
      const currentDate = moment().startOf('day');
      osago.policyParameters.policyStartDate = currentDate.add(fromFastProlongation ? 1 : 4, 'day').format('DD.MM.YYYY');
    }
  }

}
