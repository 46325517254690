import { Component, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-advantages-yafuel',
  templateUrl: './app-advantages-yafuel.component.html',
  styleUrls: ['./app-advantages-yafuel.component.scss']
})
export class AppAdvantagesYafuelComponent  {

}
