import {Component, Input} from '@angular/core';
import {FormService} from "../../services/form.service";
import {getCarBrandNameById, getCarModelNameByModelId} from "../../functions/getCarBrandAndModel";
import {AppService} from "../../services/app.service";
import {IAccordionHeader} from "../../interface/accordion-header.interface";
import {ContentService} from "../../services/content.service";
import {FormControl} from "@angular/forms";
import {IMask} from "angular-imask";
import {MASKS} from "../../constants/masks";
import {resolve} from "@angular/compiler-cli";

@Component({
  selector: 'app-accordion-header',
  standalone: true,
  templateUrl: './accordion-header.component.html',
  styleUrls: ['./accordion-header.component.scss']
})
export class AccordionHeaderComponent {
  // Имя заголовка
  @Input() headerName!: string;
  // Заголовок
  @Input() title!: string;
  // Подзаголовок
  @Input() subTitle!: string;
  // Иконка
  @Input() iconName!: string;
  // Индекс водителя
  @Input() driverIndex!: number;

  public accordionHeader: IAccordionHeader = {};
  // Показываем кнопку "редактировать"
  public isShowEditBtn = true;

  constructor(private readonly appService: AppService,
              private readonly formService: FormService,
              public readonly contentService: ContentService) {
  }

  public formValue = this.formService.form.value;
  get getAccordionTitle(): void {
    switch (this.headerName) {
      case 'autopark': {
        this.accordionHeader.title = this.contentService.content.formComponent.autoparkDataTitle;
        this.accordionHeader.subTitle = this.contentService.content.formComponent.autoparkDataSubTitle;
        this.accordionHeader.iconName = 'autopark';
        this.isShowEditBtn = false;
        return;
      }
      case 'carData': {
        const masked = IMask.createMask(MASKS.find((mask: any) => mask.systemName === 'licensePlate')?.mask);
        const carBrand = getCarBrandNameById(this.formService?.form.get('carData')?.get('brandId')?.value, this.appService.brandsList);
        const carModel = getCarModelNameByModelId(this.formService?.form.get('carData')?.get('modelId')?.value, this.appService.modelsList);
        const licensePLate = this.formService?.form.get('carData')?.get('licensePlate')?.value;
        if (masked && licensePLate) {
          masked.resolve(licensePLate?.toString());
        }
        this.accordionHeader.title = carBrand && carModel ? `${carBrand + ' ' + carModel}` : this.contentService.content.formComponent.carDataTitle;
        this.accordionHeader.subTitle = licensePLate && masked.value ? masked.value : this.contentService.content.formComponent.carDataSubTitle;
        this.accordionHeader.iconName = 'car-data';
        return;
      }
      case 'driver': {
        const driversWithoutRestriction = this.formService.form.get('driversWithoutRestriction') as FormControl;
        const driver = this.formService.drivers.at(this.driverIndex)?.value;
        const driverLastName = driver.driverLastName;
        const driverFirstName = driver.driverFirstName;
        this.accordionHeader.title = driverLastName && driverFirstName && !driversWithoutRestriction.value ? `${driverLastName + ' ' + driverFirstName}` : this.contentService.content.formComponent.driverDataTitle;
        this.accordionHeader.subTitle = driversWithoutRestriction.value ? this.contentService.content.formComponent.driverDataWithoutDriverSubTitle : this.contentService.content.formComponent.driverDataSubTitle;
        this.accordionHeader.iconName = 'driver';
        return;
      }
      case 'addDriver': {
        this.accordionHeader.subTitle = this.contentService.content.formComponent.driverDataAddDriverSubTitle;
        this.accordionHeader.iconName = 'driver-add';
        return;
      }
      case 'owner': {
        const owner = this.formService.form.get('owner')?.value;
        const ownerLastName = owner.ownerLastName;
        const ownerFirstName = owner.ownerFirstName;
        this.accordionHeader.title = ownerLastName && ownerFirstName ? `${ownerLastName + ' ' + ownerFirstName}` : this.contentService.content.formComponent.ownerDataTitle;
        this.accordionHeader.subTitle = this.contentService.content.formComponent.ownerDataSubTitle;
        this.accordionHeader.iconName = 'owner';
        return;
      }
      case 'insurer': {
        const insurer = this.formService.form.get('insurer')?.value;
        const insurerLastName = insurer.insurerLastName;
        const insurerFirstName = insurer.insurerFirstName;
        this.accordionHeader.title = insurerLastName && insurerFirstName ? `${insurerLastName + ' ' + insurerFirstName}` : this.contentService.content.formComponent.insurerDataTitle;
        this.accordionHeader.subTitle = this.contentService.content.formComponent.insurerDataSubTitle;
        this.accordionHeader.iconName = 'insurer';
        return;
      }
      case 'legalInsurer': {
        const insurer = this.formService.form.get('insurer')?.value;
        const insurerLastName = insurer.insurerLastName;
        const insurerFirstName = insurer.insurerFirstName;
        this.accordionHeader.title = insurerLastName && insurerFirstName ? `${insurerLastName + ' ' + insurerFirstName}` : this.contentService.content.formComponent.insurerDataTitle;
        this.accordionHeader.subTitle = this.contentService.content.formComponent.insurerDataSubTitle;
        this.accordionHeader.iconName = 'insurer';
        return;
      }
      case 'legalOwner': {
        const owner = this.formService.form.get('owner')?.value;
        const ownerLastName = owner.ownerLastName;
        const ownerFirstName = owner.ownerFirstName;
        this.accordionHeader.title = ownerLastName && ownerFirstName ? `${ownerLastName + ' ' + ownerFirstName}` : this.contentService.content.formComponent.ownerDataTitle;
        this.accordionHeader.subTitle = this.contentService.content.formComponent.ownerDataSubTitle;
        this.accordionHeader.iconName = 'owner';
        return;
      }
      case 'contacts': {
        const masked = IMask.createMask(MASKS.find((mask: any) => mask.systemName === 'phone')?.mask);
        const contacts = this.formService.form.get('contacts')?.value;
        const phone = contacts.phone;
        const email = contacts.email;
        if (masked && phone) {
          masked.resolve(phone?.toString());
        }
        this.accordionHeader.title = phone && masked.value ? masked.value : this.contentService.content.formComponent.contactsDataTitle;
        this.accordionHeader.subTitle = email ? email : this.contentService.content.formComponent.contactsDataSubTitle;
        this.accordionHeader.iconName = 'contacts';
        return;
      }
      default: {
        return;
      }

    }
  }

}
