@if (isMainPage) {
<header class="header">
  <div class="container">
    <div class="row my-3 my-sm-5 d-flex align-items-center logo-list">
      <div class="col-auto">
        <div class="logo">
          <img src="assets/landingFiles/logo-sm.svg" alt="ОСАГО Онлайн">
        </div>
      </div>
      <div class="col-auto">
        <img class="mt-3 mt-sm-0" src="assets/landingFiles/insapp-logo.svg" alt="Insapp">
      </div>
    </div>

    <div class="row d-flex flex-column-reverse flex-md-row align-items-center mb-3 mb-md-5">
      <div class="col-md-6">
        <h1>ОФОРМИ ОСАГО ОНЛАЙН</h1>
        <p class="mt-4">Получи в подарок промокод на скидку в сервисе Яндекс Заправки за покупку полиса</p>
      </div>
      <div class="col-md-6">
        <img src="assets/landingFiles/car.png" class="w-100" alt="Car Image">
      </div>
    </div>
  </div>
</header>
}

<div class="osago">
  <div class="container osago-container">
    @if (isLoading) {
    <div class="m-5 d-flex justify-content-center">
      <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    } @else {
    <router-outlet></router-outlet>
    }
  </div>
</div>

@if (isMainPage) {
<section class="company-swiper my-3 my-md-5">
  <app-company-swiper></app-company-swiper>
</section>

<section class="advantages">
  <div class="container">
    <app-advantages-yafuel></app-advantages-yafuel>
  </div>
</section>

<section class="faq mt-5">
  <div class="container">
    <app-faq></app-faq>
  </div>
</section>

<section class="footer my-5">
  <div class="container mb-3"><a class="text-decoration-underline" href="https://yandex.ru/legal/promocode_zapravki/"
      target="_blank"><small>Условия использования промокодов</small></a></div>
  <app-footer></app-footer>
</section>
}
