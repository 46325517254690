<form [formGroup]="formInsurer" (ngSubmit)="onSubmit()" novalidate>
  <div class="insurer">

    <div class="alert alert-primary mb-3 mb-sm-4">
      Страхователь - лицо, от имени которого заключается договор страхования, он оплачивает полис<br>
      и имеет право в будущем вносить в него изменения. Страхователь должен быть либо собственником ТС, либо вписан в полис как водитель
    </div>

    @if (formService.drivers.value?.length >= 1 && getDriverTitle(formService.form.get('drivers')?.value[0])) {
      <div class="row driver-selector">
        <div class="col-auto mb-3 mb-sm-4" *ngFor="let driver of formService.form.get('drivers')?.value; let i = index">
          <button (click)="selectDriver(i)"
                  [class]="driver.driverIsInsurer  ? 'btn-primary' : 'btn-outline-primary'"
                  type="button"
                  class="btn btn-sm">{{ getDriverTitle(driver) }}</button>
        </div>
        <div class="col-auto mb-3 mb-sm-4">
          <button (click)="selectDriver(-1)"
                  [class]="getActiveDriver ? 'btn-primary' : 'btn-outline-primary'"
                  type="button"
                  class="btn btn-sm">{{contentService.content.formComponent.insurerComponent.otherDriverBtn}}
          </button>
        </div>
      </div>
    }

    <div class="row">
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="insurerLastName"
          [suggestionPart]="daDataSystemName.SURNAME"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="insurerFirstName"
          [suggestionPart]="daDataSystemName.NAME"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="insurerMiddleName"
          [suggestionPart]="daDataSystemName.PATRONYMIC"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-form-text-field formControlName="insurerBirthDate" [isReadonly]="isLoading"></app-form-text-field>
      </div>
      @if (formInsurer.get('passportLicense')?.enabled) {
        <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
          <app-form-text-field formControlName="passportLicense" [isReadonly]="isLoading"></app-form-text-field>
        </div>
      }
      @if (formInsurer.get('insurerPassportDate')?.enabled) {
        <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
          <app-form-date-field formControlName="insurerPassportDate" [isReadonly]="isLoading"></app-form-date-field>
        </div>
      }
      <div class="col-12 col-md-7 mb-3 mb-md-4">
        <app-autocomplete
          formControlName="insurerCity"
          [daDataControlName]="'insurerAddressDaData'"
          [suggestionPart]="daDataSystemName.ADDRESS"
          [isReadonly]="isLoading"></app-autocomplete>
      </div>
      <div class="col-12 col-md-5 mb-3 mb-md-4">
        <app-form-text-field formControlName="insurerHouseNumber" [isReadonly]="isLoading"></app-form-text-field>
      </div>
    </div>

    <div class="row actions justify-content-end">
      <div class="col-12 col-sm-auto mb-3 mb-sm-4">
        <button type="submit" class="btn btn-primary btn-lg w-100" [disabled]="isLoading">
          @if (isLoading) {
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          } @else {
            <span>Продолжить</span>
          }
        </button>
      </div>
    </div>

  </div>
</form>
