export function scrollToFirstError() {
  // Находим все элементы формы, у которых есть ошибка валидации
  setTimeout(() => {
    const errorElements = document.querySelectorAll('.form-control.is-invalid');

    if (errorElements.length > 0) {
      // Получаем первый элемент с ошибкой
      const firstErrorElement = errorElements[0];

      // Рассчитываем позицию для скролла
      const elementPosition = firstErrorElement.getBoundingClientRect().top;
      const offset = window.pageYOffset;
      const clientHeight = document.documentElement.clientHeight;
      const scrollTo = offset + elementPosition - (clientHeight / 2);

      // Прокручиваем страницу к этому элементу
      window.scrollTo({
        top: scrollTo,
        behavior: 'smooth'
      });
    }
  }, 0)
}
