import {IPurposeOfUse} from "../interface/osago-application.interface";
import {PurposeOfUseEnum} from "../enums/purpose-of-use.enum";

// Список всех целей использования авто авто
export const PURPOSE_OF_USE: IPurposeOfUse[] = [
  {
    systemName: PurposeOfUseEnum.Personal,
    value: 'Личная'
  },
  {
    systemName: PurposeOfUseEnum.Official,
    value: 'Служебная'
  },
  {
    systemName: PurposeOfUseEnum.Taxi,
    value: 'Такси'
  },
  {
    systemName: PurposeOfUseEnum.EducationDrive,
    value: 'Учебная езда'
  },
  {
    systemName: PurposeOfUseEnum.SpecialDrive,
    value: 'Дорожные и специальные транспортные средства'
  },
  {
    systemName: PurposeOfUseEnum.RentalDrive,
    value: 'Прокат'
  },
  {
    systemName: PurposeOfUseEnum.EmergencyAndUtilityDrive,
    value: 'Экстренные и коммунальные службы'
  },
  {
    systemName: PurposeOfUseEnum.Other,
    value: 'Прочее'
  }
];

export const PURPOSE_OF_USE_INDIVIDUAL: IPurposeOfUse[] = [
  {
    systemName: PurposeOfUseEnum.Personal,
    value: 'Личная'
  },
  {
    systemName: PurposeOfUseEnum.Taxi,
    value: 'Такси'
  }
];
