<div class="card h-auto shadow-0 border-0 p-3 p-sm-4 custom-rounded">
  <div class="card-body p-0">
    <div class="row align-items-center">
      <div class="col-12 col-sm-6 mb-3 mb-sm-0">
        <div class="main-title-item mb-0">{{contentService.content.entityIndividualComponent.title}}</div>
      </div>
      <div class="col-12 col-sm-6">
        <div class="row justify-content-end d-flex flex-nowrap">
          <div class="col-6 col-sm-auto">
            <button type="button" class="btn legal-btn w-100" (click)="changeIsJuridical(false)"
                    [ngClass]="!form.get('isJuridical')?.value ? 'btn-primary' : 'btn-outline-primary'">
              {{contentService.content.entityIndividualComponent.individual}}
            </button>
          </div>
          <div class="col-6 col-sm-auto">
            <button type="button" class="btn legal-btn w-100 px-0 px-sm-3" (click)="changeIsJuridical(true)"
                    [ngClass]="form.get('isJuridical')?.value ? 'btn-primary' : 'btn-outline-primary'">
              {{contentService.content.entityIndividualComponent.juristic}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
