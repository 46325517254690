export const faqData = [
  {
    category: 'Общие вопросы',
    questions: [
      {
        question: 'Зачем оформлять ОСАГО?',
        answer: `
          В соответствии с Федеральным законом «Об ОСАГО» наличие полиса автострахования ОСАГО является обязательным для всех автовладельцев.
          При покупке транспортного средства владелец обязан заключить договор ОСАГО, так как управление автомобилем без действующего полиса запрещено.
          За отсутствие страховки автовладельцу грозят штрафные санкции.
          <br><br>Наличие данного полиса является гарантией возмещения ущерба, нанесенного транспортным средством, всем пострадавшим в дорожно-транспортном происшествии.
          Причем возмещением убытков будет заниматься не лично владелец авто, признанный виноватым в аварии, а страховая компания, с которой заключен договор автострахования.
        `,
      },
      {
        question: 'Что такое е-ОСАГО? Обязательно ли распечатывать полис?',
        answer: `
          Электронный полис ОСАГО — это тот же договор обязательного страхования автогражданской ответственности, только в виде электронного документа,
          который автовладелец, при желании, может самостоятельно распечатать на простой бумаге в черно-белом или цветном варианте.
          <br><br>Бланки бумажных полисов оформляются в 2019 году исключительно розовыми, а e-ОСАГО может быть любого цвета: розового, голубого, зелёного и даже чёрно-белого.
          Требований к электронной версии полиса нет. Заверять электронный полис е-ОСАГО не нужно никак и нигде.
          <br><br>Распечатывать электронный полис ОСАГО не обязательно. Достаточно иметь под рукой его серию и номер, либо показать его на экране мобильного телефона.
        `,
      },
      {
        question: 'От чего зависит стоимость ОСАГО?',
        answer: `
          Сумма платежа зависит от размера базовой ставки и коэффициентов. В соответствии с Указанием Банка России от 08.12.2021 6007-У
          «О страховых тарифах по обязательному страхованию гражданской ответственности владельцев транспортных средств» установлен тарифный коридор —
          максимальные и минимальные значения базовой ставки ОСАГО, в пределах которых страховые компании определяют стоимость полисов.
          С 09.01.2022 максимальное значение базовой ставки не может превышать 5980 рублей, минимальное значение - 2224 рублей (для легковых автомобилей категории В, используемых физлицами).
          <br><br>Коэффициенты:
          <ol>
            <li class="mb-2">Мощность двигателя ТС. Чем больше показатель, тем выше расчетный коэффициент мощности (КМ).</li>
            <li class="mb-2">Территория преимущественного использования. Водители в крупных городах чаще попадают в аварии, чем жители сельской местности.
            Поэтому для мегаполисов коэффициент выше, чем для регионов (КТ).</li>
            <li class="mb-2">Возраст и стаж водителя. Чем меньше возраст и стаж автовладельца, тем выше будет стоимость полиса (КВС).</li>
            <li class="mb-2">Число водителей, допущенных к управлению ТС (КО). При неограниченном списке базовый страховой тариф ОСАГО умножают на коэффициент КО=2,32.
            При отражении в полисе ограниченного перечня лиц — на 1,0, при условии, что эти водители имеют достаточный возраст и стаж.</li>
            <li class="mb-2">Аварии в прошлом (бонус-малус или КБМ). Безаварийная езда дает право на скидку. При аккуратном вождении в течение года стоимость полиса снижается на 5 %,
            в течение двух лет подряд — на 10 % и так далее. Максимально страховые тарифы ОСАГО могут быть снижены на 50 % в течение 10 лет.</li>
            <li class="mb-2">Возможность использования транспортных средств с прицепом (КПР). Этот коэффициент актуален в первую очередь для юридических лиц,
            владельцев грузовых авто, мотоциклов и мотороллеров. На физ.лиц, владеющих легковым автотранспортом, его действие не распространяется.</li>
            <li>Период использования транспортного средства (КС). Он отражает период времени в течение календарного года, на протяжении которого будет использоваться авто.
            Минимальный период использования в договорах с физлицами составляет 3 месяца.</li>
          </ol>
        `,
      },
      {
        question: 'Какой полис ОСАГО стоит дешевле – электронный или оформленный у агента?',
        answer: `
          Агрегатор даст Вам возможность сравнить стоимость полиса в разных страховых компаниях. Ваше время - бесценно!
        `,
      },
      {
        question: 'Что я могу застраховать? На какой срок можно оформить ОСАГО?',
        answer: `
          Можно застраховать ТС категории «B» физическим лицам, которые зарегистрированы на территории РФ. Цель использования — личная езда.
          Максимальное количество водителей в договоре — 5. Можно выбрать функцию без ограничений. Срок страхования — 12 месяцев.
        `,
      },
      {
        question: 'Что будет, если я укажу в полисе е-ОСАГО недостоверные данные о&nbsp;себе или&nbsp;своем автомобиле?',
        answer: `
          В случае если указанные в электронном полисе данные не достоверны и привели к снижению стоимости полиса, то по Закону об ОСАГО вне зависимости
          от наступления страхового случая страховщик имеет полное право взыскать с недобросовестного страхователя «сэкономленную» им сумму.
          Кроме того, страховая компания имеет право предъявить к нему регрессное требование в размере произведенной страховой выплаты, если авария произошла по вине клиента.
        `,
      },
      {
        question: 'С какой даты можно оформить ОСАГО?',
        answer: `
          Каждая страховая компания устанавливает свой срок, с которого оформленный полис вступает в силу.
          <br>ПАО СК «Росгосстрах» — 4 дня от даты оплаты,
          <br>АО «Ренессанс Страхование» — на следующий день,
          <br>АО «Тинькофф Страхование» — на следующий день,
          <br>СПАО «Ингосстрах» — 4 дня от даты оплаты,
          <br>ООО СК «Согласие» — 4 дня от даты оплаты,
          <br>САО «ВСК» — 4 дня от даты оплаты,
          <br>АО «МАКС» — 3 дня от даты оплаты.
          <br>ООО «Зетта Страхование» — 4 дня от даты оплаты.
          <br>АО «СОГАЗ» — 4 дня от даты оплаты.
          <br>ПАО «САК «Энергогарант» — 4 дня от даты оплаты.
          <br><br>Рекомендуем указать дату начала действия полиса не менее 4-х календарных дней от текущей даты, чтобы получить возможность оформить полис от наибольшего количества страховых компаний.
        `,
      },
      {
        question: 'Как быстро приходит оформленный полис и куда?',
        answer: `
          Полис придет на почту, указанную при оформлении в течение 20 минут. Если по каким-то причинам Вы не обнаружили полис, напишите нам на
          <a href="mailto:support@insapp.ru"><strong>support&#64;insapp.ru</strong></a>.
        `,
      },
      {
        question: 'Как внести изменения в выпущенный полис?',
        answer: `
          Для всех страховых механизм внесения изменений разный. Самый действенный способ — дойти до офиса СК и внести все изменения. Если это невозможно, ниже представлен список доступных изменений в личном кабинете каждой СК.
          <br><br>Авторизация в личный кабинет клиента возможна только по данным, указанным во время оформления полиса!
          <br><br>ООО СК «Сбербанк страхование». Обо всех изменениях необходимо уведомить страховую компанию. Вы можете внести изменения через личный кабинет на сайте компании в разделе кабинет. Для этого необходимо войти в личный кабинет и оформить заявление на внесение изменений в электронном виде пошагово. Просим Вас приложить к заявлению также скан копии (или читаемые фото) документа (-ов) в подтверждение изменений. Срок внесения изменений составляет до 2 рабочих дней. Веб-сайт страховой компании: sberbankins.ru
          <br><br>АО «Тинькофф». Возможно внести любые изменения. Для этого страхователю необходимо позвонить на горячую линию страховой компании с номера, на который оформлен полис: 8 800 755-80-00.
          Веб-сайт страховой компании: tinkoffinsurance.ru
          <br><br>СПАО «Ингосстрах». Внести изменения можно на сайте страховой компании. Список доступных изменений: добавление водителя, изменить данные водителя, поменять гос. номер ТС, изменить данные ПТС/СТС.
          Веб-сайт страховой компании: ingos.ru
          <br><br>ООО «Ренессанс». Внести изменения возможно на сайте страховой компании. Для просмотра полного списка возможных изменений необходимо авторизоваться в личном кабинете страховой компании.
          Веб-сайт страховой компании: renins.ru
          <br><br>ООО «СК «Согласие». Внести изменения можно на сайте страховой компании. Список доступных изменений: внесение нового водительского удостоверения, изменение данных водителей, дополнение данных собственника, изменение гос. номера ТС.
          Веб-сайт страховой компании: lk.soglasie.ru
          <br><br>САО «ВСК» тел. 8 800 775-15-75, СК "Росгосстрах" тел. 8 800 200-0-900. Внесение изменений возможно только в офисе СК. Подробную информацию необходимо уточнить на сайте страховой компании.
          Веб-сайты страховых компаний: vsk.ru и rgs.ru
          <br><br>АО «МАКС». Внести изменения можно в офисе страховой компании, в личном кабинете на сайте компании, либо написать на почту: info@makc.ru
          Веб-сайт страховой компании: makc.ru
          <br><br>ООО «Зетта Страхование». Внести изменения можно в личном кабинете на сайте, а также в офисе компании. Телефон круглосуточного контакт-центра 8 800 700-77-07 (звонок по РФ бесплатный).
          Веб-сайт страховой компании: zettains.ru
          <br><br>АО «СОГАЗ». Внести изменения можно в личном кабинете на сайте страховой компании, в мобильном приложении или в офисе компании. Телефон круглосуточного контакт-центра: 8 800 333-0-888.
          Веб-сайт страховой компании: sogaz.ru
          <br><br>АО ГСК «Югория». Внести изменения можно в личном кабинете на сайте страховой компании или в офисе компании.
          Телефон контакт-центра: 8 800 100-82-00.
          Веб-сайт страховой компании: ugsk.ru
          <br><br>ПАО «САК «Энергогарант». Внести изменения можно в офисе компании. Телефон контакт-центра: 8 495 737-03-30.
          Веб-сайт страховой компании: energogarant.ru
        `,
      },
      {
        question: 'Как проверить подлинность электронного полиса ОСАГО?',
        answer: `
          Проверить действительность электронного полиса можете на сайте Российского Союза Автостраховщиков.
          <br><br>Существует два варианта:
          <ol>
            <li class="mb-2">По номеру полиса. Заходите в данный раздел сайта, укажите серию полиса, введите его номер. Результат проверки получаете мгновенно. Предоставляются данные о сроке действия договора, страховой компании, которая его заключила и статусе бланка. Если статус бланка - "находится у страхователя" и есть все данные о сроке действия, то все в порядке.</li>
            <li class="mb-2">По номеру автомобиля или VIN. Заходите в данный раздел сайта, выберите "реквизиты транспортного средства" и укажите регистрационный номер машины или ее VIN.</li>
          </ol>
        `,
      },
      {
        question: 'Какие документы нужны для оформления ОСАГО?',
        answer: `
          Документ на автомобиль (ПТС или свидетельство о регистрации) – потребуется указать реквизиты документа и характеристики транспортного средства. Паспорт собственника (или иной документ, удостоверяющий личность) – необходимо будет внести данные документа, а также сведения о собственнике авто (если страхователем выступает иное лицо, потребуется также и паспорт страхователя). Водительские удостоверения – необходимо указать реквизиты документов и сведения о водителях, которые будут допущены к управлению. Предыдущий (текущий) полис ОСАГО – это необязательно, но указание его данных может упростить оформление, а также положительно повлиять на количество и качество предложений.
        `,
      }
    ]
  },
  {
    category: 'Документы и оформление',
    questions: [
      {
        question: 'Как быстро приходит оформленный полис и куда?',
        answer: `
          Полис придет после оплаты на почту, указанную при оформлении, в течение 30 минут.
        `,
      },
      {
        question: 'Какой пакет документов нужен для покупки полиса е-ОСАГО онлайн?',
        answer: `
          Предоставлять документы не требуется.
          <br><br>Желающим оформить электронный полис ОСАГО на сайте потребуется ввести Государственный регистрационный знак, данные страхователя/собственника, данные водительского удостоверения при ограничении количества допущенных водителей.
        `,
      },
      {
        question: 'Как можно оплатить электронный полис ОСАГО?',
        answer: `
          Оплата электронного полиса ОСАГО осуществляется банковской картой при оформлении.
        `,
      },
      {
        question: 'Что будет, если я укажу в полисе е-ОСАГО недостоверные данные о своем автомобиле?',
        answer: `
          В случае если указанные в электронном полисе данные не достоверны и привели к снижению стоимости полиса, то по Закону об ОСАГО вне зависимости от наступления страхового случая страховщик имеет полное право взыскать с недобросовестного страхователя «сэкономленную» им сумму. Кроме того, страховая компания имеет право предъявить к нему регрессное требование в размере произведенной страховой выплаты, если авария произошла по вине клиента.
        `,
      },
      {
        question: 'Как внести изменения в выпущенный полис? Как расторгнуть полис?',
        answer: `
          Необходимо обратиться к Страховщику, с которым заключен договор ОСАГО.
          <br>Контакты Страховщиков:
          <br><br><strong>ООО СК «Сбербанк страхование».</strong> Веб-сайт страховой компании: <a href="https://sberbankins.ru/" target="_blank">https://sberbankins.ru/</a>
          <br><br><strong>АО «Альфастрахование».</strong> Веб-сайт страховой компании: <a href="https://www.alfastrah.ru/" target="_blank">https://www.alfastrah.ru/</a>
          <br><br><strong>АО «Группа Ренессанс Страхование».</strong> Веб-сайт страховой компании: <a href="https://www.renins.ru/" target="_blank">https://www.renins.ru/</a>
          <br><br><strong>ООО «СК «Согласие».</strong> Веб-сайт страховой компании: <a href="https://www.soglasie.ru/" target="_blank">https://lk.soglasie.ru/</a>
          <br><br><strong>САО «ВСК».</strong> Веб-сайт страховой компании: <a href="https://www.vsk.ru/" target="_blank">https://www.vsk.ru/</a>
          <br><br><strong>АО «МАКС».</strong> Веб-сайт страховой компании: <a href="https://www.makc.ru/" target="_blank">https://www.makc.ru/</a>
          <br><br><strong>ООО «Зетта Страхование».</strong> Веб-сайт страховой компании: <a href="https://www.zettains.ru/" target="_blank">https://www.zettains.ru/</a>
          <br><br><strong>ПАО СК «Росгосстрах».</strong> Веб-сайт страховой компании: <a href="https://www.rgs.ru/" target="_blank">https://www.rgs.ru/</a>
          <br><br><strong>АО «СОГАЗ».</strong> Веб-сайт страховой компании: <a href="https://www.sogaz.ru/" target="_blank">https://www.sogaz.ru/</a>
          <br><br><strong>АО «Тинькофф Страхование».</strong> Веб-сайт страховой компании: <a href="https://www.tinkoffinsurance.ru/" target="_blank">https://www.tinkoffinsurance.ru/</a>
          <br><br><strong>СПАО «Ингосстрах».</strong> Веб-сайт страховой компании: <a href="https://www.ingos.ru/" target="_blank">https://www.ingos.ru/</a>
          <br><br><strong>АО «ИНТАЧ СТРАХОВАНИЕ».</strong> Веб-сайт страховой компании: <a href="https://in-touch.ru/" target="_blank">https://www.in-touch.ru/</a>
        `,
      },
      {
        question: 'Как проверить подлинность электронного полиса ОСАГО?',
        answer: `
          Проверить действительность электронного полиса можете на сайте Российского Союза Автостраховщиков. Существует два варианта:
          <br><br>1. По номеру полиса. Заходите в данный <a href="#" target="_blank">раздел сайта</a>, укажите серию полиса, введите его номер. Результат проверки получаете мгновенно.
          Предоставляются данные о сроке действия договора, страховой компании, которая его заключила и статусе бланка. Если статус бланка — «находится у страхователя» и есть все данные о сроке действия, то все в порядке.
          <br><br>2. По номеру автомобиля или VIN. Заходите в данный <a href="#" target="_blank">раздел сайта</a>, выберите «реквизиты транспортного средства» и укажите регистрационный номер машины или ее VIN.
        `,
      },
      {
        question: 'Что делать если полис не пришёл на почту?',
        answer: `
          Полис направляется на почту, указанную при оформлении в течение 30 минут после оплаты.
          <br><br>Если вы не получили полис, напишите на: <a href="mailto:support@insapp.ru"><strong>support&#64;insapp.ru</strong></a>, сообщите указанные при оформлении договора:
          <ul>
            <li>мобильный телефон клиента;</li>
            <li>государственный регистрационный знак транспортного средства;</li>
            <li>email, на который необходимо направить договор.</li>
          </ul>
        `,
      },
      {
        question: 'На какой срок можно оформить полис?',
        answer: `
          Полис оформляется на срок 12 месяцев.
        `,
      },
      {
        question: 'Машину продали. Как вернуть стоимость ОСАГО?',
        answer: `
          Необходимо обратиться к Страховщику, с которым заключен договор ОСАГО.
          <br>Контакты Страховщиков:
          <br><br><strong>ООО СК «Сбербанк страхование».</strong> Веб-сайт страховой компании: <a href="https://sberbankins.ru/" target="_blank">https://sberbankins.ru/</a>
          <br><br><strong>АО «Альфастрахование».</strong> Веб-сайт страховой компании: <a href="https://www.alfastrah.ru/" target="_blank">https://www.alfastrah.ru/</a>
          <br><br><strong>АО «Группа Ренессанс Страхование».</strong> Веб-сайт страховой компании: <a href="https://www.renins.ru/" target="_blank">https://www.renins.ru/</a>
          <br><br><strong>ООО «СК «Согласие».</strong> Веб-сайт страховой компании: <a href="https://www.soglasie.ru/" target="_blank">https://lk.soglasie.ru/</a>
          <br><br><strong>САО «ВСК».</strong> Веб-сайт страховой компании: <a href="https://www.vsk.ru/" target="_blank">https://www.vsk.ru/</a>
          <br><br><strong>АО «МАКС».</strong> Веб-сайт страховой компании: <a href="https://www.makc.ru/" target="_blank">https://www.makc.ru/</a>
          <br><br><strong>ООО «Зетта Страхование».</strong> Веб-сайт страховой компании: <a href="https://www.zettains.ru/" target="_blank">https://www.zettains.ru/</a>
          <br><br><strong>ПАО СК «Росгосстрах».</strong> Веб-сайт страховой компании: <a href="https://www.rgs.ru/" target="_blank">https://www.rgs.ru/</a>
          <br><br><strong>АО «СОГАЗ».</strong> Веб-сайт страховой компании: <a href="https://www.sogaz.ru/" target="_blank">https://www.sogaz.ru/</a>
          <br><br><strong>АО «Тинькофф Страхование».</strong> Веб-сайт страховой компании: <a href="https://www.tinkoffinsurance.ru/" target="_blank">https://www.tinkoffinsurance.ru/</a>
          <br><br><strong>СПАО «Ингосстрах».</strong> Веб-сайт страховой компании: <a href="https://www.ingos.ru/" target="_blank">https://www.ingos.ru/</a>
          <br><br><strong>АО «ИНТАЧ СТРАХОВАНИЕ».</strong> Веб-сайт страховой компании: <a href="https://in-touch.ru/" target="_blank">https://www.in-touch.ru/</a>
        `,
      }
    ]
  }
];
