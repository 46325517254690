// Применяем данные водителя
import {FormGroup} from "@angular/forms";

export function setDriver(driverData: any, driverFormGroup: FormGroup): void {
  const {
    driverFirstName,
    driverLastName,
    oldDriverLastName,
    driverMiddleName,
    driverBirthDate,
    rememberOnlyYear,
    experienceDate,
    experienceYear
  } = (driverFormGroup)?.controls;

  driverFirstName.setValue(driverData.firstName, { emitEvent: false });
  driverLastName.setValue(driverData.lastName, { emitEvent: false });
  driverMiddleName.setValue(driverData.middleName!, { emitEvent: false });
  oldDriverLastName.setValue(driverData.oldLastName, { emitEvent: false });
  driverBirthDate.setValue(driverData.birthDate, { emitEvent: false });
  rememberOnlyYear.setValue(driverData.rememberOnlyYear, { emitEvent: false });

  if (driverData.rememberOnlyYear && driverData.rememberOnlyYear === true) {
    const experienceYearValue = driverData.experienceStartDate?.split('.')[2]!;
    experienceYear.setValue(experienceYearValue ? experienceYearValue : null, { emitEvent: false });
  } else {
    setTimeout(() => {
      experienceDate.setValue(driverData.experienceStartDate, { emitEvent: false });
    }, 10);
  }

  setDriverLicense(driverData.driverLicense, driverFormGroup);
  setOldDriverLicense(driverData.oldDriverLicense, driverFormGroup);

  // Применяем старые права если пришли с ссылки из почты
  if (driverData.driverLicense && driverData.driverLicense.oldLicenseNumber && driverData.driverLicense.oldLicenseSeries && driverData.driverLicense.lastNameInOldLicense) {
    setOldDriverLicenseFromEmail(driverData.driverLicense, driverFormGroup);
  }
}

// Применяем данные водительских прав
function setDriverLicense(driverLicenseData: any, driverFormGroup: FormGroup): void {
  const {
    driverLicense,
    experienceDate,
  } = driverFormGroup?.controls;

  driverLicense.setValue(driverLicenseData.licenseSeries + driverLicenseData.licenseNumber, { emitEvent: false });
  experienceDate.setValue(driverLicenseData.licenseIssueDate, { emitEvent: false });
}

// Применяем данные старых водительских прав
function setOldDriverLicense(driverLicenseData: any, driverFormGroup: FormGroup): void {
  const {
    licenseWasChanged,
    oldLicense,
    oldDriverLastName,
  } = driverFormGroup?.controls;

  if (driverLicenseData?.licenseSeries && driverLicenseData?.licenseNumber) {
    oldLicense.setValue(driverLicenseData?.licenseSeries + driverLicenseData?.licenseNumber, { emitEvent: false });
  }
  if (driverLicenseData?.lastNameInOldLicense) {
    oldDriverLastName.setValue(driverLicenseData?.lastNameInOldLicense, { emitEvent: false });
  }
  if (driverLicenseData?.licenseSeries || driverLicenseData?.licenseNumber || driverLicenseData?.lastNameInOldLicense) {
    licenseWasChanged.setValue(true);
  }
}

// Применяем данные старых водительских прав
function setOldDriverLicenseFromEmail(driverLicenseData: any, driverFormGroup: FormGroup): void {
  const {
    licenseWasChanged,
    oldLicense,
    oldDriverLastName,
  } = driverFormGroup?.controls;
  oldLicense.setValue(driverLicenseData?.oldLicenseSeries + driverLicenseData?.oldLicenseNumber, { emitEvent: false });
  oldDriverLastName.setValue(driverLicenseData?.lastNameInOldLicense, { emitEvent: false });
  licenseWasChanged.setValue(true);
}
