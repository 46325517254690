<h3 class="page-title mt-4 mb-4">Проверка данных</h3>

<app-policy-data [isCollapse]="false"></app-policy-data>

<div class="row actions justify-content-between my-4">
  <div class="col-12 col-sm-auto mb-3 mb-sm-0">
    @if (!osagoService.paymentLink || !isDataCheckingBeforePaymentSms) {
      <button (click)="navigateToOffersPage()" type="button"
              class="btn btn-outline-primary btn-add-driver btn-lg w-100">
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M19.6734 22.707C19.8825 22.5193 20 22.2647 20 21.9993C20 21.7339 19.8825 21.4794 19.6734 21.2917L14.5577 16.7016C14.3541 16.5113 14.2405 16.2577 14.2405 15.994C14.2405 15.7302 14.3541 15.4766 14.5577 15.2863L19.6734 10.6962C19.8766 10.5075 19.989 10.2546 19.9865 9.9922C19.9839 9.72977 19.8666 9.47873 19.6598 9.29316C19.453 9.10758 19.1732 9.00232 18.8807 9.00004C18.5882 8.99776 18.3065 9.09864 18.0961 9.28096L12.9804 13.8701C12.6696 14.1489 12.423 14.4799 12.2548 14.8443C12.0866 15.2086 12 15.5991 12 15.9935C12 16.3878 12.0866 16.7783 12.2548 17.1427C12.423 17.507 12.6696 17.838 12.9804 18.1169L18.0961 22.707C18.3053 22.8946 18.5889 23 18.8847 23C19.1805 23 19.4642 22.8946 19.6734 22.707Z"/>
        </svg>
        Выбрать компанию
      </button>
    }
  </div>
  <div class="col-12 col-sm-auto">
    <button (click)="navigateToCreatePolicy()" type="submit" class="btn btn-primary btn-lg w-100">
      <span>Все верно</span>
    </button>
  </div>
</div>

