//textmask mask fixer - value contains mask and incorrect date
export class TextMaskHelper {
    static adjustCaretPosition(fieldId: any, position: any) {
        const elem = document.getElementById(fieldId);
        if (elem == null)
            return;

        const currentPosition = TextMaskHelper.getCaretPosition(elem);
        if (position != null && currentPosition != null && !(currentPosition > position))
            TextMaskHelper.setCaretPosition(elem, position);
    }

    static getCaretPosition(elem: any) {
        return elem.selectionStart || elem.selectionStart == 0
            ? elem.selectionStart
            : null;
    }

    static setCaretPosition(elem: any, position: number) {
        if (elem.createTextRange) {
            var range = elem.createTextRange();
            range.move('character', position);
            range.select();
        } else if (elem.selectionStart)
            elem.setSelectionRange(position, position);
    }

    static getDate(maskedValue: string): string | null {
        if (maskedValue == null)
            return null;

        const isDash = maskedValue?.toString().indexOf("-") != -1;
        let value = maskedValue?.toString().replace(/[^0-9]/g, '');
        if (value.length == 9)
            value = value.substr(0, 8);

        if (value.length != 8)
            return null;

        return isDash
            ? value.substr(6, 2) + '.' +  value.substr(4, 2) + '.' + value.substr(0, 4)
            : value.substr(0, 2) + '.' +  value.substr(2, 2) + '.' + value.substr(4);
    }

    static getYear(maskedValue: string): string | null {
        if (maskedValue == null) {
            return '';
        }

        let value = maskedValue?.replace(/[^0-9]/g, '');

        if (value.length === 5) {
            value = value.substr(0, 4);
        }

        if (value.length !== 4) {
            return null;
        }

        return value;
    }

    static driverLicense(maskedValue: string): string | null {
        let value = maskedValue?.replace(/\_/g, '').replace(/\s/g, '')

        if (value.length == 11)
            value = value.substr(0, 10);

        if (value.length != 10)
            return null;

        return value;
    }

    static removeMask(maskedValue: string, maxLength: number | null = null): string {
        if (maskedValue != null && typeof maskedValue === "number")
            return maskedValue;

        let value = maskedValue != null
            ? maskedValue?.replace(/\+| |\-|\(|\)/gi, '').replace(/\_/g, '')
            : null;

        return (value != null && maxLength != null && maxLength < value.length)
            ? value.substr(0, maxLength)
            : value || '';
    }

    static translateChar(char: string) {
        switch (char) {
            case '0':
            case '1':
            case '2':
            case '3':
            case '4':
            case '5':
            case '6':
            case '7':
            case '8':
            case '9':
            case '_':
                return char;
            case 'а':
            case 'А':
            case 'f':
            case 'F':
                return 'А';
            case 'в':
            case 'В':
            case 'd':
            case 'D':
                return 'В';
            case 'е':
            case 'Е':
            case 't':
            case 'T':
                return 'Е';
            case 'к':
            case 'К':
            case 'r':
            case 'R':
                return 'К';
            case 'м':
            case 'М':
            case 'v':
            case 'V':
                return 'М';
            case 'н':
            case 'Н':
            case 'y':
            case 'Y':
                return 'Н';
            case 'о':
            case 'О':
            case 'j':
            case 'J':
                return 'О';
            case 'р':
            case 'Р':
            case 'h':
            case 'H':
                return 'Р';
            case 'с':
            case 'С':
            case 'c':
            case 'C':
                return 'С';
            case 'т':
            case 'Т':
            case 'n':
            case 'N':
                return 'Т';
            case 'у':
            case 'У':
            case 'e':
            case 'E':
                return 'У';
            case '{':
            case '[':
            case 'х':
            case 'Х':
                return 'Х';
        };

        return null;
    }

    static unmask(maskedValue: string | null, maxLength: number | null = null): string | null {
        if (maskedValue == null)
            return null;

        if (maskedValue.indexOf('_') != -1 && maskedValue.indexOf('_') < (maskedValue.length - 1))
            return null;

        let value = maskedValue != null
            ? maskedValue?.replace(/\+| |\-|\(|\)/gi, '').replace(/\_/g, '')
            : null;

        return (value != null && maxLength != null && maxLength < value.length)
            ? value.substr(0, maxLength)
            : value;
    }

    static toPtsFormat(value: string | null): string | null {
        if (value == null || value.length != 10)
            return null;

        return value.substr(0, 2) + " " + value.substr(2, 2) + " " + value.substr(4, 3) + " " + value.substr(7);
    }

    static toVinFormat(value: string | null): string | null {
        if (value == null || value.length != 17)
            return null;

        return value.substr(0, 3) + " " + value.substr(3, 6) + " " + value.substr(9);
    }
}
