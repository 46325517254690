import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormService} from "../../../shared/services/form.service";
import {AppService} from "../../../shared/services/app.service";
import {Subscription} from "rxjs";
import {FormControl, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import moment from "moment-mini";
import {OsagoService} from "../../../shared/services/osago.service";
import {NgOptimizedImage} from "@angular/common";
import {FormPolicyAlertComponent} from "./form-policy-alert/form-policy-alert.component";
import {FormDateFieldComponent} from "../../../shared/components/form-date-field/form-date-field.component";
import {CollapseModule} from "ngx-bootstrap/collapse";

@Component({
  selector: 'app-form-policy-date',
  standalone: true,
  templateUrl: './form-policy-date.component.html',
  imports: [
    NgOptimizedImage,
    ReactiveFormsModule,
    FormPolicyAlertComponent,
    FormDateFieldComponent,
    CollapseModule
  ],
  styleUrls: ['./form-policy-date.component.scss']
})
export class FormPolicyDateComponent implements OnInit {
  @Output() changePolicyStartDate: EventEmitter<string> = new EventEmitter<string>();
  constructor(public readonly formService: FormService,
              public readonly osagoService: OsagoService,
              public readonly appService: AppService) {
    const policyStartDateControl = this.formService.form.get('policyStartDate');
    const policyStartDate = policyStartDateControl?.value;
    if (!policyStartDateControl?.value) {
      this.initPolicyDate();
    } else {
      // Триггер для нового поиска (пееписать, вынести в отдельную функцию)
      this.formService.form.get('policyStartDate')?.setValue(policyStartDate);
      this.form.get('policyStartDate')?.setValue(policyStartDate);
    }
  }

  // Подписка
  private subscription: Subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;

  // Форма
  public form: FormGroup = new FormGroup({
    policyStartDate: new FormControl(null, Validators.required)
  })

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit() {
    this.subscription.add(
      this.formService.form.get('policyStartDate')?.valueChanges.subscribe((value) => {
        this.form.get('policyStartDate')?.setValue(value, {emitEvent: false});
      })
    );
  }

  // --------------------------------------------------------------------------

  // Инициализируем дату начала действия полиса
  private initPolicyDate(): void {
    // Если текущая дата начала действия полиса раньше текущей даты или равна,
    // то увеличиваем дату начала действия полиса на 4 дня
    // Можно выбрать дату не раньше текущей +1 день
    const policyStartDateControl = this.formService.form.get('policyStartDate');
    const policyStartDate = policyStartDateControl?.value;

    const currentDay = moment().startOf('day'); // Текущий день без времени
    const policyStartDay = moment(policyStartDate ? policyStartDate : new Date()).startOf('day'); // День начала полиса без времени

    // Сравниваем, если дата начала полиса раньше или равна текущему дню
    if (policyStartDay.isSameOrBefore(currentDay)) {
      // Увеличиваем дату начала полиса на 4 дня
      const newPolicyStartDate = policyStartDay.add(4, 'days').format('DD.MM.YYYY');
      this.formService.form.get('policyStartDate')?.setValue(newPolicyStartDate);
      this.form.get('policyStartDate')?.setValue(newPolicyStartDate);
    }
  }

  // Сохраняем дату начла действия полиса
  public savePolicyStartDate(): void {
    this.form.markAsTouched();
    if (this.form.invalid) return;
    this.osagoService.isShowFormStartDatePolicy = !this.osagoService.isShowFormStartDatePolicy;
    const policyStartDate = this.form.get('policyStartDate')?.value;
    this.formService.form.get('policyStartDate')?.setValue(policyStartDate);
    this.changePolicyStartDate.emit(policyStartDate);
  }

}
