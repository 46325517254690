import {Component, Input, OnDestroy} from '@angular/core';
import {FormService} from "../../services/form.service";
import {AppService} from "../../services/app.service";
import {OsagoService} from "../../services/osago.service";
import {NgIf, NgOptimizedImage} from "@angular/common";
import {EditInfoComponent} from "./edit-info/edit-info.component";
import {CollapseModule} from "ngx-bootstrap/collapse";

@Component({
  selector: 'app-policy-data',
  standalone: true,
  templateUrl: './policy-data.component.html',
  imports: [
    NgIf,
    EditInfoComponent,
    CollapseModule,
    NgOptimizedImage,
  ],
  styleUrls: ['./policy-data.component.scss']
})
export class PolicyDataComponent implements OnDestroy {

  @Input() isCollapse = false;

  constructor(public readonly formService: FormService,
              public readonly appService: AppService,
              public readonly osagoService: OsagoService) {
  }
  // Индикатор загрузки
  public isLoading!: boolean;

  // --------------------------------------------------------------------------
  // Инициализация

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.osagoService.isShowEditInfo) {
      this.osagoService.isShowEditInfo = false;
    }
  }
  // --------------------------------------------------------------------------
}
