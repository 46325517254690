import {
  Component, EventEmitter, forwardRef,
  Inject,
  Injector,
  Input,
  OnDestroy,
  OnInit, Output,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControlDirective,
  FormControlName,
  FormGroupDirective, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR,
  NgControl,
  UntypedFormControl
} from "@angular/forms";
import {Subscription} from "rxjs";
import {FormService} from "../../services/form.service";
import {ValidationService} from "../../services/validation.service";

@Component({
  selector: 'app-form-switch',
  standalone: true,
  templateUrl: './form-switch.component.html',
  styleUrls: ['./form-switch.component.scss'],
  imports: [
    FormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FormSwitchComponent),
      multi: true,
    }
  ]
})
export class FormSwitchComponent implements ControlValueAccessor, OnInit, OnDestroy {
  // Событие выбора элемента
  @Output() switchOnSelect = new EventEmitter<any>();
  // Заголовок
  @Input() label!: string;
  // Показываем календарь
  @Input() isShowCalendar: boolean = false;
  // Только для чтения
  @Input() isReadonly!: boolean;

  // Контрол изменяемого input
  public inputModel!: any;
  // Контрол
  public control!: UntypedFormControl;
  // Имя контрола
  public name!: string;
  // Подписка на контрол
  private subscription: Subscription = new Subscription();
  // Сообщения валидации
  public messages: { [key: string]: any } = {};
  // Флаг, идет загрузка списка
  public isLoading = false;

  // Вызовем когда значение изменится
  private onTouched: any = () => {
  };

  // Вызовем при любом действии пользователя с контроллом
  private onChange: any = () => {
  };

  constructor(@Inject(Injector) private injector: Injector,
              public readonly formService: FormService,
              private readonly validationService: ValidationService) {
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    const injectedControl = this.injector.get(NgControl);
    this.name = injectedControl.name + 'controlName'  + Math.random().toString(36).substr(2, 9);

    switch (injectedControl.constructor) {
      case FormControlName: {
        this.control = this.injector.get(FormGroupDirective).getControl(injectedControl as FormControlName);
        break;
      }
      default: {
        this.control = (injectedControl as FormControlDirective).form as UntypedFormControl;
        break;
      }
    }

    // Применяем параметры контрола
    if (injectedControl.name) {
      this.initPropertyControl(injectedControl.name.toString());
    }

  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  // --------------------------------------------------------------------------

  // Событие изенения контрола
  public onModelChange(value: boolean): void {
    // Действия, выполняемые при изменении значения чекбокса
    this.onChange(value);
    this.switchOnSelect.emit(value);
    this.onTouched();
  }

  // Применяем параметры контролла
  public initPropertyControl(injectedControlName: string): void {
    const propertyControl = this.formService.propertyControls[injectedControlName];
    if (propertyControl?.validation?.messages) {
      this.messages = propertyControl?.validation?.messages;
    }
    if (propertyControl?.label) {
      this.label = propertyControl.label;
    }

    this.validationService.setControlValidation(propertyControl, this.control);

    return;
  }

  // Вызовет форма, если значение изменилось извне
  public writeValue(value: any): void {
    this.inputModel = value;
  }


  // Сохраняем обратный вызов для изменений
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  // Сохраняем обратный вызов для "касаний"
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // Установка состояния disabled
  public setDisabledState(isDisabled: boolean): void {
    this.isReadonly = isDisabled;
  }

}
