import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, concatMap, debounce, delay, EMPTY, interval, Observable, of, tap} from 'rxjs';
import {catchError, map, take} from 'rxjs/operators';
import {HttpClient} from '@angular/common/http';
import {v4 as uuidv4} from 'uuid';

// Сервисы
import {FormService} from './form.service';
import {HttpService} from './http.service';
import {SettingsService} from './settings.service';

// Интерфейсы
import {IUrlParams} from '../interface/url-params.interface';
import {IApplicationRequest, IApplicationResponse} from "../interface/application.interface";
import {environment} from '@environment/environment';
import {ICarBrand} from "../interface/car-brands.interface";
import {ICarModel} from "../interface/car-models.interface";
import {
  IApplicationCarData,
  ICarIdentificatorsSelectList, IPolicySeries, IPurposeOfUse,
} from "../interface/osago-application.interface";
import {IDocumentTypes} from "../interface/document-types.interface";
import {DOCUMENT_TYPES} from "../constants/document-types";
import {CAR_IDENTIFICATORS} from "../constants/car-identificators";
import {PURPOSE_OF_USE, PURPOSE_OF_USE_INDIVIDUAL} from "../constants/purpose-of-use";
import {CacheService} from "./cache.service";
import {NavigationService} from "./navigation.service";
import moment from "moment-mini";
import {POLICY_SERIES} from "../constants/policy-series";
import {YandexMetrikaGoalsEnum} from "../enums/yandex-metrika-goals.enum";
import {YandexMetrikaService} from "./yandex-metrika.service";
import {LoggingService} from "./loggingService";
import { validate as uuidValidate, version as uuidVersion } from 'uuid';
import {getUtmData} from "../functions/utmData";
import {getPlatformId} from "../functions/platform";
import {getWebMasterId} from "../functions/webMaster";

@Injectable({
  providedIn: 'root'
})
export class AppService extends HttpService {
  private ym = inject(YandexMetrikaService);
  private loggingService = inject(LoggingService);
  private settingsService = inject(SettingsService);
  private formService = inject(FormService);
  private cacheService = inject(CacheService);
  private navigationService = inject(NavigationService);
  protected override http = inject(HttpClient);

  constructor() {
    super();
    this.checkIsArmApplication();
  }

  // тип заявки - новая\пролонгация etc
  private applicationType = 'New';
  // Анкета открыта из арма
  private _isArmApplication = false;
  // Анкета из арма
  private _armApplication: BehaviorSubject<any | null> = new BehaviorSubject<any | null>(null);
  armApplication$ = this._armApplication.asObservable();
  // Парметр src из ссылки, например OffersRecivedEmail
  public srcUrlParam: string = '';
  // UID заявки
  public applicationId!: string;
  // UID клиента
  public clientId!: string;
  // Массив данных авто брендов
  public brandsList: ICarBrand[] = [];
  // Массив данных авто брендов
  public modelsList: ICarModel[] = [];
  // Массив данных авто брендов
  public originalModelsList: ICarModel[] = [];
  // Данные списка типов документа
  public documentTypes: IDocumentTypes[] = DOCUMENT_TYPES;
  // Данные списка типов документа
  public carIdentificators: ICarIdentificatorsSelectList[] = CAR_IDENTIFICATORS;
  // Данные серий полисов
  public policySeries: IPolicySeries[] = POLICY_SERIES;
  // Данные списка типов документа
  public purposeOfUseList: IPurposeOfUse[] = PURPOSE_OF_USE;
  // Данные списка типов документа для физ лиц
  public purposeOfUseListIndividual: IPurposeOfUse[] = PURPOSE_OF_USE_INDIVIDUAL;
  // Статус заявки
  public setWidgetDisplayedStatus = false;
  // АБ тест
  public alfaIdABTestingGroup!: number;
  // Агент айди, от этого параметра зависит, будет ли работать выбор оффера через отправку смс
  public agentId = '';
  // Показывать кнопку AlfaID
  public isShowBtnAlfaId = false;

  public checkIsArmApplication() {
    this._isArmApplication = this.isOpenFromArm();

    if (this._isArmApplication) {
      window.addEventListener('message', (ev: MessageEvent) => {
        if (ev.data && ev.data.type && ev.data.type === 'OsagoApplication') {
          console.log("AppService::checkIsArmApplication::windowEvent");
          this.settingsService.apiKey = ev.data.apikey;
          this.applicationType = ev.data.applicationType;
          this._armApplication.next(ev.data.application);
          this._armApplication.complete();
        }
      });

      window.opener.postMessage({type: 'OsagoApplication'}, '*');
    }
  }

  public get armApplication() {
    return this._armApplication.value;
  }

  private isOpenFromArm(): boolean {
    try {
      if (document.referrer == null || document.referrer == '') {
        return false;
      }

      var url = new URL(document.referrer);
      return url.hostname.toLowerCase() == environment.armHost;
    } catch (e) {
      return false;
    }
  }

  get isArmApplication(): boolean {
    return this._isArmApplication;
  }

  // --------------------------------------------------------------------------
  // Запросы вызываются при инициализации, старт запросов в OnInit app.component
  public loadApp(): Observable<any> {
    const urlParams = this.getUrlParams();

    // Добавляем sessionId
    this.settingsService.sessionId = uuidv4();

    // Сначала смотрим какие у нас данные есть, проверяем url, localStorage
    return this.getClientId()
      .pipe(
        map((clientId) => this.setClientId(clientId)),
        concatMap(() => (urlParams.agentId || urlParams.src === 'DataCheckingBeforePaymentSms') ? this.setAppIdFromUrl(urlParams.applicationId!) : this.createApplication()),
        concatMap(() => this.getCarBrands()),
        concatMap(() => this.getOsagoCacheByClientId()),
        concatMap(() => {
          // если догонялка, пролонгация и т.д.
          if (urlParams !== null
            && ((urlParams.src || urlParams.agentId)
            && (urlParams.offerId
                && urlParams.offerId !== '0')
                || urlParams.alfaOfferId)) {
            return this.cacheService.getOsagoCacheByApplicationId(urlParams, urlParams.alfaOfferId && this.applicationId)
              .pipe(
                tap((res) => {

                }),
              concatMap(() => this.getCarModels()),
            );
          } else {
            return this.getClientId();
          }
        }),
        take(1)
      );
  }

  private setAppIdFromUrl(applicationId: string): Observable<null> {
    this.formService.form.get('applicationId')?.setValue(applicationId);
    return of(null);
  }

  private getOsagoCacheByClientId(): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey,
      clientId: this.settingsService.clientId
    }
    return this.http.post(environment.apiUrl + 'client/GetCache', request)?.pipe(
      map((res: any) => {

        // res = {
        //   "value": {
        //     "applications": [
        //       {
        //         "applicationId": "08dcd70b-44b0-93d1-bee7-4311d403fdf1",
        //         "license": "Т031ВУ799"
        //       }
        //     ],
        //     "osagoPolicies": [
        //       {
        //         "license": "Т031ВУ799",
        //         "osago": {
        //           "apiKey": "b90352835097480fa062713bf706f6b6",
        //           "applicationId": "08dcd70b-44b0-93d1-bee7-4311d403fdf1",
        //           "carData": {
        //             "licensePlate": "Т031ВУ799",
        //             "carPower": "250",
        //             "diagnosticCard": {
        //               "hasNoCard": false
        //             },
        //             "brandId": 108,
        //             "brandName": "Kia",
        //             "modelId": 1681,
        //             "modelName": "Mohave",
        //             "productionYear": "2017",
        //             "purposeOfUseType": "Personal",
        //             "hasNoLicensePlate": false,
        //             "hasTrailer": false,
        //             "carDocument": {
        //               "documentType": "STS",
        //               "documentSeries": "7758",
        //               "documentNumber": "714802",
        //               "documentIssueDate": "24.03.2018"
        //             },
        //             "carIdentificators": {
        //               "vin": "XWEKN814DJ0004451"
        //             }
        //           },
        //           "carCharacteristicsRequestId": "08dcd70b-49d9-b943-bee7-4311d403fe63",
        //           "hasDriversRestriction": true,
        //           "drivers": [
        //             {
        //               "firstName": "Иван",
        //               "lastName": "Иванов",
        //               "middleName": "Иванович",
        //               "birthDate": "10.10.1990",
        //               "driverExperience": 13,
        //               "driverAge": 33,
        //               "driverLicense": {
        //                 "isForeignLicense": false,
        //                 "licenseIssueDate": "10.10.2010",
        //                 "licenseNumber": "231231",
        //                 "licenseSeries": "1231"
        //               },
        //               "gender": "Male",
        //               "rememberOnlyYear": false,
        //               "experienceStartDate": "10.10.2010"
        //             }
        //           ],
        //           "owner": {
        //             "email": null,
        //             "isJuridical": false,
        //             "ownerIsInsured": false,
        //             "firstName": null,
        //             "lastName": null,
        //             "middleName": null,
        //             "birthDate": null,
        //             "passport": {
        //               "passportSeries": null,
        //               "passportNumber": null,
        //               "passportIssueDate": null,
        //               "registrationAddressData": {
        //                 "addressAsString": "undefined",
        //                 "daDataAddress": null,
        //                 "hasNoApartment": false
        //               }
        //             }
        //           },
        //           "insured": {
        //             "email": null,
        //             "firstName": "Иван",
        //             "lastName": "Иванов",
        //             "middleName": "Иванович",
        //             "birthDate": "10.10.1990",
        //             "passport": {
        //               "passportSeries": "1231",
        //               "passportNumber": "231231",
        //               "passportIssueDate": "10.10.2010",
        //               "registrationAddressData": {
        //                 "addressAsString": "г Москва, ул 1-я Белогорская, д 2",
        //                 "daDataAddress": {
        //                   "value": "г Москва, ул 1-я Белогорская, д 2",
        //                   "postal_code": "117623",
        //                   "country": "Россия",
        //                   "country_iso_code": "RU",
        //                   "federal_district": "Центральный",
        //                   "region_fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
        //                   "region_kladr_id": "7700000000000",
        //                   "region_iso_code": "RU-MOW",
        //                   "region_with_type": "г Москва",
        //                   "region_type": "г",
        //                   "region_type_full": "город",
        //                   "region": "Москва",
        //                   "area_fias_id": null,
        //                   "area_kladr_id": null,
        //                   "area_with_type": null,
        //                   "area_type": null,
        //                   "area_type_full": null,
        //                   "area": null,
        //                   "city_fias_id": "0c5b2444-70a0-4932-980c-b4dc0d3f02b5",
        //                   "city_kladr_id": "7700000000000",
        //                   "city_with_type": "г Москва",
        //                   "city_type": "г",
        //                   "city_type_full": "город",
        //                   "city": "Москва",
        //                   "city_area": "Юго-западный",
        //                   "city_district_fias_id": null,
        //                   "city_district_kladr_id": null,
        //                   "city_district_with_type": null,
        //                   "city_district_type": null,
        //                   "city_district_type_full": null,
        //                   "city_district": null,
        //                   "settlement_fias_id": null,
        //                   "settlement_kladr_id": null,
        //                   "settlement_with_type": null,
        //                   "settlement_type": null,
        //                   "settlement_type_full": null,
        //                   "settlement": null,
        //                   "street_fias_id": "caae4da5-e4c2-49ec-837c-79c14382d270",
        //                   "street_kladr_id": "77000000000083100",
        //                   "street_with_type": "ул 1-я Белогорская",
        //                   "street_type": "ул",
        //                   "street_type_full": "улица",
        //                   "street": "1-я Белогорская",
        //                   "stead_fias_id": null,
        //                   "stead_cadnum": null,
        //                   "stead_type": null,
        //                   "stead_type_full": null,
        //                   "stead": null,
        //                   "house_fias_id": null,
        //                   "house_kladr_id": null,
        //                   "house_cadnum": null,
        //                   "house_flat_count": "0",
        //                   "house_type": "д",
        //                   "house_type_full": "дом",
        //                   "house": "2",
        //                   "block_type": null,
        //                   "block_type_full": null,
        //                   "block": null,
        //                   "entrance": null,
        //                   "floor": null,
        //                   "flat_fias_id": null,
        //                   "flat_cadnum": null,
        //                   "flat_type": null,
        //                   "flat_type_full": null,
        //                   "flat": null,
        //                   "flat_area": null,
        //                   "square_meter_price": null,
        //                   "flat_price": null,
        //                   "room_fias_id": null,
        //                   "room_cadnum": null,
        //                   "room_type": null,
        //                   "room_type_full": null,
        //                   "room": null,
        //                   "postal_box": null,
        //                   "fias_id": "caae4da5-e4c2-49ec-837c-79c14382d270",
        //                   "fias_code": null,
        //                   "fias_level": "7",
        //                   "fias_actuality_state": "0",
        //                   "kladr_id": "77000000000083100",
        //                   "geoname_id": "524901",
        //                   "capital_marker": "0",
        //                   "okato": "45293594000",
        //                   "oktmo": "45909000",
        //                   "tax_office": "7727",
        //                   "tax_office_legal": "7727",
        //                   "timezone": "UTC+3",
        //                   "geo_lat": "55.55149",
        //                   "geo_lon": "37.58544",
        //                   "beltway_hit": "OUT_MKAD",
        //                   "beltway_distance": "3",
        //                   "metro": null,
        //                   "divisions": null,
        //                   "qc_geo": "0",
        //                   "qc_complete": null,
        //                   "qc_house": null,
        //                   "history_values": null,
        //                   "unparsed_parts": null,
        //                   "source": null,
        //                   "qc": null
        //                 },
        //                 "hasNoApartment": false
        //               }
        //             }
        //           },
        //           "policyParameters": {
        //             "policyStartDate": "21.09.2024",
        //             "osagoUtilisationPeriod": 12
        //           }
        //         }
        //       }
        //     ],
        //     "kaskoPolicies": []
        //   },
        //   "result": true,
        //   "error": null
        // }

        if (res.value && res.value.osagoPolicies) {
          this.cacheService.cacheData.osagoPolicies = res.value.osagoPolicies;
        }
      }),
      catchError(error => {
        this.loggingService.trace('Ошиба запроса client/GetCache', error);
        this.loggingService.error('Ошиба запроса client/GetCache');
        return of(null);
      })
    );
  }

  // Получаем данные из параметров url
  public getUrlParams(): IUrlParams {
    // Это сделано из-за проблемы параметра, который вставляет альфа через знак вопроса к нашим параметрам
    const baseUrl = window.location.href.replace('%3F', '&');
    // Создаем ссылку
    const url: URL = new URL(baseUrl);
    // Получаем параметры
    const params: URLSearchParams = url.searchParams;
    // Получаем параметры из url
    const urlParams: IUrlParams = {};

    // Преобразуем все ключи параметров в нижний регистр
    const lowerCaseParams: Record<string, string | null> = {};
    params.forEach((value, key) => {
      lowerCaseParams[key.toLowerCase()] = value;
    });

    // Если есть offersRecivedEmail в параметрах url, то используем его
    const offersRecivedEmail: string | null = lowerCaseParams['offersrecivedemail'];
    if (offersRecivedEmail) {
      urlParams.offersRecivedEmail = offersRecivedEmail;
    }

    // Если есть offerId в параметрах url, то используем его
    const offerId: string | null = lowerCaseParams['offerid'];
    if (offerId) {
      urlParams.offerId = offerId;
    }

    // Если есть webMasterID в параметрах url, то используем его
    const webMasterID: string | null = lowerCaseParams['webmasterid'];
    if (webMasterID) {
      urlParams.WebMasterID = webMasterID;
    }

    // Если есть platformId в параметрах url, то используем его
    const platformID: string | null = lowerCaseParams['platformid'];
    if (platformID) {
      urlParams.PlatformID = platformID;
    }

    // Если есть cpaClientUid в параметрах url, то используем его
    const cpaClientUid: string | null = lowerCaseParams['cpaclientuid'];
    if (cpaClientUid) {
      urlParams.CpaClientUid = cpaClientUid;
      this.formService.form.get('cpaClientId')?.setValue(cpaClientUid);
    }

    // Если есть cpaClientUid в параметрах url, то используем его
    const cpaClientId: string | null = lowerCaseParams['cpaclientid'];
    if (cpaClientId) {
      urlParams.CpaClientId = cpaClientId;
    }

    // Если есть partnerApplicationId в параметрах url, то используем его
    const alfaOfferId: string | null = lowerCaseParams['alfaofferid'];
    if (alfaOfferId) {
      urlParams.alfaOfferId = alfaOfferId;
    }

    // Если есть cpaClientUid2 в параметрах url, то используем его
    const cpaClientUid2: string | null = lowerCaseParams['cpaclientuid2'];
    if (cpaClientUid2) {
      urlParams.CpaClientUid2 = cpaClientUid2;
    }

    // Если есть loyalty в параметрах url, то используем его
    const loyalty: string | null = lowerCaseParams['loyalty'];
    if (loyalty) {
      urlParams.loyalty = loyalty;
      // localStorage.setItem('loyaltyAlfaId', loyalty);
    }
    const loyaltyFromLocalStorage: string | null = localStorage.getItem('loyaltyAlfaId');
    if (loyaltyFromLocalStorage) {
      // urlParams.loyalty = loyaltyFromLocalStorage;
    }

    // Если мы пришли из осаго
    const fromOsago = lowerCaseParams['fromosago'];
    if (fromOsago !== null) {
      urlParams.fromOsago = true;
    }

    // Если мы хотим обойти отправку sms кода
    const skipAuth = lowerCaseParams['skipauth'];
    if (skipAuth !== null) {
      urlParams.SkipAuth = true;
    }

    // Если мы пришли из осаго
    const src = lowerCaseParams['src'];
    if (src !== null) {
      urlParams.src = src;
    }

    // Если есть apiKey в параметрах url, то используем его
    const apiKey: string | null = lowerCaseParams['apikey'];
    if (apiKey) {
      this.settingsService.apiKey = apiKey;
      urlParams.apiKey = apiKey;
    }

    // Если есть applicationId в параметрах url, то используем его
    const applicationId: string | null = lowerCaseParams['applicationid'];
    if (applicationId) {
      urlParams.applicationId = applicationId;
    }

    // Если есть clientId в параметрах url, то используем его
    const clientId: string | null = lowerCaseParams['clientid'];
    if (clientId) {
      urlParams.clientId = clientId;
    }

    // Если есть agentid в параметрах url, то используем его
    const agentId: string | null = lowerCaseParams['agentid'];
    if (agentId !== null) {
      urlParams.agentId = agentId;
      this.agentId = agentId;
    }

    // Если есть clientId в параметрах url, то используем его
    const prolongation: string | null = lowerCaseParams['prolongation'];
    if (prolongation !== null) {
      urlParams.isProlongation = prolongation === 'true';
    }

    // Если есть code в параметрах url, то используем его
    const code: string | null = lowerCaseParams['code'];
    if (code !== null) {
      urlParams.code = code;
    }

    // Если есть utm_source в параметрах url, то используем его
    const utmSource: string | null = lowerCaseParams['utm_source'];
    if (utmSource !== null) {
      urlParams.utmSource = utmSource;
    }

    // Если есть utm_source в параметрах url, то используем его
    const utmMedium: string | null = lowerCaseParams['utm_medium'];
    if (utmMedium !== null) {
      urlParams.utmMedium = utmMedium;
    }

    // Если есть utm_campaign в параметрах url, то используем его
    const utmCampaign: string | null = lowerCaseParams['utm_campaign'];
    if (utmCampaign !== null) {
      urlParams.utmCampaign = utmCampaign;
    }

    // Если есть Osago2Clicks в параметрах url, то используем его
    const osago2Clicks: string | null = lowerCaseParams['Osago2Clicks'];
    if (osago2Clicks !== null) {
      urlParams.osago2Clicks = osago2Clicks;
    }

    return urlParams;
  }

  // Создаем новую заявку
  public createApplication(productType: any = 'Osago'): Observable<IApplicationResponse | null> {
    const params = this.getUrlParams();
    const host = window.location.origin;
    const {value} = this.formService.form;
    const request: IApplicationRequest = {
      apiKey: this.settingsService.apiKey,
      productType,
      successPaymentUrl: host + '/success',
      source: this.srcUrlParam || undefined,
      clientId: this.settingsService.clientId!,
      channelType: "Widget",
      cpaClientUid: params.CpaClientUid || value?.cpaClientUid || params?.loyalty,
      cpaClientUid2: params.CpaClientUid2,
      platformID: getPlatformId(params.PlatformID!),
      agentId: params.agentId,
      localTime: moment().format('YYYY-MM-DDTHH:mm:ss.SSS'),
      prolongation: params.isProlongation || undefined,
      returnClientChannelType: params.src || undefined,
      webMasterId: getWebMasterId(params.WebMasterID!),
      utm: getUtmData(params),
      parentApplicationId: params.src === 'Osago2Clicks' ? params.applicationId : undefined
    }
    return this.post(environment.apiUrl + 'app/new', request)
      .pipe(
        map((res) => {
          if (res.value && res.value?.abTestingGroups && res.value?.abTestingGroups.alfaIdABTestingGroup) {
            this.alfaIdABTestingGroup = res.value?.abTestingGroups.alfaIdABTestingGroup;
          }
          if (res && res.result && res.value) {
            this.applicationId = res.value.applicationId;
            this.changeApplicationId(res.value);
          }

          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.NewResultError);
          }

          return res.value
        }),
        concatMap(() => this.setWidgetDisplayed(params)),
        map((res) => {
          this.setWidgetDisplayedStatus = true;
          return res;
        }),
        catchError(error => {
          this.loggingService.error('Ошиба запроса app/new');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorAppNew);
          return of(null);
        })
      );
  }

  setWidgetDisplayed(urlParams: IUrlParams): Observable<any> {
    const request = {
      apiKey: this.settingsService.apiKey,
      applicationId: this.applicationId
    };
    this.formService.form.get('applicationId')?.setValue(this.applicationId);

    if (urlParams && urlParams?.closeNewRequest && urlParams.applicationId) {
      this.applicationId = urlParams.applicationId;
      this.formService.form.get('applicationId')?.setValue(urlParams.applicationId);
      return new Observable(subscriber => subscriber.next({applicationId: urlParams.applicationId}));
    } else {
      return this.post(environment.apiUrl + 'app/SetStatusWidgetDisplayed', request)
        .pipe(
          take(1),
          tap((res: any) => {
            if (res && !res.result) {
              // this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
              this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.SetStatusWidgetDisplayedResultError);
            }
          }),
          catchError(error => {
            this.loggingService.trace('Ошиба запроса app/SetStatusWidgetDisplayed', error);
            this.loggingService.error('Ошиба запроса app/SetStatusWidgetDisplayed');
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorSetStatusWidgetDisplayed);
            return of([]);
          })
        );
    }
  }

  // Применить applicationId к системе
  public changeApplicationId(newApplicationResponse: any): void {
    const applicationId = newApplicationResponse?.applicationId;
    this.navigationService.changeQueryParams({applicationId});
  }

  // Получаем clientId из localStorage или генерируем новый
  private getClientId(): Observable<string> {

    const urlParams = this.getUrlParams();
    const clientIdFromUrl = urlParams?.clientId;
    // Проверяем что clientId является UUID с помощью библотеки uuidv4
    const clientIdFromUrlIsUuid = uuidValidate(clientIdFromUrl!);
    // Берем clientId из localStorage
    const clientIdFromLocalStorage = localStorage.getItem('clientId');

    // Если clientId есть в localStorage, то возвращаем его
    if (clientIdFromLocalStorage && !clientIdFromUrl) {
      return of(clientIdFromLocalStorage);
    } else if (clientIdFromUrl && clientIdFromUrlIsUuid) {
      return of(clientIdFromUrl);
    } else if (clientIdFromUrl && !clientIdFromUrlIsUuid && clientIdFromLocalStorage) {
      return of(clientIdFromLocalStorage);
    } else {
      // Иначе, генерируем новый clientId и сохраняем его в localStorage
      const generateClientId = uuidv4();
      localStorage.setItem('clientId', generateClientId);
      return of(generateClientId);
    }
  }

  // Устанавливаем clientId
  setClientId(clientId: string) {
    // проверить что clientId является UUID
    if (clientId.match(/^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/)) {
      this.settingsService.clientId = clientId;
    }
    this.settingsService.clientId = clientId;
  }

  // Получаем данные всех брендов авто
  public getCarBrands(): Observable<ICarBrand[]> {
    return this.post(environment.apiUrl + 'dictionaries/GetAllBrands', {
      apiKey: this.settingsService.apiKey
    }).pipe(
      take(1),
      map((res: any) => {
        this.brandsList = res.value.brands;

        if (res && !res.result) {
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.GetCarBrandsResultError);
        }

        return res && res.value && res.value.brands || [];
      }),
      catchError(error => {
        this.loggingService.trace('Ошиба запроса dictionaries/GetAllBrands', error);
        this.loggingService.error('Ошиба запроса dictionaries/GetAllBrands');
        this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetCarBrands);
        return of([]);
      })
    );
  }

  // Получаем данные всех моделей, выбранной марки авто
  public getCarModels(brandId?: number | string): Observable<ICarModel[]> {
    if (!brandId) {
      brandId = this.formService.form.get('carData')?.get('brandId')?.value;
    }

    if (!brandId && !this.formService.form.get('carData')?.get('brandId')?.value) {
      return of([]);
    } else {
      return this.post(environment.apiUrl + 'dictionaries/GetAllModels', {
        apiKey: this.settingsService.apiKey,
        brandId
      }).pipe(
        map((res: any) => {
          this.modelsList = res.value?.models;
          this.originalModelsList = res.value?.models;

          if (res && !res.result) {
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.CommontResultError);
            this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.GetCarModelsResultError);
          }

          return res && res.value && res.value.models || [];
        }),
        catchError(error => {
          this.loggingService.trace('Ошиба запроса dictionaries/GetAllModels', error);
          this.loggingService.error('Ошиба запроса dictionaries/GetAllModels');
          this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.ErrorGetCarModels);
          return of([]);
        })
      );
    }
  }

  // Получаем название бренда авто по ID
  public getCarBrandNameById = (brandId?: number) => this.brandsList
    .filter(brands => brandId ? brands.brandId === brandId : brands.brandId === this.formService.form.get('carData')?.value?.brandId)
    .map(brand => brand.brandName);

  // Получаем название бренда авто по ID
  public getCarModelNameByModelId = (modelId?: number) => this.modelsList
    .filter(models => modelId ? models.modelId === modelId : models.modelId === this.formService.form.get('carData')?.value?.modelId)
    .map(model => model.modelName);

}
