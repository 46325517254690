import {Component, EventEmitter, inject, OnDestroy, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {EMPTY, Subject, Subscription} from 'rxjs';

// Сервисы
import {FormService} from "../../../shared/services/form.service";
import {AppService} from "../../../shared/services/app.service";
import {CacheService} from "../../../shared/services/cache.service";

// Перечисления
import {DaData} from "../../../shared/enums/da-data.enum";
import {ContentService} from "../../../shared/services/content.service";
import {scrollToFirstError} from "../../../shared/functions/scrollToFirstError";
import {JsonPipe} from "@angular/common";
import {debounceTime, distinctUntilChanged, switchMap, takeUntil} from "rxjs/operators";
import {DadataService} from "../../../shared/services/dadata.service";
import {TypeOrganisation} from "../../../shared/enums/type-organisation";
import {setLegalOwnerFromDaData} from "../../../shared/functions/setFormDataFromForm";
import {FormSwitchComponent} from "../../../shared/components/form-switch/form-switch.component";
import {AutocompleteComponent} from "../../../shared/components/autocomplete/autocomplete.component";
import {FormTextFieldComponent} from "../../../shared/components/form-text-field/form-text-field.component";
import {FormDateFieldComponent} from "../../../shared/components/form-date-field/form-date-field.component";
import {formatQuerySuggestionAddress} from "../../../shared/functions/da-data";

// Интерфейсы

// Хелперы

@Component({
  selector: 'app-legal-owner',
  templateUrl: './legal-owner.component.html',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    JsonPipe,
    FormSwitchComponent,
    AutocompleteComponent,
    FormTextFieldComponent,
    FormDateFieldComponent
  ],
  styleUrls: ['./legal-owner.component.scss']
})
export class LegalOwnerComponent implements OnInit, OnDestroy {
  @Output() submitOwnerForm: EventEmitter<boolean> = new EventEmitter<boolean>;

  private cacheService = inject(CacheService);

  constructor(public readonly formService: FormService,
              public readonly appService: AppService,
              public readonly contentService: ContentService,
              private readonly dadataService: DadataService) {
  }

  protected readonly TypeOrganisation = TypeOrganisation;
  // Форма страхователя
  public legalOwnerIsJuridical = (this.formService.legalOwner.get('legalOwnerIsJuridical') as FormControl);
  public formLegalOwnerIsLegal = (this.formService.legalOwner?.get('legalOwnerIsLegal') as UntypedFormGroup);
  public formLegalOwnerIsNotLegal = (this.formService.legalOwner?.get('legalOwnerIsNotLegal') as UntypedFormGroup);
  // Форма страховщика
  public formLegalInsurer = (this.formService.form.get('legalInsurer') as UntypedFormGroup);
  // Форма контактов
  public formContacts = (this.formService.form.get('contacts') as UntypedFormGroup);
  // Policy
  public policy = (this.formService.form.get('policy') as UntypedFormControl);
  // Индикатор загрузки
  public isLoading = false;
  // DaData системные имена
  public daDataSystemName = DaData;
  private destroy$ = new Subject<void>()
  // Подписка на контрол
  private subscription: Subscription = new Subscription();
  // Показываем таймер
  public isShowTimer = false;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    // При заходе на роут, делаем плавный переход вверх
    window.scrollTo({top: 0, behavior: 'smooth'});
    this.subscribeForm();
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  // Отправка формы
  public onSubmit(): void {
    this.formLegalOwnerIsLegal.markAllAsTouched();
    this.formLegalOwnerIsNotLegal.markAllAsTouched();

    scrollToFirstError();

    if (this.formService.legalOwner.invalid) {
        return;
    }

    this.submitOwnerForm.emit(true);

    this.subscription.add(
      this.cacheService.saveCache().subscribe()
    );
  }

  // Подписка на изменение формы
  private subscribeForm(): void {
    const {
      legalOwnerValue
    } = (this.formLegalOwnerIsLegal  as FormGroup).controls;
  }

  // Выбрали компанию собственника
  public onChangeLegalOwner(event: any): void {
    const {
      legalOwnerBirthDate,
      legalOwnerPassportLicense,
      legalOwnerPassportDate,
    } = this.formLegalOwnerIsLegal.controls;
    legalOwnerBirthDate?.reset(null, { emitEvent: false });
    legalOwnerPassportLicense?.reset(null, { emitEvent: false });
    legalOwnerPassportDate?.reset(null, { emitEvent: false });

    const addressValue = event?.item?.data?.address?.value;
    this.dadataService.suggestionAddress(
      formatQuerySuggestionAddress(addressValue),
      DaData.ADDRESS_FULL
    ).subscribe((res) => {
      const value = res[0]?.value;
      const data = res[0]?.data;
      const daData = {value, ...data};
      this.formLegalOwnerIsLegal.get('legalOwnerLegalAddressDaData')?.setValue(daData);
      setLegalOwnerFromDaData(this.formLegalOwnerIsLegal as FormGroup);
    });
  }

}
