<div class="modal-body">
  <div id="outerContainer">
    <div class="pdf-container">
     
    </div>
  </div>
</div>
@if (bsModalRef) {
  <div class="modal-footer">
    <div class="row">
      <div class="col-12 col-sm-auto">
        <button type="button" class="btn btn-outline-secondary btn-lg w-100 mb-2 mb-sm-0" (click)="bsModalRef.hide()">Закрыть</button>
      </div>
    </div>
  </div>
}

