import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter, inject,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import {Subscription} from "rxjs";
import {environment} from "@environment/environment";
import {FormService} from "../../../shared/services/form.service";
import {NavigationService} from "../../../shared/services/navigation.service";
import {YandexMetrikaService} from "../../../shared/services/yandex-metrika.service";
import {IOffer} from "../../../shared/interface/offers.interface";
import {OsagoService} from "../../../shared/services/osago.service";
import {SettingsService} from "../../../shared/services/settings.service";
import {AppService} from "../../../shared/services/app.service";
import {RatingModule} from "ngx-bootstrap/rating";
import {FormsModule} from "@angular/forms";
import {NgClass, NgForOf, NgIf} from "@angular/common";
import {PriceComponent} from "./price/price.component";
import {RemoveFocusDirective} from "../../../shared/directives/remove-focus.directive";
import {SendPaymentSMSComponent} from "./send-payment-sms/send-payment-sms.component";
import {UpsalesComponent} from "./upsales/upsales.component";
import {CollapseModule} from "ngx-bootstrap/collapse";
import {GiftItemComponent} from "./gift-item/gift-item.component";
import moment from "moment-mini";
import {IUrlParams} from "../../../shared/interface/url-params.interface";

@Component({
  selector: 'app-offer-item',
  standalone: true,
  templateUrl: './offer-item.component.html',
  styleUrls: ['./offer-item.component.scss'],
  imports: [
    RatingModule,
    FormsModule,
    NgForOf,
    PriceComponent,
    NgClass,
    RemoveFocusDirective,
    SendPaymentSMSComponent,
    NgIf,
    UpsalesComponent,
    CollapseModule,
    GiftItemComponent
  ]
})
export class OfferItemComponent implements OnInit, AfterViewInit, OnDestroy {

  // Данные оффера
  @Input() offer!: IOffer;
  // Если ищем офферов которые можно оплатить
  @Input() isApproveOffer: boolean = false;
  // Если повторно выбираем оффера после того как предыдущий отказал
  @Input() isRepeatOffers: boolean = false;
  @Output() triggerNewGetOffers: EventEmitter<boolean> = new EventEmitter<boolean>();

  public settingsService = inject(SettingsService);
  public appService = inject(AppService);

  urlParams!: IUrlParams;

  constructor(public readonly formService: FormService,
              public readonly osagoService: OsagoService,
              private readonly navigationService: NavigationService,
              private readonly cd: ChangeDetectorRef,
              private readonly ym: YandexMetrikaService,) {
  }

  public isPaymentCountdownStarted = false;
  public paymentCountdown = '02:00';
  private countdownInterval: any;
  // Документ
  public agreement = true;

  // Системное имя партнера
  public partnerSystemName = environment.partnerSystemName;


  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit() {
    if (this.isApproveOffer) {
      this.startPaymentCountdown();
      this.getMortgagePaymentLink(this.offer.offerId);
    }

    this.urlParams = this.appService.getUrlParams();
  }

  public ngAfterViewInit() {

  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
  }

  // --------------------------------------------------------------------------

  // Счетчик
  public countdown = 120;
  // Подписка
  private subscriptions: Subscription[] = [];
  // Показываем подарки
  public isShowGifts = false;


  isClicked = false;

  toggleClickState() {
    this.isClicked = !this.isClicked;
  }

  get getCreditRemainAmount(): string {
    return '';
  }

  // Выбрали оффера
  public selectOffer(offer: IOffer): void {
    this.osagoService.paymentLink = '';
    // Помечаем оффера в списке как выбранный
    offer.isSelected = true;
    this.osagoService.selectedOffer = offer;
    this.subscriptions.push(
      this.osagoService.selectOffer()
        .subscribe(() => {
          this.navigationService.navigate('data-checking');
        })
    );

  }

  // Получаем ссылку на оплату
  public getMortgagePaymentLink(offerId: string): void {
  }

  // Оплатить
  public payment(): void {
  }

  // Открыть черновик
  public openDraft(): void {

  }

  // Открыть черновик
  public openAdditionalDraft(): void {

  }

  // Счетчик
  public startPaymentCountdown() {
    this.isPaymentCountdownStarted = true;

    // начинаем обратный отсчет
    this.countdownInterval = setInterval(() => {
      const timeParts = this.paymentCountdown.split(':');
      let minutes = +timeParts[0];
      let seconds = +timeParts[1];

      if (minutes === 0 && seconds === 0) {
        clearInterval(this.countdownInterval);
        this.isPaymentCountdownStarted = false;
        // Вставляем событие клика для ссылок на документы
        this.setClickForDocs();
        return;
      }

      if (seconds === 0) {
        minutes--;
        seconds = 59;
      } else {
        seconds--;
      }

      this.cd.detectChanges();

      this.paymentCountdown = `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
    }, 1000);
  }

  // Вставляем событие клика для ссылок на документы
  public setClickForDocs(): void {
    setTimeout(() => {
      // В этом методе контент [innerHTML]="item" уже доступен
      const elements = document.querySelectorAll('.form-check-label a');
      elements.forEach((element: any) => {
        element.addEventListener('click', (event: Event) => {
          event.preventDefault();

          if (!element.data) {
            element.data = element.href;
          }

          const initialState = {
            src: element.data,
          };


          element.href = 'javascript:void(0)';
          element.target = '_self';
        });

      });
    }, 100)
  }

  // Проверяем наличие бейджей пролонгации
  get checkProlongationBadge(): boolean {
    return this.offer.badges.find((badge: any) => badge.title === 'Ваша текущая страховая') === null;
  }

  // Проверяем если валидна пролонгация
  get getProlongtaionValidity(): boolean {
    if (this.osagoService.prolongationOffer?.endDate) {
      const policyDate = moment(this.osagoService.prolongationOffer?.endDate!); // ISO формат обрабатывается моментом автоматически
      const currentDate = moment().startOf('day');
      const maxDate = moment().startOf('day').add(59, 'days');

      if (!policyDate.isValid() || policyDate.isBefore(currentDate) || policyDate.isAfter(maxDate)) {
        return false;
      } else {
        return this.offer.isProlongation || this.osagoService.prolongationOffer?.insurerType === this.offer.insurerType;
      }
    } else {
      return false;
    }
  }

  protected readonly environment = environment;
}
