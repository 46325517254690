import {Component, inject, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {conformToMask} from "angular2-text-mask";
import {NavigationService} from "../../../../shared/services/navigation.service";
import {FormService} from "../../../../shared/services/form.service";
import {AppService} from "../../../../shared/services/app.service";
import {OsagoService} from "../../../../shared/services/osago.service";
import {IMask} from "angular-imask";
import {MASKS} from "../../../constants/masks";
import {DatePipe, JsonPipe} from "@angular/common";
import { EditInfoOwnerComponent } from './edit-info-owner/edit-info-owner.component';
import {EditInfoLegalInsurerComponent} from "./edit-info-legal-insurer/edit-info-legal-insurer.component";
import {TypeOrganisation} from "../../../enums/type-organisation";
import {
  EditInfoIndividualInsurerComponent
} from "./edit-info-individual-insurer/edit-info-individual-insurer.component";
import {EditInfoInsurerComponent} from "./edit-info-insurer/edit-info-insurer.component";
import {EditInfoLegalOwnerComponent} from "./edit-info-legal-owner/edit-info-legal-owner.component";
import {EditInfoIndividualOwnerComponent} from "./edit-info-individual-owner/edit-info-individual-owner.component";
import {
  EditInfoLegalOwnerIsNotLegalComponent
} from "./edit-info-legal-owner-is-not-legal/edit-info-legal-owner-is-not-legal.component";
import {YandexMetrikaGoalsEnum} from "../../../enums/yandex-metrika-goals.enum";
import {ActivatedRoute} from "@angular/router";


@Component({
  selector: 'app-edit-info',
  standalone: true,
  templateUrl: './edit-info.component.html',
  imports: [
    JsonPipe,
    EditInfoOwnerComponent,
    EditInfoLegalInsurerComponent,
    EditInfoIndividualInsurerComponent,
    EditInfoInsurerComponent,
    EditInfoLegalOwnerComponent,
    EditInfoIndividualOwnerComponent,
    EditInfoLegalOwnerIsNotLegalComponent,
    DatePipe
  ],
  styleUrls: ['./edit-info.component.scss']
})
export class EditInfoComponent implements OnInit {

  private readonly route = inject(ActivatedRoute);

  constructor(public readonly formService: FormService,
              public readonly appService: AppService,
              private readonly navigationService: NavigationService,
              public readonly osagoService: OsagoService) {
  }

  // Данные формы
  public formData = this.formService.form.value;
  // Подписка
  public subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;
  public dateMask = [/\d/, /\d/, '.', /\d/, /\d/, '.', /\d/, /\d/, /\d/, /\d/];
  public passportLicense = [/\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
  public phone = ['+', '7', ' ', '(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/];
  // Выбранный оффер
  public selectedOffer = this.osagoService.selectedOffer;
  // Параметр fromFastProlongation
  public fromFastProlongation: boolean = false;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.fromFastProlongation = this.route.snapshot.queryParamMap.get('fromFastProlongation') === 'true';
  }

  // --------------------------------------------------------------------------

  // Переход по роуту
  public navigate(route: string): void {
    this.navigationService.navigate(route);
  }

  // Возвращаем дату в формате маски
  public getDateMask(value: string): string {
    if (value) {
      const conformedValue = conformToMask(value, this.dateMask, {});
      return conformedValue.conformedValue;
    } else {
      return '';
    }
  }

  // Возвращаем номер телефона в формате маски
  public getPhoneMask(value: string): string {
    if (value) {
      const masked = IMask.createMask(MASKS.find((mask: any) => mask.systemName === 'phone')?.mask);
      const contacts = this.formService.form.get('contacts')?.value;
      const phone = contacts.phone;
      if (masked && phone) {
        masked.resolve(phone?.toString());
      }
      return masked.value;
    } else {
      return '';
    }
  }

  protected readonly TypeOrganisation = TypeOrganisation;
}
