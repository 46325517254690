// Получаем данные webMaster из параметров url или telegram,
// в случе если клиент зашел через telegram бота, то возвращаем tgBot
export function getWebMasterId(webMasterId: string): string {
  const telegramPlatform = (window as any)?.Telegram?.WebApp?.platform;
  if (telegramPlatform && telegramPlatform !== 'unknown' && !webMasterId) {
    return 'tgBot';
  } else {
    return webMasterId;
  }
}
