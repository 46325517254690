import {Insured, IOsagoCache} from "../interface/cache.interface";
import {FormArray, FormGroup} from "@angular/forms";
import {IDriverForm} from "../interface/osago-application.interface";
import {setDriver} from "./setDriversFormDataFromCache";
import {getFormatDate} from "./formatDate";
import {IDriver, IInsured} from "../interface/application.interface";

// Если анкета от физ лица
export function setIndividualFormDataFromCache(cacheData: IOsagoCache, form: FormGroup): Promise<void> {
  console.log(cacheData);
  return new Promise<void>((resolve) => {
    form.get('isJuridical')?.setValue(false);

    // Применяем данные водителей
    const drivers = form.get('drivers') as FormArray;
    const driversData = cacheData.drivers;
    form.get('driversWithoutRestriction')?.setValue(!cacheData.hasDriversRestriction);

    const cacheOwner = cacheData.owner;
    const cacheInsurer = cacheData.insured;

    // Если есть водители
    if (driversData) {
      // Применяем данные собственника
      const ownerFormGroup = form.get('owner') as FormGroup;
      setOwner(cacheOwner, ownerFormGroup);

      // Применяем данные страхователя
      const insurerFormGroup = form.get('insurer') as FormGroup;

      if (cacheData.owner?.ownerIsInsured) {
        // TODO ТУТ ДЖОЛЖНА БЫТЬ СИНХРОНИЗАЦИЯ ВОДИТЕЛЯ И СОБСТВЕННИКА
        setInsurer(cacheData?.owner, insurerFormGroup);
      } else {
        setInsurer(cacheInsurer, insurerFormGroup);
      }

      //   // Применяем данные страхователя
      //   const insurerFormGroup = form.get('insurer') as FormGroup;
      //   const cacheInsurer = cacheData.insurer;
      //   setInsurer(cacheInsurer, insurerFormGroup);
    } else {
      // Применяем данные собственника
      const ownerFormGroup = form.get('owner') as FormGroup;
      const cacheOwner = cacheData.owner;
      setOwner(cacheOwner, ownerFormGroup);
      // Собственник является страхователем
      form.get('ownerIsInsured')?.setValue(cacheData.owner?.ownerIsInsured);

      // Применяем данные страхователя
      const insurerFormGroup = form.get('insurer') as FormGroup;
      setInsurer(cacheData?.owner, insurerFormGroup);
    }

    // Если есть водители
    if (driversData) {
      driversData.forEach((driver: any, index: number) => {
        setDriver(driver, drivers.at(index) as FormGroup);
        // Проверяем, является ли водитель собственником
        const driverIsOwner = compareDriverIsOwner(driver, cacheData?.owner);
        if (driverIsOwner) {
          setDriverIsOwner(cacheData?.owner, drivers.at(index) as FormGroup);
        }
        // Проверяем, является ли водитель страхователем
        const driverIsInsurer = driver.firstName && driver.lastName && driver.birthDate && compareDriverIsInsurer(driver, cacheData?.insured);
        if (driverIsInsurer && cacheData?.insured) {
          setDriverIsInsurer(cacheData?.insured, drivers.at(index) as FormGroup);
        }
      });
    }

    resolve();
  });
}

// Применяем данные собственника
function setOwner(cacheOwner: any, ownerFormGroup: FormGroup): void {
  const {
    ownerFirstName,
    ownerLastName,
    ownerMiddleName,
    ownerBirthDate,
    passportLicense,
    ownerPassportDate,
    ownerCity,
    ownerHouseNumber,
    ownerAddressDaData
  } = ownerFormGroup?.controls;

  ownerFirstName?.setValue(cacheOwner?.firstName, { emitEvent: false });
  ownerLastName?.setValue(cacheOwner?.lastName, { emitEvent: false });
  ownerMiddleName?.setValue(cacheOwner?.middleName, { emitEvent: false });
  ownerBirthDate?.setValue(cacheOwner?.birthDate, { emitEvent: false });

  const passportLicenseValue = cacheOwner?.passport?.passportSeries + '' + cacheOwner?.passport?.passportNumber;
  passportLicense?.setValue(passportLicenseValue, { emitEvent: false });
  ownerPassportDate?.setValue(cacheOwner?.passport?.passportIssueDate, { emitEvent: false });

  // Применяем данные адреса
  const flat = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.flat;
  const flatType = cacheOwner?.passport?.registrationAddressData?.daDataAddress?.flat_type;
  const addressWithoutFlat = getAddressWithoutFlat(cacheOwner?.passport?.registrationAddressData?.addressAsString, flat, flatType);
  const daDataAddress = cacheOwner?.passport?.registrationAddressData?.daDataAddress;

  if (daDataAddress && addressWithoutFlat
    && (daDataAddress?.fias_level?.toString() === '7'
      || daDataAddress?.fias_level?.toString() === '8'
      || daDataAddress?.fias_level?.toString() === '9'
      || daDataAddress?.fias_level?.toString() === '65'
      || daDataAddress?.fias_level?.toString() === '75')
  ) {
    ownerHouseNumber.setValue(daDataAddress?.flat, { emitEvent: false });
    ownerCity.setValue(addressWithoutFlat, { emitEvent: false });
    ownerAddressDaData?.setValue(daDataAddress, { emitEvent: false });
  }

}

// Применяем данные страхователя
function setInsurer(cacheInsurer: any, insurerFormGroup: FormGroup): void {
  console.log(cacheInsurer);
  const {
    insurerFirstName,
    insurerLastName,
    insurerMiddleName,
    insurerBirthDate,
    passportLicense,
    insurerPassportDate,
    insurerCity,
    insurerHouseNumber,
    insurerAddressDaData
  } = insurerFormGroup?.controls;

  insurerFirstName?.setValue(cacheInsurer?.firstName, { emitEvent: false });
  insurerLastName?.setValue(cacheInsurer?.lastName, { emitEvent: false });
  insurerMiddleName?.setValue(cacheInsurer?.middleName, { emitEvent: false });
  insurerBirthDate?.setValue(cacheInsurer?.birthDate, { emitEvent: false });

  if (cacheInsurer?.passport?.passportSeries && cacheInsurer?.passport?.passportNumber) {
    passportLicense.setValue(cacheInsurer?.passport?.passportSeries + cacheInsurer?.passport?.passportNumber, { emitEvent: false });
  }

  // Применяем данные адреса
  const flat = cacheInsurer?.passport?.registrationAddressData?.daDataAddress?.flat;
  const flatType = cacheInsurer?.passport?.registrationAddressData?.daDataAddress?.flat_type;
  const addressWithoutFlat = getAddressWithoutFlat(cacheInsurer?.passport?.registrationAddressData?.addressAsString, flat, flatType);
  const daDataAddress = cacheInsurer?.passport?.registrationAddressData?.daDataAddress;

  setTimeout(() => {
    insurerPassportDate?.setValue(cacheInsurer?.passport?.passportIssueDate, { emitEvent: false });

    if (daDataAddress && addressWithoutFlat
      && (daDataAddress?.fias_level?.toString() === '7'
        || daDataAddress?.fias_level?.toString() === '8'
        || daDataAddress?.fias_level?.toString() === '9'
        || daDataAddress?.fias_level?.toString() === '65'
        || daDataAddress?.fias_level?.toString() === '75')
    ) {
      insurerHouseNumber.setValue(flat?.toString(), { emitEvent: false });
      insurerCity.setValue(addressWithoutFlat, { emitEvent: false });
      insurerAddressDaData?.setValue(daDataAddress, { emitEvent: false });
    }
  }, 100);
  console.log(insurerFormGroup);
}

// Сравниваем водителя с собственником, если совпадает, то он являеся собственником
function compareDriverIsOwner(driverData: any, ownerData: any): boolean {
  return driverData?.firstName !== null
    && driverData?.lastName !== null
    && driverData?.birthDate !== null
    && (driverData?.firstName === ownerData?.firstName
      && driverData?.lastName === ownerData?.lastName
      && driverData?.middleName === ownerData?.middleName
      && driverData?.birthDate === ownerData?.birthDate);
}

// Применяем данные собственника к форме водителя, т.к. найденный водитель является собственнимком авто
function setDriverIsOwner(ownerData: any, driverFormGroup: FormGroup): void {
  const {
    driverIsOwner,
    driverIsInsurer,
    driverPassportLicense,
    driverPassportDate,
    driverCity,
    driverHouseNumber,
    driverAddressDaData
  } = (driverFormGroup)?.controls;

  driverIsOwner.setValue(true);
  // Если страхователь является собственником, то у эго водителя должен быть выбран свич "Является страхователем"

  if (ownerData?.passport?.passportSeries && ownerData?.passport?.passportNumber) {
    driverPassportLicense.setValue(ownerData?.passport?.passportSeries + ownerData?.passport?.passportNumber, { emitEvent: false });
  }
  driverPassportDate.setValue(ownerData?.passport?.passportIssueDate, { emitEvent: false });

  // Применяем данные адреса
  const flat = ownerData?.passport?.registrationAddressData?.daDataAddress?.flat;
  const flatType = ownerData?.passport?.registrationAddressData?.daDataAddress?.flat_type;
  const addressWithoutFlat = getAddressWithoutFlat(ownerData?.passport?.registrationAddressData?.addressAsString, flat, flatType);
  const daDataAddress = ownerData?.passport?.registrationAddressData?.daDataAddress;

  if (daDataAddress && addressWithoutFlat
    && (daDataAddress?.fias_level?.toString() === '7'
      || daDataAddress?.fias_level?.toString() === '8'
      || daDataAddress?.fias_level?.toString() === '9'
      || daDataAddress?.fias_level?.toString() === '65'
      || daDataAddress?.fias_level?.toString() === '75')
  ) {
    driverHouseNumber.setValue(flat?.toString(), { emitEvent: false });
    driverCity.setValue(addressWithoutFlat, { emitEvent: false });
    driverAddressDaData.setValue(daDataAddress, { emitEvent: false });
  }
  driverIsInsurer.setValue(ownerData.ownerIsInsured);
}

// Применяем данные страхователя к форме водителя, т.к. найденный водитель является страхователем авто
function setDriverIsInsurer(insurerData: any, driverFormGroup: FormGroup): void {
  const {
    driverIsInsurer,
    driverPassportLicense,
    driverPassportDate,
    driverCity,
    driverHouseNumber,
    driverAddressDaData,
  } = (driverFormGroup)?.controls;

  driverIsInsurer.setValue(true);
  // Если страхователь является собственником, то у эго водителя должен быть выбран свич "Является страхователем"

  if (insurerData?.passport?.passportSeries && insurerData?.passport?.passportNumber) {
    driverPassportLicense.setValue(insurerData?.passport?.passportSeries + insurerData?.passport?.passportNumber, { emitEvent: false });
  }
  driverPassportDate.setValue(insurerData?.passport?.passportIssueDate, { emitEvent: false });

  // Применяем данные адреса
  const flat = insurerData?.passport?.registrationAddressData?.daDataAddress?.flat;
  const flatType = insurerData?.passport?.registrationAddressData?.daDataAddress?.flat_type;
  const addressWithoutFlat = getAddressWithoutFlat(insurerData?.passport?.registrationAddressData?.addressAsString, flat, flatType);
  const daDataAddress = insurerData?.passport?.registrationAddressData?.daDataAddress;

  if (daDataAddress && addressWithoutFlat
    && (daDataAddress?.fias_level?.toString() === '7'
      || daDataAddress?.fias_level?.toString() === '8'
      || daDataAddress?.fias_level?.toString() === '9'
      || daDataAddress?.fias_level?.toString() === '65'
      || daDataAddress?.fias_level?.toString() === '75')
  ) {
    driverHouseNumber.setValue(flat?.toString(), { emitEvent: false });
    driverCity.setValue(addressWithoutFlat, { emitEvent: false });
    driverAddressDaData.setValue(daDataAddress, { emitEvent: false });
  }
}

export function getAddressWithoutFlat(addressAsString: string, flat: string, flatType: string): string {
  if (addressAsString) {
    const occurrences = addressAsString.match(/, кв /g)?.length || 0;
    const occurrencesOffice = addressAsString.match(/, офис /g)?.length || 0;
    if (occurrences > 1) {
      return '';
    } else {
      if (occurrencesOffice > 1) {
        return '';
      } else {
        return addressAsString
          ?.replace(`, ${flatType ? flatType + ' ' : ''}${flat}`, '')
          ?.replace(/,\s*кв\s.*$/, '')
          ?.replace(/,\s*офис\s.*$/, '')
          ?.replace('undefined', '');
      }
    }
  } else {
    return '';
  }
}

// Сравниваем водителя с страхователем, если совпадает, то он являеся страхователем
function compareDriverIsInsurer(driverData: IDriver, insurerData: any): boolean {
  return driverData?.firstName !== null
    && driverData?.lastName !== null
    && driverData?.birthDate !== null
    && (driverData?.firstName === insurerData?.firstName
      && driverData?.lastName === insurerData?.lastName
      && driverData?.middleName === insurerData?.middleName
      && driverData?.birthDate === insurerData?.birthDate);
}
