import {Component, inject} from '@angular/core';
import {ReactiveFormsModule} from "@angular/forms";
import {FormService} from "../../services/form.service";

@Component({
  selector: 'app-privacy-license',
  standalone: true,
  imports: [
    ReactiveFormsModule
  ],
  templateUrl: './privacy-license.component.html',
  styleUrl: './privacy-license.component.scss'
})
export class PrivacyLicenseComponent {

  public formService = inject(FormService);

}
