import {AfterViewInit, Directive, ElementRef, HostListener, OnInit, Renderer2} from '@angular/core';

@Directive({
  standalone: true,
  selector: '[appRemoveFocus]' // Селектор директивы
})
export class RemoveFocusDirective implements AfterViewInit {

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngAfterViewInit() {
    // Проверка, является ли устройство сенсорным
    if ('ontouchstart' in window || navigator.maxTouchPoints) {
      // Убираем стили hover для мобильных устройств
      this.removeHoverStyles();
    }
  }

  private removeHoverStyles() {
    const element = this.el.nativeElement;
    // Проверяем, что элемент имеет класс btn-outline-primary
    if (element.classList.contains('btn-outline-primary')) {
      // Добавляем стиль напрямую или класс, который отключает hover
      // После клика возвращаем pointer-events обратно, если это необходимо для функционала кнопки
      element.addEventListener('click', () => {
        setTimeout(() => {
        }, 100); // Время в мс до возврата стиля, может потребоваться настройка
      });
    }
  }

  private isMobileDevice(): boolean {
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }
  // Слушатель события клика, который убирает фокус с элемента
  @HostListener('click')
  onClick() {
    this.el.nativeElement.blur();
  }

}
