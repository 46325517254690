<div class="row" [formGroup]="licenseFormGroup">
  <div class="col-12 col-sm-12 col-md-4 col-lg-4">
    <app-form-license-field [isReadonly]="isLoading || isLoadingWithoutLicense" formControlName="licensePlate" (keyup.enter)="licenseComplete()"></app-form-license-field>
  </div>
  <div class="col-12 col-sm-6 col-md-4 col-lg-4 mt-3 mt-md-0">
    <button (click)="licenseComplete()"
            [disabled]="isLoading || isLoadingWithoutLicense"
            type="button" class="btn btn-primary btn-lg w-100">
      @if (isLoading) {
        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
      }
      {{ contentService.content.licenseComponent.calculateBtn }}
    </button>
  </div>
  <div class="col-12 col-sm-6 col-md-4 col-lg-4 mt-3 mt-md-0">
    <button type="button"
            class="btn btn-outline-primary btn-lg w-100"
            (click)="navigateToCarData()"
            [disabled]="isLoadingWithoutLicense || isLoading">
      @if (isLoadingWithoutLicense) {
        <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
      }
      {{ contentService.content.licenseComponent.withoutLicenseBtn }}
    </button>
  </div>
</div>
