import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {environment} from "@environment/environment";
import {DraftService} from "../../services/draft.service";
import {OsagoService} from "../../services/osago.service";
import {PdfDocViewComponent} from "../pdf-doc-view/pdf-doc-view.component";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";

@Component({
  selector: 'app-draft',
  standalone: true,
  imports: [],
  templateUrl: './draft.component.html',
  styleUrl: './draft.component.scss',
  providers: [BsModalService],
})
export class DraftComponent implements OnInit, OnDestroy {
  private readonly draftService: DraftService = inject(DraftService);
  private readonly osagoService: OsagoService = inject(OsagoService);
  private readonly modalService = inject(BsModalService);

  // Подписка
  private subscription: Subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;
  // Ссылка на черновик полиса
  public policyDraftUrl: string = '';
  modalRef?: BsModalRef;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.getDraft();
  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  // --------------------------------------------------------------------------

  // Начинаем искать черновик полиса
  private getDraft(): void {
    this.isLoading = true;
    this.subscription.add(
      this.draftService.getDraft(this.osagoService.selectedOffer!.offerId)
        .subscribe((res) => {
          this.policyDraftUrl = res.value.policyDraftUrl;
          this.isLoading = false;
        })
    );
  }

  // Открываем черновик в модальном окне
  public openDraft(): void {
    if (environment.partnerSystemName === 'yafuel') {
      const initialState = {
        src: this.policyDraftUrl,
      };
      this.modalRef =this.modalService.show(PdfDocViewComponent, {
        initialState,
        class: 'modal-dialog-centered PDFmodal'
      });
    } else {
      window.open(this.policyDraftUrl, '_blank');
    }

  }

}
