<form [formGroup]="form" novalidate>

  @if (formService.form.get('isJuridical')?.value) {
    <div class="row">
      <div class="col-12 mb-3 mb-sm-4">
        <app-form-switch [formControl]="formService.isAutopark" [label]="'У меня автопарк (более 5 авто)'" [isReadonly]="isLoading"></app-form-switch>
      </div>
    </div>
  }

  @if (formService.form.get('carCharacteristicsRequestId')?.value) {
    <div class="alert alert-warning border-light"
         [innerHTML]="contentService.content.formComponent.carDataComponent.alertWarn"></div>
  } @else {
    <div class="alert alert-primary border-light"
         [innerHTML]="contentService.content.formComponent.carDataComponent.alert1"></div>
  }

  @if (getAlertModelId(form.get('modelId')?.value, appService.modelsList)) {
    <div class="alert alert-warning border-light"
         [innerHTML]="getAlertModelId(form.get('modelId')?.value, appService.modelsList)"></div>
  }

  <div class="row mt-3 mt-sm-4">
    <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
      <app-form-license-field formControlName="licensePlate" (changeNewLicense)="onChangeNewLicense($event)"></app-form-license-field>
    </div>
    <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
      <app-autocomplete
        formControlName="brandId"
        [typeaheadList]="appService.brandsList"
        [typeaheadOptionsLimit]="800"
        [isReadonly]="isLoading"
        (typeaheadOnSelect)="selectBrand($event)"></app-autocomplete>
    </div>
    <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
      <app-autocomplete
        formControlName="modelId"
        [typeaheadList]="appService.modelsList"
        [originalTypeaheadList]="appService.originalModelsList"
        [typeaheadOptionsLimit]="500"
        [isReadonly]="isLoading"
        [isCustomDropdown]="true"></app-autocomplete>
    </div>
    <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
      <app-form-text-field formControlName="productionYear" [isReadonly]="isLoading"></app-form-text-field>
    </div>
    <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
      <app-form-text-field formControlName="horsePower" [isReadonly]="isLoading"></app-form-text-field>
    </div>
  </div>

  @if (!formService.form.get('isJuridical')?.value) {
  <div class="alert alert-primary border-light mb-3 mb-sm-4"
       [innerHTML]="contentService.content.formComponent.carDataComponent.alert2"></div>
  } @else {
    <h5 class="mb-3 mb-sm-4">Документы на автомобиль</h5>
  }

  <div class="row" formGroupName="carDocument">
    <div class="col-12 col-md-4 col-lg-4 mb-3 mb-sm-4">
      <app-autocomplete
        formControlName="documentType"
        [isReadonly]="isLoading"
        [typeaheadList]="appService.documentTypes"
        [typeaheadOptionsLimit]="appService.documentTypes.length"
        [isDropdown]="true"></app-autocomplete>
    </div>
    <div class="col-12 col-md-4 col-lg-4 mb-3 mb-sm-4">
      <app-form-text-field [hidden]="form.get('carDocument')?.value['documentType'] !== carDocumentTypes.STS"
                           formControlName="stsNumber" [isReadonly]="isLoading"></app-form-text-field>
      <app-form-text-field [hidden]="form.get('carDocument')?.value['documentType'] !== carDocumentTypes.PTS"
                           formControlName="ptsNumber" [isReadonly]="isLoading"></app-form-text-field>
      <app-form-text-field [hidden]="form.get('carDocument')?.value['documentType'] !== carDocumentTypes.ePTS"
                           formControlName="ePtsNumber" [isReadonly]="isLoading"></app-form-text-field>
    </div>
    <div class="col-12 col-md-4 col-lg-4 mb-3 mb-sm-4">
      <app-form-date-field [hidden]="form.get('carDocument')?.value['documentType'] !== carDocumentTypes.STS"
                           formControlName="stsDate" [isReadonly]="isLoading"></app-form-date-field>
      <app-form-date-field [hidden]="form.get('carDocument')?.value['documentType'] !== carDocumentTypes.PTS"
                           formControlName="ptsDate" [isReadonly]="isLoading"></app-form-date-field>
      <app-form-date-field [hidden]="form.get('carDocument')?.value['documentType'] !== carDocumentTypes.ePTS"
                           formControlName="ePtsDate" [isReadonly]="isLoading"></app-form-date-field>
    </div>
  </div>
  <div class="row">
    <ng-container formGroupName="carIdentificators">
      <div class="col-12 col-md-4 mb-3 mb-sm-4">
        <app-autocomplete
          formControlName="identifier"
          [isReadonly]="isLoading"
          [typeaheadList]="appService.carIdentificators"
          [typeaheadOptionsLimit]="appService.carIdentificators.length"
          [isDropdown]="true"></app-autocomplete>
      </div>
      <div class="col-12 col-md-4 mb-3 mb-sm-4" [class.col-md-8]="formService.form.get('isJuridical')?.value">
        <app-form-text-field
          [hidden]="form.get('carIdentificators')?.value['identifier'] !== carIdentificators.VIN"
          formControlName="vinNumber" [isReadonly]="isLoading"></app-form-text-field>
        <app-form-text-field
          [hidden]="form.get('carIdentificators')?.value['identifier'] !== carIdentificators.BodyNumber"
          formControlName="bodyNumber" [isReadonly]="isLoading"></app-form-text-field>
        <app-form-text-field
          [hidden]="form.get('carIdentificators')?.value['identifier'] !== carIdentificators.ChassisNumber"
          formControlName="chassisNumber" [isReadonly]="isLoading"></app-form-text-field>
      </div>
    </ng-container>
    <div class="col-12 col-md-4 mb-3 mb-sm-4">
      <app-autocomplete
        formControlName="purposeOfUse"
        [isReadonly]="isLoading"
        [typeaheadList]="formService.form.get('isJuridical')?.value ? appService.purposeOfUseList : appService.purposeOfUseListIndividual"
        [typeaheadOptionsLimit]="formService.form.get('isJuridical')?.value ? appService.purposeOfUseList.length : appService.purposeOfUseListIndividual.length"
        [idKeyName]="'systemName'"
        [typeaheadOptionField]="'value'"
        [isDropdown]="true"></app-autocomplete>
    </div>
    <div class="col-12 col-md-4 mb-3 mb-sm-4" [class.col-md-8]="formService.form.get('isJuridical')?.value">
      <app-form-switch formControlName="carWithPrince" [isReadonly]="isLoading"></app-form-switch>
    </div>
  </div>

  @if (!formService.form.get('isJuridical')?.value) {
    <app-pre-price-calculator>
      <div class="row justify-content-end">
        <div class="col-12 col-sm-auto">
          <button [disabled]="isLoading" type="button" class="btn btn-primary btn-lg w-100" (click)="onSubmit()">
            @if (isLoading) {
              <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
            } @else {
              Продолжить
            }
          </button>
        </div>
      </div>
    </app-pre-price-calculator>
  } @else {
    <div class="row justify-content-end">
      <div class="col-12 col-sm-auto mb-3 mb-sm-4">
        <button [disabled]="isLoading" type="button" class="btn btn-primary btn-lg w-100" (click)="onSubmit()">
          @if (isLoading) {
            <span class="spinner-border spinner-border-sm" aria-hidden="true"></span>
          } @else {
            Продолжить
          }
        </button>
      </div>
    </div>
  }

</form>
