@if (localStorage.getItem('isAdmin')) {
  <app-test></app-test>
}

<h3 class="page-title my-3 my-sm-4">{{ contentService.content.formComponent.title }}</h3>
@if (localStorage.getItem('isAdmin')) {
  <button (click)="saveCache()">save cache</button>
  <button (click)="resetCache()">reset cache</button>
  <button (click)="resetLicensePlate()">reset licensePlate</button>
}
<accordion #accordion [isAnimated]="false" [closeOthers]="true">
  @if (formService.isAutopark.value) {
    <!--ОФФЛАЙН ФОРМА-->
    <accordion-group panelClass="autopark"
                     [isOpen]="true"
                     [isDisabled]="true"
                     class="mb-2">
      <app-accordion-header [headerName]="'autopark'" accordion-heading></app-accordion-header>
      <app-autopark></app-autopark>
    </accordion-group>
  } @else if (!formService.isAutopark.value) {
    <!--ДАННЫЕ АВТО-->
    <accordion-group [isOpen]="true" panelClass="carDataGroup"
                     (isOpenChange)="accordionService.isOpenChange('carDataGroup')"
                     class="mb-2">
      <app-accordion-header [headerName]="'carData'" accordion-heading></app-accordion-header>
      <app-car-data
        (submitCarDataForm)="accordionService.isOpenAccordionItem($event, 'carDataGroup', formService.form.get('isJuridical')?.value ? 'legalInsurerGroup' : 'driverGroup0')"></app-car-data>
    </accordion-group>
    <!-- Если выбрали физ лицо-->
    @if (!formService.form.get('isJuridical')?.value) {
      <!--ВОДИТЕЛИ-->
      @for (driver of formService.drivers.controls; track driver; let index = $index) {
        <accordion-group
          class="mb-2"
          [hidden]="(formService.form.get('driversWithoutRestriction')?.value && index > 0)"
          [panelClass]="'driverGroup' + index"
          (isOpenChange)="accordionService.isOpenChange('driverGroup' + index)">
          <app-accordion-header [headerName]="'driver'" [driverIndex]="index" accordion-heading></app-accordion-header>
          <app-driver
            [formDriver]="driverForm(index)"
            [driverIndex]="index"
            [isLoading]="isLoading"
            (submitDriversForm)="accordionService.isOpenAccordionItem($event, 'driverGroup' + index, formService.drivers.controls.length === index + 1 || formService.form.get('driversWithoutRestriction')?.value
         ? 'ownerGroup'
          : 'driverGroup' + (index + 1))"></app-driver>
        </accordion-group>
      }
      <!--ДОБАВИТЬ НОВОГО ВОДИТЕЛЯ-->
      @if (isAddDriverAccItem) {
        <accordion-group [isDisabled]="false" class="mb-2">
          <app-accordion-header [headerName]="'addDriver'" (click)="addDriver()"
                                accordion-heading></app-accordion-header>
        </accordion-group>
      }
      <!--СОБСТЕННИК-->
      <accordion-group panelClass="ownerGroup"
                       (isOpenChange)="accordionService.isOpenChange('ownerGroup')" class="mb-2">
        <app-accordion-header [headerName]="'owner'" accordion-heading></app-accordion-header>
        <app-owner
          (submitOwnerForm)="accordionService.isOpenAccordionItem($event, 'ownerGroup', 'insurerGroup')"></app-owner>
      </accordion-group>
      <!--СТРАХОВАТЕЛЬ-->
      <accordion-group panelClass="insurerGroup"
                       (isOpenChange)="accordionService.isOpenChange('insurerGroup')" class="mb-2">
        <app-accordion-header [headerName]="'insurer'" accordion-heading></app-accordion-header>
        <app-insurer
          (submitInsurerForm)="accordionService.isOpenAccordionItem($event, 'insurerGroup', 'contactsGroup')"></app-insurer>
      </accordion-group>
    } @else {
      <!--СТРАХОВАТЕЛЬ-->
      <accordion-group panelClass="legalInsurerGroup"
                       (isOpenChange)="accordionService.isOpenChange('legalInsurerGroup')" class="mb-2">
        <app-accordion-header [headerName]="'legalInsurer'" accordion-heading></app-accordion-header>
        <app-legal-insurer
          (submitInsurerForm)="accordionService.isOpenAccordionItem(
          $event,
          'legalInsurerGroup',
          !formService.legalInsurer.get('legalInsurerIsLegalOwner')?.value
          ? 'legalOwnerGroup'
          : formService.legalInsurer.get('legalInsurerType')?.value === TypeOrganisation.INDIVIDUAL
            ? 'driverGroup0'
            : 'contactsGroup')"></app-legal-insurer>
      </accordion-group>

    <div [hidden]="formService.legalInsurer.get('legalInsurerIsLegalOwner')?.value">
      <!--СОБСТЕННИК-->
      <accordion-group panelClass="legalOwnerGroup"
                       (isOpenChange)="accordionService.isOpenChange('legalOwnerGroup')" class="mb-2">
        <app-accordion-header [headerName]="'legalOwner'" accordion-heading></app-accordion-header>
        <app-legal-owner
          (submitOwnerForm)="accordionService.isOpenAccordionItem($event, 'legalOwnerGroup', formService.legalInsurer.get('legalInsurerType')?.value === TypeOrganisation.LEGAL ? 'contactsGroup' : 'driverGroup0')"></app-legal-owner>
      </accordion-group>
    </div>

    <div [hidden]="!formService.legalInsurer.get('legalInsurerType')?.value || formService.legalInsurer.get('legalInsurerType')?.value === TypeOrganisation.LEGAL">
      <!--ВОДИТЕЛИ-->
      @for (driver of formService.drivers.controls; track driver; let index = $index) {
        <accordion-group
          class="mb-2"
          [hidden]="(formService.form.get('driversWithoutRestriction')?.value && index > 0)"
          [panelClass]="'driverGroup' + index"
          (isOpenChange)="accordionService.isOpenChange('driverGroup' + index)">
          <app-accordion-header [headerName]="'driver'" [driverIndex]="index"
                                accordion-heading></app-accordion-header>
          <app-driver
            [formDriver]="driverForm(index)"
            [driverIndex]="index"
            [isLoading]="isLoading"
            (submitDriversForm)="accordionService.isOpenAccordionItem($event, 'driverGroup' + index, formService.drivers.controls.length === index + 1 || formService.form.get('driversWithoutRestriction')?.value
         ? 'contactsGroup'
          : 'driverGroup' + (index + 1))"></app-driver>
        </accordion-group>
      }
      <!--ДОБАВИТЬ НОВОГО ВОДИТЕЛЯ-->
      @if (isAddDriverAccItem) {
        <accordion-group [isDisabled]="false" class="mb-2">
          <app-accordion-header [headerName]="'addDriver'" (click)="addDriver()"
                                accordion-heading></app-accordion-header>
        </accordion-group>
      }
    </div>

    }
    <accordion-group panelClass="contactsGroup"
                     (isOpenChange)="accordionService.isOpenChange('contactsGroup')"
                     class="mb-2">
      <app-accordion-header [headerName]="'contacts'" accordion-heading></app-accordion-header>
      <app-contacts></app-contacts>
    </accordion-group>
  }
</accordion>

@if (isShowPrivacyLicense) {
  <app-privacy-license></app-privacy-license>
}

@if (getTelegramWebApp) {
  <div class="keyboard-padding"></div>
}


@if (localStorage.getItem('isAdmin')) {
  <pre>{{ formService.form.value|json }}</pre>
}

<!--{{formService.legalInsurer?.get('legalInsurerType')?.value}}-->
