<form [formGroup]="formService.form" (ngSubmit)="onSubmit()" novalidate>
  @if (driverIndex === 0) {
    <div class="row mb-3 mb-sm-4">
      <div class="col-12 col-sm-6">
        <app-form-switch formControlName="driversWithoutRestriction" [isReadonly]="isLoading"></app-form-switch>
      </div>
    </div>
  }

  @if (driverIndex === 0) {
    <div class="alert alert-primary mb-3 mb-sm-4" [hidden]="driversWithoutRestriction.value"
         [innerHTML]="contentService.content.formComponent.driversComponent.unlimitedDriversTrueAlert"></div>
  }

  <div class="alert alert-primary mb-3 mb-sm-4" [hidden]="!driversWithoutRestriction.value"
       [innerHTML]="contentService.content.formComponent.driversComponent.unlimitedDriversFalseAlert"></div>

  @if (appService.isShowBtnAlfaId && environment.partnerSystemName === 'alfa' && driverIndex === 0 && !urlParams.agentId) {
    <div class="mb-3 mb-sm-4">
      <app-alfa-id [isLoadApp]="isLoading"></app-alfa-id>
    </div>
  }

  <div [formGroup]="formDriver" [hidden]="driversWithoutRestriction.value" class="row driver">
    <!-- 1 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
      <app-autocomplete
        formControlName="driverLastName"
        [suggestionPart]="daDataSystemName.SURNAME"
        [isReadonly]="isLoading"></app-autocomplete>
    </div>
    <!-- 2 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
      <app-autocomplete
        formControlName="driverFirstName"
        [suggestionPart]="daDataSystemName.NAME"
        [isReadonly]="isLoading"></app-autocomplete>
    </div>
    <!-- 3 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
      <app-autocomplete
        formControlName="driverMiddleName"
        [suggestionPart]="daDataSystemName.PATRONYMIC"
        [isReadonly]="isLoading"></app-autocomplete>
    </div>
    <!-- 4 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
      <app-form-text-field formControlName="driverBirthDate" [isReadonly]="isLoading"></app-form-text-field>
    </div>
    <!-- 5 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
      <app-form-text-field [hidden]="formDriver.get('rememberOnlyYear')?.value"
                           formControlName="experienceDate" [isReadonly]="isLoading"></app-form-text-field>
      <app-form-text-field [hidden]="!formDriver.get('rememberOnlyYear')?.value"
                           formControlName="experienceYear" [isReadonly]="isLoading"></app-form-text-field>
    </div>
    <!-- 6 -->
    <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
      <app-form-switch formControlName="rememberOnlyYear" [isReadonly]="isLoading"></app-form-switch>
    </div>
    <!-- 7 -->
    <div class="col-12 col-md-6 col-xxl-6 mb-3 mb-sm-4">
      <app-form-text-field formControlName="driverLicense" [isReadonly]="isLoading"></app-form-text-field>
    </div>
    <!-- 8 -->
    <div class="col-12 col-md-6 col-xxl-6 mb-3 mb-sm-4">
      <app-form-switch formControlName="licenseWasChanged" [isReadonly]="isLoading"></app-form-switch>
    </div>
    <!-- 9 -->
    <div class="col-12 mb-1" [hidden]="!formDriver.get('licenseWasChanged')?.value">
      <div class="alert alert-primary border-light"
           [innerHTML]="contentService.content.formComponent.driversComponent.licenseWasChangedAlert"></div>
    </div>
    <!-- 10 -->
    <div class="col-12 col-md-6 col-xxl-4 mb-3 mb-sm-4" [hidden]="!formDriver.get('licenseWasChanged')?.value">
      <app-form-text-field formControlName="oldLicense" [isReadonly]="isLoading"></app-form-text-field>
    </div>
    <!-- 11 -->
    <div class="col-12 col-md-6 col-xxl-4 mb-3 mb-sm-4" [hidden]="!formDriver.get('licenseWasChanged')?.value">
      <app-autocomplete
        formControlName="oldDriverLastName"
        [suggestionPart]="daDataSystemName.SURNAME"
        [isReadonly]="isLoading"></app-autocomplete>
    </div>
    @if (!((formService.legalOwner.get('legalOwnerIsJuridical')?.value && formService.form.get('isJuridical')?.value) || formService.form.get('isJuridical')?.value)) {
      <!--12-->
      <div class="col-12 col-md-6 col-xxl-6 mb-3 mb-sm-4">
        <app-form-switch formControlName="driverIsOwner" [isReadonly]="isLoading"
                         (switchOnSelect)="changeDriverIsOwnerOrInsurer($event, 'owner')"></app-form-switch>
      </div>
      <!--13-->
      <div class="col-12 col-md-6 col-xxl-6 mb-3 mb-sm-4">
        <app-form-switch formControlName="driverIsInsurer" [isReadonly]="isLoading"
                         (switchOnSelect)="changeDriverIsOwnerOrInsurer($event, 'insurer')"></app-form-switch>
      </div>
      @if (formDriver.get('driverIsOwner')?.value || formDriver.get('driverIsInsurer')?.value) {
        <!--14-->
        <div class="col-12 col-md-7 mb-3 mb-sm-4">
          <app-form-text-field formControlName="driverPassportLicense" [isReadonly]="isLoading"></app-form-text-field>
        </div>
        <!--15-->
        <div class="col-12 col-md-5 mb-3 mb-sm-4">
          <app-form-text-field formControlName="driverPassportDate" [isReadonly]="isLoading"></app-form-text-field>
        </div>
        <!--16-->
        <div class="col-12 col-md-7 mb-3 mb-sm-4">
          <app-autocomplete
            formControlName="driverCity"
            [daDataControlName]="'driverAddressDaData'"
            [suggestionPart]="daDataSystemName.ADDRESS"
            [isReadonly]="isLoading"></app-autocomplete>
        </div>
        <!--17-->
        <div class="col-12 col-md-5 mb-3 mb-md-4">
          <app-form-text-field formControlName="driverHouseNumber" [isReadonly]="isLoading"></app-form-text-field>
        </div>
      }
    }

  </div>
<!--  <pre>{{ formDriver.value | json }}</pre>-->

  <div class="row actions justify-content-end">
    @if (!driversWithoutRestriction.value && formService.drivers.controls.length > 1) {
      <div class="col-12 col-sm-auto mb-3 mb-md-4">
        <button type="button" (click)="removeDriver(driverIndex)"
                class="btn btn-outline-danger btn-remove-driver btn-lg w-100">
          <svg class="remove-driver-icon" width="32" height="24" viewBox="0 0 20 24" fill="none"
               xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19 4H15.9C15.6679 2.87141 15.0538 1.85735 14.1613 1.12872C13.2687 0.40009 12.1522 0.00145452 11 0H9C7.8478 0.00145452 6.73132 0.40009 5.83875 1.12872C4.94618 1.85735 4.3321 2.87141 4.1 4H1C0.734784 4 0.48043 4.10536 0.292893 4.29289C0.105357 4.48043 0 4.73478 0 5C0 5.26522 0.105357 5.51957 0.292893 5.70711C0.48043 5.89464 0.734784 6 1 6H2V19C2.00159 20.3256 2.52888 21.5964 3.46622 22.5338C4.40356 23.4711 5.67441 23.9984 7 24H13C14.3256 23.9984 15.5964 23.4711 16.5338 22.5338C17.4711 21.5964 17.9984 20.3256 18 19V6H19C19.2652 6 19.5196 5.89464 19.7071 5.70711C19.8946 5.51957 20 5.26522 20 5C20 4.73478 19.8946 4.48043 19.7071 4.29289C19.5196 4.10536 19.2652 4 19 4ZM9 2H11C11.6203 2.00076 12.2251 2.19338 12.7316 2.55144C13.2381 2.90951 13.6214 3.41549 13.829 4H6.171C6.37858 3.41549 6.7619 2.90951 7.26839 2.55144C7.77487 2.19338 8.37973 2.00076 9 2ZM16 19C16 19.7956 15.6839 20.5587 15.1213 21.1213C14.5587 21.6839 13.7956 22 13 22H7C6.20435 22 5.44129 21.6839 4.87868 21.1213C4.31607 20.5587 4 19.7956 4 19V6H16V19Z"/>
            <path
              d="M8.00007 17.9999C8.26529 17.9999 8.51965 17.8945 8.70718 17.707C8.89472 17.5194 9.00007 17.2651 9.00007 16.9999V10.9999C9.00007 10.7346 8.89472 10.4803 8.70718 10.2928C8.51965 10.1052 8.26529 9.99986 8.00007 9.99986C7.73486 9.99986 7.4805 10.1052 7.29297 10.2928C7.10543 10.4803 7.00007 10.7346 7.00007 10.9999V16.9999C7.00007 17.2651 7.10543 17.5194 7.29297 17.707C7.4805 17.8945 7.73486 17.9999 8.00007 17.9999Z"/>
            <path
              d="M12.0002 17.9999C12.2654 17.9999 12.5197 17.8945 12.7073 17.707C12.8948 17.5194 13.0002 17.2651 13.0002 16.9999V10.9999C13.0002 10.7346 12.8948 10.4803 12.7073 10.2928C12.5197 10.1052 12.2654 9.99986 12.0002 9.99986C11.7349 9.99986 11.4806 10.1052 11.2931 10.2928C11.1055 10.4803 11.0002 10.7346 11.0002 10.9999V16.9999C11.0002 17.2651 11.1055 17.5194 11.2931 17.707C11.4806 17.8945 11.7349 17.9999 12.0002 17.9999Z"/>
          </svg>
          {{ contentService.content.formComponent.driversComponent.removeDriverBtn }}
        </button>
      </div>
    }

    @if (!driversWithoutRestriction.value) {
      <div class="col-12 col-sm-auto mb-3 mb-md-4">
        <button type="button" (click)="addDrivers()"
                [disabled]="formService.drivers.controls.length === 5 || formDriver.invalid"
                class="btn btn-outline-primary btn-add-driver btn-lg w-100">
          <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M21 15H17V11C17 10.7348 16.8946 10.4804 16.7071 10.2929C16.5196 10.1054 16.2652 10 16 10C15.7348 10 15.4804 10.1054 15.2929 10.2929C15.1054 10.4804 15 10.7348 15 11V15H11C10.7348 15 10.4804 15.1054 10.2929 15.2929C10.1054 15.4804 10 15.7348 10 16C10 16.2652 10.1054 16.5196 10.2929 16.7071C10.4804 16.8946 10.7348 17 11 17H15V21C15 21.2652 15.1054 21.5196 15.2929 21.7071C15.4804 21.8946 15.7348 22 16 22C16.2652 22 16.5196 21.8946 16.7071 21.7071C16.8946 21.5196 17 21.2652 17 21V17H21C21.2652 17 21.5196 16.8946 21.7071 16.7071C21.8946 16.5196 22 16.2652 22 16C22 15.7348 21.8946 15.4804 21.7071 15.2929C21.5196 15.1054 21.2652 15 21 15Z"/>
          </svg>
          {{ contentService.content.formComponent.driversComponent.addDriverBtn }}
        </button>
      </div>
    }

    <div class="col-12 col-sm-auto mb-3 mb-sm-4">
      <button type="submit" class="btn btn-primary btn-lg w-100" [disabled]="isLoading">
        @if (isLoading) {
          <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
        } @else {
          <span>{{ contentService.content.formComponent.driversComponent.submitBtn }}</span>
        }
      </button>
    </div>
  </div>

</form>
