<div class="overflow-hidden">
  <div class="swiper-container">
    <div class="swiper-wrapper">
      @for (item of cacheService.cacheData.osagoPolicies; track item) {
        <div class="swiper-slide">
          <div class="swipe-card position-relative">
            <button type="button"
                    (click)="changeAppFromCache(item)"
                    [tooltip]="item?.license === licensePlate ? 'Текущая анкета уже выбрана' : ''"
                    container="body"
                    class="cache-btn">
              <div class="brand text-black">
                @if (item?.osago?.owner?.isJuridical || item?.osago?.owner?.legalOwnerIsJuridical || item?.osago?.insured?.isJuridical; ) {
                  <span class="text-secondary">Юр. лицо -</span>
                }
                {{ item?.osago?.carData.brandId ? appService.getCarBrandNameById(item.osago?.carData?.brandId) : '-' }}
              </div>
              <div class="license-number text-secondary">
                {{ item.hasNoLicensePlate ? 'Без номера' : item.license }} <a class="btn-link">Продолжить</a>
              </div>
            </button>
            @if (item.isLoading) {
              <div class="card-loader">
                <div class="spinner-border spinner-border-sm" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
              </div>
            }
            @if (isLoading && !item.isLoading) {
              <div class="card-loader"></div>
            }
          </div>
        </div>
      }
    </div>
  </div>
</div>
