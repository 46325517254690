import {inject, Injectable} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from '@angular/forms';
import {Subscription} from 'rxjs';

// Хелперы
import {subControl} from '../helpers';

// Перечисления
import {CarDocumentTypesEnum} from '../enums/car-document-types.enum';
import {CarIdentificatorsEnum} from '../enums/car-identificators.enum';

// Интерфейсы
import {IPropertyControls} from '../interface/property-controls.interface';

// Данные контролов
import {propertyControls} from "../constants/property-controls";

import {
  ICarDataFromCharacteristic,
  IDriverForm
} from "../interface/osago-application.interface";
import {PurposeOfUseEnum} from "../enums/purpose-of-use.enum";
import moment from 'moment-mini';
import {syncControls, syncControlsValue} from "../functions/subscribtionFormControls";
import {resetOwnerOrInsurerFromDriver, setOwnerOrInsurerFromDriver} from "../functions/setFormDataFromForm";
import {TypeOrganisation} from "../enums/type-organisation";
import {SettingsService} from "./settings.service";

@Injectable({
  providedIn: 'root'
})
export class FormService {

  private settingsService = inject(SettingsService);

  // Форма
  public form: UntypedFormGroup;
  // Флаг страхователь является собственником авто
  public ownerIsInsured;
  // Если собственник другой водитель
  public ownerIsOtherDriver = false;
  // Если страхователь другой водитель
  public insurerIsOtherDriver = false;
  public subscription: Subscription = new Subscription();
  // Подписка на изменения формы Водителя
  public subscriptionDriver: Subscription = new Subscription();
  // Подписка на изменения формы Страхователя Юр лица
  public subscriptionLegalInsurer: Subscription = new Subscription();
  // Подписка на изменения формы Собственник Юр лица
  public subscriptionLegalOwner: Subscription = new Subscription();
  // Данные контролов
  public propertyControls: IPropertyControls = propertyControls;

  constructor(private readonly fb: UntypedFormBuilder) {

    this.form = this.getFormGroup();

    this.addDriver();

    this.ownerIsInsured = (this.form.get('ownerIsInsured') as UntypedFormControl);

    // Подписка на контрол "Без водителей"
    this.subscribeWithoutDriverControl();

    this.subscribeFormIsJuridicalControl();
    // Подписка на изменение формы carData
    this.subscribeFormCarData();
    // Подписка на изменение формы Собственник Юр лицо
    this.subscribeFormLegalInsurer();
    // Подписка на изменение формы Страхователь Юр лицо
    this.subscribeFormLegalOwner();
    // Подписка на изменение формы Страхователь Юр лицо
    this.subscriptionLegalOwnerAndLegalInsurer();

  }

  // Форма данных авто
  get privacyLicenseFormControl(): FormControl {
    return this.form.get('privacyLicense') as FormControl;
  }

  // Форма данных авто
  get licenseFormGroup(): FormGroup {
    return this.form.get('carData') as FormGroup;
  }

  // Форма с массиво контролов водитея
  get drivers(): UntypedFormArray {
    return this.form.get('drivers') as UntypedFormArray;
  }

  // Форма данных собственника
  get owner(): UntypedFormGroup {
    return this.form.get('owner') as UntypedFormGroup;
  }

  // Форма данных страхователя
  get insurer(): UntypedFormGroup {
    return this.form.get('insurer') as UntypedFormGroup;
  }

  // Форма контактов
  get contacts(): UntypedFormGroup {
    return this.form.get('contacts') as UntypedFormGroup;
  }

  // Форма данных собственника юр лица
  get legalOwner(): UntypedFormGroup {
    return this.form.get('legalOwner') as UntypedFormGroup;
  }

  // Форма данных страхователя юр лица
  get legalInsurer(): UntypedFormGroup {
    return this.form.get('legalInsurer') as UntypedFormGroup;
  }

  // Автопарк авто
  get isAutopark(): UntypedFormControl {
    return this.form.get('isAutopark') as UntypedFormControl;
  }

  public getFormGroup(): FormGroup {
    return this.fb.group({
      apiKey: [this.settingsService.apiKey, [Validators.required]],
      applicationId: [null, [Validators.required]],
      privacyLicense: [true, [Validators.requiredTrue]],
      cpaClientUid: [null],
      carCharacteristicsRequestId: [null],
      hasDriversRestriction: [false],
      driversWithoutRestriction: [false],
      policyStartDate: [moment().add(4, 'days').format('DD.MM.YYYY')],
      isJuridical: [false],
      isAutopark: [false],
      carData: this.fb.group({
        hasNoLicensePlate: [false],
        licensePlate: [null],
        horsePower: [null],
        horsePowerKw: [null],
        diagnosticCard: this.fb.group({
          hasNoCard: [false],
        }),
        modelId: [null,],
        brandId: [null],
        brandName: [null],
        modelName: [null],
        productionYear: [null],
        carDocument: this.fb.group({
          documentIssueDate: [''],
          documentNumber: [''],
          documentSeries: [''],
          documentType: [CarDocumentTypesEnum.STS, [Validators.required]],
          stsNumber: [null, [Validators.required]],
          stsDate: [null, [Validators.required]],
          ptsNumber: [null, [Validators.required]],
          ptsDate: [null, [Validators.required]],
          ePtsNumber: [null, [Validators.required]],
          ePtsDate: [null, [Validators.required]]
        }),
        carIdentificators: this.fb.group({
          identifier: [CarIdentificatorsEnum.VIN, [Validators.required]],
          vinNumber: [null, [Validators.required]],
          bodyNumber: [null, [Validators.required]],
          chassisNumber: [null, [Validators.required]],
        }),
        purposeOfUse: [PurposeOfUseEnum.Personal, [Validators.required]],
        carWithPrince: false,
        horsePowerSwitch: false
      }),
      drivers: this.fb.array([]),
      owner: this.fb.group({
        ownerFirstName: [null, [Validators.required]],
        ownerLastName: [null, [Validators.required]],
        ownerMiddleName: [null],
        ownerBirthDate: [null, [Validators.required]],
        passportLicense: [null, [Validators.required]],
        ownerPassportDate: [null, [Validators.required]],
        ownerCity: [null, [Validators.required]],
        ownerHouseNumber: [null],
        ownerAddressDaData: [null],
        ownerIsJuridical: [false],
      }),
      ownerIsInsured: [false],
      insurer: this.fb.group({
        insurerFirstName: [null, [Validators.required]],
        insurerLastName: [null, [Validators.required]],
        insurerMiddleName: [null],
        insurerBirthDate: [null, [Validators.required]],
        passportLicense: [null,],
        insurerPassportDate: [null, [Validators.required]],
        insurerCity: [null, [Validators.required]],
        insurerHouseNumber: [null],
        insurerAddressDaData: [null],
        insurerIsJuridical: [false],
      }),
      legalInsurer: this.fb.group({
        legalInsurerValue: [null],
        legalInsurerType: [null],
        legalInsurerDaData: [null],
        legalInsurerName: [null, [Validators.required]],
        legalInsurerInn: [null, [Validators.required]],
        legalInsurerOgrn: [null, [Validators.required]],
        legalInsurerKpp: [null, [Validators.required]],
        legalInsurerEgryl: [null, [Validators.required]],
        legalInsurerAddress: [null, [Validators.required]],
        legalInsurerAddressDaData: [null, [Validators.required]],
        legalInsurerHouseNumber: [null],
        legalInsurerRegCertificate: [null],
        legalInsurerRegCertificateDateIssue: [null],
        legalInsurerWithoutCertificate: true,
        legalInsurerIsLegalOwner: true,
        legalInsurerFirstName: [null, [Validators.required]],
        legalInsurerLastName: [null, [Validators.required]],
        legalInsurerMiddleName: [null],
        legalInsurerBirthDate: [null, [Validators.required]],
        passportLicense: [null,],
        legalInsurerPassportDate: [null, [Validators.required]],
      }),
      legalOwner: this.fb.group({
        legalOwnerIsJuridical: [true],
        legalOwnerIsLegal: this.fb.group({
          legalOwnerValue: [null],
          legalOwnerType: [null],
          legalOwnerDaData: [null, [Validators.required]],
          legalOwnerName: [null, [Validators.required]],
          legalOwnerInn: [null, [Validators.required]],
          legalOwnerOgrn: [null, [Validators.required]],
          legalOwnerKpp: [null, [Validators.required]],
          legalOwnerEgryl: [null, [Validators.required]],
          legalOwnerLegalAddress: [null, [Validators.required]],
          legalOwnerLegalAddressDaData: [null, [Validators.required]],
          legalOwnerLegalHouseNumber: [null],
          legalOwnerRegCertificate: [null],
          legalOwnerRegCertificateDateIssue: [null],
          legalOwnerWithoutCertificate: true,
          legalOwnerFirstName: [null, [Validators.required]],
          legalOwnerLastName: [null, [Validators.required]],
          legalOwnerMiddleName: [null],
          legalOwnerBirthDate: [null, [Validators.required]],
          legalOwnerPassportLicense: [null,],
          legalOwnerPassportDate: [null, [Validators.required]],
        }),
        legalOwnerIsNotLegal: this.fb.group({
          legalOwnerFirstName: [null, [Validators.required]],
          legalOwnerLastName: [null, [Validators.required]],
          legalOwnerMiddleName: [null],
          legalOwnerBirthDate: [null, [Validators.required]],
          legalOwnerPassportLicense: [null, [Validators.required]],
          legalOwnerPassportDate: [null, [Validators.required]],
          legalOwnerCity: [null, [Validators.required]],
          legalOwnerHouseNumber: [null],
          legalOwnerAddressDaData: [null],
        })
      }),
      contacts: this.fb.group({
        email: [null, [Validators.required]],
        phone: [null, [Validators.required]]
      }),
    })
  }

  // Новый водитель
  public newDriver(driver?: IDriverForm): UntypedFormGroup {
    return this.fb.group({
      driverFirstName: [driver?.driverFirstName, Validators.required],
      driverLastName: [driver?.driverLastName, Validators.required],
      oldDriverLastName: [driver?.oldDriverLastName, Validators.required],
      driverMiddleName: [driver?.driverMiddleName],
      driverBirthDate: [driver?.driverBirthDate, Validators.required],
      driverLicense: [driver?.driverLicense, Validators.required],
      oldLicense: [driver?.oldLicense, Validators.required],
      experienceDate: [driver?.experienceDate, Validators.required],
      experienceYear: [driver?.experienceYear, Validators.required],
      rememberOnlyYear: [driver?.rememberOnlyYear || false],
      licenseWasChanged: [driver?.licenseWasChanged || false],
      driverIsOwner: [driver?.driverIsOwner || false],
      driverIsInsurer: [driver?.driverIsInsurer || false],
      driverPassportLicense: [driver?.driverPassportLicense, [Validators.required]],
      driverPassportDate: [driver?.driverPassportDate, [Validators.required]],
      driverCity: [driver?.driverCity, [Validators.required]],
      driverHouseNumber: [driver?.driverHouseNumber],
      driverAddressDaData: [driver?.driverAddressDaData],
    });
  }

  // Добавить нового водителя
  public addDriver(): number {
    this.drivers.push(this.newDriver());
    // TODO временное решение
    setTimeout(() => {
      this.subscribeFormDriver(this.drivers.controls[this.drivers.controls.length - 1] as UntypedFormGroup);
    }, 0);
    return this.drivers.length;
  }

  // Удалить водителя
  public removeDriver(i: number): void {
    this.drivers.removeAt(i);
  }

  // Подписка на контрол "Без водителей"
  public subscribeWithoutDriverControl(): void {
    const driversWithoutRestriction = this.form.get('driversWithoutRestriction');
    const driversFormGroup = this.drivers;
    const isJuridical = this.form.get('isJuridical');
    const legalOwnerFormGroup = this.legalOwner;
    const legalInsurerFormGroup = this.legalInsurer;

    const driversIsAvailable = () => {
      return ((legalInsurerFormGroup.get('legalInsurerType')?.value === TypeOrganisation.INDIVIDUAL && isJuridical?.value && !driversWithoutRestriction?.value)
        || (!isJuridical?.value && !driversWithoutRestriction?.value))
    };

    // TODO нужно заново блочить контролы для собственника и страхователя в случае если снова выключили контрол
    this.subscription.add(subControl(driversWithoutRestriction!, driversFormGroup!, driversIsAvailable));
  };

  // Подписка на изменение контрола "Юр | Физ лицо"
  public subscribeFormIsJuridicalControl(): void {
    const isJuridical = this.form.get('isJuridical');
    const legalOwnerIsJuridical = (this.legalOwner.get('legalOwnerIsJuridical') as FormControl);
    const driversFormGroup = this.drivers;
    const ownerFormGroup = this.owner;
    const insurerFormGroup = this.insurer;
    const legalOwnerFormGroup = this.legalOwner;
    const legalInsurerFormGroup = this.legalInsurer;
    const driversWithoutRestriction = this.form.get('driversWithoutRestriction');

    const driversIsAvailable = () => {
      return ((legalInsurerFormGroup.get('legalInsurerType')?.value === TypeOrganisation.INDIVIDUAL && isJuridical?.value && !driversWithoutRestriction?.value)
        || (!isJuridical?.value && !driversWithoutRestriction?.value))
    };

    this.subscription.add(subControl(isJuridical!, ownerFormGroup!, () => !isJuridical?.value));
    this.subscription.add(subControl(isJuridical!, insurerFormGroup!, () => !isJuridical?.value));
    this.subscription.add(subControl(isJuridical!, legalOwnerFormGroup!, () => isJuridical?.value));
    this.subscription.add(subControl(isJuridical!, legalInsurerFormGroup!, () => isJuridical?.value));

    // this.subscription.add(subControl(legalInsurerFormGroup.get('legalInsurerIsLegalOwner')!, legalOwnerFormGroup!, () => !legalInsurerFormGroup.get('legalInsurerIsLegalOwner')?.value));

    const legalOwnerIsLegal = (this.legalOwner.get('legalOwnerIsLegal') as FormGroup);
    const legalOwnerIsNotLegal = (this.legalOwner.get('legalOwnerIsNotLegal') as FormGroup);
    this.subscriptionLegalOwner.add(subControl(isJuridical!, legalOwnerIsLegal!, () => legalOwnerIsJuridical?.value && isJuridical?.value));
    this.subscriptionLegalOwner.add(subControl(isJuridical!, legalOwnerIsNotLegal!, () => !legalOwnerIsJuridical?.value && isJuridical?.value));

    this.subscription.add(subControl(isJuridical!, driversFormGroup!, driversIsAvailable));
    this.subscription.add(subControl(legalInsurerFormGroup.get('legalInsurerType')!, driversFormGroup!, driversIsAvailable));
  }

  // Подписка на изменения формы carData
  public subscribeFormCarData(): void {
    const {
      brandId,
      modelId,
      carDocument,
      carIdentificators,
      productionYear,
      horsePower,
      horsePowerKw,
      horsePowerSwitch
    } = (this.form.get('carData') as UntypedFormGroup)?.controls;

    const isDocTypeSts = () => carDocument?.get('documentType')?.value === CarDocumentTypesEnum.STS;
    const isDocTypePts = () => carDocument?.get('documentType')?.value === CarDocumentTypesEnum.PTS;
    const isDocTypeEpts = () => carDocument?.get('documentType')?.value === CarDocumentTypesEnum.ePTS;

    const isIdentificatorVin = () => carIdentificators.get('identifier')?.value === CarIdentificatorsEnum.VIN;
    const isDocTypeBody = () => carIdentificators.get('identifier')?.value === CarIdentificatorsEnum.BodyNumber;
    const isDocTypeChassis = () => carIdentificators.get('identifier')?.value === CarIdentificatorsEnum.ChassisNumber;

    this.subscription.add(subControl(brandId!, modelId!));
    this.subscription.add(subControl(modelId!, productionYear!));
    this.subscription.add(subControl(productionYear!, horsePower!));

    this.subscriptionDriver.add(subControl(horsePowerSwitch, horsePower, () => !horsePowerSwitch.value));
    this.subscriptionDriver.add(subControl(horsePowerSwitch, horsePowerKw, () => horsePowerSwitch.value));

    this.subscription.add(subControl(carDocument?.get('documentType')!, carDocument?.get('stsNumber')!, isDocTypeSts));
    this.subscription.add(subControl(carDocument?.get('documentType')!, carDocument?.get('ptsNumber')!, isDocTypePts));
    this.subscription.add(subControl(carDocument?.get('documentType')!, carDocument?.get('ePtsNumber')!, isDocTypeEpts));
    this.subscription.add(subControl(carDocument?.get('documentType')!, carDocument?.get('stsDate')!, isDocTypeSts));
    this.subscription.add(subControl(carDocument?.get('documentType')!, carDocument?.get('ptsDate')!, isDocTypePts));
    this.subscription.add(subControl(carDocument?.get('documentType')!, carDocument?.get('ePtsDate')!, isDocTypeEpts));
    //
    this.subscription.add(subControl(carIdentificators.get('identifier')!, carIdentificators.get('vinNumber')!, isIdentificatorVin));
    this.subscription.add(subControl(carIdentificators.get('identifier')!, carIdentificators.get('bodyNumber')!, isDocTypeBody));
    this.subscription.add(subControl(carIdentificators.get('identifier')!, carIdentificators.get('chassisNumber')!, isDocTypeChassis));
  }

  // Подписка на изменения формы driver
  public subscribeFormDriver(formDriver: UntypedFormGroup): void {
    // TODO нужно посмотреть сколько подписок в subscription на протяжении всей формы

    this.subscriptionDriverAndOwner(formDriver);
    this.subscriptionDriverAndInsurer(formDriver);
    this.subscriptionOwnerAndInsurer();

    const {
      rememberOnlyYear,
      experienceDate,
      experienceYear,
      licenseWasChanged,
      oldLicense,
      oldDriverLastName,
      driverIsOwner,
      driverIsInsurer,
      driverPassportLicense,
      driverPassportDate,
      driverCity,
      driverHouseNumber,
      driverAddressDaData,
    } = (formDriver as UntypedFormGroup)?.controls;

    const driversWithoutRestriction = this.form.get('driversWithoutRestriction') as FormControl;
    const legalOwnerIsJuridical = (this.legalOwner.get('legalOwnerIsJuridical') as FormControl);
    const legalInsurerType = this.legalInsurer.get('legalInsurerType');

    this.subscriptionDriver.add(subControl(rememberOnlyYear, experienceDate, () => !rememberOnlyYear.value));
    this.subscriptionDriver.add(subControl(rememberOnlyYear, experienceYear, () => rememberOnlyYear.value));

    this.subscriptionDriver.add(subControl(licenseWasChanged, oldLicense, () => licenseWasChanged.value));
    this.subscriptionDriver.add(subControl(licenseWasChanged, oldDriverLastName, () => licenseWasChanged.value));

    const isDriverIsOwnerOrInsurer = () => ((driverIsOwner.value || driverIsInsurer?.value) && !driversWithoutRestriction.value);

    this.subscriptionDriver.add(subControl(driverIsOwner, driverPassportLicense, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(driverIsOwner, driverPassportDate, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(driverIsOwner, driverCity, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(driverIsOwner, driverHouseNumber, isDriverIsOwnerOrInsurer));

    this.subscriptionDriver.add(subControl(driverIsInsurer, driverPassportLicense, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(driverIsInsurer, driverPassportDate, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(driverIsInsurer, driverCity, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(driverIsInsurer, driverHouseNumber, isDriverIsOwnerOrInsurer));

    this.subscriptionDriver.add(subControl(driversWithoutRestriction, driverPassportLicense, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(driversWithoutRestriction, driverPassportDate, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(driversWithoutRestriction, driverCity, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(driversWithoutRestriction, driverHouseNumber, isDriverIsOwnerOrInsurer));

    this.subscriptionDriver.add(subControl(legalInsurerType!, driverPassportLicense, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(legalInsurerType!, driverPassportDate, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(legalInsurerType!, driverCity, isDriverIsOwnerOrInsurer));
    this.subscriptionDriver.add(subControl(legalInsurerType!, driverHouseNumber, isDriverIsOwnerOrInsurer));
  }

  // Подписка на синхронизацию форм Водителя и Собственника у физ лиц
  private subscriptionDriverAndOwner(formDriver: FormGroup): void {
    const {
      driverFirstName,
      driverLastName,
      driverMiddleName,
      driverBirthDate,
      driverPassportLicense,
      driverPassportDate,
      driverCity,
      driverHouseNumber,
      driverAddressDaData,
      driverIsOwner
    } = (formDriver as UntypedFormGroup)?.controls;
    const {
      ownerFirstName,
      ownerLastName,
      ownerMiddleName,
      ownerBirthDate,
      passportLicense,
      ownerPassportDate,
      ownerCity,
      ownerHouseNumber,
      ownerAddressDaData,
    } = (this.owner as UntypedFormGroup)?.controls;

    let subscription = new Subscription();
    this.subscription.add(
      driverIsOwner.valueChanges.subscribe((value) => {
        if (value) {
          subscription = new Subscription();
          subscription.add(syncControlsValue(driverLastName, ownerLastName, driverIsOwner));
          subscription.add(syncControlsValue(driverFirstName, ownerFirstName, driverIsOwner));
          subscription.add(syncControlsValue(driverMiddleName, ownerMiddleName, driverIsOwner));
          subscription.add(syncControlsValue(driverBirthDate, ownerBirthDate, driverIsOwner));

          subscription.add(syncControlsValue(driverPassportLicense, passportLicense, driverIsOwner));
          subscription.add(syncControlsValue(passportLicense, driverPassportLicense, driverIsOwner));

          subscription.add(syncControlsValue(driverPassportDate, ownerPassportDate, driverIsOwner));
          subscription.add(syncControlsValue(ownerPassportDate, driverPassportDate, driverIsOwner));

          subscription.add(syncControlsValue(driverCity, ownerCity, driverIsOwner));
          subscription.add(syncControlsValue(ownerCity, driverCity, driverIsOwner));

          subscription.add(syncControlsValue(driverAddressDaData, ownerAddressDaData, driverIsOwner));
          subscription.add(syncControlsValue(ownerAddressDaData, driverAddressDaData, driverIsOwner));

          subscription.add(syncControlsValue(driverHouseNumber, ownerHouseNumber, driverIsOwner));
          subscription.add(syncControlsValue(ownerHouseNumber, driverHouseNumber, driverIsOwner));
        } else {
          subscription.unsubscribe();
        }
      })
    );
  }

  // Подписка на синхронизацию форм Водителя и Страхователя у физ лиц
  public subscriptionDriverAndInsurer(formDriver: FormGroup): void {
    const {
      driverFirstName,
      driverLastName,
      driverMiddleName,
      driverBirthDate,
      driverPassportLicense,
      driverPassportDate,
      driverCity,
      driverHouseNumber,
      driverAddressDaData,
      driverIsOwner,
      driverIsInsurer
    } = (formDriver as UntypedFormGroup)?.controls;
    const {
      insurerFirstName,
      insurerLastName,
      insurerMiddleName,
      insurerBirthDate,
      passportLicense,
      insurerPassportDate,
      insurerCity,
      insurerHouseNumber,
      insurerAddressDaData,
    } = (this.insurer as UntypedFormGroup)?.controls;

    let subscription = new Subscription();
    this.subscription.add(
      driverIsInsurer.valueChanges.subscribe((value) => {
        if (value) {
          subscription = new Subscription();
          subscription.add(syncControlsValue(driverLastName, insurerLastName, driverIsInsurer));
          subscription.add(syncControlsValue(driverFirstName, insurerFirstName, driverIsInsurer));
          subscription.add(syncControlsValue(driverMiddleName, insurerMiddleName, driverIsInsurer));
          subscription.add(syncControlsValue(driverBirthDate, insurerBirthDate, driverIsInsurer));

          subscription.add(syncControlsValue(driverPassportLicense, passportLicense, driverIsInsurer));
          subscription.add(syncControlsValue(passportLicense, driverPassportLicense, driverIsInsurer));

          subscription.add(syncControlsValue(driverPassportDate, insurerPassportDate, driverIsInsurer));
          subscription.add(syncControlsValue(insurerPassportDate, driverPassportDate, driverIsInsurer));

          subscription.add(syncControlsValue(driverCity, insurerCity, driverIsInsurer));
          subscription.add(syncControlsValue(insurerCity, driverCity, driverIsInsurer));

          subscription.add(syncControlsValue(driverAddressDaData, insurerAddressDaData, driverIsInsurer));
          subscription.add(syncControlsValue(insurerAddressDaData, driverAddressDaData, driverIsInsurer));

          subscription.add(syncControlsValue(driverHouseNumber, insurerHouseNumber, driverIsInsurer));
          subscription.add(syncControlsValue(insurerHouseNumber, driverHouseNumber, driverIsInsurer));
        } else {
          subscription.unsubscribe();
        }
      })
    );
  }

  // Подписак на синхронизацию форм Собсвтенника и Страхователя у физ лиц
  private subscriptionOwnerAndInsurer(): void {
    const owner = (this.owner as UntypedFormGroup);
    const insurer = (this.insurer as UntypedFormGroup);

    let subscription = new Subscription();
    this.subscription.add(
      this.ownerIsInsured?.valueChanges.subscribe((value) => {
        if (value) {
          subscription = new Subscription();
          // subscription.add(syncControlsValue(owner.get('ownerBirthDate')!, insurer.get('insurerBirthDate')!, this.ownerIsInsured));
          // subscription.add(syncControlsValue(owner.get('ownerBirthDate')!, insurer.get('insurerBirthDate')!, this.ownerIsInsured));

          subscription.add(syncControlsValue(owner.get('passportLicense')!, insurer.get('passportLicense')!, this.ownerIsInsured));
          subscription.add(syncControlsValue(insurer.get('passportLicense')!, owner.get('passportLicense')!, this.ownerIsInsured));

          // TODO кода мы изменили дату но не до конца и выбрали "другой"
          //  то дата синхронизируется с водителем или страхователем или
          //  со всеми ими, и там дата не до конца заполнена и это ошибка
          subscription.add(syncControlsValue(owner.get('ownerPassportDate')!, insurer.get('insurerPassportDate')!, this.ownerIsInsured));
          subscription.add(syncControlsValue(insurer.get('insurerPassportDate')!, owner.get('ownerPassportDate')!, this.ownerIsInsured));

          subscription.add(syncControlsValue(owner.get('ownerCity')!, insurer.get('insurerCity')!, this.ownerIsInsured));
          subscription.add(syncControlsValue(insurer.get('insurerCity')!, owner.get('ownerCity')!, this.ownerIsInsured));

          subscription.add(syncControlsValue(owner.get('ownerAddressDaData')!, insurer.get('insurerAddressDaData')!, this.ownerIsInsured));
          subscription.add(syncControlsValue(insurer.get('insurerAddressDaData')!, owner.get('ownerAddressDaData')!, this.ownerIsInsured));

          subscription.add(syncControlsValue(owner.get('ownerHouseNumber')!, insurer.get('insurerHouseNumber')!, this.ownerIsInsured));
          subscription.add(syncControlsValue(insurer.get('insurerHouseNumber')!, owner.get('ownerHouseNumber')!, this.ownerIsInsured));
        } else {
          subscription.unsubscribe();
        }
      })
    );
  }

  // Подписка на изменение формы Страхоррвателя у Юр лица
  public subscribeFormLegalInsurer(): void {
    const {
      legalInsurerType,
      legalInsurerDaData,
      legalInsurerName,
      legalInsurerInn,
      legalInsurerOgrn,
      legalInsurerKpp,
      legalInsurerAddress,
      legalInsurerHouseNumber,
      legalInsurerIsLegalOwner,
      legalInsurerFirstName,
      legalInsurerLastName,
      legalInsurerMiddleName,
      legalInsurerBirthDate,
      passportLicense,
      legalInsurerPassportDate,
      legalInsurerWithoutCertificate,
      legalInsurerRegCertificate,
      legalInsurerRegCertificateDateIssue,
      legalInsurerEgryl
    } = (this.legalInsurer as FormGroup)?.controls;

    this.subscriptionLegalInsurer.add(subControl(legalInsurerWithoutCertificate!, legalInsurerEgryl!, () => legalInsurerWithoutCertificate?.value));

    this.subscriptionLegalInsurer.add(subControl(legalInsurerWithoutCertificate!, legalInsurerRegCertificate!, () => !legalInsurerWithoutCertificate?.value));
    this.subscriptionLegalInsurer.add(subControl(legalInsurerWithoutCertificate!, legalInsurerRegCertificateDateIssue!, () => !legalInsurerWithoutCertificate?.value));

    this.subscriptionLegalInsurer.add(subControl(legalInsurerType!, legalInsurerOgrn!, () => legalInsurerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalInsurerType!, legalInsurerKpp!, () => legalInsurerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalInsurerType!, legalInsurerWithoutCertificate!, () => legalInsurerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalInsurerType!, legalInsurerEgryl!, () => legalInsurerType?.value === TypeOrganisation.LEGAL && legalInsurerWithoutCertificate?.enabled));

    this.subscriptionLegalInsurer.add(subControl(legalInsurerType!, legalInsurerFirstName!, () => legalInsurerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalInsurerType!, legalInsurerLastName!, () => legalInsurerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalInsurerType!, legalInsurerMiddleName!, () => legalInsurerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalInsurerType!, legalInsurerBirthDate!, () => legalInsurerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalInsurerType!, passportLicense!, () => legalInsurerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalInsurerType!, legalInsurerPassportDate!, () => legalInsurerType?.value === TypeOrganisation.INDIVIDUAL));

    // this.subscriptionLegalInsurer.add(legalInsurerType.valueChanges.subscribe((value) => {
    //   if (value === TypeOrganisation.LEGAL) {}
    // }));
  }

  // Подписка на изменение формы Собственника у Юр лица
  public subscribeFormLegalOwner(): void {
    const legalOwnerIsJuridical = (this.legalOwner.get('legalOwnerIsJuridical') as FormControl);
    const legalOwnerIsLegal = (this.legalOwner.get('legalOwnerIsLegal') as FormGroup);
    const legalOwnerIsNotLegal = (this.legalOwner.get('legalOwnerIsNotLegal') as FormGroup);
    const legalInsurerFormGroup = this.legalInsurer;

    this.subscriptionLegalOwner.add(subControl(legalOwnerIsJuridical!, legalOwnerIsLegal!, () => legalOwnerIsJuridical?.value));
    this.subscriptionLegalOwner.add(subControl(legalOwnerIsJuridical!, legalOwnerIsNotLegal!, () => !legalOwnerIsJuridical?.value));

    const {
      legalOwnerWithoutCertificate,
      legalOwnerRegCertificate,
      legalOwnerRegCertificateDateIssue,
      legalOwnerEgryl,
      legalOwnerType,
      legalOwnerOgrn,
      legalOwnerKpp,
      legalOwnerFirstName,
      legalOwnerLastName,
      legalOwnerMiddleName,
      legalOwnerBirthDate,
      legalOwnerPassportLicense,
      legalOwnerPassportDate,
    } = (legalOwnerIsLegal as FormGroup)?.controls;


    this.subscriptionLegalInsurer.add(subControl(legalOwnerWithoutCertificate!, legalOwnerEgryl!, () => legalOwnerWithoutCertificate?.value && legalOwnerType?.value === TypeOrganisation.LEGAL));

    this.subscriptionLegalInsurer.add(subControl(legalOwnerWithoutCertificate!, legalOwnerRegCertificate!, () => !legalOwnerWithoutCertificate?.value && legalOwnerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerWithoutCertificate!, legalOwnerRegCertificateDateIssue!, () => !legalOwnerWithoutCertificate?.value && legalOwnerType?.value === TypeOrganisation.LEGAL));

    this.subscriptionLegalInsurer.add(subControl(legalInsurerFormGroup.get('legalInsurerIsLegalOwner')!, legalOwnerEgryl!, () => legalOwnerWithoutCertificate?.value && legalOwnerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalInsurerFormGroup.get('legalInsurerIsLegalOwner')!, legalOwnerRegCertificate!, () => !legalOwnerWithoutCertificate?.value && legalOwnerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalInsurerFormGroup.get('legalInsurerIsLegalOwner')!, legalOwnerRegCertificateDateIssue!, () => !legalOwnerWithoutCertificate?.value && legalOwnerType?.value === TypeOrganisation.LEGAL));

    this.subscriptionLegalInsurer.add(subControl(legalOwnerType!, legalOwnerOgrn!, () => legalOwnerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerType!, legalOwnerKpp!, () => legalOwnerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerType!, legalOwnerEgryl!, () => legalOwnerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerType!, legalOwnerRegCertificate!, () => !legalOwnerWithoutCertificate?.value && legalOwnerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerType!, legalOwnerRegCertificateDateIssue!, () => !legalOwnerWithoutCertificate?.value && legalOwnerType?.value === TypeOrganisation.LEGAL));

    this.subscriptionLegalInsurer.add(subControl(legalOwnerIsJuridical!, legalOwnerOgrn!, () => legalOwnerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerIsJuridical!, legalOwnerKpp!, () => legalOwnerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerIsJuridical!, legalOwnerEgryl!, () => legalOwnerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerIsJuridical!, legalOwnerRegCertificate!, () => !legalOwnerWithoutCertificate?.value && legalOwnerType?.value === TypeOrganisation.LEGAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerIsJuridical!, legalOwnerRegCertificateDateIssue!, () => !legalOwnerWithoutCertificate?.value && legalOwnerType?.value === TypeOrganisation.LEGAL));

    this.subscriptionLegalInsurer.add(subControl(legalOwnerType!, legalOwnerFirstName!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerType!, legalOwnerLastName!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerType!, legalOwnerMiddleName!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerType!, legalOwnerBirthDate!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerType!, legalOwnerPassportLicense!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerType!, legalOwnerPassportDate!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));

    this.subscriptionLegalInsurer.add(subControl(legalOwnerIsJuridical!, legalOwnerFirstName!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerIsJuridical!, legalOwnerLastName!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerIsJuridical!, legalOwnerMiddleName!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerIsJuridical!, legalOwnerBirthDate!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerIsJuridical!, legalOwnerPassportLicense!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));
    this.subscriptionLegalInsurer.add(subControl(legalOwnerIsJuridical!, legalOwnerPassportDate!, () => legalOwnerType?.value === TypeOrganisation.INDIVIDUAL));
  }

  // Подписка на синхронизацию форм Собсвтенника и Страхователя у юр лиц
  public subscriptionLegalOwnerAndLegalInsurer(): void {
    const legalOwner = (this.legalOwner as UntypedFormGroup);
    const legalInsurer = (this.legalInsurer as UntypedFormGroup);

    const {
      legalInsurerValue,
      legalInsurerType,
      legalInsurerDaData,
      legalInsurerName,
      legalInsurerInn,
      legalInsurerOgrn,
      legalInsurerKpp,
      legalInsurerEgryl,
      legalInsurerWithoutCertificate,
      legalInsurerRegCertificateDateIssue,
      legalInsurerRegCertificate,
      legalInsurerAddress,
      legalInsurerHouseNumber,
      legalInsurerAddressDaData,
      legalInsurerIsLegalOwner,

      legalInsurerFirstName,
      legalInsurerLastName,
      legalInsurerMiddleName,
      legalInsurerBirthDate,
      passportLicense,
      legalInsurerPassportDate,
    } = legalInsurer?.controls;
    const {
      legalOwnerValue,
      legalOwnerType,
      legalOwnerDaData,
      legalOwnerName,
      legalOwnerInn,
      legalOwnerOgrn,
      legalOwnerKpp,
      legalOwnerEgryl,
      legalOwnerWithoutCertificate,
      legalOwnerRegCertificateDateIssue,
      legalOwnerRegCertificate,
      legalOwnerLegalAddress,
      legalOwnerLegalHouseNumber,
      legalOwnerLegalAddressDaData,

      legalOwnerFirstName,
      legalOwnerLastName,
      legalOwnerMiddleName,
      legalOwnerBirthDate,
      legalOwnerPassportLicense,
      legalOwnerPassportDate,
    } = (legalOwner?.get('legalOwnerIsLegal') as FormGroup)?.controls;
    // const {
    //   legalOwnerFirstName,
    //   legalOwnerLastName,
    //   legalOwnerMiddleName,
    //   legalOwnerBirthDate,
    //   legalOwnerPassportLicense,
    //   legalOwnerPassportDate,
    //   legalOwnerCity,
    //   legalOwnerHouseNumber,
    //   legalOwnerAddressDaData,
    // } = (legalOwner?.get('legalOwnerIsNotLegal') as FormGroup)?.controls;

    let subscription = new Subscription();
    this.subscription.add(
      legalInsurerIsLegalOwner?.valueChanges.subscribe((value) => {
        if (value) {
          subscription = new Subscription();
          subscription.add(syncControls(legalInsurerValue!, legalOwnerValue!));

          subscription.add(syncControls(legalInsurerType!, legalOwnerType!, true, true));

          subscription.add(syncControls(legalInsurerDaData!, legalOwnerDaData!));

          subscription.add(syncControls(legalInsurerName!, legalOwnerName!));

          subscription.add(syncControls(legalInsurerInn!, legalOwnerInn!));

          subscription.add(syncControls(legalInsurerOgrn!, legalOwnerOgrn!));

          subscription.add(syncControls(legalInsurerKpp!, legalOwnerKpp!));

          subscription.add(syncControls(legalInsurerEgryl!, legalOwnerEgryl!));

          subscription.add(syncControls(legalInsurerAddress!, legalOwnerLegalAddress!));

          subscription.add(syncControls(legalInsurerHouseNumber!, legalOwnerLegalHouseNumber!));

          subscription.add(syncControls(legalInsurerAddressDaData!, legalOwnerLegalAddressDaData!));

          subscription.add(syncControls(legalInsurerWithoutCertificate!, legalOwnerWithoutCertificate!));

          subscription.add(syncControls(legalInsurerRegCertificateDateIssue!, legalOwnerRegCertificateDateIssue!));

          subscription.add(syncControls(legalInsurerRegCertificate!, legalOwnerRegCertificate!));

          subscription.add(syncControls(legalInsurerFirstName!, legalOwnerFirstName!));

          subscription.add(syncControls(legalInsurerLastName!, legalOwnerLastName!));

          subscription.add(syncControls(legalInsurerMiddleName!, legalOwnerMiddleName!));

          subscription.add(syncControls(legalInsurerBirthDate!, legalOwnerBirthDate!));

          subscription.add(syncControls(passportLicense!, legalOwnerPassportLicense!));

          subscription.add(syncControls(legalInsurerPassportDate!, legalOwnerPassportDate!));

          // subscription.add(syncControls(legalInsurerAddress!, legalOwnerCity!));
          // subscription.add(syncControls(legalOwnerCity!, legalInsurerAddress!));
          //
          // subscription.add(syncControls(legalInsurerHouseNumber!, legalOwnerHouseNumber!));
          // subscription.add(syncControls(legalOwnerHouseNumber!, legalInsurerHouseNumber!));
          //
          // subscription.add(syncControls(legalInsurerAddressDaData!, legalOwnerAddressDaData!));
          // subscription.add(syncControls(legalOwnerAddressDaData!, legalInsurerAddressDaData!));
        } else {
          subscription.unsubscribe();
        }
      })
    );
  }

  public validateAllFormFields(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof UntypedFormControl) {
        control.markAsUntouched({onlySelf: true});
        control.markAsPristine();
      } else if (control instanceof UntypedFormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }

  // Применяем данные авто из запроса характеристик
  public setCarDataFromCharacteristicRequest(carData: ICarDataFromCharacteristic): void {
    const carDataFormGroup: FormGroup = this.form.get('carData') as FormGroup;

    const {
      modelId,
      modelName,
      brandId,
      brandName,
      productionYear,
      horsePower,
      horsePowerKw,
      carDocument,
      carIdentificators
    } = carDataFormGroup.controls;

    brandId.setValue(carData.brandId);
    modelId.setValue(carData.modelId);
    brandName.setValue(carData.brandName);
    modelName.setValue(carData.modelName);
    productionYear.setValue(carData.productionYear?.toString());
    horsePower.setValue(carData.carPower?.toString());
    horsePowerKw.setValue(carData.carPowerKw?.toString());

    carDocument.get('stsNumber')?.setValue(carData.stsSeriesNumber?.toString());
    carDocument.get('stsDate')?.setValue(moment(carData.stsIssueDate).format("DD.MM.YYYY"));

    if (carData.vin) {
      carIdentificators.get('identifier')?.setValue(CarIdentificatorsEnum.VIN);
      carIdentificators.get('vinNumber')?.setValue(carData.vin?.toString());
    } else if (carData.bodyNumber) {
      carIdentificators.get('identifier')?.setValue(CarIdentificatorsEnum.BodyNumber)
      carIdentificators.get('bodyNumber')?.setValue(carData.bodyNumber?.toString());
    } else if (carData.chassisNumber) {
      carIdentificators.get('identifier')?.setValue(CarIdentificatorsEnum.ChassisNumber)
      carIdentificators.get('chassisNumber')?.setValue(carData.chassisNumber?.toString());
    }

  }

  // Сбрасываем форму
  public resetForm(): void {
    // this.subscription.unsubscribe();
    // this.subscriptionDriver.unsubscribe();
    // this.subscriptionLegalInsurer.unsubscribe();
    // this.subscriptionLegalOwner.unsubscribe();

    // resetCarData(this.licenseFormGroup);
    // this.form.get('driversWithoutRestriction')?.setValue(false);
    // Удаляем всех водителей
    this.drivers.clear();
    // Добавляем первого дефолтного водителя
    this.addDriver();
    this.owner.reset();
    this.insurer.reset();
    this.contacts.reset();

    this.form.get('driversWithoutRestriction')?.setValue(false);

    this.subscribeWithoutDriverControl();
    // this.subscribeFormIsJuridicalControl();
    // // Подписка на изменение формы carData
    // this.subscribeFormCarData();
    // // Подписка на изменение формы Собственник Юр лицо
    // this.subscribeFormLegalInsurer();
    // // Подписка на изменение формы Страхователь Юр лицо
    // this.subscribeFormLegalOwner();
    // // Подписка на изменение формы Страхователь Юр лицо
    // this.subscriptionLegalOwnerAndLegalInsurer();
  }

  public changeDriverIsOwnerOrInsurer(event: boolean, formGroupName: string, driverIndex?: number, isNotReset: boolean = false): void {
    const formGroup = formGroupName === 'owner' ? this.owner : this.insurer;
    if (!isNotReset) {
      resetOwnerOrInsurerFromDriver(formGroup, formGroupName);
    }
    // this.ownerIsInsured.setValue(false, { emitEvent: false });

    this.drivers.controls.forEach((driver, index) => {
      // Получаем текущего активного водителя
      const activeDriver = index === driverIndex;
      // Для всех неактивных, отключаем выбарный контрол driverIsOwner или driverIsInsurer
      if (activeDriver && event) {
        setOwnerOrInsurerFromDriver(driver.value, formGroup, formGroupName);
      }

      if (activeDriver) {
        if (formGroupName === 'owner') {
          driver.get('driverIsOwner')?.setValue(true, { emitEvent: true });
          this.ownerIsInsured.setValue(event && driver.get('driverIsInsurer')?.value, { emitEvent: false });
        } else if (formGroupName === 'insurer') {
          driver.get('driverIsInsurer')?.setValue(true, { emitEvent: true });
          this.ownerIsInsured.setValue(event && driver.get('driverIsOwner')?.value, { emitEvent: false });
        }
      } else {
        if (formGroupName === 'owner') {
          driver.get('driverIsOwner')?.setValue(false, { emitEvent: true });
        } else if (formGroupName === 'insurer') {
          driver.get('driverIsInsurer')?.setValue(false, { emitEvent: true });
        }
      }
    });

    if (formGroupName === 'owner') {
      // Если мы выбрали driverIsOwner
      const findDriverIsOwner = this.drivers.value.find((driver: IDriverForm) => driver.driverIsOwner);
      // Если такой водитель не найден, значит это "Другой"
      this.ownerIsOtherDriver = !findDriverIsOwner;
    } else if (formGroupName === 'insurer') {
      // Если мы выбрали driverIsInsurer
      const findDriverIsInsurer = this.drivers.value.find((driver: IDriverForm) => driver.driverIsInsurer);
      // Если такой водитель не найден, значит это "Другой"
      this.insurerIsOtherDriver = !findDriverIsInsurer;
    }
  }

}
