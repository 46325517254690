import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router, RouterLink, RouterOutlet} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {filter, Subscription} from "rxjs";
import {CommonModule} from "@angular/common";

// Сервисы
import {AppService} from "src/app/shared/services/app.service";
import {FormService} from "src/app/shared/services/form.service";

// Компоненты
import {CompanySwiperComponent} from "src/app/shared/components/company-swiper/company-swiper.component";
import {FaqComponent} from "src/app/shared/components/faq/faq.component";
import {FooterComponent} from "src/app/shared/components/footer/footer.component";
import {finalize} from "rxjs/operators";
import { AppAdvantagesYafuelComponent } from 'landings/osago-yafuel/app/components/app-advantages-yafuel/app-advantages-yafuel.component';


@Component({
  selector: 'app-root',
  templateUrl: '../../osago-yafuel/app/app.component.html',
  standalone: true,
  imports: [
    CommonModule,
    CompanySwiperComponent,
    RouterOutlet,
    AppAdvantagesYafuelComponent,
    FaqComponent,
    FooterComponent,
    RouterLink
  ],
  styleUrls: ['../../osago-yafuel/app/app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  private appService = inject(AppService);
  private router = inject(Router);
  private titleService = inject(Title);
  public formService = inject(FormService);

  // Заголовок сайта
  private title = 'ОСАГО ОНЛАЙН';
  private faviconHref = 'src/assets/landingFiles/favicon.ico';
  private favIcon: HTMLLinkElement | null = document.querySelector('#appIcon');

  // Если главная страница
  public isMainPage = false;

  // Индикатор загрузки
  public isLoading!: boolean;

  // Подписка
  private subscription = new Subscription();

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    console.log('Этот компонент заменили!');
    this.initApp();

    this.subscription.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd)
      ).subscribe(() => {
        this.isMainPage = this.router.url?.split('?')[0] === '/' || this.router.url?.split('?')[0] === '/prolongation';
      })
    );

    this.titleService.setTitle(this.title);
    if (this.favIcon != null) {
      this.favIcon.href = this.faviconHref;
    }
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  // --------------------------------------------------------------------------

  // Инициализация необъходимых данных перед стартом
  private initApp(): void {
    this.isLoading = true;
    this.subscription.add(
      this.appService.loadApp()
        .pipe(finalize(() => this.isLoading = false))
        .subscribe(() => {
          const urlParams = this.appService.getUrlParams();
          if (urlParams !== null
            && urlParams.applicationId
            && urlParams.src
            && urlParams.offerId !== '0') {
            this.router.navigate(['/offers'], {
              queryParamsHandling: 'merge'
            });
          } else {
            if (!this.router.url.includes('/prolongation')) {
              this.router.navigate(['/'], {
                queryParamsHandling: 'merge'
              });
            }
          }
        })
    );
  }

}
