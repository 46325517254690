import {Component, EventEmitter, inject, OnDestroy, OnInit, Output} from '@angular/core';
import {ReactiveFormsModule, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {concatMap, EMPTY, Subject, Subscription} from 'rxjs';

// Сервисы
import {FormService} from "../../../shared/services/form.service";
import {AppService} from "../../../shared/services/app.service";
import {ValidationService} from "../../../shared/services/validation.service";
import {CacheService} from "../../../shared/services/cache.service";

// Перечисления
import {DaData} from "../../../shared/enums/da-data.enum";
import {IDriverForm} from "../../../shared/interface/osago-application.interface";
import {scrollToFirstError} from "../../../shared/functions/scrollToFirstError";
import {ContentService} from "../../../shared/services/content.service";
import {AutocompleteComponent} from "../../../shared/components/autocomplete/autocomplete.component";
import {FormTextFieldComponent} from "../../../shared/components/form-text-field/form-text-field.component";
import {FormDateFieldComponent} from "../../../shared/components/form-date-field/form-date-field.component";
import {NgForOf} from "@angular/common";
import {getFormatDate} from "../../../shared/functions/formatDate";

// Интерфейсы

// Хелперы

@Component({
  selector: 'app-insurer',
  standalone: true,
  templateUrl: './insurer.component.html',
  imports: [
    ReactiveFormsModule,
    AutocompleteComponent,
    FormTextFieldComponent,
    FormDateFieldComponent,
    NgForOf,
  ],
  styleUrls: ['./insurer.component.scss']
})
export class InsurerComponent implements OnInit, OnDestroy {
  @Output() submitInsurerForm: EventEmitter<boolean> = new EventEmitter<boolean>;

  public contentService = inject(ContentService);

  constructor(public readonly formService: FormService,
              public readonly appService: AppService,
              private readonly validationService: ValidationService,
              private readonly cacheService: CacheService) {
  }

  // Форма страхователя
  public formOwner = (this.formService.form.get('owner') as UntypedFormGroup);
  // Форма страховщика
  public formInsurer = (this.formService.form.get('insurer') as UntypedFormGroup);
  // Флаг страхователь является собственником авто
  public ownerIsInsured = (this.formService.form.get('ownerIsInsured') as UntypedFormControl);
  // Policy
  public policy = (this.formService.form.get('policy') as UntypedFormControl);
  // Данные водителей
  public drivers: IDriverForm[] = (this.formService.form.get('drivers') as UntypedFormControl).value;
  // Индикатор загрузки
  public isLoading = false;
  // DaData системные имена
  public daDataSystemName = DaData;
  private destroy$ = new Subject<void>()
  // Подписка на контрол
  private subscription: Subscription = new Subscription();
  private previousValue: any;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    // При заходе на роут, делаем плавный переход вверх
    window.scrollTo({top: 0, behavior: 'smooth'});

    this.subscribeInsurerForm();
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  // --------------------------------------------------------------------------

  private subscribeInsurerForm(): void {
    this.previousValue = this.formInsurer.value; // Инициализация предыдущего значения

    this.subscription.add(
      this.formInsurer.valueChanges?.subscribe(() => {
        if (!this.areValuesEqual(this.previousValue, this.formInsurer.value)) {
          this.checkActiveDriver();
          this.previousValue = this.formInsurer.value;  // Обновление предыдущего значения
        }
      })
    );
  }

  // Метод для сравнения предыдущего и текущего значения формы
  private areValuesEqual(value1: any, value2: any): boolean {
    return JSON.stringify(value1) === JSON.stringify(value2);
  }

  public checkActiveDriver(): void {
    const {value} = this.formInsurer;
    const driverIndex = this.formService.drivers.value?.findIndex((driver: IDriverForm) => {
      return (driver.driverFirstName === value.insurerFirstName
        && driver.driverLastName === value.insurerLastName
        && driver.driverMiddleName === value.insurerMiddleName
        && getFormatDate(driver.driverBirthDate) === getFormatDate(value.insurerBirthDate))
    });
    this.formService.changeDriverIsOwnerOrInsurer(true, 'insurer', driverIndex, true);
  }

  // Отправка формы
  public onSubmit(): void {
    this.formOwner.markAllAsTouched();
    this.formInsurer.markAllAsTouched();
    scrollToFirstError();
    const isValidCarDataPage = this.validationService.validationCarDataForm;
    const isValidDriversPage = this.validationService.validationDriversDataForm;
    const isValidOwnerPage = this.validationService.validationOwnerDataForm;
    const isValidInsurerPage = this.validationService.validationInsurerDataForm;

    if (!isValidCarDataPage || !isValidDriversPage || !isValidOwnerPage || !isValidInsurerPage) {
      return;
    }

    this.submitInsurerForm.emit(true);

    this.subscription.add(
      this.cacheService.saveCache()
        // .pipe(
        //   concatMap(() => this.osagoService.setWidgetStatus(WidgetStatuses.InsuredScreen))
        // )
        .subscribe()
    );
  }

  // Получаем (Фамилия И.О.) для заголовка в контролле ownerIsInsured
  public getDriverTitle(driver: any): string {
    // Возвращаем значение, только в случае если страхователь является собственником авто
    if (driver.driverLastName
      && driver.driverFirstName?.length
      && driver.driverFirstName[0]) {
      const lastName = driver.driverLastName;
      const firstName = driver.driverFirstName[0] + '.';
      const middleName = driver.driverMiddleName?.length && driver.driverMiddleName[0] ? driver.driverMiddleName[0] + '.' : '';
      return lastName + ' ' + firstName + ' ' + middleName;
    } else {
      return '';
    }
  }

  // Выбрать водителя
  public selectDriver(driverIndex?: number): void {
    // Снимаем метки с формы собственника что бы не показывать валидацию после выбора водителя
    // this.formInsurer.markAsUntouched();
    if (driverIndex === -1) {
      this.formService.form.get('ownerIsInsured')?.setValue(false, { emitEvent: true });
    }
    this.formService.changeDriverIsOwnerOrInsurer(true, 'insurer', driverIndex);
  }

  get getActiveDriver(): boolean {
    const findDriverIsInsurer = this.formService.drivers.value.find((driver: IDriverForm) => driver.driverIsInsurer);
    // Если такой водитель не найден, значит это "Другой"
    this.formService.insurerIsOtherDriver = !findDriverIsInsurer;
    return this.formService.insurerIsOtherDriver;
  }

}
