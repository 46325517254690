@if (alfaIdService.nameFromAlfaId && !alfaIdService.isLoading) {
  <div class="nameFromAlfaId">
    {{ alfaIdService.nameFromAlfaId }}
  </div>
} @else {
  <button type="button"
          class="btn btn-primary alfaIdBtn"
          (click)="getAlfaIdUrl()"
          [disabled]="alfaIdService.isLoading || isLoadApp">
    @if (alfaIdService.isLoading || isLoadApp) {
      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
    }
    @if (!alfaIdService.isLoading && !isLoadApp) {
      <span>Войти с <img src="assets/landingFiles/images/alfaId.svg" alt="Alfa" loading="lazy"> ID</span>
    }
  </button>
}
