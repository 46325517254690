import {Component, inject, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {OsagoService} from "../../shared/services/osago.service";
import {Subscription} from "rxjs";
import {finalize} from "rxjs/operators";
import {YandexMetrikaService} from "../../shared/services/yandex-metrika.service";
import {YandexMetrikaGoalsEnum} from "../../shared/enums/yandex-metrika-goals.enum";
import {PolicyDataComponent} from "../../shared/components/policy-data/policy-data.component";
import {ErrorPaymentLinkComponent} from "./error-payment-link/error-payment-link.component";
import {TimerComponent} from "../../shared/components/timer/timer.component";
import {OfferItemComponent} from "../offers/offer-item/offer-item.component";
import {SelectedOfferComponent} from "../../shared/components/selected-offer/selected-offer.component";
import {OfferPlaceholderComponent} from "../../shared/components/offer-placeholder/offer-placeholder.component";
import {UpsalesComponent} from "../offers/offer-item/upsales/upsales.component";
import {ActivatedRoute} from "@angular/router";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ModalService} from "../../shared/services/modal.service";
import {ROUTES} from "../../shared/enums/routes.enum";
import {FormService} from "../../shared/services/form.service";
import {AppService} from "../../shared/services/app.service";
import {CacheService} from "../../shared/services/cache.service";
import {NavigationService} from "../../shared/services/navigation.service";
import {environment} from "@environment/environment";

@Component({
  selector: 'app-create-policy',
  standalone: true,
  templateUrl: './create-policy.component.html',
  imports: [
    PolicyDataComponent,
    ErrorPaymentLinkComponent,
    TimerComponent,
    OfferItemComponent,
    SelectedOfferComponent,
    OfferPlaceholderComponent,
    UpsalesComponent
  ],
  providers: [ModalService, BsModalService],
  styleUrl: './create-policy.component.scss'
})
export class CreatePolicyComponent implements OnInit, OnDestroy {
  @ViewChild('templateSuccessPaymentLink') templateRef!: TemplateRef<any>;
  public osagoService = inject(OsagoService);
  public ym = inject(YandexMetrikaService);
  private readonly route = inject(ActivatedRoute);
  public modalService = inject(BsModalService);
  private formService = inject(FormService);
  private appService = inject(AppService);
  private cacheService = inject(CacheService);
  private navigationService = inject(NavigationService);

  // Индикатор загрузки
  public isLoading = false;
  // Подписка
  private subscription = new Subscription();
  // Статус получения ссылки на оплату
  public isPaymentLinkResult = true;
  // Параметр fromFastProlongation
  public fromFastProlongation: boolean = false;
  modalRef?: BsModalRef;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.PaymentLinkPage);

    const licensePlate = this.formService.licenseFormGroup?.get('licensePlate')?.value;
    const osagoProfileData = this.cacheService.cacheData?.osagoPolicies.find(policy => policy.license === licensePlate)?.osago;
    const urlParams = this.appService.getUrlParams();

    const useUpsales = this.osagoService.selectedOffer?.upsales?.some(upsale => upsale.isSelected) || false;

    if (osagoProfileData?.selectedOffer
      && osagoProfileData?.paymentLink
      && (urlParams.agentId || urlParams.src === 'DataCheckingBeforePaymentSms')
      && this.osagoService.selectedOffer?.insurerType === osagoProfileData.selectedOffer?.insurerType) {
      if (!this.osagoService.selectedOffer) {
        this.osagoService.selectedOffer = osagoProfileData.selectedOffer;
      }
      if (!this.osagoService.paymentLink && !this.osagoService.offers?.length) {
        this.osagoService.paymentLink = osagoProfileData.paymentLink;
      } else if (this.osagoService.offers?.length) {
        this.checkAndCallPaymentLink();
      }
    } else {
      if (this.fromFastProlongation
        || ((this.osagoService.selectedOffer?.insurerType === 'MAKS' || this.osagoService.selectedOffer?.insurerType === 'Rosgosstrah')
          && environment.partnerSystemName === 'alfa' && !useUpsales)) {

      } else {
        this.checkAndCallPaymentLink();
      }
    }
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
    this.osagoService.paymentLink = '';
  }
  // --------------------------------------------------------------------------

  private checkAndCallPaymentLink(): void {
    this.fromFastProlongation = this.route.snapshot.queryParamMap.get('fromFastProlongation') === 'true';
    if (!this.fromFastProlongation) {
      this.getPaymentLink();
    }
  }

  // Получаем ссылку на оплату
  public getPaymentLink(paymentMethod: string = 'paymentCardMethod'): void {
    this.osagoService.isLoadingFromCreatePolicy = true;
    this.subscription.add(
      this.osagoService.getPaymentLinkRepeatedly('', paymentMethod)
        .subscribe({
          next: (res) => {
            if (res?.result) {
              this.osagoService.selectedOffer = res?.value?.offer;
              this.osagoService.paymentLink = res?.value?.osagoPaymentLink;
              if (this.fromFastProlongation && res.value?.status === 'Received' && res.value?.osagoPaymentLink) {
                this.modalRef = this.modalService.show(this.templateRef, {
                  class: 'modal-lg modal-dialog-centered'
                });
              }
            } else {
              // Если не удалось получить ссылку на оплату, прекращаем повторные запросы и обновляем список офферов
              if (environment.partnerSystemName !== 'rgs-iframe') {
                this.updateOffersList();
              }
              this.osagoService.isLoadingFromCreatePolicy = false;
            }
            this.isPaymentLinkResult = res?.result;
            if (!res?.result) {
              this.osagoService.isLoadingFromCreatePolicy = false;
            }
          },
          error: () => {
            this.osagoService.isLoadingFromCreatePolicy = false;
          }
        })
    );
  }

  // Снова берем список офферов, но уже без текущего
  private updateOffersList(): void {
    this.isLoading = true;
    this.subscription.add(
      this.osagoService.updateOffersList()
        .pipe(
          finalize(() => this.isLoading = false)
        )
        .subscribe((offers) => {
      })
    );
  }

  protected readonly environment = environment;
}
