import {
  Component,
  ElementRef, inject,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {CacheService} from "../../../shared/services/cache.service";
import {FormService} from "../../../shared/services/form.service";
import {AppService} from "../../../shared/services/app.service";
import {setFormDataFromCache} from "../../../shared/functions/setFormDataFromCache";
import {NavigationService} from "../../../shared/services/navigation.service";
import {concatMap, Subscription} from "rxjs";
import {FormGroup} from "@angular/forms";
import {IDriverForm} from "../../../shared/interface/osago-application.interface";
import {finalize, map} from "rxjs/operators";
import {SwiperDirective} from "../../../shared/directives/swiper.directive";
import {TooltipModule} from "ngx-bootstrap/tooltip";
import Swiper from "swiper";
import {JsonPipe, NgForOf, NgIf} from "@angular/common";
import {OsagoService} from "../../../shared/services/osago.service";
import {WidgetStatuses} from "../../../shared/enums/widgetStatuses.enum";
import {AccordionService} from "../../../shared/services/accordion.service";

@Component({
  selector: 'app-cache-list',
  templateUrl: './cache-list.component.html',
  standalone: true,
  imports: [
    SwiperDirective,
    TooltipModule,
    NgForOf,
    NgIf
  ],
  styleUrls: ['./cache-list.component.scss']
})
export class CacheListComponent implements OnInit, OnDestroy {
  public appService = inject(AppService);
  public cacheService = inject(CacheService);
  public osagoService = inject(OsagoService);
  private el = inject(ElementRef);
  private formService = inject(FormService);
  private navigation = inject(NavigationService);

  // Свайпер
  private swiper!: Swiper;

  // Номер авто примененный к форме
  public licensePlate = '';

  // Индикатор загрузки
  public isLoading = false;

  // Подписка
  private subscription: Subscription = new Subscription();

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.appService.isShowBtnAlfaId = true;
    this.swiperInit();
    this.licensePlate = this.formService.form.get('carData')?.get('licensePlate')?.value;
  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
  // --------------------------------------------------------------------------

  // Инициализация слайдера
  private swiperInit(): void {
    this.swiper = new Swiper(this.el.nativeElement.querySelector('.swiper-container'), {
      slidesPerView: 'auto',
      spaceBetween: 10,
      freeMode: true,
      loop: false,
    });
  }

  // Выбрали анкету из кэша
  public changeAppFromCache(cacheData: any): void {
    this.isLoading = true;
    cacheData.isLoading = true;
    this.formService.subscription = new Subscription();
    this.formService.subscriptionDriver = new Subscription();
    this.formService.drivers.clear();
    const selectedOsagoCacheData = cacheData.osago;

    this.subscription.add(
      this.appService.createApplication()
        .pipe(
          concatMap(() => this.osagoService.setWidgetStatus(WidgetStatuses.CalculateFromCache)),
          map(() => this.initDriversFormArray(cacheData.osago.drivers)),
          concatMap(() => this.appService.getCarModels(selectedOsagoCacheData.carData.brandId)),
          finalize(() =>  {
            setFormDataFromCache(selectedOsagoCacheData, this.formService.form)
              .then(r => {
              this.isLoading = false;
              this.appService.isShowBtnAlfaId = false;
              cacheData.isLoading = false;
              this.formService.form.get('carData')?.updateValueAndValidity();
              this.formService.form.get('carData')?.markAllAsTouched();
              this.navigation.navigate('form');
            }).catch(e => {
              this.isLoading = false;
              cacheData.isLoading = false;
              console.log(e);
            });
          })
        )
        .subscribe(() => {

        })
    );

  }

  // Задаем количесвто водителей исходя из данных полученных в кэше
  private initDriversFormArray(drivers: any): void {
    // Добавялем водителей из кэша
    if (drivers && drivers.length) {
      // Удаляем всех водителей
      for (let i = 0; i <= this.formService.drivers.length; i++) {
        this.formService.removeDriver(i);
      }
      // this.formService.drivers.push(this.formService.newDriver());
      drivers.forEach((driver: IDriverForm, index: number) => {
        this.formService.drivers.push(this.formService.newDriver(driver));
        this.formService.subscribeFormDriver(this.formService.drivers.at(index) as FormGroup);
      })
    } else {
      this.formService.addDriver();
    }
  }

}
