import {Component, Input, OnInit} from '@angular/core';
import {PopoverModule} from "ngx-bootstrap/popover";

@Component({
  selector: 'app-price',
  standalone: true,
  imports: [
    PopoverModule
  ],
  templateUrl: './price.component.html',
  styleUrl: './price.component.scss'
})
export class PriceComponent implements OnInit {
  // Цена
  @Input() premiumAmount!: number;
  // Цена
  @Input() priceTitle: string = 'Цена';
  // Цена сервиса "подели"
  @Input() installmentFirstPay!: string;
  public installmentFirstPayFormatString = '';

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.installmentFirstPayFormatString = this.getInstallmentFirstPay;
  }
  // --------------------------------------------------------------------------

  //  Получаем цену в формате без остатка, округленную в большую сторону
  get getPremiumAmount(): string {
    const roundedPremiumAmount = Math.ceil(this.premiumAmount);
    return roundedPremiumAmount.toLocaleString('ru-RU', {
      maximumFractionDigits: 0,
      useGrouping: true
    });
  }

  //  Получаем цены от сервиса "подели" в формате без остатка, округленную в большую сторону
  get getInstallmentFirstPay(): string {
    const roundedPremiumAmount = Math.ceil(Number(this.installmentFirstPay));
    return roundedPremiumAmount.toLocaleString('ru-RU', {
      maximumFractionDigits: 0,
      useGrouping: true
    });
  }
}
