import {Component, OnDestroy, OnInit} from '@angular/core';
import {PriceCalculatorService} from "../../../../shared/services/priceCalculator.service";
import {ContentService} from "../../../../shared/services/content.service";
import {filter, Subscription} from 'rxjs';
import {FormService} from "../../../../shared/services/form.service";
import {IPodeliPriceInterface} from "../../../../shared/interface/podeli-price.interface";
import {debounceTime, distinctUntilChanged, finalize} from "rxjs/operators";
import {CurrencyRuPipe} from "../../../../shared/pipes";

@Component({
  selector: 'app-pre-price-calculator',
  standalone: true,
  imports: [
    CurrencyRuPipe
  ],
  templateUrl: './pre-price-calculator.component.html',
  styleUrls: ['./pre-price-calculator.component.scss']
})
export class PrePriceCalculatorComponent implements OnInit, OnDestroy {

  constructor(private readonly priceCalculatorService: PriceCalculatorService,
              public readonly contentService: ContentService,
              private readonly formService: FormService) {
  }
  // Цены
  public price!: IPodeliPriceInterface;
  // Индикатор загрузки
  public isLoading = false;
  // Подписка
  private subscription = new Subscription();

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.getPodeliPrice();
    const licensePlate = this.formService.form.get('carData')?.get('licensePlate')!;
    const horsePower = this.formService.form.get('carData')?.get('horsePower')!;
    this.subscription.add(
      licensePlate.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(() => licensePlate.valid)
      ).subscribe(() => this.getPodeliPrice())
    );
    this.subscription.add(
      horsePower.valueChanges.pipe(
        debounceTime(500),
        distinctUntilChanged(),
        filter(() => horsePower.valid)
      ).subscribe(() => this.getPodeliPrice())
    );
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  // Получаем данные подели сервиса
  public getPodeliPrice(): void {
    this.isLoading = true;
    const license = this.formService.form.get('carData')?.get('licensePlate')?.value || '';
    const region = license.substr(6);
    const horsePower = this.formService.form.get('carData')?.get('horsePower')?.value;
    const horsePowerKw = this.formService.form.get('carData')?.get('horsePowerKw')?.value;
    const hasNoLicensePlate = this.formService.form.get('carData')?.get('hasNoLicensePlate')?.value;
    if (horsePower) {
      this.subscription.add(
        this.priceCalculatorService.getPodeliPrice(horsePower, hasNoLicensePlate, region, horsePowerKw)
          .pipe(
            finalize(() => this.isLoading = false)
          )
          .subscribe((price) => {
            this.price = price.value;
          }));
    }
  }

}
