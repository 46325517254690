import {Component, inject, Input} from '@angular/core';
import {FormService} from "../../../../services/form.service";
import {NavigationService} from "../../../../services/navigation.service";

@Component({
  selector: 'app-edit-info-legal-insurer',
  standalone: true,
  imports: [],
  templateUrl: './edit-info-legal-insurer.component.html',
  styleUrl: './edit-info-legal-insurer.component.scss'
})
export class EditInfoLegalInsurerComponent {
  // Параметр fromFastProlongation
  @Input() fromFastProlongation: boolean = false;

  private formService = inject(FormService);
  private navigationService = inject(NavigationService);

  // Данные формы
  public formData = this.formService.form.value;

  // Переход по роуту
  public navigate(route: string): void {
    this.navigationService.navigate(route);
  }
}
