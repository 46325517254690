<div class="test-block">
  <div class="btn-group" dropdown>
    <button id="button-basic" dropdownToggle type="button" class="btn btn-primary btn-sm dropdown-toggle"
            aria-controls="dropdown-basic">
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Тестовые функции&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
      <span class="caret"></span>
    </button>
    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
        role="menu" aria-labelledby="button-basic">
      <li role="menuitem"><a class="dropdown-item" (click)="generateCarNumber()">
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-shuffle" viewBox="0 0 16 16">
          <path fill-rule="evenodd" d="M0 3.5A.5.5 0 0 1 .5 3H1c2.202 0 3.827 1.24 4.874 2.418.49.552.865 1.102 1.126 1.532.26-.43.636-.98 1.126-1.532C9.173 4.24 10.798 3 13 3v1c-1.798 0-3.173 1.01-4.126 2.082A9.6 9.6 0 0 0 7.556 8a9.6 9.6 0 0 0 1.317 1.918C9.828 10.99 11.204 12 13 12v1c-2.202 0-3.827-1.24-4.874-2.418A10.6 10.6 0 0 1 7 9.05c-.26.43-.636.98-1.126 1.532C4.827 11.76 3.202 13 1 13H.5a.5.5 0 0 1 0-1H1c1.798 0 3.173-1.01 4.126-2.082A9.6 9.6 0 0 0 6.444 8a9.6 9.6 0 0 0-1.317-1.918C4.172 5.01 2.796 4 1 4H.5a.5.5 0 0 1-.5-.5"/>
          <path d="M13 5.466V1.534a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192m0 9v-3.932a.25.25 0 0 1 .41-.192l2.36 1.966c.12.1.12.284 0 .384l-2.36 1.966a.25.25 0 0 1-.41-.192"/>
        </svg>
        Сгенерировать номер авто</a></li>
      <li role="menuitem" dropdown triggers="mouseover" placement="right" container="body">
        <a dropdownToggle class="dropdown-item dropdown-toggle"
           (click)="false">Заполнить данными водителя <span class="caret"></span></a>
        <ul *dropdownMenu class="dropdown-menu" role="menu">
          <li class="p-3 pt-0 pb-0 text-primary">Без данных паспорта и адреса</li>
          <li role="menuitem"><a class="dropdown-item" (click)="addDriver(1)">Иванов</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="addDriver(2)">Петров</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="addDriver(3)">Сидоров</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="addDriver(4)">Михайлов</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="addDriver(5)">Соколов</a></li>
          <li class="divider dropdown-divider"></li>
          <li class="p-3 pt-0 pb-0 text-primary">С данными пасопрта и адреса</li>
          <li role="menuitem"><a class="dropdown-item" (click)="addDriver(1)">Иванов</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="addDriver(2)">Петров</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="addDriver(3)">Сидоров</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="addDriver(4)">Михайлов</a></li>
          <li role="menuitem"><a class="dropdown-item" (click)="addDriver(5)">Соколов</a></li>
        </ul>
      </li>

<!--      <li role="menuitem"><a class="dropdown-item" href="#">Another action</a></li>-->
<!--      <li role="menuitem"><a class="dropdown-item" href="#">Something else here</a></li>-->
<!--      <li class="divider dropdown-divider"></li>-->
<!--      <li role="menuitem"><a class="dropdown-item" href="#">Separated link</a>-->
<!--      </li>-->
    </ul>
  </div>
  <button class="btn btn-primary btn-sm" type="button" routerLink="/prolongation" queryParamsHandling="merge">Быстрая пролонгация</button>
  <button class="btn btn-primary btn-sm" type="button" (click)="generatePdf()">Генерация PDF</button>
</div>
