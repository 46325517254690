<div class="card h-auto shadow-0 border-0 p-0 info-filter-block custom-rounded mb-2 mb-sm-2">
  <div class="card-body p-0">
    <div class="row p-3 p-sm-4 align-items-center"
         (click)="osagoService.isShowFormStartDatePolicy = !osagoService.isShowFormStartDatePolicy"
         [attr.aria-expanded]="osagoService.isShowFormStartDatePolicy" aria-controls="collapseBasic">
      <div class="col-auto">
        <img ngSrc="./assets/images/offers-icons/calendar.svg" alt="" height="48" width="48">
      </div>
      <div class="col px-0 px-sm-2 d-flex flex-column justify-content-center align-items-start text-wrap">
        <div class="main-title-item mb-0 text-wrap">Полис с {{formService.form.get('policyStartDate')?.value}} (на&nbsp;1&nbsp;год)</div>
        <div class="second-title-item text-wrap">
          Вы можете изменить дату начала действия полиса
        </div>
      </div>
      <div class="col-auto">
        <div class="d-flex justify-content-between">
          <a class="btn-edit">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M22.853 1.14795C22.1732 0.469151 21.2517 0.0878906 20.291 0.0878906C19.3303 0.0878906 18.4089 0.469151 17.729 1.14795L1.46502 17.412C0.999266 17.8751 0.629977 18.426 0.378513 19.0327C0.127049 19.6395 -0.00159798 20.2901 1.49812e-05 20.947V23C1.49812e-05 23.2652 0.105372 23.5195 0.292908 23.7071C0.480445 23.8946 0.734799 24 1.00002 24H3.05301C3.70978 24.0018 4.36038 23.8734 4.96717 23.6221C5.57396 23.3708 6.12487 23.0016 6.58801 22.536L22.853 6.27095C23.5315 5.59115 23.9126 4.66992 23.9126 3.70945C23.9126 2.74899 23.5315 1.82776 22.853 1.14795ZM5.17401 21.122C4.61002 21.6822 3.84798 21.9977 3.05301 22H2.00002V20.947C1.999 20.5529 2.07617 20.1625 2.22705 19.7984C2.37793 19.4343 2.59953 19.1038 2.87901 18.826L15.222 6.48295L17.522 8.78295L5.17401 21.122ZM21.438 4.85695L18.932 7.36395L16.632 5.06895L19.139 2.56195C19.29 2.41126 19.4693 2.29179 19.6664 2.21036C19.8636 2.12893 20.0749 2.08714 20.2883 2.08738C20.5016 2.08761 20.7128 2.12986 20.9098 2.21172C21.1069 2.29357 21.2858 2.41343 21.4365 2.56445C21.5872 2.71547 21.7067 2.8947 21.7881 3.09189C21.8695 3.28908 21.9113 3.50038 21.9111 3.71372C21.9109 3.92706 21.8686 4.13827 21.7868 4.33529C21.7049 4.5323 21.585 4.71126 21.434 4.86195L21.438 4.85695Z"
                fill="#98A2B3"/>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <div [formGroup]="form" id="collapseBasic" class="px-3 px-sm-4" [collapse]="!osagoService.isShowFormStartDatePolicy" [isAnimated]="true">

      <app-form-policy-alert></app-form-policy-alert>

      <app-form-date-field
        [isShowCalendar]="true"
        formControlName="policyStartDate"
        [isReadonly]="isLoading"></app-form-date-field>

      <div class="row py-3 py-sm-4">
        <div class="col text-end">
          <button class="btn btn-outline-primary btn-lg" (click)="savePolicyStartDate()">Сохранить</button>
        </div>
      </div>

    </div>

  </div>
</div>
