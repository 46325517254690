<form [formGroup]="formContacts" (ngSubmit)="onSubmit()" novalidate>
  <div class="contacts">

    <div class="alert alert-primary mb-3 mb-sm-4">
      Телефон нужен страховой компании для подтверждения расчёта ОСАГО, а на указанную почту мы вышлем готовый полис
    </div>

    <div class="row">
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-form-text-field formControlName="email" [isReadonly]="isLoading"></app-form-text-field>
      </div>
      <div class="col-12 col-md-4 col-xxl-4 mb-3 mb-sm-4">
        <app-form-text-field formControlName="phone" [isReadonly]="isLoading"></app-form-text-field>
      </div>
    </div>

    <div class="row actions justify-content-end">
      <div class="col-12 col-sm-auto mb-3 mb-sm-4">
        <button type="submit" class="btn btn-primary btn-lg w-100" [disabled]="isLoading || formService.privacyLicenseFormControl.invalid">
          @if (isLoading) {
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
          } @else {
            <span>Продолжить</span>
          }
        </button>
      </div>
    </div>

  </div>
</form>
