import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from "rxjs";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {FormService} from "../../../shared/services/form.service";
import {ContentService} from "../../../shared/services/content.service";
import {OfflineFormService} from "../../../shared/services/offline-form.service";
import {scrollToFirstError} from "../../../shared/functions/scrollToFirstError";
import {DaData} from "../../../shared/enums/da-data.enum";
import {finalize} from "rxjs/operators";
import {NavigationService} from "../../../shared/services/navigation.service";
import {ROUTES} from "../../../shared/enums/routes.enum";
import {YandexMetrikaGoalsEnum} from "../../../shared/enums/yandex-metrika-goals.enum";
import {YandexMetrikaService} from "../../../shared/services/yandex-metrika.service";
import {AutocompleteComponent} from "../../../shared/components/autocomplete/autocomplete.component";
import {FormTextFieldComponent} from "../../../shared/components/form-text-field/form-text-field.component";
import {FormSwitchComponent} from "../../../shared/components/form-switch/form-switch.component";

@Component({
  selector: 'app-autopark',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    AutocompleteComponent,
    FormTextFieldComponent,
    FormSwitchComponent
  ],
  templateUrl: './autopark.component.html',
  styleUrl: './autopark.component.scss'
})
export class AutoparkComponent implements OnInit, OnDestroy {

  public formService = inject(FormService);
  public offlineFormService = inject(OfflineFormService);
  public contentService = inject(ContentService);
  public navigationService = inject(NavigationService);
  public ym = inject(YandexMetrikaService);

  // Индикатор загруки
  public isLoading = false;
  // Формат
  public form: FormGroup = this.offlineFormService.form as FormGroup;
  // Подписка
  public subscription = new Subscription();

  protected readonly daDataSystemName = DaData;

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.OfflineFormAutopark);
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  // --------------------------------------------------------------------------

  // Нажали "Отправить заявку"
  public onSubmit(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      scrollToFirstError();
      return;
    }

    this.isLoading = true;
    this.subscription.add(
      this.offlineFormService.createApplicationAndSendOfflineForm()
        .pipe(finalize(() => {
          this.isLoading = false;
          this.formService.isAutopark.setValue(false);
          this.navigationService.navigate(ROUTES.Success, { isOffline: true })
        }))
        .subscribe()
    );
  }
}
