// Получаем название бренда авто по ID
export function getCarBrandNameById(brandId?: number, brandsList: any[] = []): string {
  const brand = brandsList
    .find(brands => brands.brandId === brandId);
  return brand && brand.brandName ? brand.brandName : '';
}

// Получаем название модели авто по ID
export function getCarModelNameByModelId(modelId?: number, modelList: any[] = []): string {
  const model = modelList
    .find(model => model.modelId === modelId);
  return model && model.modelName ? model.modelName : '';
}

export function getModelCategoryNameByModelId(modelId?: number, modelList: any[] = []): string {
  const model = modelList.find(x => x.modelId === modelId);
  if (model) {
    const categoryMessages: { [key: number]: string } = {
      1: 'Мотоцикл (A)',
      2: 'Легковой автомобиль (B)',
      3: 'Грузовой автомобиль (C)',
      4: 'Автобус (D)',
      9: 'Спецтехника'
    };

    const categoryMessage = categoryMessages[model.carCategoryId];

    if (categoryMessage) {
      return categoryMessage;
    }
  }
  return '';
}

export function getAlertModelId(modelId?: number, modelList: any[] = []): string {
  const model = modelList.find(x => x.modelId === modelId);

  if (model) {
    const categoryMessages: { [key: number]: string } = {
      1: 'Мотоцикл (A)',
      2: 'Легковой автомобиль (B)',
      3: 'Грузовой автомобиль (C)',
      4: 'Автобус (D)',
      9: 'Спецтехника'
    };

    const categoryMessage = categoryMessages[model.carCategoryId];

    if (categoryMessage) {
      return `Обратите внимание, что Вы делаете расчёт на ТС категории <strong>${categoryMessage}</strong>.</br>Если Ваше ТС иной категории, просьба обратиться в страховые компании напрямую.`;
    }
  }
  return '';
}
