import {Component, EventEmitter, inject, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Subject, Subscription, timer} from 'rxjs';
import {map, takeUntil} from 'rxjs/operators';
import {TimerService} from "../../services/timer.service";

@Component({
  selector: 'app-timer',
  standalone: true,
  templateUrl: './timer.component.html',
  styleUrls: ['./timer.component.scss']
})
export class TimerComponent implements OnInit, OnDestroy {
  @Output() changeTimerValue: EventEmitter<number> = new EventEmitter<number>();
  // Индикатор загрузки
  @Input() isLoading = false;
  // Начальное время таймера в минутах и секундах
  @Input() min = 0;
  @Input() sec = 30;
  // Текс таймера
  @Input() timerText = '';

  public timerService = inject(TimerService);

  constructor() {
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    this.timerService.totalTime = (this.min * 60 + this.sec) * 1000; // конвертация в миллисекунды
    this.timerService.timeLeft = this.timerService.totalTime; // Устанавливаем оставшееся время равным общему времени
    this.timerService.startTimer();
  }

  public ngOnDestroy(): void {
    this.timerService.stopTimer();
    this.timerService.countdown?.unsubscribe();
  }

  // --------------------------------------------------------------------------



}
