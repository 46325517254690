import {Component, EventEmitter, inject, OnDestroy, OnInit, Output} from '@angular/core';
import {ReactiveFormsModule, UntypedFormGroup} from '@angular/forms';
import {concatMap, EMPTY, filter, of, Subscription} from 'rxjs';
import {finalize, take} from "rxjs/operators";
import {ActivatedRoute} from "@angular/router";

// Сервисы
import {FormService} from "../../../shared/services/form.service";
import {AppService} from "../../../shared/services/app.service";
import {NavigationService} from "../../../shared/services/navigation.service";
import {CacheService} from "../../../shared/services/cache.service";
import {ProlongationFormService} from "../../../shared/services/prolongationForm.service";
import {OsagoService} from "../../../shared/services/osago.service";

// Перечисления
import {ROUTES} from '../../../shared/enums/routes.enum';

// Компоненты
import {FormTextFieldComponent} from "../../../shared/components/form-text-field/form-text-field.component";

@Component({
  selector: 'app-contacts',
  standalone: true,
  templateUrl: './contacts.component.html',
  imports: [
    FormTextFieldComponent,
    ReactiveFormsModule
  ],
  styleUrls: ['./contacts.component.scss']
})
export class ContactsComponent implements OnInit, OnDestroy {
  @Output() submitContactsForm: EventEmitter<boolean> = new EventEmitter<boolean>;

  private readonly route = inject(ActivatedRoute);
  private readonly prolongationFormService = inject(ProlongationFormService);

  public formService = inject(FormService);

  constructor(public readonly appService: AppService,
              private readonly navigationService: NavigationService,
              private readonly cacheService: CacheService,
              private readonly osagoService: OsagoService) {
  }

  // Форма контактов
  public formContacts = (this.formService.form.get('contacts') as UntypedFormGroup);
  // Индикатор загрузки
  public isLoading = false;
  // Подписка на контрол
  private subscription: Subscription = new Subscription();

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    // При заходе на роут, делаем плавный переход вверх
    window.scrollTo({top: 0, behavior: 'smooth'});
  }

  // Уничтожение
  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  // --------------------------------------------------------------------------

  // Отправка формы
  public onSubmit(): void {
    console.log(this.formService.form);
    this.formContacts.markAllAsTouched();
    this.formService.form.updateValueAndValidity();

    if (this.formService.form.invalid) {
      return;
    }

    this.isLoading = true;
    this.osagoService.offers = [];
    this.osagoService.selectedOffer = null;

    const fromFastProlongation = this.route.snapshot.queryParamMap.get('fromFastProlongation') === 'true';

    this.subscription.add(
      this.cacheService.saveCache()
        .pipe(
          // concatMap(() => this.osagoService.setWidgetStatus(WidgetStatuses.ContactsScreen)),
          concatMap(() => fromFastProlongation ? of(EMPTY) : of(null)),
          filter(() => fromFastProlongation),
          concatMap(() => this.appService.createApplication()),
          concatMap(() => this.osagoService.sendOsagoApplication()),
          concatMap(() => this.osagoService.sendToInsurersGetOffers('Alfa')),
          concatMap(() => {
            const offers = this.osagoService.offers;
            const selectedOffer = offers.find(offer => offer.insurerType === 'Alfa');
            if (selectedOffer) {
              this.osagoService.selectedOffer = selectedOffer;
            }
            return of(selectedOffer);
          }),
          filter((selectedOffer) => !!selectedOffer),
          take(1),  // Прекращаем после первого валидного значения (если selectedOffer найден)
          concatMap(() => this.osagoService.selectOffer()),
          finalize(() => {
            this.isLoading = false;
            if (fromFastProlongation && this.osagoService.selectedOffer) {
              this.navigationService.navigate('create-policy', { fromFastProlongation: true });
            } else {
              this.navigationService.navigate(ROUTES.Offers);
            }
          })
        )
        .subscribe(() => {

        })
    );
  }

}
