export enum APP_STATUS {

    // Получена новая заявка
    New = 'New',

    // Анкета отображена клиенту
    WidgetDisplayed = 'WidgetDisplayed',

    // Анкета заполнена
    Filled = 'Filled',

    // Запрошена СМС для авторизации
    AuthorizationRequest = 'AuthorizationRequest',

    // Телефон подтвержден
    Authorized = 'Authorized',

    // Запрос отправлен в страховые компании
    SentToInsurers = 'SentToInsurers',

    // Отказ всех страховых
    RejectAllInsurers = 'RejectAllInsurers',

    // Получено одобрение
    OfferRecived = 'OfferRecived',

    // Выбрано предложение
    OfferChosen = 'OfferChosen',

    // Получена оплата от партнера
    PaidFromPartner = 'PaidFromPartner',

    // Получена ссылка на оплату
    PaymentLinkReceived = 'PaymentLinkReceived',

    // В работе оператора КЦ
    WorkInCallCenter = 'WorkInCallCenter',

    // Клиент будет оформлять позже
    KaskoHold = 'KaskoHold',

    // Отказ клиента на этапе продажи в КЦ
    RejectInCallCenter = 'RejectInCallCenter',

    // Клиент отправлен на осмотр авто
    KaskoCarInspection = 'KaskoCarInspection',

    // Оплачено
    Paid = 'Paid',

    // Полис вступил в силу
    PolicyIsActive = 'PolicyIsActive',

    // Передана в бухгалтерскую систему (1С)
    SentToAccountingSystem = 'SentToAccountingSystem',

}
