import {Component, inject, OnDestroy, OnInit} from '@angular/core';
import {OsagoService} from "../../shared/services/osago.service";
import {NavigationService} from "../../shared/services/navigation.service";
import {YandexMetrikaService} from "../../shared/services/yandex-metrika.service";
import {YandexMetrikaGoalsEnum} from "../../shared/enums/yandex-metrika-goals.enum";
import {PolicyDataComponent} from "../../shared/components/policy-data/policy-data.component";
import {JsonPipe} from "@angular/common";
import {ROUTES} from "../../shared/enums/routes.enum";
import {FormService} from "../../shared/services/form.service";
import {finalize} from "rxjs/operators";
import {concatMap, filter, Subscription} from "rxjs";
import {AppService} from "../../shared/services/app.service";
import {CacheService} from "../../shared/services/cache.service";
import {IOffer} from "../../shared/interface/offers.interface";
import {LoggingService} from "../../shared/services/loggingService";

@Component({
  selector: 'app-data-checking',
  standalone: true,
  templateUrl: './data-checking.component.html',
  imports: [
    PolicyDataComponent,
    JsonPipe
  ],
  styleUrls: ['./data-checking.component.scss']
})
export class DataCheckingComponent implements OnInit, OnDestroy {

  private formService = inject(FormService);
  private appService = inject(AppService);
  private cacheService = inject(CacheService);
  public osagoService = inject(OsagoService);

  // Подписка
  private subscription: Subscription = new Subscription();
  // Индикатор загрузки
  public isLoading = false;
  // Если в параметре src указан src=DataCheckingBeforePaymentSms
  public isDataCheckingBeforePaymentSms = false;

  constructor(private readonly navigationService: NavigationService,
              private readonly ym: YandexMetrikaService) {
    this.ym.onYandexReachGoal(YandexMetrikaGoalsEnum.OfferSelectionPage);
    this.osagoService.isShowEditInfo = true;
  }

  // --------------------------------------------------------------------------
  // Инициализация
  public ngOnInit(): void {
    if (this.formService.form.invalid) {
      this.navigationService.navigate(ROUTES.MainPage)
    } else {
      const urlParams = this.appService.getUrlParams();
      const licensePlate = this.formService.licenseFormGroup?.get('licensePlate')?.value;
      const osagoProfileData = this.cacheService.cacheData?.osagoPolicies.find(policy => policy.license === licensePlate)?.osago;

      if (osagoProfileData?.selectedOffer && ((urlParams.agentId) || urlParams.src === 'DataCheckingBeforePaymentSms')) {
        if (!this.osagoService.selectedOffer) {
          this.osagoService.selectedOffer = osagoProfileData.selectedOffer;
        }
        if (!this.osagoService.paymentLink) {
          this.osagoService.paymentLink = osagoProfileData.paymentLink;
        }
        this.isDataCheckingBeforePaymentSms = urlParams.src === 'DataCheckingBeforePaymentSms';

        if (this.isDataCheckingBeforePaymentSms) {
          this.subscription.add(
            this.osagoService.setReturnChannel()
              .subscribe(() => {
              })
          );
        }
      }
    }
  }

  // Уничтожение
  public ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    this.osagoService.isShowEditInfo = false;
  }
  // --------------------------------------------------------------------------

  // Переход на страницу офферов
  public navigateToOffersPage(): void {
    this.navigationService.navigate('offers');
  }
  // Переход на страницу создания полиса
  public navigateToCreatePolicy(): void {
    this.navigationService.navigate('create-policy');
  }

}
